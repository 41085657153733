import React, { FunctionComponent, ReactNode, useCallback, useEffect, useRef } from 'react';
import './index.scss';

interface WhiteCardProps {
    padding?: number;
    paddingTop?: number;
    paddingBottom?: number;
    paddingRight?: number;
    paddingLeft?: number;
    width?: number;
    heigth?: number;
    className?: string;
    style?: React.CSSProperties;
    greenBorder?: boolean;
    onScroll?: (progress: number) => void;
    children?: ReactNode;
}

const WhiteCard: FunctionComponent<WhiteCardProps> = ({
    padding,
    paddingBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    width,
    heigth,
    className,
    greenBorder,
    onScroll,
    style,
    children,
}) => {
    const cardRef = useRef<HTMLDivElement>(null);

    const handleScroll = useCallback(() => {
        if (onScroll) {
            onScroll(calculateScrollProgress());
        }
    }, [onScroll]);

    const calculateScrollProgress = (): number => {
        const component = cardRef.current;
        if (component) {
            const scrollTop = component.scrollTop;
            const scrollHeight = component.scrollHeight - component.clientHeight;
            const progress = (scrollTop / scrollHeight) * 100;
            return progress;
        }
        return 0;
    };

    useEffect(() => {
        const card = cardRef.current;

        if (card) {
            card.addEventListener('scroll', handleScroll);

            return () => {
                card.removeEventListener('scroll', handleScroll);
            };
        }
    }, [onScroll, handleScroll]);

    return (
        <div
            className={`WhiteCard ${className ? className : ''} ${
                greenBorder ? 'green-border' : ''
            }`}
            ref={cardRef}
            style={{
                paddingTop: paddingTop ?? padding,
                paddingBottom: paddingBottom ?? padding,
                paddingRight: paddingRight ?? padding,
                paddingLeft: paddingLeft ?? padding,
                width: width,
                height: heigth,
                ...style,
            }}
        >
            {children}
        </div>
    );
};

export default WhiteCard;
