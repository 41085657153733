import React from 'react';
import Button from '../../../Button';
import './index.scss';
import Copydeck from '../../../../i18n/Copydeck';

export interface SubmitProps {
    text?: string;
}

const Submit: React.FC<SubmitProps> = ({ text }) => {
    return (
        <div className="FormSubmit">
            <Button text={text ?? Copydeck.submit} type="primary" />
        </div>
    );
};

export default Submit;
