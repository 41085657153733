import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const AddIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            fill="none"
        >
            <g clip-path="url(#clip0_5823_35963)">
                <path
                    d="M5 16H27"
                    stroke={props.fill}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16 5V27"
                    stroke={props.fill}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};
