import React from 'react';
import './index.scss';
import InclusionDiversityIllustration1 from './../../../../Assets/Img/LifeAtSpreeAI/inclusion_diversity1.webp';
import InclusionDiversityIllustration2 from './../../../../Assets/Img/LifeAtSpreeAI/inclusion_diversity2.webp';
import InclusionDiversityIllustrationDesktop from './../../../../Assets/Img/LifeAtSpreeAI/inclusion_diversity_desktop.webp';
import { useWindowDimensions } from '../../../../Hooks/use-window-dimensions';
import Carousel from '../../../../Components/Carousel';
import Copydeck from '../../../../i18n/Copydeck';
import WrapperSection from '../../../../Components/WrapperSection';
import WrapperImage from '../../../../Components/WrapperImage';

const LifeAtSpreeAISectionInclusionDiversity = () => {
    const { windowWidth } = useWindowDimensions();

    return (
        <div className="LifeAtSpreeAISectionInclusionDiversity">
            <div className="wrapper">
                <div className="top">
                    <WrapperSection.Title>
                        {Copydeck.lifeAtSpreeAipageInclusionDiversityTitle}
                    </WrapperSection.Title>

                    <h4 className="text-body">
                        {Copydeck.lifeAtSpreeAipageInclusionDiversityDesc}
                    </h4>
                </div>

                {windowWidth > 975 ? (
                    <div className="illustrations mt-40">
                        <WrapperImage
                            uniqueSrc={InclusionDiversityIllustrationDesktop}
                            alt={Copydeck.lifeAtSpreeAipageInclusionDiversityTitle}
                            width={745}
                        />
                    </div>
                ) : (
                    <div className="carousel-container">
                        <Carousel
                            centerMode={false}
                            infiniteScroll={false}
                            slidesToShow={1}
                            carouselElements={[
                                <div className="illustration">
                                    <img
                                        src={InclusionDiversityIllustration1}
                                        alt="Inclusion & Diversity"
                                        loading="lazy"
                                    />
                                </div>,

                                <div className="illustration">
                                    <img
                                        src={InclusionDiversityIllustration2}
                                        alt="Inclusion & Diversity"
                                        loading="lazy"
                                    />
                                </div>,
                            ]}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default LifeAtSpreeAISectionInclusionDiversity;
