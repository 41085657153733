export const AnimatedXIcon = () => {
    return (
        <svg
            id="animated-svg"
            className="run-animation"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 193.3 193.3"
        >
            <line className="stroke-2 animated" x1="46.9" y1="46.9" x2="146.4" y2="146.4" />
            <line className="stroke-2 animated" x1="146.4" y1="46.9" x2="46.9" y2="146.4" />
        </svg>
    );
};
