import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const IslandIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" {...props} fill="none">
            <g clipPath="url(#clip0_9137_7346)">
                <path
                    d="M3.75 35C3.75 35 10 30 20 30C30 30 36.25 35 36.25 35"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M27.4999 11.25C14.9999 17.5 19.9999 30 19.9999 30"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M27.5 11.25C27.5 11.25 24.8125 9.90938 21.875 10C19.1469 10.0891 16.2031 11.4359 15 16.25"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M27.5 11.25C27.5 11.25 25 1.48438 16.25 6.36719"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M36.25 21.25C36.25 11.25 27.5 11.25 27.5 11.25"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M27.5 11.25C27.5 11.25 30 2.98437 36.25 7.70781"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M28.75 21.25C28.75 21.25 32.2828 16.25 27.5078 11.25"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.125 25C9.85089 25 11.25 23.6009 11.25 21.875C11.25 20.1491 9.85089 18.75 8.125 18.75C6.39911 18.75 5 20.1491 5 21.875C5 23.6009 6.39911 25 8.125 25Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_9137_7346">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
