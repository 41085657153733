import React, { ChangeEvent, useMemo } from 'react';
import { SupportedLanguage } from '../../../../Stores/LanguageStore';
import { useStores } from '../../../../Hooks/use-stores';
import './select-language.scss';

const HeaderSelectLanguage = () => {
    const { languageStore } = useStores();

    const handleLangChange = (event: ChangeEvent<HTMLSelectElement>) => {
        languageStore.setCurrentLanguage(event.target.value as SupportedLanguage);
    };

    const supportedLanguages = useMemo(() => {
        return languageStore.getSupportedLanguages();
    }, [languageStore]);

    if (!languageStore.currentLanguage) {
        return <></>;
    }

    return (
        <select
            onChange={handleLangChange}
            value={languageStore.currentLanguage}
            className="HeaderSelectLanguage text-body-primary-font cursor-pointer"
        >
            {supportedLanguages.map((lang) => (
                <option key={lang?.label} value={lang.language}>
                    {lang.label}
                </option>
            ))}
        </select>
    );
};

export default HeaderSelectLanguage;
