import './index.scss';

interface TabElementProps {
    label: string;
    value: string;
    isActive: boolean;
    onClick: () => void;
    textColor: string;
    backgroundColor: string;
}

const TabElement: React.FC<TabElementProps> = ({
    isActive,
    label,
    onClick,
    value,
    textColor,
    backgroundColor,
}) => {
    return (
        <button
            onClick={onClick}
            role="tab"
            className="TabElement"
            style={{
                backgroundColor: isActive ? backgroundColor : '#F6F6F6',
                color: isActive ? textColor : '#2B2B2B',
            }}
        >
            {label}
        </button>
    );
};

export default TabElement;
