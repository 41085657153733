import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import WrapperSectionTitle from '../../../Components/WrapperSection/title';
import RequirementCards from './Components/RequirementCards';
import { useWindowDimensions } from '../../../Hooks/use-window-dimensions';
import Carousel from '../../../Components/Carousel';

interface PartnerRessourcesHeaderProps {
    onChangeRequirements: (value: 'premium' | 'standard' | 'none') => void;
    requirements: 'premium' | 'standard' | 'none';
}

const PartnerRessourcesHeader: React.FC<PartnerRessourcesHeaderProps> = ({
    onChangeRequirements,
    requirements,
}) => {
    const { t } = useTranslation();
    const { windowWidth } = useWindowDimensions();

    const items = [
        <RequirementCards
            isSelected={requirements === 'standard'}
            color="#2EB67D"
            title={t('PartnerAsk.partner_ask_header_card_title_1')}
            desc={t('PartnerAsk.partner_ask_header_card_desc_1')}
            items={[
                {
                    title: t('PartnerAsk.partner_ask_question_card_2_title_1'),
                    desc: t('PartnerAsk.partner_ask_question_card_desc_1'),
                },
                {
                    title: t('PartnerAsk.partner_ask_question_card_2_title_2'),
                    desc: t('PartnerAsk.partner_ask_question_card_desc_2'),
                },
                {
                    title: t('PartnerAsk.partner_ask_question_card_2_title_3'),
                    desc: t('PartnerAsk.partner_ask_question_card_desc_3'),
                },
                {
                    title: t('PartnerAsk.partner_ask_question_card_2_title_4'),
                    desc: t('PartnerAsk.partner_ask_question_card_desc_4'),
                },
            ]}
            onPressSeeMore={() => onChangeRequirements('standard')}
        />,
        <RequirementCards
            isSelected={requirements === 'premium'}
            color="#9F2589"
            title={t('PartnerAsk.partner_ask_header_card_2_title_1')}
            desc={t('PartnerAsk.partner_ask_header_card_2_desc_1')}
            items={[
                {
                    title: t('PartnerAsk.partner_ask_question_card_2_title_1'),
                    desc: t('PartnerAsk.partner_ask_question_card_2_desc_1'),
                },
                {
                    title: t('PartnerAsk.partner_ask_question_card_2_title_2'),
                    desc: t('PartnerAsk.partner_ask_question_card_2_desc_2'),
                },
                {
                    title: t('PartnerAsk.partner_ask_question_card_2_title_3'),
                    desc: t('PartnerAsk.partner_ask_question_card_2_desc_3'),
                },
                {
                    title: t('PartnerAsk.partner_ask_question_card_2_title_4'),
                    desc: t('PartnerAsk.partner_ask_question_card_2_desc_4'),
                },
            ]}
            onPressSeeMore={() => onChangeRequirements('premium')}
        />,
    ];

    return (
        <div className="PartnerRessourcesHeader">
            <div className="top">
                <WrapperSectionTitle className="capitalize">
                    {t('PartnerAsk.partner_ask_title_section')}
                </WrapperSectionTitle>

                <div className="title-desc">
                    <div>{t('PartnerAsk.partner_ask_title_desc_1')}</div>

                    <div>{t('PartnerAsk.partner_ask_title_desc_2')}</div>
                </div>
            </div>

            <div className="bottom">
                {windowWidth < 1000 ? (
                    <Carousel
                        centerMode={false}
                        infiniteScroll={false}
                        slidesToShow={1}
                        carouselElements={items}
                    />
                ) : (
                    <>
                        {items[0]}
                        {items[1]}
                    </>
                )}
            </div>
        </div>
    );
};

export default PartnerRessourcesHeader;
