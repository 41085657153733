import { FC, useEffect, useState } from 'react';
import { SvgProps } from '../SvgProps';
export type CircleIconType = 'windmill' | 'head' | 'earth' | 'thumb';
// type ArcType = 'top' | 'right' | 'bottom' | 'left';
type gradientDirection =
    | 'diagonalGradientTopLeft'
    | 'diagonalGradientTopRight'
    | 'diagonalGradientBottomLeft'
    | 'diagonalGradientBottomRight';

interface ConfigurableCircleProps extends SvgProps {
    activeIcon: CircleIconType;
}

const ImpactAndInclusionsCircle: FC<ConfigurableCircleProps> = ({ activeIcon, ...props }) => {
    const [currentGradient, setCurrentGradient] =
        useState<gradientDirection>('diagonalGradientTopLeft');

    useEffect(() => {
        const gradientDirectionMap: Record<CircleIconType, gradientDirection> = {
            windmill: 'diagonalGradientTopLeft',
            head: 'diagonalGradientTopRight',
            earth: 'diagonalGradientBottomLeft',
            thumb: 'diagonalGradientBottomRight',
        };
        setCurrentGradient(gradientDirectionMap[activeIcon]);
    }, [activeIcon]);

    // Helper function to determine icon opacity
    const getIconOpacity = (icon: CircleIconType): { opacity: number } => {
        if (icon === activeIcon) {
            return { opacity: 1 };
        }
        return { opacity: 0.35 };
    };

    return (
        <svg
            width="575"
            height="551"
            viewBox="0 0 575 551"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0"
                y="0"
                width="575"
                height="551"
                fill={`url(#${currentGradient})`}
                mask="url(#arcMask)"
            />

            {/* Windmill icon */}
            {activeIcon === 'windmill' && (
                <g filter="url(#blurFilterWindmill)">
                    <rect
                        x="20"
                        y="23"
                        width="173"
                        height="173"
                        rx="86.5"
                        fill="#2EB67D"
                        fill-opacity="0.2"
                    />
                </g>
            )}
            <g style={getIconOpacity('windmill')} id="windmill">
                <g
                    opacity={activeIcon === 'windmill' ? 1.0 : 0.5}
                    clipPath="url(#clip0_7517_112712)"
                >
                    <path
                        d="M88.5993 67.3311L64.7637 53.3467C64.4792 53.1778 64.164 53.0666 63.8363 53.0196C63.5085 52.9726 63.1746 52.9908 62.8539 53.073C62.5332 53.1552 62.232 53.2999 61.9677 53.4986C61.7033 53.6974 61.4811 53.9463 61.3138 54.2311L57.5503 60.5873C57.3809 60.8709 57.2695 61.1852 57.2226 61.5119C57.1756 61.8386 57.1941 62.1714 57.2769 62.491C57.3597 62.8105 57.5052 63.1106 57.705 63.3739C57.9048 63.6371 58.1549 63.8584 58.441 64.0248L106.144 91.9998C106.43 92.1663 106.68 92.3875 106.879 92.6508C107.079 92.914 107.225 93.2141 107.308 93.5337C107.39 93.8533 107.409 94.186 107.362 94.5127C107.315 94.8395 107.204 95.1537 107.034 95.4373L103.271 101.794C103.103 102.078 102.881 102.327 102.617 102.526C102.353 102.725 102.051 102.869 101.731 102.952C101.41 103.034 101.076 103.052 100.748 103.005C100.42 102.958 100.105 102.847 99.8208 102.678L75.9852 88.6936"
                        stroke="#2EB67D"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M71.5878 71.7187L57.553 95.4687C57.3835 95.7522 57.2719 96.0662 57.2248 96.3928C57.1776 96.7194 57.1958 97.0521 57.2783 97.3717C57.3609 97.6912 57.506 97.9914 57.7055 98.2547C57.905 98.5181 58.1548 98.7395 58.4406 98.9062L64.8198 102.656C65.1044 102.825 65.4198 102.936 65.7477 102.983C66.0756 103.03 66.4095 103.011 66.7303 102.929C67.051 102.846 67.3521 102.701 67.6164 102.502C67.8806 102.303 68.1026 102.054 68.2697 101.769L96.3329 54.25C96.5 53.965 96.722 53.7157 96.9862 53.5167C97.2504 53.3176 97.5516 53.1726 97.8723 53.0901C98.193 53.0076 98.527 52.9892 98.8549 53.0359C99.1828 53.0827 99.4982 53.1937 99.7828 53.3625L106.162 57.1125C106.448 57.2792 106.698 57.5006 106.897 57.764C107.097 58.0274 107.242 58.3275 107.324 58.6471C107.407 58.9666 107.425 59.2993 107.378 59.6259C107.331 59.9525 107.219 60.2666 107.05 60.55L93.0148 84.3"
                        stroke="#2EB67D"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M52.1855 120.5H112.402"
                        stroke="#2EB67D"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M94.3262 99.4219L97.3495 120.5"
                        stroke="#2EB67D"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M70.4612 98.0342L67.2402 120.5"
                        stroke="#2EB67D"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
            </g>

            {/* Head icon */}
            {activeIcon === 'head' && (
                <g filter="url(#blurFilterHead)">
                    <rect
                        x="396"
                        y="23"
                        width="173"
                        height="173"
                        rx="86.5"
                        fill="#2EB67D"
                        fill-opacity="0.2"
                    />
                </g>
            )}
            <g style={getIconOpacity('head')} id="head">
                <g opacity={activeIcon === 'head' ? 1.0 : 0.5} clipPath="url(#clip1_7517_112712)">
                    <path
                        d="M487.754 75.5C490.526 75.5 492.772 73.2614 492.772 70.5C492.772 67.7386 490.526 65.5 487.754 65.5C484.983 65.5 482.736 67.7386 482.736 70.5C482.736 73.2614 484.983 75.5 487.754 75.5Z"
                        stroke="#2EB67D"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M492.772 95.5C495.543 95.5 497.79 93.2614 497.79 90.5C497.79 87.7386 495.543 85.5 492.772 85.5C490.001 85.5 487.754 87.7386 487.754 90.5C487.754 93.2614 490.001 95.5 492.772 95.5Z"
                        stroke="#2EB67D"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M485.244 115.5H470.19C469.525 115.5 468.886 115.237 468.416 114.768C467.945 114.299 467.681 113.663 467.681 113V101.691L459.031 97.7438C458.46 97.4583 458.019 96.9663 457.799 96.3681C457.58 95.7699 457.597 95.1106 457.849 94.525L465.172 80.5C465.172 76.2386 466.265 72.0479 468.348 68.3259C470.431 64.6039 473.433 61.4741 477.072 59.2337C480.71 56.9934 484.862 55.7168 489.134 55.5253C493.407 55.3337 497.658 56.2336 501.483 58.1393C505.308 60.0451 508.581 62.8935 510.991 66.4141C513.4 69.9347 514.867 74.0107 515.251 78.2549C515.635 82.4991 514.924 86.7706 513.185 90.664C511.446 94.5573 508.738 97.9432 505.316 100.5L507.825 120.5"
                        stroke="#2EB67D"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M487.754 65.5002V55.6221"
                        stroke="#2EB67D"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M514.844 75.5H505.316L495.982 86.6594"
                        stroke="#2EB67D"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
            </g>

            {/* Earth icon */}
            {activeIcon === 'earth' && (
                <g filter="url(#blurFilterEarth)">
                    <rect
                        x="20"
                        y="355"
                        width="173"
                        height="173"
                        rx="86.5"
                        fill="#2EB67D"
                        fill-opacity="0.2"
                    />
                </g>
            )}
            <g style={getIconOpacity('earth')} id="earth">
                <g opacity={activeIcon === 'earth' ? 1.0 : 0.5} clipPath="url(#clip2_7517_112712)">
                    <path
                        d="M82.2956 451C98.9239 451 112.404 437.569 112.404 421C112.404 404.431 98.9239 391 82.2956 391C65.6674 391 52.1875 404.431 52.1875 421C52.1875 437.569 65.6674 451 82.2956 451Z"
                        stroke="#2EB67D"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M65.9512 419.519L70.1287 412.25C70.3492 411.87 70.6665 411.554 71.0486 411.334C71.4308 411.115 71.8642 411 72.3052 411H77.5459C77.9696 411.001 78.3865 410.893 78.7565 410.688L82.611 408.575C82.7806 408.484 82.9377 408.372 83.0783 408.241L91.5211 400.638C91.9412 400.257 92.2192 399.746 92.3098 399.187C92.4004 398.628 92.2981 398.056 92.0198 397.563L88.7267 391.685"
                        stroke="#2EB67D"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M63.4095 397.634L59.715 406.337C59.4655 406.927 59.452 407.589 59.6774 408.187L63.2841 417.772C63.4323 418.163 63.6768 418.512 63.9951 418.785C64.3135 419.058 64.6955 419.247 65.1062 419.334L71.8273 420.775C72.2 420.855 72.5497 421.018 72.8497 421.252C73.1496 421.487 73.392 421.786 73.5585 422.128L74.7502 424.587C74.9558 425.01 75.2767 425.366 75.676 425.615C76.0754 425.865 76.5371 425.997 77.0084 425.997H81.3364"
                        stroke="#2EB67D"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M108.982 434.9L92.5545 424.831C92.2557 424.65 91.9226 424.532 91.576 424.485L84.419 423.522C83.8815 423.45 83.3349 423.553 82.861 423.816C82.3872 424.079 82.0117 424.488 81.7908 424.981L77.4942 434.588C77.2945 435.035 77.2308 435.53 77.3111 436.013C77.3914 436.496 77.6121 436.944 77.9458 437.303L84.1368 443.656C84.4051 443.944 84.6018 444.29 84.7108 444.668C84.8197 445.045 84.838 445.443 84.764 445.828L83.7698 450.966"
                        stroke="#2EB67D"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
            </g>

            {/* Thumb icon */}
            {activeIcon === 'thumb' && (
                <g filter="url(#blurFilterThumb)">
                    <rect
                        x="396"
                        y="355"
                        width="173"
                        height="173"
                        rx="86.5"
                        fill="#2EB67D"
                        fill-opacity="0.2"
                    />
                </g>
            )}

            <g style={getIconOpacity('thumb')} id="thumb">
                <g opacity={activeIcon === 'thumb' ? 1.0 : 0.5} clipPath="url(#clip3_7517_112712)">
                    <path
                        d="M457.642 415.5H472.696V448H457.642C456.976 448 456.338 447.737 455.868 447.268C455.397 446.799 455.133 446.163 455.133 445.5V418C455.133 417.337 455.397 416.701 455.868 416.232C456.338 415.763 456.976 415.5 457.642 415.5Z"
                        stroke="#2EB67D"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <path
                        d="M472.695 415.5L485.24 390.5C487.902 390.5 490.455 391.554 492.337 393.429C494.219 395.304 495.276 397.848 495.276 400.5V408H515.348C516.06 408 516.764 408.151 517.413 408.443C518.061 408.735 518.64 409.161 519.111 409.693C519.582 410.225 519.934 410.851 520.143 411.529C520.352 412.207 520.415 412.921 520.326 413.625L516.562 443.625C516.409 444.833 515.82 445.944 514.903 446.75C513.987 447.555 512.807 448 511.585 448H472.695"
                        stroke="#2EB67D"
                        stroke-width="4"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
            </g>

            <defs>
                <filter
                    id="blurFilterWindmill"
                    x="-80"
                    y="-77"
                    width="373"
                    height="373"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_7517_112621" />
                </filter>
                <filter
                    id="blurFilterHead"
                    x="336"
                    y="-37"
                    width="293"
                    height="293"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation="30" result="effect1_foregroundBlur_7517_112712" />
                </filter>
                <filter
                    id="blurFilterEarth"
                    x="-80"
                    y="255"
                    width="373"
                    height="373"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_7517_112667" />
                </filter>
                <filter
                    id="blurFilterThumb"
                    x="296"
                    y="255"
                    width="373"
                    height="373"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                    />
                    <feGaussianBlur stdDeviation="50" result="effect1_foregroundBlur_7517_112758" />
                </filter>
                <clipPath id="clip0_7517_112712">
                    <rect width="80.2883" height="80" transform="translate(42.1504 48)" />
                </clipPath>
                <clipPath id="clip1_7517_112712">
                    <rect
                        width="80.2883"
                        height="80"
                        fill="white"
                        transform="translate(447.609 48)"
                    />
                </clipPath>
                <clipPath id="clip2_7517_112712">
                    <rect
                        width="80.2883"
                        height="80"
                        fill="white"
                        transform="translate(42.1523 381)"
                    />
                </clipPath>
                <clipPath id="clip3_7517_112712">
                    <rect
                        width="80.2883"
                        height="80"
                        fill="white"
                        transform="translate(447.605 383)"
                    />
                </clipPath>
                <linearGradient id="diagonalGradientTopLeft" x1="0%" y1="0%" x2="100%" y2="100%">
                    <stop offset="0%" stopColor="#007048" stopOpacity={1} />
                    <stop offset="40%" stopColor="#2EB67D" stopOpacity={1} />
                    <stop offset="60%" stopColor="#2EB67D" stopOpacity={0.8} />
                    <stop offset="100%" stopColor="#007048" stopOpacity={0.2} />
                </linearGradient>

                <linearGradient id="diagonalGradientTopRight" x1="100%" y1="0%" x2="0%" y2="100%">
                    <stop offset="0%" stopColor="#007048" stopOpacity={1} />
                    <stop offset="40%" stopColor="#2EB67D" stopOpacity={1} />
                    <stop offset="60%" stopColor="#2EB67D" stopOpacity={0.8} />
                    <stop offset="100%" stopColor="#007048" stopOpacity={0.2} />
                </linearGradient>

                <linearGradient id="diagonalGradientBottomLeft" x1="0%" y1="100%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="#007048" stopOpacity={1} />
                    <stop offset="40%" stopColor="#2EB67D" stopOpacity={1} />
                    <stop offset="60%" stopColor="#2EB67D" stopOpacity={0.8} />
                    <stop offset="100%" stopColor="#007048" stopOpacity={0.2} />
                </linearGradient>

                <linearGradient
                    id="diagonalGradientBottomRight"
                    x1="100%"
                    y1="100%"
                    x2="0%"
                    y2="0%"
                >
                    <stop offset="0%" stopColor="#007048" stopOpacity={1} />
                    <stop offset="40%" stopColor="#2EB67D" stopOpacity={1} />
                    <stop offset="60%" stopColor="#2EB67D" stopOpacity={0.8} />
                    <stop offset="100%" stopColor="#007048" stopOpacity={0.2} />
                </linearGradient>
                <mask id="arcMask">
                    {/* Top arc */}
                    <path
                        d="M414.033 57.1519C412.586 58.6018 411.187 60.0995 409.838 61.6424C371.312 38.9905 326.423 26 278.5 26C232.739 26 189.745 37.8446 152.416 58.6352C150.977 57.1276 149.487 55.6685 147.95 54.2607C186.5 32.4496 231.046 20 278.5 20C328.012 20 374.359 33.5532 414.033 57.1519Z"
                        fill={`url(#${currentGradient})`}
                    />

                    {/* Right arc */}
                    <path
                        d="M524.688 367.77C533.323 341.918 538 314.255 538 285.5C538 256.745 533.323 229.082 524.688 203.23C526.479 202.225 528.233 201.163 529.948 200.045C539.059 226.861 544 255.604 544 285.5C544 315.397 539.059 344.139 529.948 370.955C528.233 369.837 526.479 368.775 524.688 367.77Z"
                        fill={`url(#${currentGradient})`}
                    />

                    {/* Bottom arc */}
                    <path
                        d="M409.838 509.358C371.312 532.009 326.423 545 278.5 545C232.739 545 189.745 533.155 152.416 512.365C150.977 513.872 149.487 515.332 147.95 516.739C186.5 538.55 231.046 551 278.5 551C328.012 551 374.359 537.447 414.033 513.848C412.586 512.398 411.187 510.901 409.838 509.358Z"
                        fill={`url(#${currentGradient})`}
                    />

                    {/* Left arc */}
                    <path
                        d="M33.7118 371.846C31.9827 373.017 30.2952 374.245 28.6522 375.526C18.5214 347.415 13 317.102 13 285.5C13 253.898 18.5214 223.585 28.6522 195.474C30.2952 196.755 31.9827 197.983 33.7118 199.154C24.1837 226.166 19 255.227 19 285.5C19 315.773 24.1837 344.834 33.7118 371.846Z"
                        fill={`url(#${currentGradient})`}
                    />
                </mask>
            </defs>
        </svg>
    );
};

export default ImpactAndInclusionsCircle;
