import React from 'react';
import { useTranslation } from 'react-i18next';

import './index.scss';
import WrapperSection from '../../Components/WrapperSection';
import FaqItem from './Components/faq-item';

const FAQPage = () => {
    const { t } = useTranslation();

    const FAQ = [
        {
            question: 'FAQPage.faq_question1',
            answer: 'FAQPage.faq_answer1',
        },
        {
            question: 'FAQPage.faq_question8',
            answer: 'FAQPage.faq_answer8',
        },
        {
            question: 'FAQPage.faq_question2',
            answer: 'FAQPage.faq_answer2',
        },
        {
            question: 'FAQPage.faq_question9',
            answer: 'FAQPage.faq_answer9',
        },
        {
            question: 'FAQPage.faq_question3',
            answer: 'FAQPage.faq_answer3',
        },
        {
            question: 'FAQPage.faq_question4',
            answer: 'FAQPage.faq_answer4',
        },
        {
            question: 'FAQPage.faq_question5',
            answer: 'FAQPage.faq_answer5',
        },
        {
            question: 'FAQPage.faq_question10',
            answer: 'FAQPage.faq_answer10',
        },
        {
            question: 'FAQPage.faq_question6',
            answer: 'FAQPage.faq_answer6',
        },
        {
            question: 'FAQPage.faq_question7',
            answer: 'FAQPage.faq_answer7',
            hasCustomHTML: {
                answer: true,
            },
        },
    ];

    return (
        <div className="FAQPage">
            <div className="hero">
                <WrapperSection.Title>{t('FAQPage.faq_title')}</WrapperSection.Title>
            </div>

            <div className="wrapper-faq">
                {FAQ.map((faq, index) => (
                    <FaqItem key={index} {...faq} />
                ))}
            </div>
        </div>
    );
};

export default FAQPage;
