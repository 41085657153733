import React from 'react';
import { inject } from 'aurelia-dependency-injection';
import UserStore from '../Stores/UserStore';
import TokenStore from '../Stores/TokenStore';
import {
    LanguageStore,
    LoadingStore,
    WelcomeModalStore,
    onBoardingModalStore,
    CookiesStore,
} from '../Stores';

export interface GlobalStores {
    languageStore: LanguageStore;
    loadingStore: LoadingStore;
    userStore: UserStore;
    tokenStore: TokenStore;
    welcomeModalStore: WelcomeModalStore;
    onboardingModalStore: onBoardingModalStore;
    cookiesStore: CookiesStore;
}

@inject(
    LanguageStore,
    LoadingStore,
    UserStore,
    TokenStore,
    WelcomeModalStore,
    onBoardingModalStore,
    CookiesStore
)
export class StoresContext {
    context: React.Context<GlobalStores>;

    constructor(
        languageStore: LanguageStore,
        loadingStore: LoadingStore,
        userStore: UserStore,
        tokenStore: TokenStore,
        welcomeModalStore: WelcomeModalStore,
        onboardingModalStore: onBoardingModalStore,
        cookiesStore: CookiesStore
    ) {
        this.context = React.createContext({
            languageStore,
            loadingStore,
            userStore,
            tokenStore,
            welcomeModalStore,
            onboardingModalStore,
            cookiesStore,
        });
    }
}
