import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const HandHeartIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" {...props} fill="none">
            <g clip-path="url(#clip0_7196_23691)">
                <path
                    d="M9.375 40.625H3.125C2.7106 40.625 2.31317 40.4604 2.02015 40.1674C1.72712 39.8743 1.5625 39.4769 1.5625 39.0625V31.25C1.5625 30.8356 1.72712 30.4382 2.02015 30.1451C2.31317 29.8521 2.7106 29.6875 3.125 29.6875H9.375"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M21.875 31.25H28.125L41.2109 28.2402C41.692 28.1084 42.1971 28.0893 42.6868 28.1844C43.1765 28.2795 43.6377 28.4862 44.0345 28.7885C44.4312 29.0909 44.753 29.4806 44.9746 29.9275C45.1963 30.3744 45.3119 30.8664 45.3125 31.3652C45.3127 31.9676 45.1451 32.558 44.8284 33.0704C44.5117 33.5827 44.0584 33.9966 43.5195 34.2656L35.9375 37.5L23.4375 40.625H9.375V29.6875L14.2578 24.8047C14.6942 24.3698 15.2121 24.0252 15.7819 23.7906C16.3516 23.556 16.962 23.436 17.5781 23.4375H27.3438C28.3798 23.4375 29.3733 23.8491 30.1059 24.5816C30.8385 25.3142 31.25 26.3078 31.25 27.3438C31.25 28.3798 30.8385 29.3733 30.1059 30.1059C29.3733 30.8385 28.3798 31.25 27.3438 31.25H21.875Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18.8926 23.4375C16.9922 21.0391 15.625 18.4688 15.625 15.625C15.625 11.3887 19.0762 7.81254 23.332 7.81254C24.8531 7.79469 26.3448 8.23127 27.6163 9.06636C28.8877 9.90146 29.8809 11.0971 30.4688 12.5C31.0566 11.0971 32.0498 9.90146 33.3212 9.06636C34.5927 8.23127 36.0844 7.79469 37.6055 7.81254C41.8613 7.81254 45.3125 11.3887 45.3125 15.625C45.3125 21.334 39.8086 26.3809 35.5039 29.5547"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_7196_23691">
                    <rect width="50" height="50" fill={props.fill} />
                </clipPath>
            </defs>
        </svg>
    );
};
