import * as React from 'react';
import { SvgProps } from '../../SvgProps';

export const RecycleIcon: React.FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_7622_61459)">
                <path
                    d="M19.0285 29.3926L16.0273 26.3926L19.0285 23.3926"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M24.3598 9.79102L23.2608 13.8898L19.1621 12.791"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M9.89498 17.9888L8.79467 13.8926L4.69727 14.9888"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M8.79497 13.8926L3.29342 23.3926C3.11792 23.6965 3.02548 24.0412 3.02539 24.3921C3.0253 24.743 3.11756 25.0877 3.2929 25.3917C3.46825 25.6957 3.7205 25.9482 4.02434 26.1239C4.32818 26.2995 4.67291 26.3922 5.0239 26.3926H11.0256"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M16.0273 26.3926H27.0315C27.3825 26.3922 27.7273 26.2995 28.0311 26.1239C28.335 25.9482 28.5873 25.6957 28.7626 25.3917C28.938 25.0877 29.0303 24.743 29.0302 24.3921C29.0301 24.0412 28.9376 23.6965 28.7621 23.3926L25.8685 18.3926"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M23.2598 13.8922L17.7578 4.39217C17.5821 4.08851 17.3295 3.83638 17.0256 3.6611C16.7216 3.48582 16.3768 3.39355 16.0259 3.39355C15.6749 3.39355 15.3302 3.48582 15.0262 3.6611C14.7222 3.83638 14.4697 4.08851 14.294 4.39217L11.4004 9.39217"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_7622_61459">
                    <rect
                        width="32.012"
                        height="32"
                        fill="white"
                        transform="translate(0.0214844 0.392578)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
export default RecycleIcon;
