import { useContext } from 'react';
import FormContext from '../context';
import { ERROR } from '../providers';

const useForm = () => {
    const formContext = useContext(FormContext);

    return formContext as {
        onSubmit: () => {
            [key: string]: string;
        };
        onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
        getValue: (name: string) => string;
        getErrors: (inputName: string) => ERROR[];
        clearValues: () => void;
        onChangeFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
    };
};

export default useForm;
