import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const PauseIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            {...props}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_9878_16862)">
                <path
                    d="M6 4V21"
                    stroke={props.fill}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19 4V21"
                    stroke={props.fill}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_9878_16862">
                    <rect width="24" height="24" fill={props.fill} />
                </clipPath>
            </defs>
        </svg>
    );
};
