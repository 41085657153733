import React from 'react';
import AboutUsSectionHero from './Sections/Hero';
import AboutUsSectionLeaders from './Sections/Leaders';
import AboutUsSectionRevolution from './Sections/Revolution';
import AboutUsTeamsSectionPage from './Sections/Teams';
import './index.scss';
import PetSection from '../LifeAtSpreeAI/Sections/Pets';

const AboutUsPage = () => {
    return (
        <div className="AboutUsPage">
            <AboutUsSectionHero />
            <AboutUsSectionLeaders />
            <AboutUsTeamsSectionPage />
            <PetSection />
            <AboutUsSectionRevolution />
        </div>
    );
};

export default AboutUsPage;
