import React from 'react';

export interface WrapperSectionTitleProps {
    children?: React.ReactNode;
    className?: string;
    htmlTag?: 'h1' | 'h2' | 'h3';
    dangerousHTML?: string;
    style?: React.CSSProperties;
}

const WrapperSectionTitle: React.FC<WrapperSectionTitleProps> = ({
    children,
    className,
    htmlTag = 'h2',
    dangerousHTML: dangerouslySetInnerHTML,
    style,
}) => {
    const Component = htmlTag;

    return (
        <Component
            className={`WrapperSectionTitle text-main-header ${className}`}
            style={style}
            dangerouslySetInnerHTML={
                dangerouslySetInnerHTML ? { __html: dangerouslySetInnerHTML } : undefined
            }
        >
            {dangerouslySetInnerHTML ? null : children}
        </Component>
    );
};

export default WrapperSectionTitle;
