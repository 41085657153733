import React from 'react';
import { BlockDto } from '../../Services/Blog';
import RichText from './RichText';
import MediaStrapi from './Media';

interface RenderStrapiBlocksProps {
    blocks: BlockDto[];
}

const RenderStrapiBlocks: React.FC<RenderStrapiBlocksProps> = ({ blocks }) => {
    return (
        <div className="RenderStrapiBlocks">
            {blocks.map((block, index) => {
                switch (block.__component) {
                    case 'shared.rich-text':
                        return <RichText key={index} block={block} />;
                    case 'shared.media':
                        return <MediaStrapi key={index} block={block} />;
                    default:
                        return null;
                }
            })}
        </div>
    );
};

export default RenderStrapiBlocks;
