import React, { useEffect, useState } from 'react';
import { TLEADER } from '../../../../../Constants/type';
import { useTranslation } from 'react-i18next';
import './card-leader.scss';
import { CloseIcon } from '../../../../../Icons/close-icon';
import { theme } from '../../../../../Style/theme';
import { LinkedinIcon } from '../../../../../Icons/linkedin-icon';
import useHandleAnimation from '../../../../../Hooks/use-handle-animation';
import { useWindowDimensions } from '../../../../../Hooks/use-window-dimensions';

interface CardLeaderProps {
    leader: TLEADER;
    flip?: boolean;
    isLast?: boolean;
}

const CardLeader: React.FC<CardLeaderProps> = ({ leader, flip = true, isLast = false }) => {
    const { t } = useTranslation();
    const [isSelected, setSelected] = useState(false);

    const [showAnimationOfLasted, setShowAnimationOfLasted] = useState(true);
    const [defaultFlipIsEnabled, setDefaultFlipIsEnabled] = useState(false);

    const { windowWidth } = useWindowDimensions();
    const isMobile = windowWidth < 1000;

    const onChangeVisibleCard = (isVisible: boolean) => {
        if (!isVisible) return;

        const timeout = setTimeout(() => {
            onClickLasted();
        }, 3500);

        return () => clearTimeout(timeout);
    };

    useHandleAnimation('.-breakpoint-lasted', null, onChangeVisibleCard);

    const onClickCard = () => {
        if (!flip) return;

        if (!defaultFlipIsEnabled) return;

        setSelected((prev) => {
            return !prev;
        });
    };

    useEffect(() => {
        if (isLast) {
            setShowAnimationOfLasted(true);
            setSelected(true);

            return;
        }

        setDefaultFlipIsEnabled(true);
    }, [isLast]);

    const onClickLasted = () => {
        setSelected(false);

        setTimeout(() => {
            setShowAnimationOfLasted(false);
            setDefaultFlipIsEnabled(true);
        }, 500);
    };

    useEffect(() => {
        if (!isSelected && isLast) {
            const timeout = setTimeout(() => {
                setSelected(true);

                setTimeout(() => {
                    setShowAnimationOfLasted(true);
                    setDefaultFlipIsEnabled(false);
                }, 500);
            }, 10000);

            return () => clearTimeout(timeout);
        }
    }, [isSelected]);

    return (
        <div className={`CardLeader ${isSelected ? '-selected' : ''}`} onClick={onClickCard}>
            <div className={`card-container ${isSelected ? '-selected' : ''}`}>
                <div className="side -front">
                    {flip && (
                        <div className="wrapper-close-icon">
                            <CloseIcon
                                fill={theme['white']}
                                width={10}
                                height={10}
                                className={`${isSelected ? '-rotate-0' : '-rotate-45'}`}
                            />
                        </div>
                    )}

                    <img
                        src={leader.imgSmall}
                        alt="Leader of spreeAI"
                        width={280}
                        height={375}
                        className="card-leader-image"
                    />

                    <div className="wrapper-leader-info">
                        <div className="infos">
                            <div className="text-body-primary-font-bold text-white">
                                {leader.fullName}
                            </div>
                            <div className="text-body job-title text-white">
                                {t(leader.jobTitleNameKey)}
                            </div>
                            {t(leader.jobTitleNameKey).length < 25 && (
                                <div className="text-body vanish">i</div>
                            )}
                        </div>
                    </div>
                </div>

                {flip && defaultFlipIsEnabled ? (
                    <div className="side -back">
                        <div className="wrapper-close-icon">
                            <CloseIcon
                                fill={theme['white']}
                                width={10}
                                height={10}
                                className={`${isSelected ? '-rotate-0' : '-rotate-45'}`}
                            />
                        </div>

                        <img
                            src={leader.imgSmall}
                            alt="Leader of spreeAI"
                            width={280}
                            height={375}
                            className="card-leader-image"
                        />

                        <div className="wrapper-leader-info -selected">
                            <div className="infos">
                                <div
                                    className={`text-body-primary-font-bold text-white ${
                                        isSelected ? 'leader-selected' : ''
                                    }`}
                                >
                                    {leader.fullName}
                                </div>

                                <div
                                    className={`text-description text-body-small text-white ${
                                        isSelected ? 'leader-selected' : ''
                                    }`}
                                >
                                    {t(leader.descriptionKey)}
                                </div>
                                {leader.linkedinUrl && (
                                    <a
                                        href={leader.linkedinUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="leader-extra-link"
                                    >
                                        <LinkedinIcon fill="#ffffff" width={24} height={24} />
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                ) : showAnimationOfLasted ? (
                    <div className="side -back -breakpoint-lasted" onClick={onClickLasted}>
                        <img
                            src={leader.imgSmall}
                            alt="Leader of spreeAI"
                            width={280}
                            height={375}
                            className="card-leader-image"
                        />

                        <div className="wrapper-leader-info -center-content -selected">
                            <div className="infos -lasted">
                                <div
                                    className={`text-body-primary-font-bold text-white t-align-center ${
                                        isSelected ? 'leader-selected' : ''
                                    }`}
                                >
                                    {t('CardLeader.card_leader_view_lastest_board_member')}
                                </div>

                                <div
                                    className={`text-body t-align-center click-to-see ${
                                        isSelected ? 'leader-selected' : ''
                                    }`}
                                >
                                    {isMobile
                                        ? t('CardLeader.card_leader_tap_to_see')
                                        : t('CardLeader.card_leader_click_to_see')}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
export default CardLeader;
