import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import * as NavigationMenu from '@radix-ui/react-navigation-menu';

import { LogoBrand } from '../logo-brand';
import { MenuBurgerIcon } from '../../../Icons/menu-burger-icon';
import { useWindowDimensions } from '../../../Hooks/use-window-dimensions';
import LayoutHeaderMobile from './layout-header-mobile';
import CONSTANTS from '../../../Constants';
import { NAV_OPTIONS } from './config';
import PartnerIcon from '../../../Icons/partner-icon';
import { theme } from '../../../Style/theme';
import { useStores } from '../../../Hooks/use-stores';
import ButtonPartnerAccessDashboard from './Components/button-partner-access-dashboard';
import { Link } from '../../../Components/Link';
import HeaderSelectLanguage from './Components/select-language';
import Copydeck from '../../../i18n/Copydeck';

import './layout-header.scss';
import styles from './header.module.scss';

const LayoutHeader = observer(() => {
    const [mobileMenuBurgerIsOpen, setMobileMenuBurgerIsOpen] = useState(false);

    const { windowWidth } = useWindowDimensions();
    const { t } = useTranslation();
    const { welcomeModalStore, userStore } = useStores();

    const widthLogoBrand = windowWidth < 1300 ? 140 : windowWidth < 160 ? 180 : 192;

    useEffect(() => {
        if (mobileMenuBurgerIsOpen || welcomeModalStore.isVisible) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'unset';
        }
    }, [mobileMenuBurgerIsOpen, welcomeModalStore.isVisible]);

    // prevent dropdown menu from opening when clicked by mouse but not keyboard
    const preventClick = (event: any) => {
        var x = event.x || event.clientX;
        var y = event.y || event.clientY;
        if (!x && !y) {
            return;
        }
        event.preventDefault();
    };

    return (
        <div className="LayoutHeader">
            <header className="layout-header-content">
                <Link pathConfig={{ where: CONSTANTS.SCREEN.HOME }} className="logo-container">
                    <LogoBrand width={widthLogoBrand} />
                </Link>

                <NavigationMenu.Root delayDuration={0} className={styles.Root}>
                    <NavigationMenu.List className={styles.MenuList}>
                        {NAV_OPTIONS.map((navOption) => {
                            const isNotClickable = navOption?.href === '#';

                            return (
                                <>
                                    {isNotClickable ? (
                                        <NavigationMenu.Item
                                            key={navOption.title}
                                            style={{ position: 'relative' }}
                                        >
                                            <NavigationMenu.Trigger
                                                onClick={preventClick}
                                                className={`${styles.Trigger} text-body-medium-primary-font nav-item-title`}
                                            >
                                                {t(navOption.title)}
                                            </NavigationMenu.Trigger>
                                            <NavigationMenu.Content className={styles.Content}>
                                                <ul className={styles.List}>
                                                    {navOption?.content?.map((option, index) => {
                                                        return (
                                                            <li key={option.title}>
                                                                <NavigationMenu.Link asChild>
                                                                    <Link
                                                                        className={`${styles.ListItemLink} nav-item-title text-body-medium-primary-font`}
                                                                        pathConfig={{
                                                                            where: option.href,
                                                                        }}
                                                                    >
                                                                        {t(option.title)}
                                                                    </Link>
                                                                </NavigationMenu.Link>
                                                            </li>
                                                        );
                                                    })}
                                                </ul>
                                            </NavigationMenu.Content>
                                        </NavigationMenu.Item>
                                    ) : navOption.type === 'external' ? (
                                        <NavigationMenu.Item key={navOption.title}>
                                            <NavigationMenu.Link asChild>
                                                <a
                                                    href={navOption.href}
                                                    className={`${styles.Link} text-body-medium-primary-font`}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    {t(navOption.title)}
                                                </a>
                                            </NavigationMenu.Link>
                                        </NavigationMenu.Item>
                                    ) : (
                                        <NavigationMenu.Item key={navOption.title}>
                                            <NavigationMenu.Link asChild>
                                                <Link
                                                    pathConfig={{ where: navOption.href }}
                                                    className={`${styles.Link} text-body-medium-primary-font `}
                                                >
                                                    {t(navOption.title)}
                                                </Link>
                                            </NavigationMenu.Link>
                                        </NavigationMenu.Item>
                                    )}
                                </>
                            );
                        })}
                    </NavigationMenu.List>
                </NavigationMenu.Root>

                <div className="actions">
                    {userStore.isLogin() ? (
                        <ButtonPartnerAccessDashboard
                            href={CONSTANTS.SCREEN.DASHBOARD}
                            icon={<PartnerIcon fill={theme['surface-brand-rest-tertiary']} />}
                            label={t('HeaderNav.partner_access')}
                        />
                    ) : (
                        <ButtonPartnerAccessDashboard
                            href={CONSTANTS.SCREEN.CREATE_ACCOUNT}
                            label={t('HeaderNav.partner_access_no_login')}
                            icon={<PartnerIcon fill={theme['surface-brand-rest-tertiary']} />}
                        />
                    )}

                    {userStore.isLogin() ? null : (
                        <Link
                            pathConfig={{ where: CONSTANTS.SCREEN.LOGIN }}
                            className="text-body-medium-primary-font-bold cta-login"
                        >
                            {Copydeck.loginLoginSpace}
                        </Link>
                    )}

                    <HeaderSelectLanguage />
                </div>

                <button
                    className="content-menu-burger"
                    onClick={() => setMobileMenuBurgerIsOpen(true)}
                >
                    <MenuBurgerIcon />
                </button>

                {mobileMenuBurgerIsOpen ? (
                    <LayoutHeaderMobile
                        onClose={() => {
                            setMobileMenuBurgerIsOpen(false);
                        }}
                    />
                ) : null}
            </header>
        </div>
    );
});

export default LayoutHeader;
