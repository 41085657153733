import * as React from 'react';
import { SvgProps } from '../../SvgProps';

export const ListIcon: React.FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_7622_61504)">
                <path
                    d="M16.332 16.5469H27.3362"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M16.332 8.54688H27.3362"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M16.332 24.5469H27.3362"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M5.32812 8.54688L7.32869 10.5469L11.3298 6.54688"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M5.32812 16.5469L7.32869 18.5469L11.3298 14.5469"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M5.32812 24.5469L7.32869 26.5469L11.3298 22.5469"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_7622_61504">
                    <rect
                        width="32.012"
                        height="32"
                        fill="white"
                        transform="translate(0.326172 0.546875)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
export default ListIcon;
