import { FC } from 'react';
import { SvgProps } from './SvgProps';

const TrendUpIcon: FC<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            fill="none"
        >
            <g clipPath="url(#clip0_4887_24634)">
                <path
                    d="M43.5 10.5L25.5 28.5L18 21L4.5 34.5"
                    stroke={props.fill}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M43.5 22.5V10.5H31.5"
                    stroke={props.fill}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_4887_24634">
                    <rect width="48" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default TrendUpIcon;
