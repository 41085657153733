import * as React from 'react';
import { SvgProps } from '../../SvgProps';

export const SunIcon: React.FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_7622_61469)">
                <path
                    d="M16.3496 5.10742V2.10742"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M16.3503 23.1074C20.2177 23.1074 23.3529 19.9734 23.3529 16.1074C23.3529 12.2414 20.2177 9.10742 16.3503 9.10742C12.4828 9.10742 9.34766 12.2414 9.34766 16.1074C9.34766 19.9734 12.4828 23.1074 16.3503 23.1074Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M8.3457 8.10742L6.3457 6.10742"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M8.3457 24.1074L6.3457 26.1074"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M24.3535 8.10742L26.3535 6.10742"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M24.3535 24.1074L26.3535 26.1074"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M5.34488 16.1074H2.34375"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M16.3496 27.1074V30.1074"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M27.3535 16.1074H30.3546"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <rect
                x="0.40625"
                y="0.169922"
                width="31.887"
                height="31.875"
                stroke="#30B284"
                stroke-width="0.125"
            />
            <defs>
                <clipPath id="clip0_7622_61469">
                    <rect x="0.34375" y="0.107422" width="32.012" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
export default SunIcon;
