import React from 'react';
import { Modal as ReactResponsiveModal } from 'react-responsive-modal';
import './index.scss';

interface ModalProps {
    onClose: () => void;
    children: React.ReactNode;
    className?: string;
}

const Modal: React.FC<ModalProps> = ({ children, onClose, className }) => {
    return (
        <ReactResponsiveModal
            open
            onClose={onClose}
            center
            closeOnEsc={false}
            showCloseIcon={false}
            classNames={{
                modal: `lib-modal-container Modal ${className}`,
            }}
        >
            <div className="modal-content">{children}</div>
        </ReactResponsiveModal>
    );
};

export default Modal;
