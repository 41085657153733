import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const DoubleArrowDownIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="35"
            height="36"
            viewBox="0 0 35 36"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M18 27.7077L9.25 18.9577L11.2917 16.916L18 23.5879L24.7083 16.916L26.75 18.9577L18 27.7077ZM18 18.9577L9.25 10.2077L11.2917 8.16602L18 14.8379L24.7083 8.16602L26.75 10.2077L18 18.9577Z"
                fill="#1C1B1F"
            />
        </svg>
    );
};
