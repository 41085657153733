import React from 'react';
import './index.scss';
import Button from '../../../../../Components/Button';
import { useTranslation } from 'react-i18next';
import { ChevronBottomIcon } from '../../../../../Icons/chevron-bottom-icon';
import { useStores } from '../../../../../Hooks/use-stores';

interface RequirementCardsProps {
    color: string;
    title: string;
    desc: string;
    items: {
        title: string;
        desc: string;
    }[];
    onPressSeeMore: () => void;
    isSelected?: boolean;
}

const RequirementCards: React.FC<RequirementCardsProps> = ({
    color,
    title,
    desc,
    items,
    onPressSeeMore,
    isSelected,
}) => {
    const { t } = useTranslation();
    const { languageStore } = useStores();

    return (
        <div
            className={`RequirementCards -${languageStore.currentLanguage}`}
            style={{
                borderColor: color,
            }}
        >
            <div className="inner-top">
                <div
                    style={{
                        color,
                    }}
                    className="text-main-header"
                >
                    {title}
                </div>
                <div className="text-desc text-body-medium">{desc}</div>
            </div>

            <div className="inner-body">
                {items.map((item, index) => (
                    <div key={index} className="item">
                        <div className="text-body-medium-bold">{item.title}</div>
                        <div className="text-body-medium">{item.desc}</div>
                    </div>
                ))}
            </div>
            <Button
                type="white"
                text={t('PartnerAsk.cta_view_data_requirements')}
                className={`${color === '#2EB67D' ? '-green' : '-purple'} ${
                    isSelected ? '-selected' : ''
                }`}
                icon={{
                    right: (
                        <ChevronBottomIcon
                            fill="black"
                            className={`${isSelected ? '-rotate-180' : '-rotate-0'}`}
                        />
                    ),
                }}
                fullInMobile={false}
                onClick={onPressSeeMore}
            />
        </div>
    );
};

export default RequirementCards;
