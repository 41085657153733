import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const ChevronBottomIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="15"
            height="8"
            viewBox="0 0 15 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M7.49999 7.84717C7.76367 7.83963 8.00474 7.7417 8.20061 7.53076L13.9261 1.66971C14.0918 1.50398 14.1822 1.29304 14.1822 1.04443C14.1822 0.547224 13.7905 0.147949 13.2932 0.147949C13.0522 0.147949 12.8186 0.245884 12.6454 0.419155L7.50753 5.70013L2.35463 0.419155C2.18136 0.253418 1.95535 0.147949 1.70675 0.147949C1.20954 0.147949 0.817795 0.547224 0.817795 1.04443C0.817795 1.29304 0.908197 1.50398 1.07393 1.66971L6.80691 7.53076C7.01032 7.7417 7.23632 7.84717 7.49999 7.84717Z"
                fill={props.fill}
            />
        </svg>
    );
};
