import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const ChevronRightIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M21.3096 15.0384C21.3004 14.7167 21.1809 14.441 20.9328 14.1928L13.7823 7.1986C13.5709 6.9964 13.3228 6.88611 13.0195 6.88611C12.4037 6.88611 11.9258 7.36403 11.9258 7.97981C11.9258 8.27392 12.0453 8.54965 12.2567 8.76103L18.6902 15.0384L12.2567 21.3157C12.0453 21.5271 11.9258 21.7936 11.9258 22.0969C11.9258 22.7127 12.4037 23.1906 13.0195 23.1906C13.3136 23.1906 13.5709 23.0803 13.7823 22.8781L20.9328 15.8747C21.1901 15.6358 21.3096 15.36 21.3096 15.0384Z"
                fill={props.fill}
            />
        </svg>
    );
};
