import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const PlayIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" {...props} fill="none">
            <g clip-path="url(#clip0_9691_1934)">
                <path
                    d="M6.75 3.73876V20.2613C6.75245 20.3931 6.78962 20.522 6.85776 20.6349C6.9259 20.7478 7.0226 20.8407 7.13812 20.9043C7.25364 20.9679 7.38388 20.9999 7.51572 20.9972C7.64756 20.9944 7.77634 20.9569 7.88906 20.8884L21.3966 12.6272C21.5045 12.5619 21.5937 12.4699 21.6556 12.36C21.7175 12.2501 21.7501 12.1261 21.7501 12C21.7501 11.8739 21.7175 11.7499 21.6556 11.64C21.5937 11.5302 21.5045 11.4381 21.3966 11.3728L7.88906 3.11157C7.77634 3.04314 7.64756 3.00564 7.51572 3.00285C7.38388 3.00007 7.25364 3.03209 7.13812 3.0957C7.0226 3.1593 6.9259 3.25224 6.85776 3.36514C6.78962 3.47804 6.75245 3.60691 6.75 3.73876Z"
                    fill={props.fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_9691_1934">
                    <rect width="24" height="24" fill={props.fill} />
                </clipPath>
            </defs>
        </svg>
    );
};
