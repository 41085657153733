import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

const LoginIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M12.8566 19.7143C12.6137 19.7143 12.4102 19.6321 12.2459 19.4678C12.0816 19.3036 11.9994 19.1 11.9994 18.8571C11.9994 18.6143 12.0816 18.4107 12.2459 18.2464C12.4102 18.0821 12.6137 18 12.8566 18H17.9994V5.99999H12.8566C12.6137 5.99999 12.4102 5.91785 12.2459 5.75356C12.0816 5.58928 11.9994 5.38571 11.9994 5.14285C11.9994 4.89999 12.0816 4.69642 12.2459 4.53213C12.4102 4.36785 12.6137 4.28571 12.8566 4.28571H17.9994C18.4709 4.28571 18.8744 4.45356 19.2102 4.78928C19.5459 5.12499 19.7137 5.52856 19.7137 5.99999V18C19.7137 18.4714 19.5459 18.875 19.2102 19.2107C18.8744 19.5464 18.4709 19.7143 17.9994 19.7143H12.8566ZM11.2923 12.8571H5.1423C4.89944 12.8571 4.69587 12.775 4.53158 12.6107C4.3673 12.4464 4.28516 12.2428 4.28516 12C4.28516 11.7571 4.3673 11.5536 4.53158 11.3893C4.69587 11.225 4.89944 11.1428 5.1423 11.1428H11.2923L9.68516 9.53571C9.52801 9.37856 9.44944 9.18571 9.44944 8.95713C9.44944 8.72856 9.52801 8.52856 9.68516 8.35713C9.8423 8.18571 10.0423 8.09642 10.2852 8.08928C10.528 8.08213 10.7352 8.16428 10.9066 8.33571L13.9709 11.4C14.1423 11.5714 14.228 11.7714 14.228 12C14.228 12.2286 14.1423 12.4286 13.9709 12.6L10.9066 15.6643C10.7352 15.8357 10.5316 15.9178 10.2959 15.9107C10.0602 15.9036 9.85658 15.8143 9.68516 15.6428C9.52801 15.4714 9.45301 15.2678 9.46016 15.0321C9.4673 14.7964 9.54944 14.6 9.70658 14.4428L11.2923 12.8571Z"
                fill={props.fill}
            />
        </svg>
    );
};

export default LoginIcon;
