import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import LayoutPolicy from '../../../Layout/Policy/layout-policy';
import CONSTANTS from '../../../Constants';
import useCustomNavigation from '../../../Hooks/use-custom-navigation';

const TermsPolicyPage = () => {
    const { t } = useTranslation();
    const nav = useCustomNavigation();

    return (
        <LayoutPolicy title={t('terms_policy_title')}>
            <LayoutPolicy.BasicText text={t('Terms.terms_text_1')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_2')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_3')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_4')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Terms.terms_text_5')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_6')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_7')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_8')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_9')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_10')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_11')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.Title title={t('Terms.terms_text_12')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_13')} />
            <LayoutPolicy.Jump />

            <div
                onClick={() => nav(CONSTANTS.SCREEN.PRIVACY_NOTICE)}
                className="btn-delete-default-style"
            >
                <LayoutPolicy.BasicText text={t('Terms.terms_text_14')} />
            </div>

            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Terms.terms_text_15')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_16')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Terms.terms_text_17')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_18')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Terms.terms_text_19')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_20')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Terms.terms_text_21')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_22')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_23')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Terms.terms_text_24')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_25')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_26')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Terms.terms_text_27')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_28')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Terms.terms_text_29')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_30')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Terms.terms_text_31')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_32')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Terms.terms_text_33')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_34')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_35')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Terms.terms_text_36')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_37')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Terms.terms_text_38')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_39')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Terms.terms_text_40')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_41')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_42')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_43')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_44')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_45')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Terms.terms_text_46')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_47')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_48')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_49')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_50')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_51')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_52')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_53')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('Terms.terms_text_54')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_55')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_56')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_57')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Terms.terms_text_58')} />
            <LayoutPolicy.Jump />
        </LayoutPolicy>
    );
};

export default TermsPolicyPage;
