import React from 'react';
import LayoutPolicy from '../../../Layout/Policy/layout-policy';

const CustomTermsConditionsPage = () => {
    return (
        <LayoutPolicy>
            <LayoutPolicy.Title title="SpreeAI Customer Terms and Conditions" />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text="These SpreeAI Customer Terms and Conditions (“Customer Terms”) form a binding legal agreement between You (including, as applicable, the person or entity using, accessing, receiving, or paying for the SpreeAI Services (as defined below)) (“You”) and SpreeAI Corporation (“SpreeAI”) for use of the SpreeAI Services." />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText
                text="These Customer Terms govern Your access and use of SpreeAI Services. “SpreeAI Services” means any
                and all materials provided or otherwise made available to You by SpreeAI under these Customer Terms,
                including: (a) its products, services, and technology; (b) any accompanying documentation and
                specifications provided by SpreeAI from time to time (“Documentation”); (c) Modifications (as defined
                below); and SpreeAI Data (as defined below)."
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText
                text="PLEASE READ THESE CUSTOMER TERMS CAREFULLY BEFORE ACCESSING OR USING THE
                SPREEAI SERVICES. YOUR USE OF AND ACCESS TO THE SPREEAI SERVICES IS
                CONDITIONED UPON YOUR COMPLIANCE AND ACCEPTANCE OF THESE CUSTOMER
                TERMS. IF YOU DO NOT AGREE WITH THESE CUSTOMER TERMS, DO NOT USE OR ACCESS
                THE SPREEAI SERVICES."
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText
                text="BY USING OR ACCESSING THE SPREEAI SERVICES, YOU AGREE TO BE BOUND BY THE
                CUSTOMER TERMS AT ALL TIMES, AS MAY BE AMENDED FROM TIME TO TIME. IF YOU DO
                NOT AGREE TO THESE CUSTOMER TERMS, PLEASE REFRAIN FROM ACCESSING OR USING
                THE SPREEAI SERVICES. THE CUSTOMER TERMS ARE SUBJECT TO CHANGE BY SPREEAI
                AT ANY TIME; HOWEVER, THE MOST CURRENT VERSION OF THE CUSTOMER TERMS WILL
                BE AVAILABLE ON OR THROUGH THE SPREEAI SERVICES. SPREEAI ENCOURAGES YOU TO
                REGULARLY REVIEW THE CUSTOMER TERMS TO ENSURE THAT YOU ARE AWARE OF ANY
                CHANGES. BY CONTINUING TO USE THE SPREEAI SERVICES, YOU AGREE TO BE BOUND
                BY THE MOST CURRENT VERSION OF THE CUSTOMER TERMS."
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText
                text="<bold>ARBITRATION NOTICE:</bold> THESE CUSTOMER TERMS CONTAIN AN ARBITRATION CLAUSE
                BELOW. YOU AND SPREEAI AGREE THAT DISPUTES BETWEEN YOU AND SPREEAI WILL BE
                RESOLVED BY MANDATORY BINDING ARBITRATION, AND YOU AND SPREEAI WAIVE ANY
                RIGHT TO PARTICIPATE IN A CLASS-ACTION LAWSUIT OR CLASS-WIDE ARBITRATION."
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text="SPREEAI SERVICES" />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText
                text="1.1 Your Representations and Warranties. You may be required to provide information about
                Yourself in order to register for and/or use the SpreeAI Services. By accessing or using the
                SpreeAI Services, You represent and warrant that: (a) You have the legal capacity and authority
                to bind Yourself and/or the person or entity for whom You are accepting these Customer
                Terms; (b) if You are agreeing to these Customer Terms on behalf of a legal entity, You are
                authorized to enter into these Customer Terms on behalf of such entity; (c) the information that
                You have provided or will provide to SpreeAI is or will be correct and complete in all respects,
                and that You have the right to provide such information to SpreeAI; You acknowledge and
                agree that SpreeAI has and will rely upon the information that You provide and that any incorrect or incomplete information that You provide to SpreeAI may result in SpreeAI
                withholding, suspending or terminating the SpreeAI Services and/or terminating these
                Customer Terms; and (d) You will comply with these Customer Terms and all applicable local,
                state, national, and international laws, rules, and regulations (collectively, “Applicable Law”)."
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText
                text="1.2 License to Use SpreeAI Services. Subject to Your compliance with these Customer Terms,
                SpreeAI grants You a limited, non-exclusive, non-transferable, non-sublicensable, revocable
                license to access and use the SpreeAI Services, including to integrate, maintain, and support
                the SpreeAI Services within Your software, desktop, or mobile application(s) (each, an
                “Application”) and make the SpreeAI Services available to users of Your Application in
                accordance with the Documentation."
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText
                text="1.3 Modifications to SpreeAI Services. SpreeAI may, but is not obligated, to provide You with
                upgrades, updates, or modifications (collectively, “Modifications”) for the SpreeAI Services
                at SpreeAI's sole discretion. You agree to implement all Modifications, to the Application
                required by SpreeAI as a result of changes to the SpreeAI Services as soon as commercially
                practicable and that SpreeAI will not be liable or responsible to You in any way as a result of
                Your failure or refusal to do so."
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText
                text="1.4 Restrictions on Use. In using the SpreeAI Services, You will not: (a) directly or indirectly sell,
                rent, lease, sublicense, syndicate, modify, reverse engineer, decompile, lend, or otherwise alter
                the SpreeAI Services; (b) use the SpreeAI Services violation of any Applicable Law, the
                intellectual property rights or other rights of third parties, applicable privacy standards, or for
                any unauthorized purpose; (c) use the SpreeAI Services in a manner that, as determined by
                SpreeAI, exceeds reasonable request volume, constitutes excessive or abusive usage, or
                otherwise fails to comply with or is inconsistent with any part of the then-current acceptable
                use policy or Documentation; (d) remove or obscure any legal, copyright, trademark, or other
                proprietary notice contained in the SpreeAI Services; (e) imply inaccurate creation, affiliation,
                sponsorship, or endorsement of Your Application by SpreeAI; (f) disclose, copy, use,
                distribute, display, or create derivative works from any SpreeAI Services in a manner that is
                inconsistent with these Customer Terms; (g) use the SpreeAI Services to develop products,
                features, or services that are similar or competitive to SpreeAI; or (h) permit or assist any other
                party to do any of the foregoing."
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text="YOUR APPLICATION AND DATA" />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText
                text="2.1 Requirements for Your Application. You and each of Your Applications will: (a) comply with
the application requirements specified in the Documentation; and (b) not infringe on the rights,
including intellectual property rights, of any third party. In addition, at any point where Your
users interact with the SpreeAI Services through your Application, You will (a) clearly and
conspicuously display SpreeAI’s then currently posted terms of service and privacy policy
along with a disclaimer that the user is interacting directly with SpreeAI and is subject to its
policies; and (b) require Your users to expressly agree to such policies prior to using the
SpreeAI Services. You will ensure that Your users and anyone acting on Your behalf to, comply with, and not access or use the SpreeAI Services in a manner that violates, these
Customer Terms and any Applicable Law.
"
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText
                text="2.2 Customer Data. In connection with Your use of the SpreeAI Services, You may provide
                content, data, and information to SpreeAI (collectively, “Customer Data”). For all Customer
                Data, You grant SpreeAI and its affiliates a worldwide, royalty-free, sublicensable, and
                transferable license to host, store, cache, use, copy, reproduce, modify, adapt, edit, create
                derivative works, publish, display, analyze, transmit, and distribute that data. This license is for
                the purpose of operating, developing, providing, promoting, and improving the SpreeAI
                Services, and researching and developing new ones. To the extent Customer Data includes
                Personal Data, the parties agree to comply with their respective obligations under the Data
                Processing Addendum located at <a tabIndex=-1 href='https://spreeai.com/dpa'>https://spreeai.com/dpa</a> which is incorporated by reference
                into these Customer Terms.
"
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText
                text="2.3 SpreeAI Data. “SpreeAI Data” means all data processed by SpreeAI or made available to you
                in connection with this Agreement, except for Customer Data. For the avoidance of doubt,
                SpreeAI Data includes any data provided by Your users directly to SpreeAI through Your
                Application or outside of Your Application, including through SpreeAI’s photo-realistic virtual
                try-on product, which uses a photo upload from a user to create a photo-realistic image of that
                user. You acknowledge and agree that to the extent SpreeAI Data is Personal Data, SpreeAI is
                an independent data controller or business with respect to such Personal Data, and will treat
                such Personal Data in accordance with its privacy policy available at
                <a tabIndex=-1 href='https://spreeai.com/privacy-policy'>https://spreeai.com/privacy-policy</a>, which is incorporated by reference into these Customer
                Terms. Notwithstanding anything to the contrary, SpreeAI Data is not subject to the Data
                Processing Addendum. To the extent SpreeAI makes such Personal Data available to you, You
                shall: (1) process the Personal Data only as expressly permitted under these Customer Terms;
                (2) use at least the same level of protection as is required of SpreeAI by Data Protection Law
                to protect the Personal Data; and (3) notify SpreeAI if You make a determination You can no
                longer meet Your obligations under Data Protection Law with respect to the Personal Data. To
                the extent the Personal Data is subject to the CPRA, the additional obligations in this sentence
                apply: You shall not (i) sell or share the Personal Data; (ii) retain, use, or disclose the Personal
                Data for any purpose, including a commercial purpose, other than the business purposes of
                performing its obligations under this Agreement; (iii) retain, use, or disclose the Personal Data
                outside of the direct business relationship between the parties other than for the business
                purposes specified herein; or (iv) combine the Personal Data with Personal Data that You
                receive from or on behalf of another person, or collect from Your own interaction with the
                consumer, unless otherwise permitted of a service provider by CPRA. SpreeAI has the right,
                upon notice, to take reasonable and appropriate steps to stop and remediate unauthorized use
                of the Personal Data and help ensure compliance with Data Protection Law."
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText
                text="2.4 Definitions. For purposes of this Section 2, “Personal Data” means any data relating to an
                identified or identifiable natural personal, and includes “personal data,” “personal information”
                and similarly protected data as ascribed by Data Protection Law. “Data Protection Law” means
                any laws, rules, or regulations relating to privacy, security, or data protection applicable to a party in the performance of its obligations under this Agreement, including, as applicable, the
                California Privacy Rights Act (“CPRA”).
                "
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text="INTELLECTUAL PROPERTY" />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText
                text="3.1 Ownership. You acknowledge that You have no ownership, rights, title, or any other interest
                in the SpreeAI Services (including all derivatives, customizations, translations, modifications,
                improvements, and enhancements thereof) other than the licenses and rights explicitly granted
                in these Customer Terms. All rights, title, and interest, including intellectual property interests,
                in and to the SpreeAI Services are the exclusive property of SpreeAI. These Customer Terms
                do not constitute a transfer of title or ownership of the SpreeAI Services to You. Other than the
                express licenses and rights expressly granted under these Customer Terms, You retain all
                rights, title and interest in Your Application and Customer Data."
            />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="3.2 Feedback. If You elect to provide SpreeAI with any comments, suggestions, ideas,
                improvements, or feedback (collectively, “Feedback”), You hereby grant SpreeAI a non-
                exclusive, royalty-free, fully-paid, irrevocable, transferable, sub-licensable (through multiple
                tiers) right and license to use, copy, disclose, implement, distribute, and exploit such Feedback
                in any manner without restriction, and without any obligation of confidentiality, attribution,
                accounting, royalty, compensation, or other duty to You."
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text="CONFIDENTIALITY" />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText
                text="4.1 Confidential Information. In connection with the performance of these Customer Terms, You
                may receive certain confidential information of SpreeAI, which confidential information shall
                include information relating to the SpreeAI Services and these Customer Terms and any other
                information of SpreeAI reasonably understood to be confidential. You hereby agree: (a) to hold
                and maintain in strict confidence all confidential information of SpreeAI and not to disclose it
                to any third party; and (b) not to use any confidential information of SpreeAI except as
                permitted by these Customer Terms or as may be necessary to exercise rights or perform
                obligations under these Customer Terms. In the event that You disclose or are required to
                disclose confidential information, You shall provide immediate notice to SpreeAI prior to any
                disclosure to afford SpreeAI a reasonable opportunity to protect the confidential information
                from public disclosure and will reasonably assist SpreeAI with such protection. Except as
                expressly provided in these Customer Terms, if You disclose or use (or threaten to disclose or
                use) any confidential information of SpreeAI in breach of confidentiality protections hereunder,
                SpreeAI shall have the right, in addition to any other remedies available to it, to seek injunctive
                relief to enjoin such acts, it being expressly acknowledged by You that any other available
                remedies may be inadequate."
            />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="4.2 Confidential Information. In connection with the performance of these Customer Terms, You
                may receive certain confidential information of SpreeAI, which confidential information shall
                include information relating to the SpreeAI Services and these Customer Terms and any other
                information of SpreeAI reasonably understood to be confidential. You hereby agree: (a) to hold
                and maintain in strict confidence all confidential information of SpreeAI and not to disclose it
                to any third party; and (b) not to use any confidential information of SpreeAI except as
                permitted by these Customer Terms or as may be necessary to exercise rights or perform
                obligations under these Customer Terms. In the event that You disclose or are required to
                disclose confidential information, You shall provide immediate notice to SpreeAI prior to any
                disclosure to afford SpreeAI a reasonable opportunity to protect the confidential information
                from public disclosure and will reasonably assist SpreeAI with such protection. Except as
                expressly provided in these Customer Terms, if You disclose or use (or threaten to disclose or
                use) any confidential information of SpreeAI in breach of confidentiality protections hereunder,
                SpreeAI shall have the right, in addition to any other remedies available to it, to seek injunctive
                relief to enjoin such acts, it being expressly acknowledged by You that any other available
                remedies may be inadequate."
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text="FEES" />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText
                text="5.1 Fees & Payment. If applicable, You shall pay all fees in accordance with the payment terms as
                specified in the applicable statement of work, order form, or similar document governed by
                these Customer Terms. All payments will be due within 30 days from the invoice date. SpreeAI
                may collect interest at the lesser of 1.5% per month or the highest amount permitted by law on
                any amounts not paid when due. If any invoice is more than 10 days past due, SpreeAI may,
                without limiting its other rights and remedies, suspend performance and/or Your access to the
                SpreeAI Services until such invoice is paid in full. All payment obligations are non-cancellable,
                and all amounts paid are non-refundable except as otherwise specified in the applicable
                statement of work. Unless otherwise agreed to in a statement of work, SpreeAI reserves the
                right to increase its fees at any time upon notice to You."
            />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="5.2 Taxes. SpreeAI’s fees are exclusive of all taxes, levies, or duties imposed by taxing authorities,
                including for example, value-added, sales, use or withholding taxes, assessable by any
                jurisdiction whatsoever and You shall be responsible for payment of all such taxes, levies, or
                duties imposed by taxing authorities associated with these Customer Terms."
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text="TERMINATION" />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="You may terminate the Customer Terms at any time and for any reason by deleting Your account.
                SpreeAI may terminate these Customer Terms or suspend or cancel Your access to the SpreeAI
                Services if SpreeAI reasonably believes that You or Your Application is not in compliance with
                these Customer Terms and upon providing You with reasonable prior notice, You have not
                remedied such non-compliance within 5 business days. Notwithstanding the foregoing, SpreeAI
                may immediately terminate these Customer Terms or suspend or cancel Your access to the SpreeAI
                Services, if SpreeAI determines that Your use of the SpreeAI Services is detrimental or may cause
                harm to SpreeAI. Upon termination of these Customer Terms, You will be liable for any fees owed
                to SpreeAI and shall immediately cease any access and use of the SpreeAI Services and return or
                destroy any SpreeAI confidential information in Your possession or under Your reasonable control."
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text="EXPORT RESTRICTIONS" />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="The SpreeAI Services may be subject to export laws and regulations of the United States and other
                jurisdictions. You agree to comply with such restrictions and not to export or re-export the SpreeAI
                Services or any portion thereof to countries or persons prohibited under the export control laws.
                You represent and warrant that You are not named on any government list of persons or entities
                prohibited from receiving exports, and shall not violate any export embargo, prohibition, restriction
                or other similar law in connection with these Customer Terms."
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text="INJUNCTIVE RELIEF" />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="You acknowledge that any use of the SpreeAI Services contrary to these Customer Terms, or any
                transfer, distribution, sublicensing, copying or disclosure of the SpreeAI Services in violation of
                these Customer Terms or Applicable Law may cause irreparable injury to SpreeAI, its affiliates,
                suppliers, and licensors, and under such circumstances SpreeAI, its affiliates, suppliers, and
                licensors will be entitled to equitable relief, without posting bond or other security, including
                preliminary and permanent injunctive relief."
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text="REPRESENTATIONS, WARRANTIES, AND COVENANTS" />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="You represent and warrant that: (a) You will comply with these Customer Terms and all Applicable
                Law; (b) You have the full corporate right, power, and authority to enter into, and perform Your
                obligations under these Customer Terms; (c) Your entry into and performance of Your obligations
                under these Customer Terms will not conflict with or result in a breach or violation of any
                agreement with or obligation owed to a third party; (d) Your Application, Customer Data, or Your
                use of the SpreeAI Services does not and will not infringe or misappropriate any intellectual
                property rights and will comply with all Applicable Law; (e) You obtained and will maintain the
                necessary and applicable rights and consents to provide Customer Data to SpreeAI; (f) You will
                not transmit to SpreeAI in any way, any sensitive information, which includes any information that
                is based on or includes, an alleged or actual commission of a crime, health, information from online
                services directed to children under the age of 13, information about individuals You have actual
                knowledge are under the age of 13, financial status, racial or ethnic origin, religious, sex life, or
                trade union membership; and (g) You will comply with all applicable anti-corruption laws and
                rules, and will not give, offer, agree, promise to give, or authorize the direct or indirect giving, of
                any money or other thing of value to anyone to induce or reward favorable action, forbearance from
                action, or the exercise of influence."
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text="WARRANTY DISCLAIMER" />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText
                text="10.1 SPREEAI (INCLUDING ITS AFFILIATES, SUPPLIERS, AND LICENSORS) MAKES NO
                REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER EXPRESS,
                IMPLIED, STATUTORY, OR OTHERWISE AS TO THE PERFORMANCE,
                FUNCTIONALITY OR LEGAL COMPLIANCE OF THE SPREEAI SERVICES. THE
                SPREEAI SERVICES ARE PROVIDED “AS IS” AND ON AN “AS AVAILABLE” BASIS,
                EXCLUSIVE OF ANY WARRANTIES WHATSOEVER, EXPRESS OR IMPLIED,
                INCLUDING, WITHOUT LIMITATION, ANY WARRANTY FOR INFORMATION,
                DATA, DATA PROCESSING SPREEAI SERVICES, UPTIME OR UNINTERRUPTED
                ACCESS, ANY WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY,
                USEFULNESS, CORRECTNESS, PRECISION, THOROUGHNESS, COMPLETENESS OR
                CONTENT OF INFORMATION, AND ANY WARRANTY OF TITLE,
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-
                INFRINGEMENT. SPREEAI, ITS AFFILIATES, SUPPLIERS, AND LICENSORS MAKE
                NO WARRANTY OR REPRESENTATION THAT: (A) THE SPREEAI SERVICES WILL
                ALWAYS BE SECURE, ERROR-FREE, OR TIMELY; (B) THE SPREEAI SERVICES WILL FUNCTION WITHOUT DELAYS, DISRUPTIONS, OR IMPERFECTIONS; OR (C)
                THAT ANY CONTENT, USER CONTENT, OR INFORMATION YOU OBTAIN ON OR
                THROUGH THE SPREEAI SERVICES WILL BE TIMELY OR ACCURATE."
            />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="10.2 SPREEAI, ITS AFFILIATES, SUPPLIERS, AND LICENSORS SHALL NOT HAVE ANY
                RESPONSIBILITY OR ASSUME ANY LIABILITY FOR ANY CONTENT THAT YOU,
                ANY USER, OR A THIRD PARTY CREATES, UPLOADS, POSTS, SENDS, RECEIVES,
                OR STORES ON OR THROUGH SPREEAI SERVICES. YOU UNDERSTAND AND
                AGREE THAT YOU MAY BE EXPOSED TO CONTENT THAT MIGHT BE OFFENSIVE,
                ILLEGAL, OR OTHERWISE INAPPROPRIATE, NONE OF WHICH SPREEAI OR ITS
                AFFILIATES, SUPPLIERS, AND LICENSORS WILL BE RESPONSIBLE FOR"
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text="INDEMNITY" />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD HARMLESS SPREEAI AND ITS
                SUBSIDIARIES, AFFILIATES, SUPPLIERS, AND LICENSORS, AND THEIR RESPECTIVE
                OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AND ASSIGNS (THE “PROTECTED
                PARTIES”) FROM AND AGAINST ANY AND ALL THIRD PARTY CLAIMS, DEMANDS,
                FORMAL OR INFORMAL PROCEEDINGS, SUITS, AND ANY OTHER ACTIONS,
                (“INDEMNIFIABLE CLAIMS”) INCLUDING ANY RELATED LIABILITIES,
                OBLIGATIONS, LOSSES, DAMAGES, PENALTIES, FINES, JUDGMENTS, SETTLEMENTS,
                EXPENSES (INCLUDING ATTORNEYS’ AND ACCOUNTANTS’ FEES AND
                DISBURSEMENTS), AND COSTS INCURRED BY, BORNE BY, OR ASSERTED AGAINST
                SPREEAI TO THE EXTENT SUCH INDEMNIFIABLE CLAIMS IN ANY WAY RELATE TO,
                ARISE OUT OF, OR RESULT FROM ANY OF THE FOLLOWING: (A) YOUR USE OF THE
                SPREEAI SERVICES; (B) YOUR ACTUAL OR ALLEGED VIOLATION OF ANY
                APPLICABLE LAW; (C) YOUR ACTUAL OR ALLEGED BREACH OF THESE CUSTOMER
                TERMS; (D) YOUR APPLICATION OR CUSTOMER DATA; (E) YOUR ACTS, ERRORS, OR
                OMISSIONS; (F) ACTUAL OR ALLEGED INFRINGEMENT OF ANY THIRD PARTY
                RIGHTS, INCLUDING WITHOUT LIMITATION, ANY AND ALL INTELLECTUAL
                PROPERTY RIGHTS OR PRIVACY RIGHTS ARISING FROM YOUR USE OF THE SPREEAI
                SERVICES; OR (G) ANY DISPUTE OR ISSUE BETWEEN YOU AND ANY THIRD PARTY.
                SPREEAI RESERVES THE RIGHT, AT ITS OWN EXPENSE, TO ASSUME THE EXCLUSIVE
                DEFENSE AND CONTROL OF ANY MATTER OTHERWISE SUBJECT TO
                INDEMNIFICATION BY YOU (WITHOUT LIMITING YOUR INDEMNIFICATION
                OBLIGATIONS WITH RESPECT TO SUCH MATTER), AND YOU AGREE TO COOPERATE
                WITH SPREEAI’S DEFENSE OF ANY SUCH CLAIM AT YOUR OWN EXPENSE"
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text="LIMITATION OF LIABILITY." />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL
                THE PROTECTED PARTIES BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL,
                INCIDENTAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL DAMAGES
                WHATSOEVER, INCLUDING, DAMAGES FOR LOSS OF INCOME, LOSS OF DATA OR
                CONFIDENTIAL OR OTHER INFORMATION, LOST PROFITS OR REVENUE, BUSINESS
                INTERRUPTION, COST OF SUBSTITUTE SPREEAI SERVICES, PERSONAL INJURY LOSS OF PRIVACY, FAILURE TO MEET ANY DUTY (INCLUDING OF GOOD FAITH OR
                    OF REASONABLE CARE, OR NEGLIGENCE), AND ANY OTHER PECUNIARY OR OTHER
                    LOSS WHATSOEVER, REGARDLESS OF THE CAUSE, ARISING OUT OF OR IN
                    CONNECTION WITH THE CUSTOMER TERMS OR THE SPREEAI SERVICES PROVIDED
                    HEREUNDER, EVEN IF SUCH PROTECTED PARTIES HAVE BEEN ADVISED OF THE
                    POSSIBILITY OF SUCH DAMAGES, OR EVEN IF A REMEDY OTHERWISE FAILS OF ITS
                    ESSENTIAL PURPOSE, AND REGARDLESS OF WHETHER ANY CLAIM FOR RECOVERY
                    IS BASED ON THEORIES OF CONTRACT, WARRANTY, TORT (INCLUDING
                    NEGLIGENCE AND STRICT LIABILITY), OR OTHERWISE. EXCEPT AS OTHERWISE
                    PROVIDED IN THESE CUSTOMER TERMS, IF YOU ARE DISSATISFIED WITH ANY
                    PORTION OF THE SPREEAI SERVICES, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
                    CANCEL AND DISCONTINUE USING THE SPREEAI SERVICES. IN NO INSTANCE WILL
                    THE PROTECTED PARTIES’ MAXIMUM CUMULATIVE LIABILITY AND YOUR
                    EXCLUSIVE REMEDY FOR ANY CLAIMS ARISING OUT OF OR RELATED TO THESE
                    CUSTOMER TERMS EXCEED THE GREATER OF $100 USD OR THE AMOUNT
                    ACTUALLY PAID BY YOU TO SPREEAI FOR THE SPREEAI SERVICES (IF ANY) IN THE
                    3 MONTH PERIOD PRECEDING THE EVENT OR CIRCUMSTANCES GIVING RISE TO
                    SUCH CLAIMS, AND YOU AGREE THAT THIS LIMITATION REPRESENTS A
                    REASONABLE ALLOCATION OF RISK."
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text="ARBITRATION" />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText
                text="13.1 Arbitration Provision. Both parties elect to resolve any and all claims and disputes relating in
                any way to these Customer Terms or their dealings with one another (“Claims”), except for
                Claims concerning the validity, scope, or enforceability of Section 13 of these Customer Terms
                (this “Arbitration Provision”), through binding individual, non-class-action arbitration.
                SpreeAI and You each understand and agree that by allowing each other to elect to resolve any
                dispute through individual arbitration, BOTH PARTIES EXPRESSLY WAIVE THE RIGHT
                TO A COURT OR JURY TRIAL AND NO CLAIM FILED IN COURT WILL BE HEARD
                BY A JURY OR TAKE PLACE ON AN INDIVIDUAL BASIS. FURTHERMORE, YOU
                SHALL RESOLVE ANY DISPUTE BY ARBITRATION AND SUCH DISPUTE SHALL BE
                ARBITRATED ON AN INDIVIDUAL BASIS, AND NOT AS A CLASS ACTION,
                REPRESENTATIVE ACTION, CLASS ARBITRATION, OR ANY SIMILAR SUCH
                PROCEEDING. The arbitrator(s) may not consolidate more than one party's claims (except
                Claims by or against one party with respect to these Customer Terms involving the parties) and
                may not preside over any form of a representative or class proceeding"
            />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="13.2 Arbitration Rules. Arbitration of any dispute under this Arbitration Provision shall be
                administered by the American Arbitration Association (the “AAA”) pursuant to the applicable
                rules of AAA in effect at the time the arbitration is initiated. In the event that AAA is unable
                or unwilling to administer the arbitration of a dispute, then a dispute may be referred to any
                other arbitration organization at SpreeAI's sole discretion. Arbitrations shall be conducted
                before a single arbitrator. The arbitration shall take place in the federal judicial district and the
                arbitrator shall apply applicable substantive law consistent with the Federal Arbitration Act, 9
                U.S.C. §§ 1-16 (the 'FAA') and applicable statutes of limitations and shall be authorized to award any relief that would have been available in court; provided that the arbitrator's authority
                to resolve claims and make awards is limited to any and all claims between SpreeAI and You
                except as otherwise specifically stated herein. The decision by the arbitrator shall be final and
                binding on the parties. SpreeAI and You agree that this Arbitration Provision extends to any
                other parties involved in any Claims, including, without limitation, to SpreeAI’s and Your
                employees, affiliated companies and vendors. In the event of any conflict between this
                Arbitration Provision and the AAA arbitration rules or the rules of any other arbitration
                organization or arbitrator, this Arbitration Provision shall govern."
            />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text="13.3 Arbitration Fees and Costs. The arbitration fees shall be borne equally by SpreeAI and You." />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="13.4 Exceptions. Notwithstanding any other provision of these Customer Terms, SpreeAI and You
                agree that this Arbitration Provision does not stop either SpreeAI or You from exercising any
                lawful rights to seek non-arbitration, self-help remedies. SpreeAI and You agree that each party
                may seek provisional self-help remedies out of court without waiving the right to arbitrate.
                Notwithstanding any other provision of these Customer Terms, if the foregoing class action
                waiver and prohibition against class arbitration is determined to be invalid or unenforceable,
                then the arbitration provision under this Section 13.4, other than such invalid or unenforceable
                provisions, shall remain in full force and effect."
            />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="13.5 Attorneys’ Fees. If SpreeAI initiates legal action against You for collection of any amounts
                owed to SpreeAI or to enforce SpreeAI’s rights and Your obligations under these Customer
                Terms, SpreeAI is entitled to recover from You any reasonable costs and expenses including
                reasonable attorneys’ fees incurred as a result"
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text="MISCELLANEOUS" />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText
                text="14.1 Publicity and Marks. SpreeAI may use Your logos and marks to provide the SpreeAI Services
                to You and for its marketing and publicity purposes. You may only use SpreeAI’s logos and
                marks upon SpreeAI’s written consent in each instance and any such consent may be
                revocable by SpreeAI at any time at its sole discretion. You agree to immediately discontinue
                any use of SpreeAI’s logos and marks if requested by SpreeAI. All goodwill, rights, and
                benefits in the SpreeAI’s marks that arise from Your use will inure solely to SpreeAI"
            />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="14.2 Choice of Law and Forum. Subject to the Arbitration Provision, all proceedings arising out of
                or relating to these Customer Terms or their subject matter, including any tort claims, shall be
                governed by and construed under the laws of the State of Nevada, U.S.A., without regards to
                the jurisdiction’s conflicts of law rules, and as applied to agreements entered into and to be
                performed in Nevada by Nevada residents.."
            />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="14.3 SpreeAI Remedies. SpreeAI’s remedies described in these Customer Terms are in addition to
                any other remedies available to SpreeAI at law or equity. SpreeAI shall have the right to
                exercise any available rights and remedies at the same or different times."
            />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="14.4 Entire Agreement. These Customer Terms set forth the entire understanding and agreement of
                the parties, and supersedes all other agreements between the parties relating to its subject matter. Only these Customer Terms shall apply to Your use of or access to the SpreeAI
                Services.
                "
            />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="14.5 Updates to Customer Terms. SpreeAI reserves the right, in its sole discretion, to modify,
                update, change, or amend these Customer Terms or the SpreeAI Services at any time. Any
                change or update will become effective from the moment SpreeAI notifies You of such
                changes, which SpreeAI may do so via the SpreeAI Services, email, publication on SpreeAI’s
                website, or any other method SpreeAI deems appropriate. Your continued use after any such
                modification or change is made constitutes Your acceptance of such modification or change"
            />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="14.6 Waivers, and Waiver of Provisions. Failure to exercise or delay in exercising any right or
                remedy by SpreeAI shall not constitute a waiver, and any waiver granted must be in writing
                and executed by the party against whom enforcement is sought. A waiver of specific provisions
                does not extend to other provisions or subsequent breaches."
            />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="14.7 Severability. In the event that any provision in these Customer Terms are deemed invalid,
                illegal, or unenforceable in any respect by a court of competent jurisdiction, the validity,
                legality, and enforceability of the other provisions herein shall not be affected or impaired in
                any way. Additionally, if any provision of these Customer Terms is prohibited or rendered
                unenforceable under Applicable Law, such provision shall be ineffective only to the extent and
                duration of the prohibition or unenforceability, without invalidating the remaining provisions
                of these Customer Terms"
            />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="14.8 Construction. References to a section include all its subsections. The section headings are for
                convenience only and will not affect how the Customer Terms are construed. Unless the
                Customer Terms refer specifically to “business days,” all references to “days” mean calendar
                days. The Customer Terms are to be interpreted as if jointly drafted by the parties, and no
                provision is to be construed against any party because such provision was drafted by that party.
                The words “include,” “includes,” or “including” used in these Customer Terms means “include,
                without limitation,” whether or not they are in fact followed by those words or words of a
                similar nature"
            />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="14.9 Notice; Communications from SpreeAI. All notices must be in writing. Notice will be deemed
                given and effective: (a) upon receipt if delivered in person; (b) upon delivery if by an
                internationally recognized mail service (e.g., Federal Express), overnight courier, or certified
                or registered mail, postage prepaid, return receipt requested; or (c) on the date transmitted, if
                by email. Except as otherwise provided by these Customer Terms, including SpreeAI’s Privacy
                Policy, all notices from You to SpreeAI will be sent to SpreeAI, at P.O. Box 4300, Incline
                Village, NV 89450. All notices to You may be communicated by SpreeAI in several ways
                including through email (to the email address linked to Your account) or through the SpreeAI
                Services themselves. You acknowledge and agree that SpreeAI may send You communications
                or information related to the SpreeAI Services, which may include, but are not limited to, Your
                use or violation of use, updates, and promotional information and materials"
            />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="14.10 No Third-Party Beneficiaries. There are no third-party beneficiaries under these Customer
                Terms"
            />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="14.11 Relationship of Parties. These Customer Terms are entered into by SpreeAI as a provider of
                SpreeAI Services to You and do not create a franchise, joint venture, partnership, employment,
                agency, or fiduciary relationship between SpreeAI and You in any way."
            />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="14.12 Assignment. You may not transfer or assign the Customer Terms or any of Your interests,
                rights, or obligations under the Customer Terms without the prior written consent of SpreeAI.
                SpreeAI reserves the right to transfer or assign the Customer Terms or any rights or obligations
                under the Customer Terms at any time. Subject to the foregoing, the Customer Terms shall
                bind and inure to the benefit of the parties, their respective successors, and permitted assigns"
            />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText
                text="14.13 Survival. Your obligations under these Customer Terms that by their nature would continue
                beyond the termination of these Customer Terms, including those sections relating to
                indemnification, confidentiality, limitation of liability, intellectual property, warranties,
                general provisions, use of SpreeAI Services, payment, export control, will survive any
                termination of these Customer Terms. Additionally, all representations, warranties, and
                covenants herein and in these Customer Terms will survive the execution and delivery of these
                Customer Terms and will continue in full force until all obligations under these Customer
                Terms have been satisfied in full and these Customer Terms are terminated as a result"
            />
            <LayoutPolicy.Jump />
        </LayoutPolicy>
    );
};

export default CustomTermsConditionsPage;
