import React, { useState } from 'react';
import './index.scss';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Ellipses from '../../Assets/Img/CreateAccount/Ellipses.svg';
import { useWindowDimensions } from '../../Hooks/use-window-dimensions';
import Step3Mobile from './Step3/step3-mobile';
import Step4Email from './Step4Email';
import { CreateAccountRequestDto } from '../../Services/Account/accountDto';
import UseServices from '../../Hooks/use-services';
import { useStores } from '../../Hooks/use-stores';
import { SupportedLanguage } from '../../Stores/LanguageStore';

enum CreateAccountFlowStep {
    Step1,
    Step2,
    Step3,
    Step4,
}

export interface AccountFlowBaseProps {
    onNextClick: (formValues?: any) => void;
    onPrevClick: () => void;
    totalSteps: number;
    elementsAreFocusable: boolean; //inputs and such should only be tabbed focus if current step
}

const CreateAccountFlow = () => {
    const FORM_STEP_AMOUNT = 4;
    const [currentStep, setCurrentStep] = useState<CreateAccountFlowStep>(
        CreateAccountFlowStep.Step1
    );
    const { windowWidth } = useWindowDimensions();
    const MOBILE_BREAKPOINT = 950;
    const { accountService } = UseServices();
    const { loadingStore, languageStore } = useStores();

    const [animationStyles, setAnimationStyles] = useState<{
        step1: {};
        step2: {};
        step3: {};
        step4: {};
    }>({
        step1: {},
        step2: {
            display: 'none',
        },
        step3: {
            display: 'none',
        },
        step4: {
            display: 'none',
        },
    });

    const [formValues, setFormValues] = useState<any>();

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            //dont behavior smooth it makes too much movement with the step animation
        });
    };

    const stepForward = (formValues: any) => {
        if (formValues) {
            setFormValues((prev: any) => ({ ...prev, ...formValues }));
        }

        const nextStep = currentStep + 1;
        setAnimationStyles({
            step1: {
                display: nextStep === CreateAccountFlowStep.Step1 ? 'block' : 'none',
                animation: 'currentSlideToLeft 0.5s forwards',
            },
            step2: {
                display: nextStep === CreateAccountFlowStep.Step2 ? 'block' : 'none',
                animation:
                    nextStep === CreateAccountFlowStep.Step2
                        ? 'step2SlideIntoFromRight 0.5s forwards'
                        : nextStep === CreateAccountFlowStep.Step3
                        ? 'currentSlideToLeft 0.5s forwards'
                        : undefined,
            },
            step3: {
                display: nextStep === CreateAccountFlowStep.Step3 ? 'block' : 'none',
                animation:
                    nextStep === CreateAccountFlowStep.Step3
                        ? 'step3SlideInto 0.5s forwards'
                        : nextStep === CreateAccountFlowStep.Step4
                        ? 'currentSlideToLeft 0.5s forwards'
                        : undefined,
            },
            step4: {
                display: nextStep === CreateAccountFlowStep.Step4 ? 'block' : 'none',
                animation:
                    nextStep === CreateAccountFlowStep.Step4
                        ? 'step3SlideInto 0.5s forwards'
                        : undefined,
            },
        });
        setCurrentStep((prev) => prev + 1);
        scrollToTop();
    };

    const stepBackward = () => {
        const nextStep = currentStep - 1;
        setAnimationStyles((prev) => ({
            step1: {
                display: nextStep === CreateAccountFlowStep.Step1 ? 'block' : 'none',
                animation:
                    nextStep === CreateAccountFlowStep.Step1
                        ? 'step1SlideInto 0.5s forwards'
                        : prev.step1,
            },
            step2: {
                display: nextStep === CreateAccountFlowStep.Step2 ? 'block' : 'none',
                animation:
                    nextStep === CreateAccountFlowStep.Step1
                        ? 'currentSlideToRight 0.5s forwards'
                        : nextStep === CreateAccountFlowStep.Step2
                        ? 'step2SlideIntoFromLeft 0.5s forwards'
                        : undefined,
            },
            step3: {
                display: nextStep === CreateAccountFlowStep.Step3 ? 'block' : 'none',
                animation:
                    nextStep === CreateAccountFlowStep.Step2
                        ? 'currentSlideToRight 0.5s forwards'
                        : undefined,
            },
            step4: {},
        }));
        setCurrentStep((prev) => prev - 1);
        scrollToTop();
    };

    const submit = async () => {
        loadingStore.addLoading();

        const request: CreateAccountRequestDto = {
            first_name: formValues.firstName,
            last_name: formValues.lastName,
            company: formValues.companyName,
            company_role: formValues.role,
            email: formValues.email,
            password: formValues.password,
            company_url:
                formValues.companyUrl && formValues.companyUrl.length > 0
                    ? formValues.companyUrl
                    : undefined,
            language:
                languageStore.currentLanguage === SupportedLanguage.FR
                    ? 'fr-FR'
                    : languageStore.currentLanguage === SupportedLanguage.IT
                    ? 'it-IT'
                    : languageStore.currentLanguage === SupportedLanguage.ES
                    ? 'es-ES'
                    : 'en-US',
        };
        const response = await accountService.createAccount(request);
        loadingStore.removeLoading();

        if (response) stepForward(undefined);
    };

    return (
        <div className={`CreateAccountFlow -step-${currentStep}`}>
            <div className="decoration">
                <img loading="lazy" src={Ellipses} alt="decoration" />
            </div>

            <div className="steps-container">
                <div
                    className="animation-container step1"
                    style={{
                        ...animationStyles.step1,
                    }}
                >
                    <Step1
                        onNextClick={stepForward}
                        onPrevClick={stepBackward}
                        totalSteps={FORM_STEP_AMOUNT}
                        elementsAreFocusable={currentStep === CreateAccountFlowStep.Step1}
                    />
                </div>

                <div
                    className="animation-container step2"
                    style={{
                        ...animationStyles.step2,
                    }}
                >
                    <Step2
                        onNextClick={stepForward}
                        onPrevClick={stepBackward}
                        totalSteps={FORM_STEP_AMOUNT}
                        elementsAreFocusable={currentStep === CreateAccountFlowStep.Step2}
                    />
                </div>

                <div
                    className="animation-container step3"
                    style={{
                        ...animationStyles.step3,
                    }}
                >
                    {windowWidth >= MOBILE_BREAKPOINT ? (
                        <Step3
                            onNextClick={submit}
                            onPrevClick={stepBackward}
                            totalSteps={FORM_STEP_AMOUNT}
                            elementsAreFocusable={currentStep === CreateAccountFlowStep.Step3}
                        />
                    ) : (
                        <Step3Mobile
                            onNextClick={submit}
                            onPrevClick={stepBackward}
                            totalSteps={FORM_STEP_AMOUNT}
                            elementsAreFocusable={currentStep === CreateAccountFlowStep.Step3}
                        />
                    )}
                </div>

                <div
                    className="animation-container step4"
                    style={{
                        ...animationStyles.step4,
                    }}
                >
                    <Step4Email
                        email={formValues?.email}
                        onNextClick={() => undefined}
                        onPrevClick={() => undefined}
                        totalSteps={FORM_STEP_AMOUNT}
                        elementsAreFocusable={currentStep === CreateAccountFlowStep.Step4}
                    />
                </div>
            </div>
        </div>
    );
};

export default CreateAccountFlow;
