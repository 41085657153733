import React from 'react';
import useForm from './hooks';

const FormContent: React.FC<any> = ({ children, onSubmit }) => {
    const form = useForm();

    return (
        <form
            onSubmit={async (event: any) => {
                event.preventDefault();
                const result = await onSubmit(form.onSubmit());

                if (result) {
                    form.clearValues();
                }
            }}
            className="form-content"
        >
            {children}
        </form>
    );
};

export default FormContent;
