import React from 'react';
import useCustomNavigation from '../../Hooks/use-custom-navigation';
import CONSTANTS from '../../Constants';

import './index.scss';

interface BreadCrumbProps {
    currentFilter: {
        value: string;
        label: string;
    };
}

const BreadCrumb: React.FC<BreadCrumbProps> = ({ currentFilter }) => {
    const nav = useCustomNavigation();

    const onClickRoot = () => nav(CONSTANTS.SCREEN.BLOG);

    const onClickCategory = () => {
        nav(
            CONSTANTS.SCREEN.BLOG,
            undefined,
            '?categoryId=' + currentFilter.value + '&categoryName=' + currentFilter.label
        );
    };

    return (
        <div className="BreadCrumb cursor-pointer">
            <span
                className="btn-delete-default-style text-element-greys-secondary"
                onClick={onClickRoot}
            >
                BLOG
            </span>{' '}
            {'>'}{' '}
            <span
                className="tbtn-delete-default-style ext-element-greys-secondary"
                onClick={onClickCategory}
            >
                {currentFilter.label.toUpperCase()}
            </span>
        </div>
    );
};

export default BreadCrumb;
