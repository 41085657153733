import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const ShoppingBagIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" {...props} fill="none">
            <g clipPath="url(#clip0_3597_20426)">
                <path
                    d="M33.75 7.5H6.25C5.55964 7.5 5 8.05964 5 8.75V31.25C5 31.9404 5.55964 32.5 6.25 32.5H33.75C34.4404 32.5 35 31.9404 35 31.25V8.75C35 8.05964 34.4404 7.5 33.75 7.5Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M5 12.5H35"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M26.25 17.5C26.25 19.1576 25.5915 20.7473 24.4194 21.9194C23.2473 23.0915 21.6576 23.75 20 23.75C18.3424 23.75 16.7527 23.0915 15.5806 21.9194C14.4085 20.7473 13.75 19.1576 13.75 17.5"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};
