import React from 'react';
import './index.scss';
import Button from '../../../../Components/Button';
import CONSTANTS from '../../../../Constants';
import useCustomNavigation from '../../../../Hooks/use-custom-navigation';
import Copydeck from '../../../../i18n/Copydeck';
import WrapperSection from '../../../../Components/WrapperSection';
import { SMSIcon } from '../../../../Icons/sms-icon';
import { CoatHangerIcon } from '../../../../Icons/coat-hanger-icon';
import UsersIcon from '../../../../Icons/users-icon';
import DressIcon from '../../../../Icons/dress-icon';
import KeyHoleIcon from '../../../../Icons/key-hole-icon';
import RulerIcon from '../../../../Icons/ruler-icon';

const ExperienceBenefits = () => {
    const navigate = useCustomNavigation();
    const BENEFITS = [
        {
            icon: CoatHangerIcon,
            title: Copydeck.benefit1Title,
            paragraph: Copydeck.benefit1Paragraph,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
        {
            icon: SMSIcon,
            title: Copydeck.benefit2Title,
            paragraph: Copydeck.benefit2Paragraph,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
        {
            icon: UsersIcon,
            title: Copydeck.benefit3Title,
            paragraph: Copydeck.benefit3Paragraph,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
        {
            icon: DressIcon,
            title: Copydeck.benefit4Title,
            paragraph: Copydeck.benefit4Paragraph,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
        {
            icon: KeyHoleIcon,
            title: Copydeck.benefit5Title,
            paragraph: Copydeck.benefit5Paragraph,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
        {
            icon: RulerIcon,
            title: Copydeck.benefit6Title,
            paragraph: Copydeck.benefit6Paragraph,
            backgroundColor: '#F9F9F9',
            color: '#2b2b2b',
        },
    ];

    return (
        <div className="ExperienceBenefits">
            <div className="content">
                <WrapperSection.Title className="title" htmlTag="h1">
                    {Copydeck.benefitsForCustomers}
                </WrapperSection.Title>

                <ul className="benefits">
                    {BENEFITS.map((benefit, index) => {
                        const IconComponent = benefit.icon;

                        return (
                            <li key={index} className="benefit">
                                <div
                                    className="benefit-wrapper-icon"
                                    style={{
                                        backgroundColor: benefit.backgroundColor,
                                    }}
                                >
                                    <IconComponent fill={benefit.color} width={40} height={40} />
                                </div>

                                <div className="benefit-info">
                                    <div className="benefit-title text-body-primary-font-bold text-surface-grey-opposite">
                                        {benefit.title}
                                    </div>
                                    <div className="benefit-description text-body">
                                        {benefit.paragraph}
                                    </div>
                                </div>
                            </li>
                        );
                    })}
                </ul>

                <Button
                    text={Copydeck.contactUs}
                    type="black"
                    onClick={() => navigate(CONSTANTS.SCREEN.CONTACT_US)}
                    className="cta-contact-us"
                    fullInMobile={false}
                />
            </div>
        </div>
    );
};

export default ExperienceBenefits;
