import React, { FunctionComponent, useState } from 'react';
import { AccountFlowBaseProps } from '..';
import Button, { BtnSize } from '../../../Components/Button';
import { useTranslation } from 'react-i18next';
import AnimatedInput from '../Components/AnimatedInput';
import '../stepBaseStyle.scss';
import { useFormValidation } from '../../../Hooks/use-form-validation';
import { CreateAccountSchema } from '../../../Schemas/CreateAccountSchema';

interface Step2Props extends AccountFlowBaseProps {}

const Step2: FunctionComponent<Step2Props> = ({
    onPrevClick,
    onNextClick,
    totalSteps,
    elementsAreFocusable,
}) => {
    const { t } = useTranslation();
    const { validateSingleInput } = useFormValidation(CreateAccountSchema);

    const [formValues, setFormValues] = useState({});
    const [validationState, setValidationState] = useState<
        Map<string, { validated: boolean; errors: string[] }>
    >(
        new Map([
            ['email', { validated: false, errors: [] }],
            ['password', { validated: false, errors: [] }],
            ['confirmPassword', { validated: false, errors: [] }],
        ])
    );

    const onInputBlur = async (fieldName: string, value: any) => {
        const validation = await validateSingleInput(fieldName, value);

        setValidationState((prev) => {
            return new Map(prev.set(fieldName, { validated: true, errors: validation.errors }));
        });

        if (
            (fieldName === 'confirmPassword' ||
                (fieldName === 'password' && (formValues as any)['confirmPassword']?.length > 1)) &&
            value !== (formValues as any)['password']
        ) {
            setValidationState((prev) => {
                return new Map(
                    prev.set('confirmPassword', {
                        validated: true,
                        errors: [t('Errors.password_does_not_match')],
                    })
                );
            });
        }

        setFormValues((prev) => ({ ...prev, [fieldName]: value }));
    };

    const CURRENT_STEP = 2;

    return (
        <div className="Step2 stepBaseStyle">
            <h3 className="text-title-bold text-surface-grey-primary">
                {t('CreateAccount.step_2_subtitle')}
            </h3>
            <h1 className="deprecated-text-display-large">{t('CreateAccount.step_2_title')}</h1>

            <div className="inputs-container">
                <div className="row">
                    <AnimatedInput
                        label={t('account_email')}
                        name="email"
                        type="email"
                        errors={validationState.get('email')?.errors}
                        isValid={
                            validationState.get('email')!.validated &&
                            validationState.get('email')!.errors.length < 1
                        }
                        onBlur={(e) => onInputBlur('email', e)}
                        isFocusable={elementsAreFocusable}
                    />
                </div>
                <div className="row">
                    <AnimatedInput
                        label={t('password')}
                        name="password"
                        type="password"
                        errors={validationState.get('password')?.errors}
                        isValid={
                            validationState.get('password')!.validated &&
                            validationState.get('password')!.errors.length < 1
                        }
                        onBlur={(e) => onInputBlur('password', e)}
                        isFocusable={elementsAreFocusable}
                    />

                    <AnimatedInput
                        label={t('confirm_password')}
                        name="confirmPassword"
                        type="password"
                        errors={validationState.get('confirmPassword')?.errors}
                        isValid={
                            validationState.get('confirmPassword')!.validated &&
                            validationState.get('confirmPassword')!.errors.length < 1 &&
                            ((formValues as any)['password'] as string) ===
                                ((formValues as any)['confirmPassword'] as string)
                        }
                        onBlur={(e) => onInputBlur('confirmPassword', e)}
                        isFocusable={elementsAreFocusable}
                    />
                </div>
            </div>

            <div className="bottom-section">
                <p className="deprecated-text-title-medium text-element-greys-secondary">
                    {t('step')} {CURRENT_STEP}/{totalSteps}
                </p>
                <div className="navigation-container">
                    <Button
                        type="borderless"
                        className="prev-btn cursor-pointer"
                        text={t('previous_step')}
                        onClick={onPrevClick}
                        isFocusable={elementsAreFocusable}
                    />
                    <Button
                        text={t('next_step')}
                        onClick={() => onNextClick(formValues)}
                        type="primary"
                        size={BtnSize.fixed250}
                        disabled={Array.from(validationState.values()).some(
                            (validation) =>
                                validation.validated === false || validation.errors.length > 0
                        )}
                        isFocusable={elementsAreFocusable}
                    />
                </div>
            </div>
        </div>
    );
};

export default Step2;
