import './index.scss';
import cardImage1 from '../../../../Assets/Img/Home/en/card-image1.webp';
import cardImage2 from '../../../../Assets/Img/Home/en/card-image2.webp';

import cardImage1Fr from '../../../../Assets/Img/Home/fr/card-image1.webp';
import cardImage2Fr from '../../../../Assets/Img/Home/fr/card-image2.webp';

import cardImage1IT from '../../../../Assets/Img/Home/it/card-image1.webp';
import cardImage2IT from '../../../../Assets/Img/Home/it/card-image2.webp';

import cardImage1ES from '../../../../Assets/Img/Home/es/card-image1.webp';
import cardImage2ES from '../../../../Assets/Img/Home/es/card-image2.webp';

import Copydeck from '../../../../i18n/Copydeck';
import WrapperImage from '../../../../Components/WrapperImage';

const SharingAndFeedback = () => {
    return (
        <div className="SharingAndFeedback">
            <div className="card">
                <div className="image-container">
                    <WrapperImage
                        src={{
                            fr: cardImage1Fr,
                            en: cardImage1,
                            it: cardImage1IT,
                            es: cardImage1ES,
                        }}
                        alt={Copydeck.homePageHsharingAndFeedBackSharingCardText1}
                        className="image"
                    />
                </div>

                <div className="text-body-primary-font-bold text-element-black-secondary-alt title capitalize">
                    {Copydeck.homePageHsharingAndFeedBackSharingCardTitle1}
                </div>
                <div className="text-body text-element-greys-secondary">
                    {Copydeck.homePageHsharingAndFeedBackSharingCardText1}
                </div>
            </div>

            <div className="card">
                <div className="image-container">
                    <WrapperImage
                        src={{
                            fr: cardImage2Fr,
                            en: cardImage2,
                            it: cardImage2IT,
                            es: cardImage2ES,
                        }}
                        alt={Copydeck.homePageHsharingAndFeedBackSharingCardText2}
                        className="image"
                    />
                </div>

                <div className="text-body-primary-font-bold text-element-black-secondary-alt title capitalize">
                    {Copydeck.homePageHsharingAndFeedBackSharingCardTitle2}
                </div>
                <div className="text-body text-element-greys-secondary">
                    {Copydeck.homePageHsharingAndFeedBackSharingCardText2}
                </div>
            </div>
        </div>
    );
};

export default SharingAndFeedback;
