import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css'; //this must be imported before WelcomeModal
import { useStores } from '../../Hooks/use-stores';
import WelcomeModal from '../WelcomeModal';
import CookiesModal from '../CookiesModal';
import CookiesModalDetail from '../CookiesModalDetail';
import OnBoardingModal from '../OnBoardingModal';
import { observer } from 'mobx-react';
import { useWindowDimensions } from '../../Hooks/use-window-dimensions';

const ModalManagement = observer(() => {
    const [showModal, setShowModal] = useState({
        welcome: false,
        onboarding: false,
        cookies: false,
        cookiesDetail: false,
    });
    const { welcomeModalStore, onboardingModalStore, cookiesStore } = useStores();
    const { windowWidth } = useWindowDimensions();

    useEffect(() => {
        setShowModal((prev) => ({
            ...prev,
            onboarding: onboardingModalStore?.isVisible,
            welcome: welcomeModalStore?.isVisible,
            cookies: cookiesStore?.modalIsVisible,
            cookiesDetail: cookiesStore?.modalDetailIsVisible,
        }));
    }, [
        welcomeModalStore?.isVisible,
        onboardingModalStore?.isVisible,
        cookiesStore?.modalIsVisible,
        cookiesStore?.modalDetailIsVisible,
        onboardingModalStore,
        welcomeModalStore,
        cookiesStore,
    ]);

    useEffect(() => {
        cookiesStore.run();
    }, []);

    const onCloseModalOnBoarding = () => {
        onboardingModalStore.setIsVisible(false);
        setShowModal((prev) => ({ ...prev, onboarding: false }));
    };

    const onCloseModalWelcome = () => {
        welcomeModalStore.setIsVisible(false);
        setShowModal((prev) => ({ ...prev, welcome: false }));
    };

    return (
        <>
            {showModal.welcome && (
                <Modal
                    open={showModal.welcome}
                    onClose={onCloseModalWelcome}
                    center
                    closeOnEsc={false}
                    showCloseIcon={false}
                    classNames={{
                        modal: 'lib-modal-container welcome-modal',
                    }}
                >
                    <WelcomeModal onClose={onCloseModalWelcome} />
                </Modal>
            )}
            {showModal.cookies && <CookiesModal />}
            {showModal.cookiesDetail && windowWidth <= 768 ? (
                <div
                    style={{
                        zIndex: 1000,

                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <CookiesModalDetail />
                </div>
            ) : (
                showModal.cookiesDetail && (
                    <Modal
                        open={showModal.cookiesDetail}
                        onClose={() => cookiesStore.closeCookiesDetailModal()}
                        center
                        closeOnEsc={false}
                        showCloseIcon={false}
                        classNames={{
                            modal: 'lib-modal-container cookies-detail-modal',
                        }}
                    >
                        <CookiesModalDetail />
                    </Modal>
                )
            )}
            {showModal.onboarding && (
                <Modal
                    open={showModal.onboarding}
                    onClose={onCloseModalOnBoarding}
                    center
                    closeOnEsc={false}
                    showCloseIcon={false}
                    classNames={{
                        modal: 'lib-modal-container onboarding-modal',
                    }}
                >
                    <OnBoardingModal
                        onClose={onCloseModalOnBoarding}
                        urlEmbed={onboardingModalStore.urlEmbed}
                    />
                </Modal>
            )}
        </>
    );
});

export default ModalManagement;
