import { SvgProps } from './SvgProps';

const FeatureVideoIcon: React.FC<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="41"
            height="40"
            viewBox="0 0 41 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M25.8695 16.6875L18.3695 11.6875C18.1812 11.5619 17.9624 11.4898 17.7363 11.4788C17.5102 11.4678 17.2854 11.5185 17.0858 11.6253C16.8862 11.7322 16.7194 11.8912 16.6032 12.0854C16.487 12.2796 16.4256 12.5018 16.4258 12.7281V22.7281C16.4256 22.9545 16.487 23.1767 16.6032 23.3709C16.7194 23.5651 16.8862 23.7241 17.0858 23.831C17.2854 23.9378 17.5102 23.9884 17.7363 23.9775C17.9624 23.9665 18.1812 23.8944 18.3695 23.7688L25.8695 18.7688C26.041 18.6547 26.1816 18.5 26.2789 18.3184C26.3761 18.1369 26.427 17.9341 26.427 17.7281C26.427 17.5222 26.3761 17.3194 26.2789 17.1379C26.1816 16.9563 26.041 16.8016 25.8695 16.6875ZM18.9258 20.3922V15.0719L22.9227 17.7281L18.9258 20.3922ZM33.9258 6.47815H6.42578C5.76274 6.47815 5.12686 6.74154 4.65801 7.21038C4.18917 7.67922 3.92578 8.31511 3.92578 8.97815V26.4781C3.92578 27.1412 4.18917 27.7771 4.65801 28.2459C5.12686 28.7148 5.76274 28.9781 6.42578 28.9781H33.9258C34.5888 28.9781 35.2247 28.7148 35.6935 28.2459C36.1624 27.7771 36.4258 27.1412 36.4258 26.4781V8.97815C36.4258 8.31511 36.1624 7.67922 35.6935 7.21038C35.2247 6.74154 34.5888 6.47815 33.9258 6.47815ZM33.9258 26.4781H6.42578V8.97815H33.9258V26.4781ZM36.4258 32.7281C36.4258 33.0597 36.2941 33.3776 36.0597 33.612C35.8252 33.8465 35.5073 33.9781 35.1758 33.9781H5.17578C4.84426 33.9781 4.52632 33.8465 4.2919 33.612C4.05748 33.3776 3.92578 33.0597 3.92578 32.7281C3.92578 32.3966 4.05748 32.0787 4.2919 31.8443C4.52632 31.6098 4.84426 31.4781 5.17578 31.4781H35.1758C35.5073 31.4781 35.8252 31.6098 36.0597 31.8443C36.2941 32.0787 36.4258 32.3966 36.4258 32.7281Z"
                fill={props.fill}
            />
        </svg>
    );
};

export default FeatureVideoIcon;
