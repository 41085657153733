import React from 'react';
import Form from '../../Components/Form';
import './index.scss';
import { useTranslation } from 'react-i18next';
import { LogoBrandSmall } from '../../Layout/Components/logo-brand-small';
import UseServices from '../../Hooks/use-services';
import { useWindowDimensions } from '../../Hooks/use-window-dimensions';

const SupportPage = () => {
    const { t } = useTranslation();
    const { supportRequestService } = UseServices();
    const { windowWidth } = useWindowDimensions();
    const LINEAR_BREAKPOINT = 1200;

    const onSubmit = async (values: { [key: string]: string }): Promise<boolean> => {
        if (!values) {
            return false;
        }

        const attachment = values?.attachment || null;

        const formData = new FormData();
        formData.append('email', values?.contact_email || '');
        formData.append('description', values?.ticket_summary || '');
        formData.append('email', values?.description || '');

        if (attachment) formData.append('attachment', attachment);

        const result = await supportRequestService.sendSupportMessage(formData);
        return result || false;
    };

    return (
        <div
            className="SupportPage"
            style={{
                background: `linear-gradient(-${
                    windowWidth > LINEAR_BREAKPOINT ? 65 : 35
                }deg, black 50%, white 50%)`,
            }}
        >
            <div className="left-side">
                <LogoBrandSmall />
                <h1 className="deprecated-text-display-small">
                    {t('SupportPage.form_support_ticket_title')}
                </h1>

                <p className="deprecated-text-body-large">
                    {t('SupportPage.form_support_ticket_description')}
                </p>
            </div>

            <div className="form-container">
                <Form
                    onSubmit={onSubmit}
                    title={t('support_title_form')}
                    validation={[
                        {
                            inputName: 'contact_email',
                            isEmail: true,
                            required: true,
                        },
                        {
                            inputName: 'ticket_summary',
                            minLength: 5,
                            required: true,
                        },
                        {
                            inputName: 'description',
                            minLength: 20,
                            required: true,
                        },
                        {
                            inputName: 'attachment',
                            acceptedFileTypes: [
                                'image/*',
                                'application/pdf',
                                'application/msword',
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                'text/plain',
                            ],
                        },
                    ]}
                >
                    <div className="mt-30" />
                    <Form.BasicInput
                        label={t('SupportPage.ticket_contact_email')}
                        name="contact_email"
                    />

                    <Form.BasicInput
                        label={t('SupportPage.ticket_summary')}
                        name="ticket_summary"
                    />

                    <Form.TextArea label={t('SupportPage.ticket_description')} name="description" />

                    <Form.FileInput
                        label={t('SupportPage.ticket_attachement')}
                        name="attachment"
                        accept="image/*, application/pdf, .doc, .docx, .txt"
                    />

                    <Form.Submit text={t('SupportPage.create_ticket')} />
                </Form>
            </div>
        </div>
    );
};

export default SupportPage;
