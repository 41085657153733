import React from 'react';
import { SvgProps } from './SvgProps';

const KeyHoleIcon: React.FunctionComponent<SvgProps> = (props) => {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" {...props} fill="none">
            <g clipPath="url(#clip0_3597_20301)">
                <path
                    d="M16.5 28C23.1274 28 28.5 22.6274 28.5 16C28.5 9.37258 23.1274 4 16.5 4C9.87258 4 4.5 9.37258 4.5 16C4.5 22.6274 9.87258 28 16.5 28Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.5 28C23.1274 28 28.5 22.6274 28.5 16C28.5 9.37258 23.1274 4 16.5 4C9.87258 4 4.5 9.37258 4.5 16C4.5 22.6274 9.87258 28 16.5 28Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M14.5 17.465C13.7374 17.0247 13.1415 16.3451 12.8045 15.5316C12.4675 14.7181 12.4084 13.8161 12.6363 12.9656C12.8642 12.115 13.3664 11.3635 14.065 10.8274C14.7635 10.2914 15.6195 10.0009 16.5 10.0009C17.3805 10.0009 18.2365 10.2914 18.935 10.8274C19.6336 11.3635 20.1358 12.115 20.3637 12.9656C20.5916 13.8161 20.5325 14.7181 20.1955 15.5316C19.8586 16.3451 19.2626 17.0247 18.5 17.465L20.5 22H12.5L14.5 17.465Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3597_20301">
                    <rect width="32" height="32" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default KeyHoleIcon;
