import HowItWorks_1 from '../../../../Assets/Img/Experience/en/HowItWorks_1.webp';
import HowItWorks_2 from '../../../../Assets/Img/Experience/en/HowItWorks_2.webp';
import HowItWorks_3 from '../../../../Assets/Img/Experience/en/HowItWorks_3.webp';

import HowItWorks_1FR from '../../../../Assets/Img/Experience/fr/HowItWorks_1.webp';
import HowItWorks_2FR from '../../../../Assets/Img/Experience/fr/HowItWorks_2.webp';
import HowItWorks_3FR from '../../../../Assets/Img/Experience/fr/HowItWorks_3.webp';

import HowItWorks_1ES from '../../../../Assets/Img/Experience/es/HowItWorks_1.webp';
import HowItWorks_2ES from '../../../../Assets/Img/Experience/es/HowItWorks_2.webp';
import HowItWorks_3ES from '../../../../Assets/Img/Experience/es/HowItWorks_3.webp';

import HowItWorks_1IT from '../../../../Assets/Img/Experience/it/HowItWorks_1.webp';
import HowItWorks_2IT from '../../../../Assets/Img/Experience/it/HowItWorks_2.webp';
import HowItWorks_3IT from '../../../../Assets/Img/Experience/it/HowItWorks_3.webp';

import Carousel from '../../../../Components/Carousel';
import HowItWorksStep from '../../../../Components/Carousel/Components/HowItWorksStep';
import WrapperSection from '../../../../Components/WrapperSection';
import { useWindowDimensions } from '../../../../Hooks/use-window-dimensions';
import Copydeck from '../../../../i18n/Copydeck';
import './index.scss';
import WrapperImage from '../../../../Components/WrapperImage';

const ExperienceHowItWorks = () => {
    const CAROUSEL_BREAKPOINT = 1000;
    const { windowWidth } = useWindowDimensions();

    const carouselElements = [
        <HowItWorksStep
            imageElement={
                <WrapperImage
                    src={{
                        fr: HowItWorks_1FR,
                        en: HowItWorks_1,
                        it: HowItWorks_1IT,
                        es: HowItWorks_1ES,
                    }}
                    alt={`${Copydeck.step} 1`}
                />
            }
            stepNumber={1}
            stepText={Copydeck.homePageHowItWorksStep1}
        />,
        <HowItWorksStep
            imageElement={
                <WrapperImage
                    src={{
                        fr: HowItWorks_2FR,
                        en: HowItWorks_2,
                        it: HowItWorks_2IT,
                        es: HowItWorks_2ES,
                    }}
                    alt={`${Copydeck.step} 2`}
                />
            }
            stepNumber={2}
            stepText={Copydeck.homePageHowItWorksStep2}
        />,
        <HowItWorksStep
            imageElement={
                <WrapperImage
                    src={{
                        fr: HowItWorks_3FR,
                        en: HowItWorks_3,
                        it: HowItWorks_3IT,
                        es: HowItWorks_3ES,
                    }}
                    alt={`${Copydeck.step} 3`}
                />
            }
            stepNumber={3}
            stepText={Copydeck.homePageHowItWorksStep3}
        />,
    ];

    return (
        <div className="ExperienceHowItWorks">
            <WrapperSection
                padding="120"
                paddingInMobile="80"
                paddingHorizontal="16"
                className="content"
            >
                <WrapperSection.Title htmlTag="h1" className="title">
                    {Copydeck.homePageHowItWorksHowItWorksTitle}
                </WrapperSection.Title>

                <p
                    className="subtitle text-body"
                    dangerouslySetInnerHTML={{
                        __html: Copydeck.homePageHowItWorksHowItWorksSecondTitle,
                    }}
                />

                <div className="text-body text-element-greys-secondary-alt disclaimer">
                    {Copydeck.homePageHowItWorksDisclaimer}
                </div>

                {windowWidth > CAROUSEL_BREAKPOINT ? (
                    <ul className="steps">
                        {carouselElements.map((element, index) => (
                            <li key={index} className="step">
                                {element}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <div className="ExperienceHowItWorks__carouselContainer">
                        <Carousel
                            carouselElements={carouselElements}
                            centerMode={false}
                            slidesToShow={1}
                            infiniteScroll={false}
                        />
                    </div>
                )}
            </WrapperSection>
        </div>
    );
};

export default ExperienceHowItWorks;
