import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const UserSubstaIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" {...props} fill="none">
            <g clipPath="url(#clip0_7196_23613)">
                <path
                    d="M1.99805 39.0627C3.55877 36.663 5.69413 34.6911 8.21028 33.3261C10.7264 31.9611 13.5437 31.2461 16.4063 31.2461C19.2688 31.2461 22.0861 31.9611 24.6022 33.3261C27.1184 34.6911 29.2537 36.663 30.8145 39.0627"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M33.5938 31.25C36.4562 31.2483 39.2736 31.9621 41.79 33.3266C44.3063 34.691 46.4417 36.6627 48.002 39.0625"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.4062 31.25C22.0154 31.25 26.5625 26.7029 26.5625 21.0938C26.5625 15.4846 22.0154 10.9375 16.4062 10.9375C10.7971 10.9375 6.25 15.4846 6.25 21.0938C6.25 26.7029 10.7971 31.25 16.4062 31.25Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M29.8223 11.6602C31.2116 11.106 32.7061 10.8656 34.1991 10.9561C35.692 11.0466 37.1466 11.4657 38.4588 12.1835C39.771 12.9013 40.9085 13.9 41.79 15.1084C42.6715 16.3168 43.2751 17.705 43.5579 19.1737C43.8407 20.6424 43.7955 22.1555 43.4257 23.6048C43.0559 25.0541 42.3706 26.4038 41.4187 27.5575C40.4668 28.7112 39.2718 29.6404 37.9191 30.2787C36.5664 30.917 35.0895 31.2487 33.5937 31.25"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_7196_23613">
                    <rect width="50" height="50" fill={props.fill} />
                </clipPath>
            </defs>
        </svg>
    );
};
