import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import './index.scss';
import Button from '../Button';
import Logo from '../../Assets/Img/Color_Spree_Logo.webp';
import BackgroundImg from '../../Assets/Img/Login/Welcome.webp';
import ModalImg from '../../Assets/Img/WelcomeModal/Modal_Img.webp';
import ModalImgMobile from '../../Assets/Img/WelcomeModal/Modal_Img_Mobile.webp';
import { useTranslation } from 'react-i18next';
import Confetti from 'react-confetti';
import { useWindowDimensions } from '../../Hooks/use-window-dimensions';
import Carousel from '../Carousel';

interface WelcomeModalProps {
    onClose: () => void;
}
const WelcomeModal: FunctionComponent<WelcomeModalProps> = ({ onClose }) => {
    const { t } = useTranslation();
    const { windowWidth } = useWindowDimensions();
    const rightSideRef = useRef<HTMLDivElement | null>(null);
    const CAROUSEL_BREAKPOINT = 950;
    const [activeSlide, setActiveSlide] = useState(0);

    const [confettiSize, setConfettiSize] = useState<{ width: number; height: number }>({
        width: 0,
        height: 0,
    });

    const handleSlideChange = (i: number) => {
        setActiveSlide(i);
    };

    useEffect(() => {
        const handleResize = () => {
            if (rightSideRef.current) {
                setConfettiSize({
                    width: rightSideRef.current.clientWidth,
                    height: rightSideRef.current.clientHeight,
                });
            }
        };

        const resizeObserver = new ResizeObserver(handleResize);
        const current = rightSideRef.current;
        if (current) {
            resizeObserver.observe(current);
        }

        return () => {
            if (current) {
                resizeObserver.unobserve(current);
            }
        };
    }, [rightSideRef]);

    const carouselSlides = [
        <div className="left" style={{ backgroundImage: `url(${BackgroundImg})` }}>
            <div className="align">
                <h1 className="deprecated-text-title-medium-bold text-white">
                    {t('WelcomeModal.WelcomeModal_leftTitle')}
                </h1>

                <p className="text-body-small text-white">{t('WelcomeModal.WelcomeModal_leftp')}</p>
            </div>

            <img loading="lazy" className="img" src={ModalImgMobile} alt="Site" />
        </div>,
        <div className="right">
            <img loading="lazy" className="logo" src={Logo} alt="Spreeai logo" />
            <div className="align">
                <h1 className="deprecated-text-headline-medium">
                    {t('WelcomeModal.WelcomeModal_rightTitle')}
                </h1>
                <p className="deprecated-text-title-medium text-surface-grey-primary">
                    {t('WelcomeModal.WelcomeModal_rightp')}
                </p>
            </div>
            <Button
                text={t('WelcomeModal.WelcomeModal_btn')}
                type="primary"
                onClick={() => onClose()}
            />
        </div>,
    ];

    return (
        <div className="WelcomeModal">
            {windowWidth > CAROUSEL_BREAKPOINT ? (
                <>
                    <div className="left" style={{ backgroundImage: `url(${BackgroundImg})` }}>
                        <div className="align">
                            <h1 className="deprecated-text-title-large-bold text-white">
                                {t('WelcomeModal.WelcomeModal_leftTitle')}
                            </h1>

                            <p className="deprecated-text-title-small text-white">
                                {t('WelcomeModal.WelcomeModal_leftp')}
                            </p>
                        </div>

                        <img loading="lazy" className="img" src={ModalImg} alt="Site" />
                    </div>

                    <div className="right" ref={rightSideRef}>
                        <img loading="lazy" className="logo" src={Logo} alt="Spreeai logo" />
                        <div className="align">
                            <h1 className="deprecated-text-display-small">
                                {t('WelcomeModal.WelcomeModal_rightTitle')}
                            </h1>
                            <p className="deprecated-text-title-large text-surface-grey-primary">
                                {t('WelcomeModal.WelcomeModal_rightp')}
                            </p>
                        </div>

                        <Button
                            text={t('WelcomeModal.WelcomeModal_btn')}
                            type="primary"
                            onClick={() => onClose()}
                        />
                        <Confetti
                            width={confettiSize.width}
                            height={confettiSize.height / 2}
                            numberOfPieces={75}
                        />
                    </div>
                </>
            ) : (
                <div className="mobile">
                    <Carousel
                        centerMode={false}
                        infiniteScroll={true}
                        slidesToShow={1}
                        carouselElements={carouselSlides}
                        showDots={false}
                        onSlideChange={(i) => handleSlideChange(i)}
                    />
                    <div className="dots-container">
                        <div className={`dot ${activeSlide === 0 ? 'active' : ''}`} />
                        <div className={`dot ${activeSlide === 1 ? 'active' : ''}`} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default WelcomeModal;
