import { FC } from 'react';
import { SvgProps } from './SvgProps';

const CharLineUpIcon: FC<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            fill="none"
        >
            <g clipPath="url(#clip0_4887_24626)">
                <path
                    d="M42 39H6V9"
                    stroke={props.fill}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M37.5 13.5L24 27L18 21L6 33"
                    stroke={props.fill}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M37.5 21V13.5H30"
                    stroke={props.fill}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_4887_24626">
                    <rect width="48" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default CharLineUpIcon;
