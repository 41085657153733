import React, { FunctionComponent, useEffect, useState } from 'react';
import { AccountFlowBaseProps } from '..';
import Button, { BtnSize } from '../../../Components/Button';
import '../stepBaseStyle.scss';
import { useTranslation } from 'react-i18next';
import AnimatedInput from '../Components/AnimatedInput';
import { useFormValidation } from '../../../Hooks/use-form-validation';
import { CreateAccountSchema } from '../../../Schemas/CreateAccountSchema';

interface Step1Props extends AccountFlowBaseProps {}

const Step1: FunctionComponent<Step1Props> = ({
    onNextClick,
    totalSteps,
    elementsAreFocusable,
}) => {
    const { t } = useTranslation();
    const { validateSingleInput } = useFormValidation(CreateAccountSchema);
    const [validationState, setValidationState] = useState<
        Map<string, { validated: boolean; errors: string[] }>
    >(
        new Map([
            ['firstName', { validated: false, errors: [] }],
            ['lastName', { validated: false, errors: [] }],
            ['companyName', { validated: false, errors: [] }],
            ['role', { validated: false, errors: [] }],
            ['companyUrl', { validated: false, errors: [] }],
        ])
    );
    const [formValues, setFormValues] = useState({});
    const [webSite, setWebSite] = useState('');

    const onInputBlur = async (fieldName: string, value: any) => {
        const validation = await validateSingleInput(fieldName, value);
        setValidationState((prev) => {
            return new Map(prev.set(fieldName, { validated: true, errors: validation.errors }));
        });
        setFormValues((prev) => ({ ...prev, [fieldName]: value }));
    };

    useEffect(() => {
        onInputBlur('companyUrl', '');
    }, []);

    return (
        <div className="Step1 stepBaseStyle">
            <h3 className="text-title-bold text-surface-grey-primary">
                {t('CreateAccount.step_1_subtitle')}
            </h3>
            <h1 className="deprecated-text-display-large">{t('CreateAccount.step_1_title')}</h1>

            <div className="inputs-container">
                <div className="row">
                    <AnimatedInput
                        label={t('ManageAccount.input_first_name')}
                        name="firstName"
                        errors={validationState.get('firstName')?.errors}
                        isValid={
                            validationState.get('firstName')!.validated &&
                            validationState.get('firstName')!.errors.length < 1
                        }
                        onBlur={(e) => onInputBlur('firstName', e)}
                        isFocusable={elementsAreFocusable}
                    />
                    <AnimatedInput
                        label={t('ManageAccount.input_last_name')}
                        name="lastName"
                        errors={validationState.get('lastName')?.errors}
                        isValid={
                            validationState.get('lastName')!.validated &&
                            validationState.get('lastName')!.errors.length < 1
                        }
                        onBlur={(e) => onInputBlur('lastName', e)}
                        isFocusable={elementsAreFocusable}
                    />
                </div>
                <div className="row">
                    <AnimatedInput
                        label={t('company')}
                        name="companyName"
                        errors={validationState.get('companyName')?.errors}
                        isValid={
                            validationState.get('companyName')!.validated &&
                            validationState.get('companyName')!.errors.length < 1
                        }
                        onBlur={(e) => onInputBlur('companyName', e)}
                        isFocusable={elementsAreFocusable}
                    />
                    <AnimatedInput
                        label={t('role')}
                        name="role"
                        errors={validationState.get('role')?.errors}
                        isValid={
                            validationState.get('role')!.validated &&
                            validationState.get('role')!.errors.length < 1
                        }
                        onBlur={(e) => onInputBlur('role', e)}
                        isFocusable={elementsAreFocusable}
                    />
                </div>
                <div className="row">
                    <AnimatedInput
                        label={t('company_url')}
                        name="companyUrl"
                        errors={validationState.get('companyUrl')?.errors}
                        isValid={
                            validationState.get('companyUrl')!.validated &&
                            validationState.get('companyUrl')!.errors.length < 1
                        }
                        onBlur={(e) => onInputBlur('companyUrl', e)}
                        isFocusable={elementsAreFocusable}
                        isEmpty={webSite.length < 10}
                        onChange={(e) => {
                            setWebSite(e.target.value);
                        }}
                    />
                </div>
            </div>

            <div className="bottom-section">
                <p className="deprecated-text-title-medium text-element-greys-secondary">
                    {t('step')} 1/{totalSteps}
                </p>
                <div className="navigation-container">
                    <Button
                        text={t('next_step')}
                        onClick={() => onNextClick(formValues)}
                        type="primary"
                        size={BtnSize.fixed250}
                        disabled={Array.from(validationState.values()).some(
                            (validation) =>
                                validation.validated === false || validation.errors.length > 0
                        )}
                        isFocusable={elementsAreFocusable}
                    />
                </div>
            </div>
        </div>
    );
};

export default Step1;
