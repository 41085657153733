import React, { useEffect, useState } from 'react';
import './card.scss';
import { CloseIcon } from '../../../../../../Icons/close-icon';
import { theme } from '../../../../../../Style/theme';

interface CardSustainabillityProps {
    item?: {
        icon: () => any;
        title: string;
        description: string;
        longDescription: string;
        backgroundImage?: string;
    };
    flip?: boolean;
    flipUpdate?: boolean;
    isFlipControlled?: boolean;

    onManualFlip?: () => void;
    className?: string;
    forceToHide?: boolean;
}

const CardSustainabillity: React.FC<CardSustainabillityProps> = ({
    item,
    flip = true,
    flipUpdate = false,
    isFlipControlled = false,
    onManualFlip,
    className,
    forceToHide = false,
}) => {
    const [isSelected, setSelected] = useState(false);

    const onClickCard = () => {
        onManualFlip && onManualFlip();
        setSelected((prev) => {
            return !prev;
        });
    };

    useEffect(() => {
        if (!isFlipControlled) {
            return;
        }

        if (flipUpdate) {
            setSelected(true);
        } else {
            setSelected(false);
        }
    }, [flipUpdate]);

    useEffect(() => {
        setSelected(false);
    }, [forceToHide]);

    return (
        <div
            className={`CardSustainabillity ${isSelected ? '-selected' : ''} ${className}`}
            onClick={onClickCard}
        >
            <div className={`card-container ${isSelected ? '-selected' : ''}`}>
                <div className="side -front">
                    {flip && (
                        <div className="wrapper-close-icon">
                            <CloseIcon
                                fill={theme['white']}
                                width={10}
                                height={10}
                                className={`${isSelected ? '-rotate-0' : '-rotate-45'}`}
                            />
                        </div>
                    )}

                    <img
                        src={item?.backgroundImage}
                        alt="substainabillity of spreeAI"
                        width={419}
                        height={540}
                        className="card-substainabillity-image"
                    />

                    <div className="overlay"></div>

                    <div className="wrapper-substainabillity-info -center-content">
                        <div className="infos -front">
                            {item?.icon && item?.icon()}

                            <div className="text-body-primary-font-bold text-white">
                                {item?.title}
                            </div>
                            <div className="text-body job-title text-white">
                                {item?.description}
                            </div>
                        </div>
                    </div>
                </div>

                {flip ? (
                    <div className="side -back">
                        <div className="wrapper-close-icon">
                            <CloseIcon
                                fill={theme['white']}
                                width={10}
                                height={10}
                                className={`${isSelected ? '-rotate-0' : '-rotate-45'}`}
                            />
                        </div>

                        <img
                            src={item?.backgroundImage}
                            alt="substainabillity of spreeAI"
                            width={419}
                            height={540}
                            className="card-substainabillity-image"
                        />

                        <div className="wrapper-substainabillity-info -selected">
                            <div className="infos -back">
                                <div
                                    className={`text-body-primary-font-bold text-white ${
                                        isSelected ? 'substainabillity-selected' : ''
                                    }`}
                                >
                                    {item?.title}
                                </div>

                                <div
                                    className={`text-description text-body text-white ${
                                        isSelected ? 'substainabillity-selected' : ''
                                    }`}
                                >
                                    {item?.longDescription}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
export default CardSustainabillity;
