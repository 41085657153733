import React, { useState } from 'react';
import './index.scss';
import WrapperSectionTitle from '../../../Components/WrapperSection/title';
import { ChevronBottomIcon } from '../../../Icons/chevron-bottom-icon';
import { theme } from '../../../Style/theme';
import { useTranslation } from 'react-i18next';
import WrapperImage from '../../../Components/WrapperImage';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

const PartnerRessourceTryOn = () => {
    const { t } = useTranslation();
    const [isOpened, setIsOpened] = useState(false);

    return (
        <div className="PartnerRessourceTryOn">
            <div className="left">
                <WrapperSectionTitle>
                    {t('PartnerAsk.compare_try_on_result_title')}
                </WrapperSectionTitle>

                <div className="text-desc">{t('PartnerAsk.compare_try_on_result_desc')}</div>

                <div className="wrapper-dropdown">
                    <button
                        className="view_image_before_drop_down btn-delete-default-style"
                        onClick={() => {
                            setIsOpened((prev) => !prev);
                        }}
                    >
                        <div className="label-drop text-body-bold">
                            {t('PartnerAsk.view_image_before')}
                        </div>
                        <ChevronBottomIcon
                            fill={theme['black']}
                            className={` ${isOpened ? '-rotate-180' : '-rotate-0'}`}
                        />
                    </button>

                    {isOpened && (
                        <div className="image-wrapper">
                            <WrapperImage
                                className="image"
                                alt=""
                                uniqueSrc={require('./../../../Assets/Img/PartnerRessources/before.webp')}
                            />
                        </div>
                    )}
                </div>
            </div>

            <div className="right">
                <ReactCompareSlider
                    className="compare-slider"
                    itemOne={
                        <ReactCompareSliderImage src={require('./standard.png')} alt="Image two" />
                    }
                    itemTwo={
                        <ReactCompareSliderImage src={require('./premium.png')} alt="Image one" />
                    }
                />
            </div>
        </div>
    );
};

export default PartnerRessourceTryOn;
