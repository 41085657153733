import React, { FunctionComponent, ReactNode } from 'react';
import './index.scss';
import Copydeck from '../../../../i18n/Copydeck';

interface HowItWorksStepProps {
    imageElement: ReactNode;
    stepNumber: number;
    stepText: string;
}
const HowItWorksStep: FunctionComponent<HowItWorksStepProps> = ({
    imageElement,
    stepNumber,
    stepText,
}) => {
    return (
        <div className="HowItWorksStep">
            {imageElement}

            <p className="step text-body-bold">
                {Copydeck.step} {stepNumber}
            </p>

            <p className="step-text text-body">{stepText}</p>
        </div>
    );
};

export default HowItWorksStep;
