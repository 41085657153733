import React from 'react';

export interface LayoutPolicyNumberedTitleProps {
    number: number;
    title: string;
}

const LayoutPolicyNumberedTitle: React.FC<LayoutPolicyNumberedTitleProps> = ({ number, title }) => {
    return (
        <span className="LayoutPolicyNumberedTitle deprecated-text-display-small">
            {number}. &nbsp; <u>{title}</u>
        </span>
    );
};

export default LayoutPolicyNumberedTitle;
