import React from 'react';

export const LockIcon = () => {
    return (
        <svg
            width="10"
            height="13"
            viewBox="0 0 10 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M1.9548 12.7987H8.04436C8.8856 12.7987 9.29994 12.3843 9.29994 11.4678V6.76561C9.29994 5.93693 8.96094 5.51631 8.25781 5.44725V3.89661C8.25781 1.51101 6.6695 0.362152 4.99958 0.362152C3.32966 0.362152 1.74135 1.51101 1.74135 3.89661V5.46609C1.08845 5.56653 0.699219 5.98087 0.699219 6.76561V11.4678C0.699219 12.3843 1.11356 12.7987 1.9548 12.7987ZM2.94671 3.78361C2.94671 2.32086 3.87584 1.51729 4.99958 1.51729C6.11705 1.51729 7.05245 2.32086 7.05245 3.78361V5.4347L2.94671 5.44097V3.78361Z"
                fill="#1B1D1E"
            />
        </svg>
    );
};
