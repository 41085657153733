import React from 'react';
import './card-dashboard.scss';
import { useTranslation } from 'react-i18next';
import { theme } from '../../../Style/theme';

export interface CardDashboardProps {
    /**children?: If you want use only padding/size of card */
    children?: React.ReactNode;

    icon?: (color: string) => React.ReactNode;
    title?: string;
    description?: string;

    isCommingSoon?: boolean;
    onClick?: () => void;
    className?: string;
}

const CardDashboard: React.FC<CardDashboardProps> = ({
    children,
    icon,
    title,
    description,
    isCommingSoon,
    onClick,
    className,
}) => {
    const { t } = useTranslation();
    return (
        <div
            className={`CardDashboard ${onClick ? '-is-clickable' : ''} ${className || ''}`}
            onClick={onClick}
        >
            {children ? (
                <div className="inner">{children}</div>
            ) : (
                <div className="inner">
                    <div className="wrapper-icon">{icon && icon(theme['black'])}</div>
                    <div className="text-body-large-bold">{title}</div>
                    <div className="text-body-small text-desc">{description}</div>

                    <div
                        className={`text-body-medium-bold borderless-cta btn-delete-default-style ${
                            isCommingSoon ? '-is-coming-soon' : '-is-ok'
                        }`}
                        onClick={isCommingSoon ? undefined : onClick}
                    >
                        {isCommingSoon
                            ? t('DashboardPage.access_dashboard_coming_soon')
                            : t('DashboardPage.access_dashboard')}
                    </div>
                </div>
            )}
        </div>
    );
};

export default CardDashboard;
