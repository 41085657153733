import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import en from './en.json';
import fr from './fr.json';
import it from './it.json';
import es from './es.json';

i18n.use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            en: en,
            fr: fr,
            it: it,
            es: es,
        },
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });
