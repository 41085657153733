import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const GiftIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" {...props} fill="none">
            <g clipPath="url(#clip0_9137_7329)">
                <path
                    d="M33.75 12.5H6.25C5.55964 12.5 5 13.0596 5 13.75V18.75C5 19.4404 5.55964 20 6.25 20H33.75C34.4404 20 35 19.4404 35 18.75V13.75C35 13.0596 34.4404 12.5 33.75 12.5Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M32.5 20V31.25C32.5 31.5815 32.3683 31.8995 32.1339 32.1339C31.8995 32.3683 31.5815 32.5 31.25 32.5H8.75C8.41848 32.5 8.10054 32.3683 7.86612 32.1339C7.6317 31.8995 7.5 31.5815 7.5 31.25V20"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20 12.5V32.5"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M27.6234 4.87656C29.0828 6.33594 29.1687 8.79219 27.6234 10.1609C24.9812 12.5 20 12.5 20 12.5C20 12.5 20 7.51875 22.3438 4.87656C23.7078 3.33125 26.1641 3.41719 27.6234 4.87656Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.3765 4.87656C10.9171 6.33594 10.8312 8.79219 12.3765 10.1609C15.0187 12.5 19.9999 12.5 19.9999 12.5C19.9999 12.5 19.9999 7.51875 17.6562 4.87656C16.2921 3.33125 13.8359 3.41719 12.3765 4.87656Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_9137_7329">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
