import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const EllipseIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="563"
            height="346"
            viewBox="0 0 563 346"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M560.482 267.797C551.961 307.26 513.153 332.59 457.016 341.489C400.911 350.383 327.651 342.829 250.543 316.693C173.436 290.557 107.128 250.803 62.2543 207.681C17.3547 164.535 -5.96775 118.146 2.553 78.6825C11.0738 39.2193 49.8815 13.8898 106.018 4.99078C162.124 -3.90323 235.384 3.65034 312.491 29.7864C389.598 55.9225 455.906 95.6767 500.78 138.798C545.68 181.945 569.002 228.334 560.482 267.797Z"
                stroke="#717171"
            />
        </svg>
    );
};
