import Service from '..';
import i18n from 'i18next';

class SupportRequestService extends Service {
    public async sendSupportMessage(request: FormData): Promise<boolean> {
        const response = await this.multiPartPost('v1/user/support-request/form', request);

        if (response) {
            this.toastNotification(
                response,
                i18n.t('contact_us_toast_success') as string,
                i18n.t('contact_us_toast_error') as string
            );
        }

        return response?.status === 204;
    }
}

export default SupportRequestService;
