import React, { useEffect } from 'react';
import useCustomNavigation from '../Hooks/use-custom-navigation';

interface RedirectToScreenProps {
    to: string;
}

const RedirectToScreen: React.FC<RedirectToScreenProps> = ({ to }) => {
    const customNavigate = useCustomNavigation();

    useEffect(() => {
        customNavigate(to);
    }, []);

    return <div>RedirectToScreen</div>;
};

export default RedirectToScreen;
