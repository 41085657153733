import React from 'react';
import styles from './index.module.scss';

import { OpenPostions } from './components/OpenPositions';
import { JobFAQ } from './components/JobFAQ';

export const JobsPage = () => {
    return (
        <main className={styles.jobListingsWrapper}>
            <OpenPostions />
            <JobFAQ />
        </main>
    );
};
