import { useMemo } from 'react';

import WrapperSection from '../../../../Components/WrapperSection';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../../../../Constants';
import ClockIcon from '../../../../Icons/ClockIcon';
import Button from '../../../../Components/Button';
import { theme } from '../../../../Style/theme';
import { calculateReadingTime, formatDate } from '../../../../Utils/object';
import Copydeck from '../../../../i18n/Copydeck';
import { SupportedLanguage } from '../../../../Stores/LanguageStore';
import { useStores } from '../../../../Hooks/use-stores';
import { useGetLatestArticle } from '../../hooks/useGetLatestArticle';

import './index.scss';
import { Link } from '../../../../Components/Link';

const BlogPageBanner = () => {
    const { languageStore } = useStores();
    const { t } = useTranslation();

    const { article } = useGetLatestArticle();

    const readingTime = useMemo(() => {
        if (!article) {
            return;
        }

        const blocks = article.attributes.blocks;
        let numberOfCharacters = 0;

        blocks.forEach((block) => {
            if (block.__component === 'shared.rich-text') {
                numberOfCharacters += block.body.length;
            }
        });

        return calculateReadingTime(numberOfCharacters);
    }, [article]);

    return (
        <div className="BlogPageBanner">
            <div className="banner-title">
                <div>
                    <p className="text-body-medium-primary-font">
                        SpreeAI<span className="title-green">Blog</span>
                    </p>
                </div>
            </div>

            <div className="wrapper-video">
                <picture>
                    <source
                        src={article?.attributes?.cover?.data?.attributes?.formats?.medium?.url}
                        media="(max-width: 780px)"
                    />
                    <source
                        src={article?.attributes?.cover?.data?.attributes?.formats?.large?.url}
                        media="(max-width: 1000px)"
                    />
                    <source
                        srcSet={article?.attributes?.cover?.data?.attributes?.url}
                        media="(min-width: 1000px)"
                    />
                    <img
                        className="img"
                        src={article?.attributes?.cover?.data?.attributes?.formats?.large?.url}
                        alt={article?.attributes?.title}
                    />
                </picture>
            </div>

            <div className="wrapper-content-info">
                <div className="content-info-container">
                    <div className="content-info">
                        {article?.attributes?.category?.data?.attributes.name && (
                            <div className="cateogry">
                                <div className="text-white text-body-medium-primary-font    ">
                                    {article?.attributes?.category?.data?.attributes.name || 'none'}
                                </div>
                            </div>
                        )}

                        <WrapperSection.Title className="text-white">
                            {article?.attributes?.title}
                        </WrapperSection.Title>

                        {(article?.attributes?.authors?.data?.length || 0) > 0 && (
                            <div className="text-white text-body">
                                {Copydeck.blogPageBannerBy}:{' '}
                                {article?.attributes?.authors?.data
                                    ?.map((author) => author.attributes.name)
                                    .join(', ')}
                            </div>
                        )}
                        {article?.attributes?.publishedAt && (
                            <p className="text-white text-body">
                                {formatDate(
                                    article?.attributes?.publishedAt || '',
                                    languageStore.currentLanguage || SupportedLanguage.EN
                                )}
                            </p>
                        )}
                        <div className="text-white wrapper-time text-body">
                            <ClockIcon fill={theme['white']} />
                            <span className="text-body text-white">
                                {readingTime} {t('min')}
                            </span>
                        </div>
                        <Link
                            pathConfig={{
                                where: CONSTANTS.SCREEN.BLOG_ID,
                                id: `${article?.id}-${article?.attributes.slug}`,
                            }}
                        >
                            <Button fullInMobile={false} text="View Blog Post" type="white" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BlogPageBanner;
