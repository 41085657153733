import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import WhiteCard from '../../../Components/WhiteCard';
import Form from '../../../Components/Form';
import { useStores } from '../../../Hooks/use-stores';
import UseServices from '../../../Hooks/use-services';
import CONSTANTS from '../../../Constants';
import useCustomNavigation from '../../../Hooks/use-custom-navigation';

const SetNewPassword = () => {
    const { t } = useTranslation();
    const { loadingStore } = useStores();
    const { accountService } = UseServices();
    const nav = useCustomNavigation();

    const onSubmit = async (values: any) => {
        if (!values) return false;

        try {
            loadingStore.addLoading();

            const emailInUrl = window.location.search.split('email=')[1];
            const emailValue = decodeURIComponent(emailInUrl.split('&')[0]);
            const tokenInUrl = window.location.search.split('token=')[1];
            const tokenValue = decodeURIComponent(tokenInUrl.split('&')[0]);

            await accountService.forgotPasswordConfirm({
                email: emailValue,
                token: tokenValue,
                password: values.newPassword,
            });

            nav(CONSTANTS.SCREEN.LOGIN);
        } finally {
            loadingStore.removeLoading();
        }

        return true;
    };

    return (
        <div className="SetNewPassword">
            <WhiteCard>
                <h1 className="deprecated-text-headline-small">
                    {t('ForgotPassword.set_new_password')}
                </h1>
                <Form
                    onSubmit={onSubmit}
                    formHeaderLine={false}
                    className="form-container"
                    validation={[
                        {
                            required: true,
                            inputName: 'newPassword',
                        },
                        {
                            required: true,
                            inputName: 'confirm',
                        },
                        {
                            passwordConfirmMatch: { passwordField: 'newPassword' },
                            inputName: 'confirm',
                        },
                    ]}
                >
                    <Form.PasswordInput
                        label={t('ForgotPassword.new_password')}
                        name="newPassword"
                    />
                    <Form.PasswordInput
                        label={t('ForgotPassword.confirm_new_password')}
                        name="confirm"
                    />
                    <Form.Submit text={t('update')} />
                </Form>
            </WhiteCard>
        </div>
    );
};

export default SetNewPassword;
