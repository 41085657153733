import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const HandCoinsIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="40" height="41" viewBox="0 0 40 41" {...props} fill="none">
            <g clipPath="url(#clip0_3595_49051)">
                <path
                    d="M31.875 18C34.2912 18 36.25 16.0412 36.25 13.625C36.25 11.2088 34.2912 9.25 31.875 9.25C29.4588 9.25 27.5 11.2088 27.5 13.625C27.5 16.0412 29.4588 18 31.875 18Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7.5 33H2.5C2.16848 33 1.85054 32.8683 1.61612 32.6339C1.3817 32.3995 1.25 32.0815 1.25 31.75V25.5C1.25 25.1685 1.3817 24.8505 1.61612 24.6161C1.85054 24.3817 2.16848 24.25 2.5 24.25H7.5"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.5 25.5H22.5L32.9688 23.0922C33.3536 22.9867 33.7577 22.9714 34.1494 23.0475C34.5412 23.1236 34.9101 23.289 35.2276 23.5308C35.545 23.7727 35.8024 24.0845 35.9797 24.442C36.157 24.7995 36.2495 25.1931 36.25 25.5922C36.2502 26.0741 36.1161 26.5464 35.8627 26.9563C35.6093 27.3662 35.2467 27.6973 34.8156 27.9125L28.75 30.5L18.75 33H7.5V24.25L11.4062 20.3438C11.7554 19.9958 12.1697 19.7201 12.6255 19.5325C13.0813 19.3448 13.5696 19.2488 14.0625 19.25H21.875C22.7038 19.25 23.4987 19.5793 24.0847 20.1653C24.6708 20.7514 25 21.5462 25 22.375C25 23.2038 24.6708 23.9987 24.0847 24.5847C23.4987 25.1708 22.7038 25.5 21.875 25.5H17.5Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M27.4994 13.8266C26.6503 14.2281 25.6945 14.3458 24.7733 14.1621C23.8522 13.9784 23.0146 13.5031 22.3845 12.8066C21.7543 12.11 21.3651 11.2291 21.2744 10.2942C21.1836 9.3593 21.3962 8.42002 21.8806 7.61528C22.365 6.81053 23.0956 6.18306 23.9642 5.82566C24.8329 5.46826 25.7935 5.39992 26.704 5.63073C27.6145 5.86155 28.4265 6.37927 29.02 7.10732C29.6135 7.83537 29.9569 8.7351 29.9994 9.67343"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3595_49051">
                    <rect width="40" height="40" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
