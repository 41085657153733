import * as yup from 'yup';

export const CreateAccountSchema = yup.object().shape({
    firstName: yup
        .string()
        .required('Errors.e_required')
        .min(2, 'Errors.e_min_2')
        .max(75, 'Errors.e_max_75'),
    lastName: yup
        .string()
        .required('Errors.e_required')
        .min(2, 'Errors.e_min_2')
        .max(75, 'Errors.e_max_75'),
    companyName: yup
        .string()
        .required('Errors.e_required')
        .min(2, 'Errors.e_min_2')
        .max(256, 'Errors.e_max_256'),
    role: yup
        .string()
        .required('Errors.e_required')
        .min(2, 'Errors.e_min_2')
        .max(256, 'Errors.e_max_256'),
    email: yup
        .string()
        .required('Errors.e_required')
        .email('Errors.e_email')
        .min(5, 'Errors.e_min_5')
        .max(256, 'Errors.e_max_256'),
    password: yup
        .string()
        .min(8, 'Errors.e_min_8')
        .max(256, 'Errors.e_max_256')
        .required('Errors.e_required'),
    companyUrl: yup
        .string()
        .matches(/^(http|https):\/\/[^ "]+$/, 'Errors.e_url')
        .min(10, 'Errors.e_min_10')
        .max(256, 'Errors.e_max_256')
        .transform((value, originalValue) => {
            if (!value) {
                return null;
            }
            return originalValue;
        })
        .nullable()
        .optional(),
    confirm: yup
        .string()
        .min(8, 'Errors.e_min_8')
        .max(256, 'Errors.e_max_256')
        .required('Errors.e_required')
        .oneOf([yup.ref('password'), null as any], 'Errors.password_does_not_match'),
});
