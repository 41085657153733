import React, { useEffect, useState, useRef, useCallback } from 'react';
import './index.scss';

interface InfiniteSliderHorizontalProps {
    components: React.ReactNode[];
    speed?: number;
}

const InfiniteSliderHorizontal: React.FC<InfiniteSliderHorizontalProps> = ({
    components,
    speed: speedInMs = 50,
}) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true);
        setStartX(e.pageX - (wrapperRef.current?.offsetLeft || 0));
        setScrollLeft(wrapperRef.current?.scrollLeft || 0);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleMouseDrag = (e: React.MouseEvent) => {
        if (!isDragging || !wrapperRef.current || !contentRef.current) return;
        const x = e.pageX - (wrapperRef.current.offsetLeft || 0);
        const walk = (x - startX) * 2;
        let newScrollLeft = scrollLeft - walk;

        // Loop for both the left and right side
        const contentWidth = contentRef.current.offsetWidth / 2;
        if (newScrollLeft < 0) {
            newScrollLeft = contentWidth + (newScrollLeft % contentWidth);
        } else if (newScrollLeft > contentWidth) {
            newScrollLeft = newScrollLeft % contentWidth;
        }

        wrapperRef.current.scrollLeft = newScrollLeft;
    };

    const scroll = useCallback(() => {
        const wrapper = wrapperRef.current;
        const content = contentRef.current;
        if (!wrapper || !content || isDragging) return;

        const scrollIncrement = Math.max(1, Math.floor(100 / speedInMs));
        let newScrollLeft = wrapper.scrollLeft + scrollIncrement;
        const contentWidth = content.offsetWidth / 2;

        if (newScrollLeft >= contentWidth) {
            newScrollLeft = 0;
        }

        wrapper.scrollLeft = newScrollLeft;
    }, [isDragging, speedInMs]);

    useEffect(() => {
        const intervalId = setInterval(scroll, 16); // Run at ~60fps
        return () => clearInterval(intervalId);
    }, [scroll]);

    return (
        <div className="InfiniteSliderHorizontal">
            <div className="wrapper" ref={wrapperRef}>
                <div
                    className="content"
                    ref={contentRef}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseUp}
                    onMouseMove={handleMouseDrag}
                >
                    {components}
                    {components}
                </div>
            </div>
        </div>
    );
};

export default InfiniteSliderHorizontal;
