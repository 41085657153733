import { action, observable, makeAutoObservable } from 'mobx';
import CONSTANTS from '../Constants';

class TokenStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable
    private accessToken: string = '';
    private refreshToken: string = '';
    private mail: string = '';

    // setters
    @action public setAccessTokenInLocalStorage(accessToken: string): void {
        localStorage.setItem(CONSTANTS.LOCALSTORAGE_KEY.ACCESS_TOKEN, accessToken);
    }

    @action public setRefreshTokenInLocalStorage(refreshToken: string): void {
        localStorage.setItem(CONSTANTS.LOCALSTORAGE_KEY.REFRESH_TOKEN, refreshToken);
    }

    @action public setMailInLocalStorage(mail: string): void {
        localStorage.setItem(CONSTANTS.LOCALSTORAGE_KEY.MAIL, mail);
    }

    @action
    private setAccessToken(accessToken: string): void {
        this.accessToken = accessToken;
    }

    @action
    private setRefreshToken(refreshToken: string): void {
        this.refreshToken = refreshToken;
    }

    @action
    private setMail(mail: string): void {
        this.mail = mail;
    }

    @action
    public clearTokens(): void {
        this.accessToken = '';
        this.refreshToken = '';
        localStorage.removeItem(CONSTANTS.LOCALSTORAGE_KEY.ACCESS_TOKEN);
        localStorage.removeItem(CONSTANTS.LOCALSTORAGE_KEY.REFRESH_TOKEN);
    }

    // getters
    @action
    private getTokenInLocalStorage(): {
        accessToken: string;
        refreshToken: string;
        mail: string;
    } {
        const localStorageToken = {
            accessToken: localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEY.ACCESS_TOKEN) ?? '',
            refreshToken: localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEY.REFRESH_TOKEN) ?? '',
            mail: localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEY.MAIL) ?? '',
        };

        this.setAccessToken(localStorageToken.accessToken);
        this.setRefreshToken(localStorageToken.refreshToken);
        this.setMail(localStorageToken.mail);

        return {
            accessToken: localStorageToken.accessToken,
            refreshToken: localStorageToken.refreshToken,
            mail: localStorageToken.mail,
        };
    }

    @action
    getTokens(): { accessToken: string; refreshToken: string; mail: string } {
        if (!this.accessToken || !this.refreshToken || !this.mail) {
            return this.getTokenInLocalStorage();
        }

        return {
            accessToken: this.accessToken,
            refreshToken: this.refreshToken,
            mail: this.mail,
        };
    }
}

export default TokenStore;
