import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const CloseIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="17"
            height="17"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: 'pointer' }}
            {...props}
        >
            <path
                d="M0.501101 12.4965C0.0792257 12.9184 0.0591365 13.6718 0.511145 14.1037C0.943065 14.5557 1.70646 14.5356 2.12833 14.1137L6.99999 9.24208L11.8716 14.1137C12.3036 14.5457 13.0469 14.5557 13.4788 14.1037C13.9308 13.6718 13.9207 12.9184 13.4888 12.4865L8.61717 7.61485L13.4888 2.75324C13.9207 2.31128 13.9308 1.56798 13.4788 1.13606C13.0469 0.684047 12.3036 0.694092 11.8716 1.12601L6.99999 5.99766L2.12833 1.12601C1.70646 0.704136 0.943065 0.684047 0.511145 1.13606C0.0591365 1.56798 0.0792257 2.32132 0.501101 2.7432L5.37275 7.61485L0.501101 12.4965Z"
                fill={props.fill}
            />
        </svg>
    );
};
