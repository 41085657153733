import React from 'react';
import './index.scss';

interface ButtonProps {
    type:
        | 'primary'
        | 'secondary'
        | 'tertiary'
        | 'white'
        | 'black'
        | 'white-with-border'
        | 'navigation'
        | 'navigation-red' // TODO Need to change navigation name, but on figma it's called navigation;
        | 'borderless'
        | 'transparent';
    size?: BtnSize;
    text: string;
    onClick?: () => void;
    icon?: {
        right?: React.ReactNode;
    };
    className?: string;
    textClassName?: string;
    disabled?: boolean;
    isFocusable?: boolean;
    fullInMobile?: boolean;
    forcedColor?: string;
}

export enum BtnSize {
    default,
    fixed250,
}

const Button: React.FC<ButtonProps> = ({
    type,
    text,
    onClick,
    size = BtnSize.default,
    icon,
    className,
    textClassName,
    disabled,
    isFocusable = true,
    fullInMobile = true,
    forcedColor,
}) => {
    return (
        <button
            className={`Button ${className} button-${type} ${
                size === BtnSize.fixed250 ? 'fixed250' : ''
            } ${disabled ? '-disabled' : ''} ${fullInMobile ? '-full-in-mobile' : ''} ${
                forcedColor ? '-forced-color' : ''
            }`}
            onClick={onClick}
            disabled={disabled}
            tabIndex={isFocusable ? undefined : -1}
            style={{
                backgroundColor: disabled ? '' : forcedColor,
            }}
        >
            <p className="text-body-bold label">{text}</p>

            {icon && icon?.right ? <div className="button-icon">{icon.right}</div> : null}
        </button>
    );
};

export default Button;
