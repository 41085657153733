import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const ChevronLeftIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M9.56597 15.1369C9.5752 14.8139 9.69517 14.5371 9.94434 14.2879L17.1241 7.265C17.3364 7.06198 17.5855 6.95123 17.8901 6.95123C18.5084 6.95123 18.9883 7.43112 18.9883 8.04943C18.9883 8.34474 18.8683 8.62159 18.6561 8.83385L12.1961 15.1369L18.6561 21.44C18.8683 21.6523 18.9883 21.9199 18.9883 22.2244C18.9883 22.8427 18.5084 23.3226 17.8901 23.3226C17.5948 23.3226 17.3364 23.2119 17.1241 23.0089L9.94434 15.9767C9.68594 15.7368 9.56597 15.4599 9.56597 15.1369Z"
                fill={props.fill}
            />
        </svg>
    );
};
