import * as React from 'react';
import { SvgProps } from '../../SvgProps';

export const CouponIcon: React.FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_7622_61514)">
                <path
                    d="M6.27894 17.2925C6.09169 17.1051 5.98645 16.8511 5.98633 16.5863V5H17.5769C17.8419 5.00012 18.096 5.10532 18.2834 5.2925L30.7031 17.7075C30.8906 17.895 30.9959 18.1493 30.9959 18.4144C30.9959 18.6795 30.8906 18.9337 30.7031 19.1213L20.1166 29.7075C19.929 29.8949 19.6747 30.0002 19.4095 30.0002C19.1443 30.0002 18.8899 29.8949 18.7024 29.7075L6.27894 17.2925Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M11.4883 11.75C12.1786 11.75 12.7383 11.1904 12.7383 10.5C12.7383 9.80964 12.1786 9.25 11.4883 9.25C10.7979 9.25 10.2383 9.80964 10.2383 10.5C10.2383 11.1904 10.7979 11.75 11.4883 11.75Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_7622_61514">
                    <rect width="32.012" height="32" fill="white" transform="translate(0.984375)" />
                </clipPath>
            </defs>
        </svg>
    );
};
export default CouponIcon;
