import Banner from './Sections/Banner';
import BlogFutureofFashionSection from './Sections/BlogFutureofFashionSection';
import BlogList from './Sections/BlogList';
import './index.scss';

const BlogPage = () => {
    return (
        <div className="BlogPage">
            <Banner />
            <BlogList />
            <BlogFutureofFashionSection />
        </div>
    );
};

export default BlogPage;
