import { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import UseServices from '../../../Hooks/use-services';
import { ArticleDto } from '../../../Services/Blog';

export const useGetLatestArticle = () => {
    const [article, setArticle] = useState<ArticleDto | null>(null);
    const { blogService } = UseServices();
    const { t } = useTranslation();

    const getTheLastestArticles = useCallback(async () => {
        try {
            const response = await blogService.getMostRecentArticles(1);

            if (!response?.data?.[0]) {
                toast.error(t('BlogPage.fetch_error') as string);
            }
            setArticle(response?.data?.[0]);
        } catch (error) {
            console.error(error);
        }
    }, []);

    useEffect(() => {
        getTheLastestArticles();
    }, []);

    return { article };
};
