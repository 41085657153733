import React, { useState } from 'react';
import { GenericInputProps } from '../..';
import useForm from '../../hooks';
import './index.scss';
import { theme } from '../../../../Style/theme';
import { EyeIcon } from '../../../../Icons/eye-icon';

export interface PasswordInputProps extends GenericInputProps {}

const PasswordInput: React.FC<PasswordInputProps> = ({ label, name, errors }) => {
    const { onChange, getErrors, getValue } = useForm();
    const globaleErrors = errors ? errors : getErrors(name);
    const value = getValue(name);
    const [showValue, setShowValue] = useState(false);
    const EYE_COLOR = '#2B2B2B';

    return (
        <div className="PasswordInput">
            <label
                className="text-body-small"
                style={{
                    color:
                        globaleErrors?.length > 0 ? theme['surface-error-rest-primary'] : 'black',
                }}
            >
                {label}
            </label>
            <input
                style={{
                    borderColor:
                        globaleErrors?.length > 0 ? theme['surface-error-rest-primary'] : '',
                }}
                onChange={onChange}
                name={name}
                value={value}
                type={showValue ? 'text' : 'password'}
            />

            <EyeIcon
                fill={EYE_COLOR}
                className="eye cursor-pointer"
                onClick={() => setShowValue((prev) => !prev)}
                width={20}
            />

            <span className="text-surface-error-rest-primary">
                {globaleErrors?.[0]?.extraInformations}
            </span>
        </div>
    );
};

export default PasswordInput;
