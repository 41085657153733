import React, { useCallback, useEffect, useState, useRef } from 'react';
import { observer } from 'mobx-react';
import { AnimatePresence, motion } from 'framer-motion';
import WrapperSection from '../../../../Components/WrapperSection';
import ImageOrVideo from '../../../../Components/ImageOrVideo';
import UseServices from '../../../../Hooks/use-services';
import { useWindowDimensions } from '../../../../Hooks/use-window-dimensions';
import AnnoucementSectionMobile from './index-mobile';
import AnnoucementTitle from '../../../../Layout/Components/annoucement-title';
import { PlayIcon } from '../../../../Icons/play-icon';
import { PauseIcon } from '../../../../Icons/pause-icon';
import { ChevronTopIcon } from '../../../../Icons/chevron-top-icon';
import { ChevronBottomIcon } from '../../../../Icons/chevron-bottom-icon';
import { AnnoucementDto } from '../../../../Services/Annoucement';
import Copydeck from '../../../../i18n/Copydeck';
import { theme } from '../../../../Style/theme';
import './index.scss';
import useAnnoucementLearnMore from '../../../../Hooks/use-annoucement-learn-more';

const AnnouncementSection = observer(() => {
    const { annoucementService } = UseServices();
    const [currentAnnoucement, setCurrentAnnoucement] = useState<AnnoucementDto | null>(null);
    const [annoucements, setAnnoucements] = useState<AnnoucementDto[]>([]);
    const { windowWidth } = useWindowDimensions();
    const isMobile = windowWidth < 930;
    const rightDivRef = useRef<HTMLDivElement>(null);
    const observerRef = useRef<IntersectionObserver | null>(null);
    const onClickLearnMore = useAnnoucementLearnMore();
    const [previousAnnoucement, setPreviousAnnoucement] = useState<AnnoucementDto | null>(null);
    const [previousAnnoucementNeedToGo, setPreviousAnnoucementNeedToGo] = useState<boolean>(true);
    const [isPause, setIsPause] = useState(false);

    const handleAnnoucement = (annoucement: AnnoucementDto) => {
        if (annoucement.id === currentAnnoucement?.id) {
            return;
        }

        setPreviousAnnoucementNeedToGo(false);
        setPreviousAnnoucement(currentAnnoucement);

        setTimeout(() => {
            setPreviousAnnoucementNeedToGo(true);
        }, 850);

        setTimeout(() => {
            setCurrentAnnoucement(annoucement);
        }, 300);
    };

    React.useEffect(() => {
        const anchor = window.location.href.split('#')[1];

        if (anchor) {
            const splitAnchor = anchor.split(':');

            const id = splitAnchor?.[1];
            const targetAnnoucement = annoucements.find((a) => String(a.id) === String(id));

            if (targetAnnoucement) {
                handleAnnoucement(targetAnnoucement);
                scrollToCenter(annoucements.indexOf(targetAnnoucement));
            }
        }
    }, [window.location.pathname]);

    useEffect(() => {
        const items = rightDivRef.current?.querySelectorAll('.item');
        items?.forEach((item, index) => {
            if (observerRef.current) {
                observerRef.current.observe(item);
            }
        });
    }, [annoucements]);

    const getLast9Annoucement = useCallback(async () => {
        try {
            const annoucements = await annoucementService.get6Last();
            setAnnoucements(annoucements);
            handleAnnoucement(annoucements[0]);

            setTimeout(() => {
                const video = document.querySelector('.video') as HTMLVideoElement;
                if (video) {
                    try {
                        video?.play();
                    } catch (error) {
                        console.error(error);
                    }
                }
            }, 1000);
        } catch (error) {
            console.error(error);
        }
    }, []);

    const scrollToCenter = (index: number) => {
        if (rightDivRef.current) {
            const items = rightDivRef.current.children;
            if (items[index]) {
                const item = items[index] as HTMLElement;
                const containerHeight = rightDivRef.current.clientHeight;
                const itemHeight = item.offsetHeight;
                const scrollTo = item.offsetTop - containerHeight / 2 + itemHeight / 2;

                rightDivRef.current.scrollTo({
                    top: scrollTo,
                    behavior: 'smooth',
                });
            }
        }
    };

    const onClickOnItem = (index: number) => {
        handleAnnoucement(annoucements[index]);
        scrollToCenter(index);

        setTimeout(() => {
            const video = document.querySelector('.video') as HTMLVideoElement;
            if (video) {
                try {
                    video?.play();
                    setIsPause(false);
                } catch (error) {
                    console.error(error);
                }
            }
        }, 1000);
    };

    const onClickPlay = () => {
        const video = document.querySelector('.video') as HTMLVideoElement;
        if (video.paused) {
            video.play();
            setIsPause(false);
        } else {
            video.pause();
            setIsPause(true);
        }
    };

    useEffect(() => {
        getLast9Annoucement();
    }, []);

    const onClickScrollTop = () => {
        if (currentAnnoucement) {
            const currentIndex = annoucements.findIndex((a) => a === currentAnnoucement);
            const newIndex = Math.max(currentIndex - 1, 0);
            handleAnnoucement(annoucements[newIndex]);
            scrollToCenter(newIndex);
        }
    };

    const onClickScrollBottom = () => {
        if (currentAnnoucement) {
            const currentIndex = annoucements.findIndex((a) => a === currentAnnoucement);
            const newIndex = Math.min(currentIndex + 1, annoucements.length - 1);
            handleAnnoucement(annoucements[newIndex]);
            scrollToCenter(newIndex);
        }
    };

    if (isMobile) {
        return <AnnoucementSectionMobile />;
    }

    return (
        <div className="AnnouncementSection" id="annoucement">
            <div className="box">
                <div className="left">
                    {annoucements.map((annoucementToDisplay) => {
                        return (
                            <ImageOrVideo
                                key={annoucementToDisplay.id}
                                className={`wrapper-media asset-left img-current
                                    ${
                                        previousAnnoucement?.id === annoucementToDisplay.id
                                            ? '-last'
                                            : ''
                                    }
                                    ${
                                        previousAnnoucement?.id === annoucementToDisplay.id &&
                                        previousAnnoucementNeedToGo
                                            ? '-need-to-go'
                                            : ''
                                    }
                                    ${
                                        currentAnnoucement?.id === annoucementToDisplay.id
                                            ? '-active'
                                            : ''
                                    }`}
                                ext={annoucementToDisplay.PictureOrVideo.data.attributes.ext}
                                mime={annoucementToDisplay.PictureOrVideo.data.attributes.mime}
                                childrenImage={
                                    <img
                                        src={
                                            annoucementToDisplay.PictureOrVideo.data.attributes.url
                                        }
                                        alt={annoucementToDisplay.Title}
                                        className="img"
                                        loading="lazy"
                                        decoding="async"
                                    />
                                }
                                childrenVideo={
                                    <div className="wrapper-video-annoucement-left">
                                        <button
                                            className="play-btn btn-delete-default-style"
                                            onClick={onClickPlay}
                                        >
                                            {!isPause ? (
                                                <PauseIcon
                                                    fill={theme['white']}
                                                    width={30}
                                                    height={30}
                                                />
                                            ) : (
                                                <PlayIcon
                                                    fill={theme['white']}
                                                    width={30}
                                                    height={30}
                                                />
                                            )}
                                        </button>
                                        <video
                                            autoPlay
                                            loop
                                            muted
                                            controls={false}
                                            className="video"
                                        >
                                            <source
                                                src={
                                                    annoucementToDisplay.PictureOrVideo.data
                                                        .attributes.url
                                                }
                                                type={
                                                    annoucementToDisplay.PictureOrVideo.data
                                                        .attributes.mime
                                                }
                                            />
                                        </video>
                                    </div>
                                }
                            />
                        );
                    })}
                    <WrapperSection.Title className="text-white">
                        {Copydeck.annoucementSectionWhatsNews}
                    </WrapperSection.Title>
                </div>

                <div className="overlay" />

                <div className="cta-container -top" onClick={onClickScrollTop}>
                    <ChevronTopIcon fill="white" onClick={onClickScrollTop} />
                </div>

                <div className="cta-container -bottom" onClick={onClickScrollBottom}>
                    <ChevronBottomIcon fill="white" onClick={onClickScrollBottom} />
                </div>

                <div className="right" ref={rightDivRef}>
                    <AnimatePresence>
                        {annoucements.map((annoucement, index) => {
                            const currentIndex = annoucements.indexOf(currentAnnoucement as any);
                            const isActive = currentAnnoucement === annoucement;
                            const isAdjacentActive =
                                (currentIndex === 0 && index === 1) || // Si le premier est actif, on illumine aussi le deuxième
                                index === currentIndex - 1; // Sinon, on illumine celui juste avant
                            const isAdjacentActive2 =
                                (currentIndex === 0 && index === 2) || // Si le premier est actif, on illumine aussi le deuxième
                                index === currentIndex + 1; // Sinon, on illumine celui juste avant
                            const isLastItemSelected = currentIndex === annoucements.length - 1;
                            const isAdjacentActive3ifLastItemSelected = isLastItemSelected
                                ? index === currentIndex - 2
                                : false;

                            return (
                                <motion.div
                                    key={index}
                                    data-index={index}
                                    className={`item ${isActive ? '-active' : ''}`}
                                    onClick={() => onClickOnItem(index)}
                                    initial={{ opacity: 0.5 }}
                                    animate={{
                                        opacity:
                                            isActive ||
                                            isAdjacentActive ||
                                            isAdjacentActive2 ||
                                            isAdjacentActive3ifLastItemSelected
                                                ? 1
                                                : 0.5,
                                    }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <ImageOrVideo
                                        hasOverlay={currentAnnoucement === annoucement}
                                        ext={annoucement.PictureOrVideo.data.attributes.ext}
                                        mime={annoucement.PictureOrVideo.data.attributes.mime}
                                        className="wrapper-media"
                                        childrenImage={
                                            <img
                                                src={annoucement.PictureOrVideo.data.attributes.url}
                                                alt={annoucement.Title}
                                                className="img"
                                                loading="lazy"
                                            />
                                        }
                                        childrenVideo={
                                            <div className="wrapper-video-annoucement">
                                                <video muted controls={false}>
                                                    <source
                                                        src={
                                                            annoucement.PictureOrVideo.data
                                                                .attributes.url
                                                        }
                                                        type={
                                                            annoucement.PictureOrVideo.data
                                                                .attributes.mime
                                                        }
                                                    />
                                                </video>
                                            </div>
                                        }
                                    />

                                    <div className="inner">
                                        <div className="title text-white text-body-bold">
                                            <AnnoucementTitle annoucement={annoucement} />
                                        </div>

                                        {annoucement.redirectionUrl && (
                                            <button
                                                className="text-white text-body-bold learn-more btn-delete-default-style"
                                                onClick={() =>
                                                    onClickLearnMore(annoucement.redirectionUrl)
                                                }
                                            >
                                                {Copydeck.annoucementSectionLearnMoreAnnoucement}
                                            </button>
                                        )}
                                    </div>
                                </motion.div>
                            );
                        })}
                    </AnimatePresence>
                </div>
            </div>
        </div>
    );
});

export default AnnouncementSection;
