import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './cookies-items.scss';
import { theme } from '../../../Style/theme';
import { ChevronBottomIcon } from '../../../Icons/chevron-bottom-icon';
import { ChevronTopIcon } from '../../../Icons/chevron-top-icon';
import { CookieInfo } from '../../../Stores/CookiesStore';
import { useWindowDimensions } from '../../../Hooks/use-window-dimensions';
import CustomSwitch from '../../Switch';

interface CookiesItemsProps {
    title: string;
    state: boolean;
    options?: CookieInfo[];
    heightOpen?: number;
    onChange: () => void;
}

const CookiesItems: React.FC<CookiesItemsProps> = ({
    title,
    state,
    onChange,
    options,
    heightOpen = 0,
}) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const { windowWidth } = useWindowDimensions();

    const handleIsOpen = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <div className="CookiesItems">
            <div className="header">
                <div className="left" onClick={handleIsOpen}>
                    {!isOpen ? (
                        <ChevronBottomIcon
                            fill={theme['black']}
                            width={24}
                            height={24}
                            className="icon"
                        />
                    ) : (
                        <ChevronTopIcon
                            fill={theme['black']}
                            width={24}
                            height={24}
                            className="icon"
                        />
                    )}
                    <div className="question text-body-primary-font-bold text-element-black-secondary-alt">
                        {t(title)}
                    </div>
                </div>

                <div>
                    <CustomSwitch checked={state} onChange={onChange} />
                </div>
            </div>

            {options && windowWidth > 768 ? (
                <div
                    className={`bottom ${isOpen ? '-open' : '-close'}`}
                    style={{
                        height: isOpen ? heightOpen : 0,
                        overflow: 'hidden',
                    }}
                >
                    <div className="item header">
                        <div className="cell name text-body-bold">
                            {t('CookiesModal.cookie_colum_name')}
                        </div>
                        <div className="cell value text-body-bold">
                            {t('CookiesModal.cookie_colum_domain')}
                        </div>
                        <div className="cell value text-body-bold">
                            {t('CookiesModal.cookie_colum_expiration')}
                        </div>
                        <div className="cell value text-body-bold">
                            {t('CookiesModal.cookie_colum_description')}
                        </div>
                    </div>

                    {options.map((item, key) => {
                        const expiration = item.expires?.toString()
                            ? item.expires.toString()
                            : 'N/A';
                        const description = item.description ? item.description : 'N/A';
                        const domain = item.domain ? item.domain : 'N/A';

                        return (
                            <div key={key} className="item">
                                <div className="cell name text-body">{item.name}</div>
                                <div className="cell domain text-body">{domain ? domain : '-'}</div>
                                <div className="cell expiration text-body">
                                    {expiration ? expiration : '-'}
                                </div>
                                <div className="cell description text-body">
                                    {description ? description : '-'}
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : null}

            {options && windowWidth <= 768 ? (
                <div
                    className={`bottom -mobile ${isOpen ? '-open' : '-close'}`}
                    style={{
                        height: isOpen ? heightOpen : 0,
                        overflow: 'hidden',
                    }}
                >
                    {options.map((item, key) => {
                        const expiration = item.expires?.toString()
                            ? item.expires.toString()
                            : 'N/A';
                        const description = item.description ? item.description : 'N/A';
                        const domain = item.domain ? item.domain : 'N/A';

                        return (
                            <div key={key} className="item">
                                <div className="cell">
                                    <div className="title text-body-bold">Name</div>
                                    <div className="value text-body">{item.name}</div>
                                </div>
                                <div className="cell">
                                    <div className="title text-body-bold">Domain</div>
                                    <div className="value text-body">{domain ? domain : '-'}</div>
                                </div>
                                <div className="cell">
                                    <div className="title text-body-bold">Expiration</div>
                                    <div className="value text-body">
                                        {expiration ? expiration : '-'}
                                    </div>
                                </div>
                                <div className="cell">
                                    <div className="title text-body-bold">Description</div>
                                    <div className="value text-body">
                                        {description ? description : '-'}
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : null}
        </div>
    );
};

export default CookiesItems;
