import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const ShirtIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" {...props} fill="none">
            <g clipPath="url(#clip0_3597_20411)">
                <path
                    d="M30 18.75H34.4281C34.6678 18.7522 34.9035 18.6891 35.11 18.5674C35.3164 18.4458 35.4859 18.2701 35.6 18.0594L38.6109 12.3078C38.7596 12.0208 38.7883 11.6865 38.6908 11.3783C38.5932 11.0702 38.3774 10.8133 38.0906 10.6641L30 6.25"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.0001 18.75H5.57197C5.33232 18.7522 5.09659 18.6891 4.89011 18.5674C4.68364 18.4458 4.51423 18.2701 4.40009 18.0594L1.38915 12.3078C1.24049 12.0208 1.21179 11.6865 1.30934 11.3783C1.40689 11.0702 1.62272 10.8133 1.90947 10.6641L10.0001 6.25"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M25 6.25C25 7.57608 24.4732 8.84785 23.5355 9.78553C22.5979 10.7232 21.3261 11.25 20 11.25C18.6739 11.25 17.4021 10.7232 16.4645 9.78553C15.5268 8.84785 15 7.57608 15 6.25H10V32.5C10 32.8315 10.1317 33.1495 10.3661 33.3839C10.6005 33.6183 10.9185 33.75 11.25 33.75H28.75C29.0815 33.75 29.3995 33.6183 29.6339 33.3839C29.8683 33.1495 30 32.8315 30 32.5V6.25H25Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3597_20411">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
