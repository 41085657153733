import React from 'react';
import './index.scss';
import WrapperSection from '../../../../../Components/WrapperSection';
import { FeaturePartnerIcon } from '../../../../../Icons/feature-partner-icon';
import { theme } from '../../../../../Style/theme';
import { FeatureMultimediaIcon } from '../../../../../Icons/feature-multimedia-icon';
import FeatureVideoIcon from '../../../../../Icons/feature-video-icon';
import { useTranslation } from 'react-i18next';
import WrapperVideo from '../../../../../Components/WrapperVideo';

const ParteningWithUsSection = () => {
    const { t } = useTranslation();
    return (
        <div className="ParteningWithUsSection">
            <div className="left">
                <WrapperVideo
                    muted={true}
                    src={{
                        en: {
                            desktop: {
                                webm: require('./../../../../../Assets/MP4/partner-with-us.webm'),
                                mp4: require('./../../../../../Assets/MP4/partner-with-us.mp4'),
                            },
                            mobile: {
                                webm: require('./../../../../../Assets/MP4/partner-with-us.webm'),
                                mp4: require('./../../../../../Assets/MP4/partner-with-us.mp4'),
                            },
                        },
                        fr: {
                            desktop: {
                                webm: require('./../../../../../Assets/MP4/partner-with-us.webm'),
                                mp4: require('./../../../../../Assets/MP4/partner-with-us.mp4'),
                            },
                            mobile: {
                                webm: require('./../../../../../Assets/MP4/partner-with-us.webm'),
                                mp4: require('./../../../../../Assets/MP4/partner-with-us.mp4'),
                            },
                        },
                        it: {
                            desktop: {
                                webm: require('./../../../../../Assets/MP4/partner-with-us.webm'),
                                mp4: require('./../../../../../Assets/MP4/partner-with-us.mp4'),
                            },
                            mobile: {
                                webm: require('./../../../../../Assets/MP4/partner-with-us.webm'),
                                mp4: require('./../../../../../Assets/MP4/partner-with-us.mp4'),
                            },
                        },
                        es: {
                            desktop: {
                                webm: require('./../../../../../Assets/MP4/partner-with-us.webm'),
                                mp4: require('./../../../../../Assets/MP4/partner-with-us.mp4'),
                            },
                            mobile: {
                                webm: require('./../../../../../Assets/MP4/partner-with-us.webm'),
                                mp4: require('./../../../../../Assets/MP4/partner-with-us.mp4'),
                            },
                        },
                    }}
                    alt="SpreeAI Feature Video"
                />
            </div>

            <div className="right">
                <div className="subtitle text-body">
                    {t('LandingPage.partenering_with_spreeai')}
                </div>
                <WrapperSection.Title htmlTag="h2">
                    {t('LandingPage.enhance_you_brand')}
                </WrapperSection.Title>
                <div className="desc text-body">{t('LandingPage.partenring_details_if')}</div>

                <div className="wrapper-features">
                    <div className="feature">
                        <div className="feature-icon">
                            <FeaturePartnerIcon fill={theme['black']} height={36} width={36} />
                        </div>
                        <div className="feature-title text-body-large-primary-font-bold">
                            {t('PartnerRessources.partner_ressources_hero_title')}
                        </div>
                        <div className="feature-desc text-body-small">{t('Login.partner_exp')}</div>
                    </div>
                    <div className="feature">
                        <div className="feature-icon">
                            <FeatureVideoIcon fill={theme['black']} height={36} width={36} />
                        </div>
                        <div className="feature-title text-body-large-primary-font-bold">
                            {t('view_demo')}
                        </div>
                        <div className="feature-desc text-body-small">
                            {t('CreateAccount.view_demo_benefit_paragraph')}
                        </div>
                    </div>
                    <div className="feature">
                        <div className="feature-icon">
                            <FeatureMultimediaIcon fill={theme['black']} height={36} width={36} />
                        </div>
                        <div className="feature-title text-body-large-primary-font-bold">
                            {t('DashboardPage.manage_assets')}
                        </div>
                        <div className="feature-desc text-body-small">
                            {t('DashboardPage.manage_assets_desc')}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ParteningWithUsSection;
