import Button from '../../../Components/Button';
import CONSTANTS from '../../../Constants';

import styles from './styles/hiring-process.module.scss';
import sharedStyles from '../index.module.scss';
import { Link } from '../../../Components/Link';
import { FadeUp } from './FadeUp';

const steps = [
    'Application',
    'Screening Interview',
    'Hiring Manager Interview (may include technical assessment(s) for applicable roles)',
    'Hiring Team Interview',
    'Interview with our CEO',
    'Offer',
];

export const HiringProcess = () => {
    return (
        <section className={styles.sectionWrapper}>
            <div className={styles.sectionContainer}>
                <div className={styles.details}>
                    <FadeUp>
                        <h2 className={sharedStyles.heading2}>
                            What Is The Hiring Process At SpreeAI?
                        </h2>
                        <Link pathConfig={{ where: CONSTANTS.SCREEN.JOBS }}>
                            <Button type="secondary" text="Search All Positions" />
                        </Link>
                    </FadeUp>
                </div>
                <div className={styles.steps}>
                    <FadeUp delay={0.1} amount={0.3}>
                        <ol className={styles.stepsList}>
                            {steps.map((step, index) => (
                                <li key={index}>
                                    <div className={styles.step}>
                                        <div>{index + 1}.</div>
                                        <p>{step}</p>
                                    </div>
                                    {index < steps.length - 1 ? (
                                        <div className={styles.divider} />
                                    ) : null}
                                </li>
                            ))}
                        </ol>
                    </FadeUp>
                </div>
                <div className={styles.mobileButton}>
                    <Button type="secondary" text="Search All Positions" />
                </div>
            </div>
        </section>
    );
};
