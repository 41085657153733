import React, { FC } from 'react';
import { SvgProps } from './SvgProps';

const SmileyIcon: FC<SvgProps> = ({ ...props }) => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" {...props} fill="none">
            <g clipPath="url(#clip0_4887_24650)">
                <path
                    d="M24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24C6 33.9411 14.0589 42 24 42Z"
                    stroke={props.fill}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.25 22.5C18.4926 22.5 19.5 21.4926 19.5 20.25C19.5 19.0074 18.4926 18 17.25 18C16.0074 18 15 19.0074 15 20.25C15 21.4926 16.0074 22.5 17.25 22.5Z"
                    fill={props.fill}
                />
                <path
                    d="M30.75 22.5C31.9926 22.5 33 21.4926 33 20.25C33 19.0074 31.9926 18 30.75 18C29.5074 18 28.5 19.0074 28.5 20.25C28.5 21.4926 29.5074 22.5 30.75 22.5Z"
                    fill={props.fill}
                />
                <path
                    d="M31.5 28.5C29.9437 31.1906 27.3319 33 24 33C20.6681 33 18.0563 31.1906 16.5 28.5"
                    stroke={props.fill}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_4887_24650">
                    <rect width="48" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default SmileyIcon;
