import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import RenderStrapiBlocks from '../../../../../Components/RenderStrapiBlocks';
import BreadCrumb from '../../../../../Components/BreadCrumb';
import { useGetArticleById } from '../../../hooks/useGetArticlebyId';

import './index.scss';
import { BlogBanner } from '../../../Components/BlogBanner';

const Article = () => {
    const params = useParams<{ id: string }>();
    const articleId = params?.id?.split('-')[0];

    const { article, video } = useGetArticleById({ articleId });

    if (!article) {
        return null;
    }

    return (
        <div className="Article">
            <Helmet>
                {/* Standard metadata tags */}
                <title>{article?.attributes?.title}</title>
                <meta name="description" content={article?.attributes?.description || 'none'} />
                <meta
                    name="image"
                    content={
                        article?.attributes?.cover?.data?.attributes?.formats?.medium?.url || 'none'
                    }
                />
                <meta
                    name="image:secure_url"
                    content={
                        article?.attributes?.cover?.data?.attributes?.formats?.medium?.url || 'none'
                    }
                />

                {/* End standard metadata tags */}

                {/* Facebook tags */}
                <meta property="og:type" content={article?.attributes?.title} />
                <meta property="og:title" content={article?.attributes?.title} />
                <meta
                    name="og:image"
                    content={
                        article?.attributes?.cover?.data?.attributes?.formats?.medium?.url || 'none'
                    }
                />
                <meta
                    property="og:image:secure_url"
                    content={
                        article?.attributes?.cover?.data?.attributes?.formats?.medium?.url || 'none'
                    }
                />

                <meta
                    property="og:description"
                    content={article?.attributes?.description || 'none'}
                />
                {/* End Facebook tags */}

                {/* Twitter tags */}
                <meta name="twitter:creator" content={article?.attributes?.title} />
                <meta name="twitter:card" content={article?.attributes?.description || 'none'} />
                <meta name="twitter:title" content={article?.attributes?.title} />
                <meta
                    name="twitter:image"
                    content={
                        article?.attributes?.cover?.data?.attributes?.formats?.medium?.url || 'none'
                    }
                />
                <meta
                    name="twitter:image:secure_url"
                    content={
                        article?.attributes?.cover?.data?.attributes?.formats?.medium?.url || 'none'
                    }
                />

                <meta
                    name="twitter:description"
                    content={article?.attributes?.description || 'none'}
                />
                {/* End Twitter tags */}
            </Helmet>

            <BlogBanner article={article} />
            <div className="content-body">
                <BreadCrumb
                    currentFilter={{
                        value: article?.attributes?.category?.data?.id || 'all',
                        label: article?.attributes?.category?.data?.attributes?.name || 'All',
                    }}
                />

                <div className="content">
                    {video?.rawData?.html && (
                        <div
                            className="container-embed"
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{
                                __html: video?.rawData?.html,
                            }}
                        />
                    )}

                    <RenderStrapiBlocks blocks={article.attributes.blocks} />
                </div>
            </div>
        </div>
    );
};

export default Article;
