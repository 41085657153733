import './index.scss';
import Copydeck from '../../../../../i18n/Copydeck';
import useHandleUrlAnchor from '../../../../../Hooks/use-handle-url-anchor';

const PolicyPrivacyTableOfContents = () => {
    const { scrollToHash } = useHandleUrlAnchor({
        stopAutoScroll: false,
    });

    const handleButtonClick = (anchor: string) => {
        scrollToHash(`#${anchor}`);
    };

    return (
        <div className="PolicyPrivacyTableOfContents">
            <ul className="links text-body">
                <li className="links-item">
                    <button
                        className="btn-delete-default-style"
                        onClick={() => handleButtonClick('introduction')}
                    >
                        {Copydeck.policyTableOfContentsPolicyTableOfContentsSectionIntroduction}
                    </button>
                </li>
                <li className="links-item">
                    <button
                        className="btn-delete-default-style"
                        onClick={() => handleButtonClick('section-1')}
                    >
                        {Copydeck.policyTableOfContentsPolicyTableOfContentsSection1}
                    </button>
                </li>
                <li className="links-item">
                    <button
                        className="btn-delete-default-style"
                        onClick={() => handleButtonClick('section-2')}
                    >
                        {Copydeck.policyTableOfContentsPolicyTableOfContentsSection2}
                    </button>
                </li>
                <li className="links-item">
                    <button
                        className="btn-delete-default-style"
                        onClick={() => handleButtonClick('section-3')}
                    >
                        {Copydeck.policyTableOfContentsPolicyTableOfContentsSection3}
                    </button>
                </li>
                <li className="links-item">
                    <button
                        className="btn-delete-default-style"
                        onClick={() => handleButtonClick('section-4')}
                    >
                        {Copydeck.policyTableOfContentsPolicyTableOfContentsSection4}
                    </button>
                </li>
                <li className="links-item">
                    <button
                        className="btn-delete-default-style"
                        onClick={() => handleButtonClick('section-5')}
                    >
                        {Copydeck.policyTableOfContentsPolicyTableOfContentsSection5}
                    </button>
                </li>
                <li className="links-item">
                    <button
                        className="btn-delete-default-style"
                        onClick={() => handleButtonClick('section-6')}
                    >
                        {Copydeck.policyTableOfContentsPolicyTableOfContentsSection6}
                    </button>
                </li>
                <li className="links-item">
                    <button
                        className="btn-delete-default-style"
                        onClick={() => handleButtonClick('section-7')}
                    >
                        {Copydeck.policyTableOfContentsPolicyTableOfContentsSection7}
                    </button>
                </li>
                <li className="links-item">
                    <button
                        className="btn-delete-default-style"
                        onClick={() => handleButtonClick('section-8')}
                    >
                        {Copydeck.policyTableOfContentsPolicyTableOfContentsSection8}
                    </button>
                </li>
                <li className="links-item">
                    <button
                        className="btn-delete-default-style"
                        onClick={() => handleButtonClick('section-9')}
                    >
                        {Copydeck.policyTableOfContentsPolicyTableOfContentsSection9}
                    </button>
                </li>
                <li className="links-item">
                    <button
                        className="btn-delete-default-style"
                        onClick={() => handleButtonClick('section-10')}
                    >
                        {Copydeck.policyTableOfContentsPolicyTableOfContentsSection10}
                    </button>
                </li>
                <li className="links-item">
                    <button
                        className="btn-delete-default-style"
                        onClick={() => handleButtonClick('section-11')}
                    >
                        {Copydeck.policyTableOfContentsPolicyTableOfContentsSection11}
                    </button>
                </li>
                <li className="links-item">
                    <button
                        className="btn-delete-default-style"
                        onClick={() => handleButtonClick('section-12')}
                    >
                        {Copydeck.policyTableOfContentsPolicyTableOfContentsSection12}
                    </button>
                </li>
                <li className="links-item">
                    <button
                        className="btn-delete-default-style"
                        onClick={() => handleButtonClick('section-13')}
                    >
                        {Copydeck.policyTableOfContentsPolicyTableOfContentsSection13}
                    </button>
                </li>
                <li className="links-item">
                    <button
                        className="btn-delete-default-style"
                        onClick={() => handleButtonClick('section-14')}
                    >
                        {Copydeck.policyTableOfContentsPolicyTableOfContentsSection14}
                    </button>
                </li>
                <li className="links-item">
                    <button
                        className="btn-delete-default-style"
                        onClick={() => handleButtonClick('section-15')}
                    >
                        {Copydeck.policyTableOfContentsPolicyTableOfContentsSection15}
                    </button>
                </li>
            </ul>
        </div>
    );
};

export default PolicyPrivacyTableOfContents;
