import React, { useState } from 'react';
import { ChevronBottomIcon } from '../../../Icons/chevron-bottom-icon';
import UserCircleIcon from '../../../Icons/user-circle-icon';

import './dropdown-user.scss';
import { useStores } from '../../../Hooks/use-stores';
import { useTranslation } from 'react-i18next';
import useCustomNavigation from '../../../Hooks/use-custom-navigation';
import CONSTANTS from '../../../Constants';

interface DropdownUserProps {
    onClickLogout: () => void;
    onClickManageAccount: () => void;
}

const DropdownUser: React.FC<DropdownUserProps> = ({ onClickLogout, onClickManageAccount }) => {
    const { userStore } = useStores();
    const [isOpen, setIsOpen] = useState(false);
    const nav = useCustomNavigation();

    const { t } = useTranslation();

    const onClick = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <div className={`DropdownUser -open-${isOpen}`} onClick={onClick}>
            <div className="panel">
                <UserCircleIcon fill="#000" />

                <div className="content">
                    <div className="text-body-bold user-name">
                        {userStore.userInfo.first_name} {userStore.userInfo.last_name}
                    </div>

                    <div className="text-body">
                        <span className="text-body-bold">{userStore.userInfo.company}</span> •{' '}
                        {userStore.userInfo.company_role}
                    </div>
                </div>

                <ChevronBottomIcon
                    fill="#000"
                    className={`${isOpen ? '-rotate-180' : '-rotate-0'}`}
                />
            </div>

            <div className={`dropdown-content -open-${isOpen}`}>
                <div className="content">
                    <div className="user-mail text-body">{userStore.userInfo.email}</div>

                    <div
                        className="dropdown-item text-body-medium btn-delete-default-style"
                        onClick={onClickManageAccount}
                    >
                        {t('DashboardPage.cta_label_manage_account')}
                    </div>

                    <div
                        className="dropdown-item text-body-medium btn-delete-default-style"
                        onClick={() => {
                            nav(CONSTANTS.SCREEN.SUPPORT);
                        }}
                    >
                        {t('support')}
                    </div>

                    <div
                        className="dropdown-item text-body-medium btn-delete-default-style"
                        style={{
                            color: '#E41C24',
                        }}
                        onClick={onClickLogout}
                    >
                        {t('log_out')}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DropdownUser;
