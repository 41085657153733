import { Route, Routes, useNavigate } from 'react-router-dom';
import { DefaultLayout } from '../Layout';
import { ROUTER_CONFIG, ROUTE } from './config';
import CONSTANTS from '../Constants';
import { useStores } from '../Hooks/use-stores';
import { observer } from 'mobx-react-lite';
import { useCallback, useEffect } from 'react';
import { getKeyByValueRecursive } from '../Utils/object';
import useCustomNavigation from '../Hooks/use-custom-navigation';
import Forbidden401 from '../Screens/Forbidden401';

import ReactGA from 'react-ga';
import RedirectToScreen from './redirectTo';

const Router = observer(() => {
    const { userStore, languageStore, tokenStore, loadingStore, cookiesStore } = useStores();
    const nav = useNavigate();
    const customNavigate = useCustomNavigation();

    const checkIfUserIsInWrongLanguage = () => {
        try {
            const path = window.location.pathname;
            const keyRoutesOfPath = getKeyByValueRecursive(CONSTANTS.ROUTES, path);

            if (
                keyRoutesOfPath?.split('.')?.[0]?.includes((languageStore as any).currentLanguage)
            ) {
                // User is in the right language
                return;
            }

            if (!keyRoutesOfPath) {
                throw new Error('No key found for this path');
            }

            const lastKey = keyRoutesOfPath.split('.').pop();
            if (!lastKey) {
                throw new Error('Internal error');
            }

            const newPath = (CONSTANTS.ROUTES as any)[(languageStore as any).currentLanguage][
                lastKey
            ];
            if (!newPath) {
                throw new Error('Any path found');
            }

            nav(newPath);
        } catch (err) {
            console.error(err);
        }
    };

    useEffect(() => {
        if (window.location.pathname && languageStore.currentLanguage != null) {
            checkIfUserIsInWrongLanguage();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.location.pathname, languageStore.currentLanguage]);

    const loginIfTokenExists = useCallback(async () => {
        if (userStore.isLogin() && userStore.userInfo.email === '') {
            try {
                loadingStore.addLoading();
                const response = await userStore.loginWithAccessToken();

                if (!response) {
                    throw new Error('Error while logging in with access token');
                }
            } catch (err: any) {
                tokenStore.clearTokens();
                userStore.clearUserInfo();
                customNavigate(CONSTANTS.SCREEN.HOME);
            } finally {
                loadingStore.removeLoading();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const canAccess = (route: ROUTE) => {
        if (route.restricted?.needLogin) {
            const userIsLoggedIn = userStore.isLogin();
            if (!userIsLoggedIn) {
                return false;
            }
        }

        if (route.restricted?.needApproval) {
            const userIsApproved = userStore.isApproval();
            if (!userIsApproved) {
                return false;
            }
        }

        return true;
    };

    useEffect(() => {
        loginIfTokenExists();
    }, [loginIfTokenExists]);

    useEffect(() => {
        if (cookiesStore?.getCurrentCookiesConsent()?.performance) {
            //keep the console.log for now
            console.log('Google Analytics initialized');
            ReactGA.initialize('G-FK91E2YVX0');

            ReactGA.pageview(window.location.pathname + window.location.search);
        }
    }, [
        window.location.pathname,
        window.location.search,
        cookiesStore?.modalIsVisible,
        cookiesStore?.modalDetailIsVisible,
    ]);

    return (
        <Routes>
            {Object.keys(CONSTANTS.ROUTES).map((language) => {
                return ROUTER_CONFIG.map((route: ROUTE) => {
                    const path = (CONSTANTS.ROUTES as any)[language][route.path];
                    const userCanAccess = canAccess(route);

                    if (route.redirect) {
                        return (
                            <Route
                                key={path || route.path}
                                path={path || route.path}
                                element={<RedirectToScreen to={route.redirect} />}
                            />
                        );
                    }

                    return (
                        <Route
                            key={path || route.path}
                            path={path || route.path}
                            Component={() => {
                                const Content = !userCanAccess
                                    ? Forbidden401
                                    : (route.children as any);

                                return (
                                    <DefaultLayout
                                        options={
                                            (route.seo as any)?.[
                                                languageStore.currentLanguage?.toLocaleLowerCase() ||
                                                    'en'
                                            ]
                                        }
                                        usingCreateAccountLayout={
                                            route.path === CONSTANTS.SCREEN.CREATE_ACCOUNT_FLOW ||
                                            route.path === CONSTANTS.SCREEN.EMAIL_CONFIRMED
                                        }
                                        defaultFooter={
                                            route.path === CONSTANTS.SCREEN.EMAIL_CONFIRMED
                                                ? true
                                                : false
                                        }
                                    >
                                        <Content />
                                    </DefaultLayout>
                                );
                            }}
                        />
                    );
                });
            })}
        </Routes>
    );
});

export default Router;
