import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import useMeasure from 'react-use-measure';

import './faq-item.scss';
import { theme } from '../../../Style/theme';
import { AddIcon } from '../../../Icons/add-icon';
import { ChevronBottomIcon } from '../../../Icons/chevron-bottom-icon';
import { ChevronTopIcon } from '../../../Icons/chevron-top-icon';

interface FaqItemProps {
    answer: string;
    hasCustomHTML?: {
        answer: boolean;
    };
    question: string;
    type?: 'small' | 'large';
}

const FaqItem: React.FC<FaqItemProps> = ({ answer, hasCustomHTML, question, type = 'large' }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const [boundsRef, { height }] = useMeasure();

    const handleIsOpen = () => {
        setIsOpen((prev) => !prev);
    };

    // const [height, setHeight] = useState(0);
    // const ref = useRef<any>(null);

    // useEffect(() => {
    //     setHeight(ref.current.clientHeight + 31);
    // });

    return (
        <div className="FaqItem" onClick={handleIsOpen}>
            <div className={`top ${type === 'large' ? 'large' : 'small'}`}>
                {type === 'small' && !isOpen ? (
                    <ChevronBottomIcon
                        fill={theme['black']}
                        width={24}
                        height={24}
                        className="icon"
                    />
                ) : type === 'small' && isOpen ? (
                    <ChevronTopIcon fill={theme['black']} width={24} height={24} className="icon" />
                ) : null}
                <h3 className="question ">{t(question)}</h3>

                {type === 'large' && (
                    <AddIcon
                        fill={theme['black']}
                        width={24}
                        height={24}
                        className={`icon ${isOpen ? '-rotate-45' : '-rotate-0'}`}
                    />
                )}
            </div>
            <motion.div
                initial={{ height: 0 }}
                animate={{ height: isOpen ? height + 18 : 0 }}
                className="bottom"
            >
                <div ref={boundsRef}>
                    {hasCustomHTML?.answer ? (
                        <div
                            className="text-body answer -html text-element-greys-secondary"
                            dangerouslySetInnerHTML={{ __html: t(answer) }}
                        />
                    ) : (
                        <div className="text-body answer text-element-greys-secondary">
                            {t(answer)}
                        </div>
                    )}
                </div>
            </motion.div>
        </div>
    );
};

export default FaqItem;
