import { useNavigate } from 'react-router-dom';
import { useStores } from './use-stores';
import CONSTANTS from '../Constants';
import { SupportedLanguage } from '../Stores/LanguageStore';

const useCustomNavigation = () => {
    const navigation = useNavigate();
    const { languageStore } = useStores();

    const customNavigation = (where: string, id?: string, params?: string) => {
        const language = (languageStore.currentLanguage as any) || SupportedLanguage.EN;
        const routesInLanguage = (CONSTANTS.ROUTES as any)?.[language]?.[where];

        if (!routesInLanguage) {
            return;
        }

        if (id) {
            const routesInLanguageWithoutLast3Chars = routesInLanguage.slice(0, -3);
            navigation(routesInLanguageWithoutLast3Chars + id);
            return;
        }

        navigation(`${routesInLanguage}${params ? params : ''}`, {
            replace: true,
        });
    };

    return customNavigation;
};

export default useCustomNavigation;
