import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const CheckmarkIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.6402 7.2318C23.0645 7.58537 23.1218 8.21593 22.7682 8.64021L12.7682 20.6402C12.5884 20.856 12.326 20.9863 12.0453 20.999C11.7646 21.0117 11.4916 20.9058 11.2929 20.7071L5.29289 14.7071C4.90237 14.3166 4.90237 13.6834 5.29289 13.2929C5.68342 12.9024 6.31658 12.9024 6.70711 13.2929L11.9328 18.5186L21.2318 7.35984C21.5853 6.93556 22.2159 6.87824 22.6402 7.2318Z"
                fill={props.fill}
            />
        </svg>
    );
};
