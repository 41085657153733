import React from 'react';
import { SvgProps } from './SvgProps';

const UsersIcon: React.FunctionComponent<SvgProps> = (props) => {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" {...props} fill="none">
            <g clipPath="url(#clip0_3597_20312)">
                <path
                    d="M11 20C14.5899 20 17.5 17.0899 17.5 13.5C17.5 9.91015 14.5899 7 11 7C7.41015 7 4.5 9.91015 4.5 13.5C4.5 17.0899 7.41015 20 11 20Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.77881 25.0001C2.77767 23.4643 4.1443 22.2023 5.75464 21.3286C7.36497 20.455 9.16801 19.9974 11.0001 19.9974C12.8321 19.9974 14.6351 20.455 16.2455 21.3286C17.8558 22.2023 19.2224 23.4643 20.2213 25.0001"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M22 20C23.832 19.9989 25.6351 20.4558 27.2456 21.329C28.856 22.2022 30.2227 23.4641 31.2213 25"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M19.5863 7.4625C20.4754 7.10787 21.4319 6.954 22.3875 7.0119C23.343 7.0698 24.2739 7.33802 25.1137 7.79742C25.9535 8.25681 26.6815 8.89602 27.2456 9.66939C27.8098 10.4428 28.1961 11.3312 28.3771 12.2712C28.5581 13.2112 28.5292 14.1795 28.2925 15.1071C28.0559 16.0346 27.6172 16.8984 27.008 17.6368C26.3988 18.3752 25.634 18.9698 24.7683 19.3784C23.9026 19.7869 22.9573 19.9992 22.0001 20"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3597_20312">
                    <rect width="32" height="32" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default UsersIcon;
