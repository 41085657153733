import { action, makeAutoObservable, observable } from 'mobx';

class OnBoardingModalStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable
    public isVisible: boolean = false;
    public urlEmbed: string = '';

    @action
    setIsVisible(visible: boolean, urlEmbed?: string): void {
        this.isVisible = visible;

        if (urlEmbed) {
            this.urlEmbed = urlEmbed;
        }
    }
}

export default OnBoardingModalStore;
