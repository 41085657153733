import React from 'react';
import InfiniteSliderHorizontal from '../../../../Components/InfiniteSliderHorizontal';

import Pet1 from './../../../../Assets/Img/LifeAtSpreeAI/pets/pet1.webp';
import Pet2 from './../../../../Assets/Img/LifeAtSpreeAI/pets/pet2.webp';
import Pet3 from './../../../../Assets/Img/LifeAtSpreeAI/pets/pet3.webp';
import Pet4 from './../../../../Assets/Img/LifeAtSpreeAI/pets/pet4.webp';
import Pet5 from './../../../../Assets/Img/LifeAtSpreeAI/pets/pet5.webp';
import Pet6 from './../../../../Assets/Img/LifeAtSpreeAI/pets/pet6.webp';
import Pet7 from './../../../../Assets/Img/LifeAtSpreeAI/pets/pet7.webp';
import Pet8 from './../../../../Assets/Img/LifeAtSpreeAI/pets/pet8.webp';
import Pet9 from './../../../../Assets/Img/LifeAtSpreeAI/pets/pet9.webp';
import Pet10 from './../../../../Assets/Img/LifeAtSpreeAI/pets/pet10.webp';
import Pet11 from './../../../../Assets/Img/LifeAtSpreeAI/pets/pet11.webp';
import Pet12 from './../../../../Assets/Img/LifeAtSpreeAI/pets/pet12.webp';
import Pet14 from './../../../../Assets/Img/LifeAtSpreeAI/pets/pet14.webp';
import Pet15 from './../../../../Assets/Img/LifeAtSpreeAI/pets/pet15.webp';

import './index.scss';
import WrapperSection from '../../../../Components/WrapperSection';
import { useTranslation } from 'react-i18next';
import PetCard from './Components/pet-card';

const PetSection = () => {
    const { t } = useTranslation();
    return (
        <div className="PetSection">
            <WrapperSection.Title dangerousHTML={t('LifeAtSpreeAIPage.pet_section_title')} />

            <div className="wrapper-slider">
                <div className="overlay-blur -left" />
                <InfiniteSliderHorizontal
                    components={[
                        <PetCard owner="(Tiffany)" src={Pet1} />,
                        <PetCard owner="(Tiffany)" src={Pet2} />,
                        <PetCard owner="(Enyo)" src={Pet3} />,
                        <PetCard owner="(Tiffany)" src={Pet4} />,
                        <PetCard owner="(Danel)" src={Pet5} />,
                        <PetCard owner="(Tuan Anh)" src={Pet6} />,
                        <PetCard owner="(Tiffany)" src={Pet7} />,
                        <PetCard owner="(Tiffany)" src={Pet8} />,
                        <PetCard owner="(John)" src={Pet9} />,
                        <PetCard owner="(Lolia)" src={Pet10} />,
                        <PetCard owner="(Tiffany)" src={Pet11} />,
                        <PetCard owner="(Tiffany)" src={Pet12} />,
                        <PetCard owner="(Victor)" src={Pet14} />,
                        <PetCard owner="(Victor)" src={Pet15} />,
                    ]}
                    speed={100}
                />
                <div className="overlay-blur -right" />
            </div>
        </div>
    );
};

export default PetSection;
