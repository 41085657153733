import React from 'react';

export interface LayoutPolicyJumpProps {
    nbr?: number;
}

const LayoutPolicyJump: React.FC<LayoutPolicyJumpProps> = ({ nbr = 1 }) => {
    return (
        <div className="LayoutPolicyJump">
            {Array.from(Array(nbr).keys()).map((_) => (
                <br key={_} />
            ))}
        </div>
    );
};

export default LayoutPolicyJump;
