import React from 'react';

interface LogoBrandSmallProps {
    width?: number;
    height?: number;
}

export const LogoBrandSmall: React.FC<LogoBrandSmallProps> = ({ width = 54, height = 53 }) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 54 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="LogoBrandSmall"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M39.3743 28.7855L38.3678 24.3181L38.3678 24.3181C38.2672 23.6232 38.1665 22.9282 38.2672 22.2333C38.4684 18.0638 41.5885 14.5892 45.715 13.6957L49.4389 12.9015C49.7409 12.8022 50.1435 12.9015 50.4454 13.1C50.7473 13.2986 50.9486 13.5964 51.0493 13.9935L51.4519 15.979C51.6532 16.6739 51.1499 17.3689 50.4454 17.5674L46.7215 18.3616C44.9098 18.6594 43.5008 20.0493 43.0982 21.8362C42.8969 22.5311 42.8969 23.1268 43.0982 23.8217L44.004 27.6934C44.2053 28.3884 43.7021 29.0833 42.9975 29.2818L40.9846 29.7782C40.2801 29.9768 39.5756 29.4804 39.3743 28.7855ZM50.1833 27.9912C52.2911 27.9912 53.9998 26.2958 53.9998 24.2044C53.9998 22.113 52.2911 20.4175 50.1833 20.4175C48.0756 20.4175 46.3669 22.113 46.3669 24.2044C46.3669 26.2958 48.0756 27.9912 50.1833 27.9912Z"
                fill="#9F2589"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M32.9235 15.7806L28.2938 15.3835C27.5892 15.2842 26.8847 15.0856 26.2808 14.7878C22.3556 13.2987 19.9401 9.32771 20.4433 5.15816L20.8459 1.485C20.8459 1.0879 21.0472 0.790075 21.3491 0.591526C21.6511 0.392976 21.953 0.293701 22.3556 0.293701L24.3685 0.492251C25.0731 0.591526 25.677 1.28645 25.5763 1.98137L25.1737 5.65454C24.9724 7.44148 25.8782 9.22843 27.4886 10.1219C28.0925 10.4197 28.6963 10.6183 29.4009 10.7176L33.4267 11.0154C33.4595 11.0261 33.4934 11.0369 33.5282 11.0479C33.8137 11.1385 34.1531 11.2462 34.3326 11.5118C34.5339 11.8096 34.6345 12.1074 34.6345 12.5045L34.4332 14.5893C34.4223 14.6216 34.4114 14.6551 34.4002 14.6893C34.3084 14.9709 34.1992 15.3057 33.93 15.4827C33.628 15.6813 33.3261 15.7806 32.9235 15.7806ZM32.3185 8.03727C34.4263 8.03727 36.135 6.34184 36.135 4.25042C36.135 2.159 34.4263 0.463575 32.3185 0.463575C30.2108 0.463575 28.5021 2.159 28.5021 4.25042C28.5021 6.34184 30.2108 8.03727 32.3185 8.03727Z"
                fill="#FCAB01"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.15252 17.9836C9.26028 17.9836 10.969 16.2882 10.969 14.1968C10.969 12.1053 9.26028 10.4099 7.15252 10.4099C5.04476 10.4099 3.33609 12.1053 3.33609 14.1968C3.33609 16.2882 5.04476 17.9836 7.15252 17.9836ZM16.5279 22.1338C16.2259 22.7294 15.8233 23.3251 15.4208 23.8215C12.8039 27.0975 8.1742 28.0903 4.24898 26.6012L0.827 25.112C0.52506 24.9135 0.223119 24.6157 0.122473 24.3178C-0.0788204 24.02 0.0218262 23.6229 0.122473 23.3251L0.927646 21.4389C1.12894 20.9425 1.63217 20.6447 2.13541 20.6447C2.3367 20.6447 2.53799 20.6447 2.73929 20.7439L6.16127 22.2331C7.87226 22.928 9.88519 22.6302 11.1936 21.4389C11.5962 21.0418 11.9988 20.4461 12.3007 19.8505L13.8104 16.1773C14.1124 15.4824 14.9175 15.1845 15.6221 15.4824L17.5343 16.2766C17.8363 16.3758 18.1382 16.6737 18.2389 16.9715C18.3395 17.2693 18.3395 17.6664 18.2389 17.9642L16.5279 22.0345V22.1338Z"
                fill="#E41C24"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.9309 32.1606L19.4536 35.1389C19.9568 35.6352 20.3594 36.2309 20.762 36.8265C22.9762 40.3012 22.5736 44.9671 19.7555 48.0446L17.2393 50.8243C17.0381 51.0228 16.7361 51.2214 16.3335 51.2214C15.9309 51.3207 15.629 51.1221 15.3271 50.9236L13.8174 49.5337C13.2135 49.0373 13.2135 48.2431 13.7167 47.6475L16.2329 44.8678C17.4406 43.5772 17.7426 41.691 17.0381 40.0033C16.7361 39.4077 16.3335 38.9113 15.8303 38.4149L12.8109 35.8338C12.3077 35.3374 12.207 34.5432 12.7103 33.9476L14.0187 32.3592C14.22 32.0613 14.5219 31.8628 14.9245 31.8628H15.0251C15.3271 31.8628 15.7296 31.9621 15.9309 32.1606ZM8.55941 45.7958C10.6672 45.7958 12.3758 44.1003 12.3758 42.0089C12.3758 39.9175 10.6672 38.2221 8.55941 38.2221C6.45166 38.2221 4.74298 39.9175 4.74298 42.0089C4.74298 44.1003 6.45166 45.7958 8.55941 45.7958Z"
                fill="#00BCB4"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M33.9072 36.1867C37.9331 35.0947 42.2609 36.9809 44.3745 40.5548L46.1861 43.9301C46.5887 44.5258 46.2868 45.32 45.6829 45.7171L43.8712 46.7098C43.5693 46.8091 43.1667 46.9084 42.8648 46.8091C42.5628 46.7098 42.2609 46.5113 42.0596 46.2135L40.1473 42.9374C39.3421 41.5475 37.7318 40.6541 36.1214 40.6541H35.6182C34.9137 40.6541 34.3098 40.8526 33.7059 41.2497L30.2839 43.3345C29.982 43.533 29.6801 43.6323 29.2775 43.533C28.9755 43.4338 28.6736 43.2352 28.4723 42.9374L27.3652 41.1504C27.0633 40.4555 27.2645 39.6613 27.8684 39.2642L31.8943 36.8816H31.9949C32.5988 36.5838 33.2027 36.3853 33.9072 36.1867ZM35.7434 52.3386C37.8512 52.3386 39.5599 50.6431 39.5599 48.5517C39.5599 46.4603 37.8512 44.7649 35.7434 44.7649C33.6357 44.7649 31.927 46.4603 31.927 48.5517C31.927 50.6431 33.6357 52.3386 35.7434 52.3386Z"
                fill="#2EB67D"
            />
        </svg>
    );
};
