import React, { useEffect } from 'react';
import './index.scss';
import WrapperSectionTitle from '../../../Components/WrapperSection/title';
import { ChevronRightIcon } from '../../../Icons/chevron-right-icon';
import { theme } from '../../../Style/theme';
import { ChevronLeftIcon } from '../../../Icons/chevron-left-icon';
import { motion } from 'framer-motion';

import Step1Both from './../../../Assets/Img/PartnerRessources/step_1_both.webp';
import Step2PremiumBoth from './../../../Assets/Img/PartnerRessources/step_2_premium_both.webp';
import Step2StandardBoth from './../../../Assets/Img/PartnerRessources/step_2_standard_both.webp';
import Step3Standard from './../../../Assets/Img/PartnerRessources/step_3_standard.webp';
import Step4Both from './../../../Assets/Img/PartnerRessources/step_4_premium_both.webp';
import { useWindowDimensions } from '../../../Hooks/use-window-dimensions';
import Carousel from '../../../Components/Carousel';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-responsive-modal';
import { CloseIcon } from '../../../Icons/close-icon';

import WrapperVideo from '../../../Components/WrapperVideo';

interface PartnerRessourcesRequirementsProps {
    requirements: 'premium' | 'standard' | 'none';
}

interface RenderElementProps {
    image?: string;
    desc: string;
    onClickElement?: () => void;
    children?: React.ReactNode;
    isSelected?: boolean;
    video?: string;
}

const RenderElement: React.FC<RenderElementProps> = ({
    image,
    desc,
    onClickElement,
    children,
    isSelected,
    video,
}) => {
    return (
        <div className={`element ${isSelected ? '-selected' : '-none'}`} onClick={onClickElement}>
            <div className="image">
                {image ? (
                    <img src={image} alt="" />
                ) : video ? (
                    <WrapperVideo
                        alt="require"
                        muted={true}
                        src={{
                            en: {
                                desktop: {
                                    mp4: video,
                                },
                                mobile: {
                                    mp4: video,
                                },
                            },
                            fr: {
                                desktop: {
                                    mp4: video,
                                },
                                mobile: {
                                    mp4: video,
                                },
                            },
                            it: {
                                desktop: {
                                    mp4: video,
                                },
                                mobile: {
                                    mp4: video,
                                },
                            },
                            es: {
                                desktop: {
                                    mp4: video,
                                },
                                mobile: {
                                    mp4: video,
                                },
                            },
                        }}
                    />
                ) : null}
            </div>

            <div className="desc" dangerouslySetInnerHTML={{ __html: desc }} />

            {!isSelected && <div className="overlay" />}

            {children}
        </div>
    );
};

const PartnerRessourcesRequirements: React.FC<PartnerRessourcesRequirementsProps> = ({
    requirements,
}) => {
    const [currentMarginLeft, setCurrentMarginLeft] = React.useState(75);
    const [selectedElement, setSelectedElement] = React.useState<null | number>(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const [disabled, setDisabled] = React.useState(false);
    const { windowWidth } = useWindowDimensions();
    const { t } = useTranslation();

    const [currentIndex, setCurrentIndex] = React.useState(0);
    const BREAKPOINT_CARDS_STANDARD =
        requirements === 'premium' ? [50, -200, -575, -975] : [50, -200, -575];

    const onClickLeft = () => {
        if (currentIndex - 1 < 0) {
            return;
        }

        setCurrentIndex((prev) => prev - 1);
    };

    const onClickRight = () => {
        if (currentIndex + 1 >= BREAKPOINT_CARDS_STANDARD.length) {
            return;
        }

        setCurrentIndex((prev) => prev + 1);
    };

    const onClickElement = (idx: number) => {
        if (disabled) {
            return;
        }
        setSelectedElement(idx);
        setIsOpen(true);
    };

    const onCloseModal = () => {
        setDisabled(true);
        setSelectedElement(null);
        setIsOpen(false);

        // disabled for 100ms to avoid double click
        setTimeout(() => {
            setDisabled(false);
        }, 500);
    };

    const elements = [
        {
            image: Step1Both,
            desc: t('RequirementsSection.requirement_label_1'),
        },
        {
            image: Step2StandardBoth,
            desc: t('RequirementsSection.requirement_label_2'),
        },
        {
            image: Step3Standard,
            desc: t('RequirementsSection.requirement_label_6'),
        },
        {
            image: Step2PremiumBoth,
            desc: t('RequirementsSection.requirement_label_4'),
        },
        {
            video: require('./step3.mp4'),
            desc: t('RequirementsSection.requirement_label_5'),
        },
        {
            image: Step4Both,
            desc: t('RequirementsSection.requirement_label_6'),
        },
    ];

    useEffect(() => {
        if (windowWidth < 1000) {
            return;
        }

        setCurrentMarginLeft(BREAKPOINT_CARDS_STANDARD[currentIndex] || 0);
    }, [currentIndex]);

    const currentColor =
        requirements === 'premium' ? 'rgba(159, 37, 137, 1)' : 'rgba(46, 182, 125, 1)';

    const clickRightIsDisabled = currentIndex === BREAKPOINT_CARDS_STANDARD.length - 1;
    const clickLeftIsDisabled = currentIndex === 0;

    if (windowWidth < 1000) {
        return (
            <div className="PartnerRessourcesRequirementsMobile" id="PartnerRessourcesRequirements">
                <div
                    className="half-circle"
                    style={{
                        backgroundColor: currentColor,
                    }}
                >
                    <WrapperSectionTitle className="capitalize text-white">
                        {requirements} {t('RequirementsSection.requirement_label')}
                    </WrapperSectionTitle>
                </div>
                <Carousel
                    carouselElements={
                        requirements === 'standard'
                            ? [
                                  <RenderElement
                                      {...elements[0]}
                                      onClickElement={() => onClickElement(0)}
                                  />,
                                  <RenderElement
                                      {...elements[1]}
                                      onClickElement={() => onClickElement(1)}
                                  />,

                                  <RenderElement
                                      {...elements[2]}
                                      onClickElement={() => onClickElement(2)}
                                  />,
                              ]
                            : [
                                  <RenderElement
                                      {...elements[0]}
                                      onClickElement={() => onClickElement(0)}
                                  />,
                                  <RenderElement
                                      {...elements[3]}
                                      onClickElement={() => onClickElement(3)}
                                  />,
                                  <RenderElement
                                      {...elements[4]}
                                      onClickElement={() => onClickElement(4)}
                                  />,
                                  <RenderElement
                                      {...elements[5]}
                                      onClickElement={() => onClickElement(5)}
                                  />,
                              ]
                    }
                    centerMode={false}
                    slidesToShow={1}
                    infiniteScroll={false}
                />

                {isOpen && (
                    <Modal
                        open={isOpen}
                        onClose={onCloseModal}
                        center
                        closeOnEsc={false}
                        showCloseIcon={false}
                        classNames={{
                            modal: 'lib-modal-container requirement-modal',
                        }}
                    >
                        <div className="wrapper-element">
                            <RenderElement {...elements[selectedElement || 0]}>
                                <div onClick={onCloseModal} className="close-icon-wp">
                                    <CloseIcon
                                        className="close-icon"
                                        fill={theme['black']}
                                        width={16}
                                        height={16}
                                    />
                                </div>
                            </RenderElement>
                        </div>
                    </Modal>
                )}
            </div>
        );
    }

    return (
        <div className="PartnerRessourcesRequirements" id="PartnerRessourcesRequirements">
            <div className={`left -${requirements}`}>
                <div
                    className="base"
                    style={{
                        backgroundColor: currentColor,
                    }}
                />
                <div
                    className="half-circle"
                    style={{
                        backgroundColor: currentColor,
                    }}
                >
                    <WrapperSectionTitle className="capitalize text-white">
                        {requirements} {t('RequirementsSection.requirement_label')}
                    </WrapperSectionTitle>
                </div>
            </div>

            <div className={`right -${requirements}`}>
                <motion.div
                    className="elements"
                    style={{
                        marginLeft: `${currentMarginLeft}px`,
                    }}
                >
                    {requirements === 'standard' ? (
                        <>
                            <RenderElement
                                isSelected={currentIndex === 0}
                                {...elements[0]}
                                onClickElement={() => onClickElement(0)}
                            />
                            <RenderElement
                                isSelected={currentIndex === 1}
                                {...elements[1]}
                                onClickElement={() => onClickElement(1)}
                            />
                            <RenderElement
                                isSelected={currentIndex === 2}
                                {...elements[2]}
                                onClickElement={() => onClickElement(2)}
                            />
                        </>
                    ) : (
                        <>
                            <RenderElement
                                isSelected={currentIndex === 0}
                                {...elements[0]}
                                onClickElement={() => onClickElement(0)}
                            />
                            <RenderElement
                                isSelected={currentIndex === 1}
                                {...elements[3]}
                                onClickElement={() => onClickElement(3)}
                            />
                            <RenderElement
                                isSelected={currentIndex === 2}
                                {...elements[4]}
                                onClickElement={() => onClickElement(4)}
                            />
                            <RenderElement
                                isSelected={currentIndex === 3}
                                {...elements[5]}
                                onClickElement={() => onClickElement(5)}
                            />
                        </>
                    )}
                </motion.div>

                <div
                    className="wrapper-control"
                    style={{
                        marginLeft: `${windowWidth - 510 - (windowWidth < 1050 ? 100 : 200)}px`,
                    }}
                >
                    <div
                        className={`control -disabled-${clickLeftIsDisabled}`}
                        onClick={onClickLeft}
                        style={{
                            backgroundColor: currentColor,
                        }}
                    >
                        <ChevronLeftIcon fill={theme['white']} />
                    </div>
                    <div
                        className={`control -disabled-${clickRightIsDisabled}`}
                        onClick={onClickRight}
                        style={{
                            backgroundColor: currentColor,
                        }}
                    >
                        <ChevronRightIcon fill={theme['white']} />
                    </div>
                </div>
            </div>

            {isOpen && (
                <Modal
                    open={isOpen}
                    onClose={onCloseModal}
                    center
                    closeOnEsc={false}
                    showCloseIcon={false}
                    classNames={{
                        modal: 'lib-modal-container requirement-modal',
                    }}
                >
                    <div className="wrapper-element">
                        <RenderElement {...elements[selectedElement || 0]}>
                            <div
                                onClick={onCloseModal}
                                className="close-icon-wp btn-delete-default-style"
                            >
                                <CloseIcon
                                    className="close-icon"
                                    fill={theme['black']}
                                    width={16}
                                    height={16}
                                />
                            </div>
                        </RenderElement>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default PartnerRessourcesRequirements;
