import { SvgProps } from './SvgProps';

const EnvelopeCheckedIcon: React.FC<SvgProps> = ({ ...props }) => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" {...props} fill="none">
            <g clipPath="url(#clip0_6378_12538)">
                <path
                    d="M6 30.2727V10.6364C6 10.2024 6.22508 9.78616 6.62573 9.47928C7.02637 9.1724 7.56976 9 8.13636 9H50.8636C51.4302 9 51.9736 9.1724 52.3743 9.47928C52.7749 9.78616 53 10.2024 53 10.6364V27V43.3636C53 43.7976 52.7749 44.2138 52.3743 44.5207C51.9736 44.8276 51.4302 45 50.8636 45H25.3036"
                    stroke={props.fill ?? '#62C89D'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M52 10L29.5 29L7 10"
                    stroke={props.fill ?? '#62C89D'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M6 39.8571L11.1 45L23 33"
                    stroke={props.fill ?? '#62C89D'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M52.25 44.5L33 27"
                    stroke={props.fill ?? '#62C89D'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_6378_12538">
                    <rect width="56" height="56" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default EnvelopeCheckedIcon;
