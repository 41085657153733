import * as React from 'react';
import { SvgProps } from '../../SvgProps';

export const CardboardIcon: React.FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_7622_61482)">
                <path
                    d="M4.22461 9.61523L16.1416 16.1352L28.0586 9.61523"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M16.1406 16.1367V28.9967"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M27.6255 22.8547L16.6214 28.8772C16.4742 28.9577 16.309 28.9999 16.1412 28.9999C15.9734 28.9999 15.8083 28.9577 15.661 28.8772L4.65691 22.8547C4.49979 22.7687 4.36862 22.6422 4.27712 22.4883C4.18561 22.3344 4.13713 22.1587 4.13672 21.9797V10.0222C4.13713 9.84314 4.18561 9.66749 4.27712 9.51357C4.36862 9.35966 4.49979 9.23312 4.65691 9.14718L15.661 3.12468C15.8083 3.04416 15.9734 3.00195 16.1412 3.00195C16.309 3.00195 16.4742 3.04416 16.6214 3.12468L27.6255 9.14718C27.7827 9.23312 27.9138 9.35966 28.0053 9.51357C28.0968 9.66749 28.1453 9.84314 28.1457 10.0222V21.9772C28.1458 22.1567 28.0975 22.3328 28.006 22.4872C27.9144 22.6416 27.783 22.7685 27.6255 22.8547Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M10.334 6.03906L22.1434 12.5003V19.0003"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <rect
                x="0.197266"
                y="0.0625"
                width="31.887"
                height="31.875"
                stroke="#33AB90"
                stroke-width="0.125"
            />
            <defs>
                <clipPath id="clip0_7622_61482">
                    <rect x="0.134766" width="32.012" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
export default CardboardIcon;
