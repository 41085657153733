import { useEffect } from 'react';

import styles from './index.module.scss';

import { Hero } from './Components/Hero';
import { HiringProcess } from './Components/HiringProcess';
import { About } from './Components/About';
import { LifeAtSpree } from './Components/LifeAtSpree';
import { NewPositions } from './Components/NewPostions';
import { Testimonials } from './Components/Testimonials';

const CareerPage = () => {
    useEffect(() => {
        const layoutEl = document.querySelector('.DefaultLayout') as HTMLElement;
        if (layoutEl) {
            layoutEl.style.overflowX = 'unset';
        }

        return () => {
            if (layoutEl) {
                layoutEl.style.overflowX = 'hidden';
            }
        };
    }, []);

    return (
        <main className={styles.jobListingsWrapper}>
            <Hero />
            <HiringProcess />
            <About />
            <LifeAtSpree />
            <Testimonials />
            <NewPositions />
        </main>
    );
};

export default CareerPage;
