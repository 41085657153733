import { useEffect, useState } from 'react';
import UseServices from '../../../Hooks/use-services';
import { JobListing } from '../../../Services/Careers';

export const useGetJobListings = () => {
    const [jobs, setJobs] = useState<JobListing[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const { careerService } = UseServices();

    useEffect(() => {
        setIsLoading(true);
        careerService
            .getJobsListings()
            .then((res) => {
                if (!res) {
                    setIsLoading(false);
                    Promise.reject('No jobs found');
                    return;
                }

                setJobs(res.jobs);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
            });
    }, []);

    return { jobs, isLoading };
};
