import React from 'react';
import useForm from '../../hooks';
import { theme } from '../../../../Style/theme';
import './index.scss';
import { GenericInputProps } from '../..';

export interface FileInputProps extends GenericInputProps {
    label: string;
    name: string;
    accept?: string;
}

const FileInput: React.FC<FileInputProps> = ({ label, name, accept }) => {
    const { getErrors, onChangeFile } = useForm();
    const errors = getErrors(name);

    return (
        <div className="FileInput">
            <label
                className="text-body-small"
                style={{
                    color: errors?.length > 0 ? theme['surface-error-rest-primary'] : 'black',
                }}
            >
                {label}
            </label>

            <input type="file" onChange={onChangeFile} name={name} accept={accept} />

            <span className="text-surface-error-rest-primary">
                {errors?.[0]?.extraInformations}
            </span>
        </div>
    );
};

export default FileInput;
