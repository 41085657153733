import './index.scss';
import Copydeck from '../../../../i18n/Copydeck';

import ExplanationImage from '../../../../Assets/Img/Home/en/explanation.webp';
import ExplanationImageFr from '../../../../Assets/Img/Home/fr/explanation.webp';
import ExplanationImageIT from '../../../../Assets/Img/Home/it/explanation.webp';
import ExplanationImageES from '../../../../Assets/Img/Home/es/explanation.webp';

import { useWindowDimensions } from '../../../../Hooks/use-window-dimensions';
import WrapperSection from '../../../../Components/WrapperSection';
import { LogoBrandSmall } from '../../../../Layout/Components/logo-brand-small';
import useHandleAnimation from '../../../../Hooks/use-handle-animation';
import { useRulesOfThrees } from '../../../../Hooks/use-rules-of-threes';
import WrapperImage from '../../../../Components/WrapperImage';

const Explanation = () => {
    const { windowWidth } = useWindowDimensions();

    // const breakpoint = 850;
    // const breakpointRemoveAnimation = 350;

    const ruleUnder850 = useRulesOfThrees(
        windowWidth < 640 ? (windowWidth <= 449 ? -90 : -60) : -60
    );
    // const ruleTop = useRulesOfThrees(windowWidth < 1048 ? -11 : -7);
    const ruleLeft = useRulesOfThrees(230);

    const CLASSNAME_BREAKPOINT = '.container-animation';
    const CLASSNAME_ADD_FOR_ANIMATION = 'animated-true';
    useHandleAnimation(CLASSNAME_BREAKPOINT, CLASSNAME_ADD_FOR_ANIMATION);

    return (
        <section className="Explanation explanation-content">
            <div className="explanation-content">
                <div className="explanation-left-section">
                    <WrapperSection.Title className="explanation-title capitalize" htmlTag="h2">
                        {Copydeck.homePageExplanationSectionExplanationTitle}
                    </WrapperSection.Title>

                    <p
                        className="text-body"
                        dangerouslySetInnerHTML={{
                            __html: Copydeck.homePageExplanationSectionSection,
                        }}
                    />
                </div>
                <div className="explanation-right-section">
                    <WrapperSection.Title className="explanation-title capitalize" htmlTag="h2">
                        {Copydeck.homePageExplanationSectionExplanationTitle}
                    </WrapperSection.Title>
                    <div className="explanation-image-container">
                        <WrapperImage
                            className="explanation-image"
                            src={{
                                en: ExplanationImage,
                                fr: ExplanationImageFr,
                                it: ExplanationImageIT,
                                es: ExplanationImageES,
                            }}
                            alt="Person shopping online"
                        />

                        <div className="container-try-on">
                            <div
                                className="container-animation"
                                style={{
                                    top: ruleUnder850,
                                    left: ruleLeft * -1,
                                }}
                            >
                                <div className="container-loupe">
                                    <div className="container-loupe-cirlce">
                                        <div className="container-try-on">
                                            <div className="circle">
                                                <LogoBrandSmall width={64} height={64} />
                                            </div>

                                            <div className="circle-text">{Copydeck.tryOn}</div>
                                        </div>
                                    </div>

                                    <div className="triangle" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Explanation;
