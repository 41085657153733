import * as React from 'react';
import { SvgProps } from '../../SvgProps';

export const ProcessorIcon: React.FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="33"
            height="33"
            viewBox="0 0 33 33"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clip-path="url(#clip0_7622_61490)">
                <path
                    d="M19.3298 13.2031H13.3281V19.2031H19.3298V13.2031Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M25.3333 6.20312H7.32655C6.77406 6.20312 6.32617 6.65084 6.32617 7.20312V25.2031C6.32617 25.7554 6.77406 26.2031 7.32655 26.2031H25.3333C25.8858 26.2031 26.3337 25.7554 26.3337 25.2031V7.20312C26.3337 6.65084 25.8858 6.20312 25.3333 6.20312Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M26.334 13.2031H29.3351"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M26.334 19.2031H29.3351"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M3.32617 13.2031H6.3273"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M3.32617 19.2031H6.3273"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M19.332 26.2031V29.2031"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M13.3281 26.2031V29.2031"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M19.332 3.20312V6.20312"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M13.3281 3.20312V6.20312"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_7622_61490">
                    <rect
                        width="32.012"
                        height="32"
                        fill="white"
                        transform="translate(0.324219 0.203125)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
export default ProcessorIcon;
