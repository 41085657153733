import React from 'react';
import './index.scss';

interface ImageOrVideoProps {
    ext: string;
    mime: string;

    childrenImage?: any;
    childrenVideo?: any;

    className?: string;
    onClick?: () => void;

    hasOverlay?: boolean;
}

const ImageOrVideo: React.FC<ImageOrVideoProps> = ({
    ext,
    mime,
    childrenImage,
    childrenVideo,
    className,
    onClick,
    hasOverlay,
}) => {
    const type =
        ext.includes('mp4') ||
        ext.includes('webm') ||
        ext.includes('ogg') ||
        ext.includes('mov') ||
        ext.includes('avi') ||
        ext.includes('flv') ||
        mime.includes('video')
            ? 'video'
            : 'image';

    if (type === 'image')
        return (
            <div onClick={onClick} className={`ImageOrVideo ${className}`}>
                {childrenImage}

                {hasOverlay ? <div className="tumbnail-overlay" /> : null}
            </div>
        );
    return (
        <div onClick={onClick} className={`ImageOrVideo ${className}`}>
            {childrenVideo}

            {hasOverlay ? <div className="tumbnail-overlay" /> : null}
        </div>
    );
};

export default ImageOrVideo;
