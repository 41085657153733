import React, { useEffect } from 'react';
import { GenericInputProps } from '../..';
import useForm from '../../hooks';
import './index.scss';
import { theme } from '../../../../Style/theme';

export interface BasicInputProps extends GenericInputProps {
    defaultValue?: string;
    style?: React.CSSProperties;
}

const BasicInput: React.FC<BasicInputProps> = ({
    label,
    name,
    type,
    defaultValue,
    disabled,
    style,
}) => {
    const { onChange, getErrors, getValue } = useForm();
    const errors = getErrors(name);
    const value = getValue(name);

    useEffect(() => {
        if (defaultValue) {
            onChange({
                target: {
                    name,
                    value: defaultValue,
                },
            } as any);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValue]);

    return (
        <div className="BasicInput" style={style}>
            <label
                className="text-body-small"
                style={{
                    color: errors?.length > 0 ? theme['surface-error-rest-primary'] : 'black',
                }}
            >
                {label}
            </label>
            <input
                style={{
                    borderColor: errors?.length > 0 ? theme['surface-error-rest-primary'] : '',
                }}
                onChange={onChange}
                name={name}
                type={type}
                value={value}
                disabled={disabled}
            />

            <span className="text-surface-error-rest-primary">
                {errors?.[0]?.extraInformations}
            </span>
        </div>
    );
};

export default BasicInput;
