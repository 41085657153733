import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const GlobeHemisphereWestIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="40" height="41" viewBox="0 0 40 41" {...props} fill="none">
            <g clipPath="url(#clip0_3595_49069)">
                <path
                    d="M20 35.5C28.2843 35.5 35 28.7843 35 20.5C35 12.2157 28.2843 5.5 20 5.5C11.7157 5.5 5 12.2157 5 20.5C5 28.7843 11.7157 35.5 20 35.5Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.8555 19.7595L13.9367 16.1251C14.0466 15.9348 14.2047 15.7768 14.395 15.6671C14.5854 15.5574 14.8014 15.4998 15.0211 15.5001H17.632C17.8431 15.5004 18.0508 15.4466 18.2352 15.3438L20.1555 14.2876C20.24 14.2421 20.3182 14.1859 20.3883 14.1204L24.5945 10.3188C24.8038 10.1285 24.9423 9.87279 24.9875 9.59352C25.0326 9.31424 24.9817 9.02792 24.843 8.78135L23.2023 5.84229"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.5913 8.81714L8.75071 13.1687C8.62638 13.4633 8.61967 13.7943 8.73196 14.0937L10.5288 18.8859C10.6027 19.0817 10.7245 19.2558 10.8831 19.3923C11.0417 19.5288 11.232 19.6233 11.4366 19.6671L14.7851 20.3875C14.9708 20.4273 15.145 20.5089 15.2944 20.6261C15.4439 20.7434 15.5647 20.8931 15.6476 21.064L16.2413 22.2937C16.3437 22.505 16.5036 22.6831 16.7026 22.8077C16.9015 22.9324 17.1316 22.9984 17.3663 22.9984H19.5226"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M33.2955 27.4501L25.1111 22.4157C24.9623 22.325 24.7963 22.266 24.6236 22.2423L21.058 21.7611C20.7902 21.7248 20.5179 21.7763 20.2818 21.9079C20.0458 22.0394 19.8587 22.2439 19.7486 22.4907L17.608 27.2939C17.5085 27.5174 17.4768 27.7652 17.5168 28.0065C17.5568 28.2479 17.6668 28.4722 17.833 28.6517L20.9174 31.8282C21.0511 31.972 21.1491 32.1452 21.2034 32.3339C21.2577 32.5226 21.2667 32.7214 21.2299 32.9142L20.7346 35.4829"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3595_49069">
                    <rect width="40" height="40" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
