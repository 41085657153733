import React from 'react';
import { useWindowDimensions } from '../../Hooks/use-window-dimensions';

export enum Device {
    Mobile = 'mobile',
    Tablet = 'tablet',
    Desktop = 'desktop',
}

interface WrapperDeviceProps {
    devices: Device[];
    children: React.ReactNode;
    breakpointMobile?: number;
}

const WrapperDevice: React.FC<WrapperDeviceProps> = ({ devices, children, breakpointMobile }) => {
    const { windowWidth } = useWindowDimensions();
    const BREAKPOINT = {
        mobile: breakpointMobile ? breakpointMobile : 500,
        tablet: 992,
    };

    if (devices.includes(Device.Mobile) && windowWidth < BREAKPOINT.mobile && windowWidth >= 0) {
        return <div className="WrapperDevice -mobile">{children}</div>;
    }

    if (
        devices.includes(Device.Tablet) &&
        windowWidth < BREAKPOINT.tablet &&
        windowWidth >= BREAKPOINT.mobile
    ) {
        return <div className="WrapperDevice -tablet">{children}</div>;
    }

    if (devices.includes(Device.Desktop) && windowWidth >= BREAKPOINT.tablet) {
        return <div className="WrapperDevice -desktop">{children}</div>;
    }

    return null;
};

export default WrapperDevice;
