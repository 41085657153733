import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { MapIcon } from '../../../Icons/map-icon';
import { TimeFiveIcon } from '../../../Icons/time-five-icon';
import { JobListing, JobLocation } from '../../../Services/Careers';
import { ChevronRightIcon } from '../../../Icons/chevron-right-icon';

import styles from './styles/job-listing-item.module.scss';

const formatLocation = (location: JobLocation) => {
    let locationString = '';
    if (location.city) {
        locationString += `${location.city}, `;
    }
    if (location.region) {
        locationString += `${location.region}, `;
    }
    locationString += location.country;

    return locationString.trim().replace(/,\s*$/, ''); // Clean up trailing commas and spaces
};

interface JobListingProps {
    job: JobListing;
}

export const JobListingItem = ({ job }: JobListingProps) => {
    const { t } = useTranslation();

    return (
        <a href={job.url} target="_blank" rel="noreferrer" className={styles.job}>
            <div className={styles.background} />
            <div className={styles.jobHeader}>
                <div className={styles.jobTitle}>
                    <div className={styles.jobTags}>
                        <div>{job.department}</div>
                        <div>{job.telecommuting ? 'Remote' : 'Hybrid'}</div>
                    </div>
                    <h3>{job.title}</h3>
                </div>
                <div className={styles.jobApply}>
                    {t('Apply Now')}
                    <span>
                        <ChevronRightIcon fill="#2b2b2b" />
                    </span>
                </div>
            </div>
            <div className={styles.jobDetails}>
                <div>
                    <MapIcon fill="#2B2B2B" />
                    {formatLocation(job.locations[0])}
                </div>
                <div>
                    <TimeFiveIcon fill="#2B2B2B" size="24" />
                    {job.employment_type}
                </div>
            </div>
            <div className={classNames(styles.jobApply, styles.hideDesktop)}>
                {t('Apply Now')}
                <span>
                    <ChevronRightIcon fill="black" />
                </span>
            </div>
        </a>
    );
};
