import React from 'react';
import './index.scss';
import Button from '../../../../Components/Button';
import CONSTANTS from '../../../../Constants';
import Ellipse from '../../../../Assets/Img/Home/Intellectual_Ellipse.webp';
import useCustomNavigation from '../../../../Hooks/use-custom-navigation';
import Copydeck from '../../../../i18n/Copydeck';
import WrapperSection from '../../../../Components/WrapperSection';

const FuturOfFashionSection: React.FC<any> = ({ ctaRef }) => {
    const nav = useCustomNavigation();

    return (
        <div className="FuturOfFashionSection">
            <div className="box" ref={ctaRef}>
                <WrapperSection.Title className="title text-white" htmlTag="h1">
                    {Copydeck.fashionSectionSectionTitle}
                </WrapperSection.Title>

                <div className="subtitle text-body text-white">
                    {Copydeck.fashionSectionSubtitle}
                </div>

                <Button
                    type="primary"
                    onClick={() => {
                        nav(CONSTANTS.SCREEN.CREATE_ACCOUNT);
                    }}
                    text={Copydeck.fashionSectionCtaTitle}
                    className="cta-button"
                    fullInMobile={false}
                />

                <img loading="lazy" className="ellipse ellipse-right" src={Ellipse} alt="logo" />
                <img loading="lazy" className="ellipse ellipse-left" src={Ellipse} alt="logo" />
            </div>
        </div>
    );
};

export default FuturOfFashionSection;
