import React from 'react';
import './index.scss';
import Button from '../../Components/Button';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../../Constants';
import WhiteCard from '../../Components/WhiteCard';
import useCustomNavigation from '../../Hooks/use-custom-navigation';

const Forbidden401 = () => {
    const { t } = useTranslation();
    const navigate = useCustomNavigation();

    return (
        <div className="Forbidden401">
            <h1 className="text-white">{CONSTANTS.ERROR_CODE.FORBIDDEN}</h1>
            <WhiteCard className="card" greenBorder>
                <h3 className="deprecated-text-headline-large">{t('page_forbidden')}</h3>
                <p className="deprecated-text-body-large">{t('page_forbidden_paragraph')}</p>
                <Button
                    text={t('go_to_login')}
                    type="primary"
                    onClick={() => navigate(CONSTANTS.SCREEN.LOGIN)}
                />
            </WhiteCard>
        </div>
    );
};

export default Forbidden401;
