import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const EmailSentIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="106"
            height="106"
            viewBox="0 0 106 106"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M53 57.375L18 35.5V79.25H57.375V88H18C15.5938 88 13.5339 87.1432 11.8203 85.4297C10.1068 83.7162 9.25 81.6563 9.25 79.25V26.75C9.25 24.3438 10.1068 22.2839 11.8203 20.5703C13.5339 18.8568 15.5938 18 18 18H88C90.4062 18 92.4661 18.8568 94.1797 20.5703C95.8932 22.2839 96.75 24.3438 96.75 26.75V57.375H88V35.5L53 57.375ZM53 48.625L88 26.75H18L53 48.625ZM83.625 101.125L77.5 95L84.3906 88H66.125V79.25H84.3906L77.3906 72.25L83.625 66.125L101.125 83.625L83.625 101.125ZM18 35.5V83.625V57.375V57.7031V26.75V35.5Z"
                fill={props.fill ?? '#2EB67D'}
            />
        </svg>
    );
};
