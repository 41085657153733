import React, { HTMLInputTypeAttribute } from 'react';
import './index.scss';
import BasicInput, { BasicInputProps } from './components/basic-input';
import Submit, { SubmitProps } from './components/submit';
import FileInput, { FileInputProps } from './components/file-input';
import FormProvider, { ValidationProps } from './providers';
import TextArea from './components/text-area';
import PasswordInput, { PasswordInputProps } from './components/password-input';
import FormContent from './form-content';

export interface GenericInputProps {
    label: string;
    placeholder?: string;
    name: string;
    type?: HTMLInputTypeAttribute;
    disabled?: boolean;
    errors?: {
        extraInformations: string;
    }[];
}

type FormProps = React.FunctionComponent<{
    children: React.ReactNode;
    formHeaderLine?: boolean;
    validation?: ValidationProps[];
    title?: string;
    className?: string;
    onSubmit: (values: any) => Promise<boolean>;
}>;

type EnhancedFormProps = FormProps & {
    BasicInput: React.FC<BasicInputProps>;
    Submit: React.FC<SubmitProps>;
    FileInput: React.FC<FileInputProps>;
    TextArea: React.FC<GenericInputProps>;
    PasswordInput: React.FC<PasswordInputProps>;
};

const Form: EnhancedFormProps = ({
    children,
    formHeaderLine = true,
    validation,
    title,
    className,
    onSubmit,
}) => {
    return (
        <FormProvider validation={validation}>
            <div className={`Form ${className ?? ''}`}>
                {formHeaderLine && <div className="form-header-line" />}

                <div className="deprecated-text-title-large form-title">{title}</div>
                <FormContent onSubmit={onSubmit}>{children}</FormContent>
            </div>
        </FormProvider>
    );
};

Form.BasicInput = BasicInput;
Form.Submit = Submit;
Form.FileInput = FileInput;
Form.TextArea = TextArea;
Form.PasswordInput = PasswordInput;

export default Form;
