import React, { HTMLInputTypeAttribute, useMemo, useState } from 'react';
import './index.scss';
import { AnimatedCheckmarkIcon } from '../../../../Icons/animated-checkmark-icon';
import { AnimatedXIcon } from '../../../../Icons/animated-x-icon';
import { EyeIcon } from '../../../../Icons/eye-icon';

interface AnimatedInputProps {
    label: string;
    name: string;
    isValid: boolean;
    isFocusable: boolean;
    errors?: string[];
    onBlur?: (value: string) => void;
    type?: HTMLInputTypeAttribute;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isEmpty?: boolean;
}

const AnimatedInput: React.FC<AnimatedInputProps> = ({
    label,
    name,
    isValid,
    errors = [],
    isFocusable,
    onBlur,
    type = 'text',
    onChange,
    isEmpty,
}) => {
    const EYE_COLOR = '#2B2B2B';
    const [showValue, setShowValue] = useState(false);

    const hasError = useMemo(() => {
        return errors.length > 0;
    }, [errors]);

    return (
        <div className={`AnimatedInput ${hasError ? 'has-error' : ''}`}>
            <div className="input-container">
                <input
                    name={name}
                    placeholder={label}
                    type={showValue === true ? 'text' : type}
                    onBlur={(d) => (onBlur ? onBlur(d.target.value) : undefined)}
                    autoComplete="new-password" //this removes autofill and we need user to focus out of every field
                    tabIndex={isFocusable ? undefined : -1}
                    onChange={onChange}
                />
                {type === 'password' && (
                    <EyeIcon
                        fill={EYE_COLOR}
                        className="eye cursor-pointer"
                        onClick={() => setShowValue((prev) => !prev)}
                        width={20}
                    />
                )}

                {isEmpty ? null : isValid && <AnimatedCheckmarkIcon />}
                {hasError && <AnimatedXIcon />}
            </div>
            {hasError && (
                <div className="error-message">
                    {errors.map((x, index) => {
                        return (
                            <span
                                className="deprecated-text-body-large text-surface-error-rest-primary"
                                key={index}
                            >
                                {x}
                            </span>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default AnimatedInput;
