import { action, observable, makeAutoObservable } from 'mobx';

export enum SupportedLanguage {
    FR = 'FR',
    EN = 'EN',
    IT = 'IT',
    ES = 'ES',
}

class LanguageStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable
    public currentLanguage: SupportedLanguage | null = null;

    @action
    setFirstRender() {
        if (localStorage.lang) {
            this.setCurrentLanguage(localStorage.lang as SupportedLanguage);
            return;
        }

        const browserLanguage = navigator.language.split('-')[0];

        this.setCurrentLanguage(
            SupportedLanguage[browserLanguage.toUpperCase() as keyof typeof SupportedLanguage] ||
                SupportedLanguage.EN
        );
    }

    @action
    setCurrentLanguage(lang: SupportedLanguage): void {
        this.currentLanguage = localStorage.lang = lang;
    }

    getSupportedLanguages(): { language: SupportedLanguage; label: string }[] {
        return [
            { language: SupportedLanguage.FR, label: 'FR' },
            { language: SupportedLanguage.EN, label: 'EN' },
            { language: SupportedLanguage.IT, label: 'IT' },
            { language: SupportedLanguage.ES, label: 'ES' },
        ];
    }
}

export default LanguageStore;
