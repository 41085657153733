import WrapperSection from '../../../../Components/WrapperSection';
import { motion } from 'framer-motion';
import { HangerIcon } from '../../../../Icons/hanger-icon';
import WrapperSectionTitle from '../../../../Components/WrapperSection/title';
import LeafIcon from '../../../../Icons/impacts-and-inclusions/our-goals/leaf-icon';
import RecycleIcon from '../../../../Icons/impacts-and-inclusions/our-goals/recycle-icon';
import SunIcon from '../../../../Icons/impacts-and-inclusions/our-goals/sun-icon';
import CardboardIcon from '../../../../Icons/impacts-and-inclusions/our-goals/cardboard-icon';
import ProcessorIcon from '../../../../Icons/impacts-and-inclusions/our-goals/processor-icon';
import ListIcon from '../../../../Icons/impacts-and-inclusions/our-goals/list-icon';
import CouponIcon from '../../../../Icons/impacts-and-inclusions/our-goals/coupon-icon';

import './index.scss';
import { useWindowDimensions } from '../../../../Hooks/use-window-dimensions';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Copydeck from '../../../../i18n/Copydeck';

interface Goal {
    wordsArray: string[];
    icon: React.ReactNode;
    status: string;
    gradientCurrentColor: string;
    isInfinite?: boolean;
}

const InclusionsAndImpactsOurGoals: React.FC<any> = ({ ctaDistanceRight }) => {
    const { windowWidth } = useWindowDimensions();
    const { t } = useTranslation();
    const isMobile = windowWidth <= 1000;

    const splitWords = (copydeckString: string): string[] => {
        if (!copydeckString) return ['', ''];
        const words = copydeckString.split(' ');
        const firstWord = words.shift() || '';
        const restOfSentence = words.join(' ');
        return [firstWord, restOfSentence];
    };

    const goals: Goal[] = [
        {
            wordsArray: splitWords(Copydeck.impactsAndInclusionsOurGoals1 as string),
            icon: <HangerIcon fill="white" />,
            status: Copydeck.impactsAndInclusionsOurGoalsCurrent,
            gradientCurrentColor: '#2EB67D',
        },
        {
            wordsArray: splitWords(Copydeck.impactsAndInclusionsOurGoals2 as string),
            icon: <LeafIcon fill="white" />,
            status: Copydeck.impactsAndInclusionsOurGoalsCurrent,
            gradientCurrentColor: '#2EB67D',
        },
        {
            wordsArray: splitWords(Copydeck.impactsAndInclusionsOurGoals3 as string),
            icon: <RecycleIcon fill="white" />,
            status: Copydeck.impactsAndInclusionsOurGoalsFuture,
            gradientCurrentColor: '#2EB67D',
        },
        {
            wordsArray: splitWords(Copydeck.impactsAndInclusionsOurGoals4 as string),
            icon: <SunIcon fill="white" />,
            status: Copydeck.impactsAndInclusionsOurGoalsFuture,
            gradientCurrentColor: '#30B284',
        },
        {
            wordsArray: splitWords(Copydeck.impactsAndInclusionsOurGoals5 as string),
            icon: <CardboardIcon fill="white" />,
            status: Copydeck.impactsAndInclusionsOurGoalsFuture,
            gradientCurrentColor: '#32AD8D',
        },
        {
            wordsArray: splitWords(Copydeck.impactsAndInclusionsOurGoals6 as string),
            icon: <ProcessorIcon fill="white" />,
            status: Copydeck.impactsAndInclusionsOurGoalsFuture,
            gradientCurrentColor: '#33A895',
        },
        {
            wordsArray: splitWords(Copydeck.impactsAndInclusionsOurGoals7 as string),
            icon: <ListIcon fill="white" />,
            status: Copydeck.impactsAndInclusionsOurGoalsFuture,
            gradientCurrentColor: '#36A39E',
        },
        {
            wordsArray: splitWords(Copydeck.impactsAndInclusionsOurGoals8 as string),
            icon: <CouponIcon fill="white" />,
            status: Copydeck.impactsAndInclusionsOurGoalsFuture,
            gradientCurrentColor: '#399EA7',
            isInfinite: true,
        },
    ];

    const dragLeftConstrait = useMemo(() => {
        if (windowWidth >= 1430) {
            return -(8 * 280);
        }

        if (windowWidth >= 1100) {
            return -2400;
        }

        if (windowWidth >= 1000) {
            return -2650;
        }

        if (windowWidth >= 900) {
            return -2500;
        }

        if (windowWidth >= 800) {
            return -2600;
        }

        if (windowWidth >= 700) {
            return -2600;
        }

        if (windowWidth >= 600) {
            return -2700;
        }

        if (windowWidth >= 500) {
            return -2800;
        }

        if (windowWidth >= 400) {
            return -2900;
        }

        if (windowWidth >= 300) {
            return -3000;
        }

        return -(8 * 280);
    }, [windowWidth]);

    return (
        <div className="OurGoalsContainer">
            <div className="inner-container">
                <WrapperSection paddingInMobile="80" className="OurGoals">
                    <WrapperSectionTitle
                        className="words"
                        dangerousHTML={Copydeck.impactsAndInclusionsOurGoalsTitle}
                        style={{
                            maxWidth: windowWidth - 80,
                        }}
                    />

                    <motion.div className="timeline">
                        <motion.div
                            className="timeline-track"
                            drag="x"
                            dragConstraints={{ right: 0, left: dragLeftConstrait }}
                        >
                            {goals.map((goal, index) => (
                                <div
                                    key={index}
                                    className={`timeline-item -infinite-${
                                        goal.isInfinite
                                    } -isLast-${index === goals.length - 1}`}
                                    style={{
                                        marginRight:
                                            index === goals.length - 1
                                                ? ctaDistanceRight
                                                : undefined,
                                        width: isMobile ? 300 : 350,
                                    }}
                                >
                                    <div className="top">
                                        <div
                                            className="timeline-status text-body"
                                            style={{ color: goal.gradientCurrentColor }}
                                        >
                                            {goal.status as string}
                                        </div>
                                        <div className="timeline-content text-body">
                                            <span className="bold">{goal.wordsArray[0]} </span>
                                            <span>{goal.wordsArray[1]}</span>
                                        </div>
                                    </div>
                                    <div
                                        className="timeline-icon"
                                        style={{ backgroundColor: goal.gradientCurrentColor }}
                                    >
                                        {goal.icon}
                                    </div>
                                </div>
                            ))}
                        </motion.div>
                    </motion.div>
                </WrapperSection>
                <div className="keep-scrolling">
                    {isMobile ? (
                        <span>{t('keep_scrolling')}</span>
                    ) : (
                        <span>{t('click_to_drag')}</span>
                    )}

                    <div className="arrow-container">
                        <div className="arrow animated-arrow"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InclusionsAndImpactsOurGoals;
