import React, { useState } from 'react';
import PartnerRessourcesHeader from './PartnerRessourcesHeader';
import Button from '../../Components/Button';
import { useTranslation } from 'react-i18next';

import './index.scss';
import PartnerRessourceTryOn from './PartnerRessourceTryOn';
import WrapperSectionTitle from '../../Components/WrapperSection/title';
import PartnerRessourcesRequirements from './PartnerRessourcesRequirements';

const PartnerRessourcesPage = () => {
    const { t } = useTranslation();

    const [requirements, setRequirements] = useState<'premium' | 'standard' | 'none'>('none');
    const ref = React.useRef<HTMLDivElement>(null);

    const onChangeRequirements = (value: 'premium' | 'standard' | 'none') => {
        const actionIsSame = value === requirements;
        if (actionIsSame) {
            setRequirements('none');
            return;
        }

        setRequirements(value);

        setTimeout(() => {
            if (value !== 'none') {
                ref.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'start',
                });
            }
        });
    };

    return (
        <div className="PartnerRessourcesPage">
            <PartnerRessourcesHeader
                requirements={requirements}
                onChangeRequirements={onChangeRequirements}
            />
            <PartnerRessourceTryOn />

            {requirements !== 'none' && (
                <div ref={ref}>
                    <PartnerRessourcesRequirements requirements={requirements} />
                </div>
            )}

            <div className="cta-bottom">
                <WrapperSectionTitle className="label">
                    {t('PartnerAsk.bottom_page_cta_label')}
                </WrapperSectionTitle>
                <Button type="primary" text={t('coming_soon')} fullInMobile={false} disabled />
            </div>
        </div>
    );
};

export default PartnerRessourcesPage;
