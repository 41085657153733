import './index.scss';
import FuturOfFashionSection from '../../../Home/Sections/FuturOfFashion';

const BlogFutureofFashionSection = () => {
    return (
        <div className="BlogFutureOfFashion">
            <FuturOfFashionSection />
        </div>
    );
};

export default BlogFutureofFashionSection;
