import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const CookiesIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            {...props}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_6619_14608)">
                <path
                    d="M14.625 17.25C15.2463 17.25 15.75 16.7463 15.75 16.125C15.75 15.5037 15.2463 15 14.625 15C14.0037 15 13.5 15.5037 13.5 16.125C13.5 16.7463 14.0037 17.25 14.625 17.25Z"
                    fill={props.fill}
                />
                <path
                    d="M8.625 16.5C9.24632 16.5 9.75 15.9963 9.75 15.375C9.75 14.7537 9.24632 14.25 8.625 14.25C8.00368 14.25 7.5 14.7537 7.5 15.375C7.5 15.9963 8.00368 16.5 8.625 16.5Z"
                    fill={props.fill}
                />
                <path
                    d="M7.875 11.25C8.49632 11.25 9 10.7463 9 10.125C9 9.50368 8.49632 9 7.875 9C7.25368 9 6.75 9.50368 6.75 10.125C6.75 10.7463 7.25368 11.25 7.875 11.25Z"
                    fill={props.fill}
                />
                <path
                    d="M12.375 12.75C12.9963 12.75 13.5 12.2463 13.5 11.625C13.5 11.0037 12.9963 10.5 12.375 10.5C11.7537 10.5 11.25 11.0037 11.25 11.625C11.25 12.2463 11.7537 12.75 12.375 12.75Z"
                    fill={props.fill}
                />
                <path
                    d="M21 12C19.8065 12 18.6619 11.5259 17.818 10.682C16.9741 9.83807 16.5 8.69347 16.5 7.5C15.3065 7.5 14.1619 7.02589 13.318 6.18198C12.4741 5.33807 12 4.19347 12 3C10.22 3 8.47991 3.52784 6.99987 4.51677C5.51983 5.50571 4.36628 6.91131 3.68509 8.55585C3.0039 10.2004 2.82567 12.01 3.17294 13.7558C3.5202 15.5016 4.37737 17.1053 5.63604 18.364C6.89472 19.6226 8.49836 20.4798 10.2442 20.8271C11.99 21.1743 13.7996 20.9961 15.4442 20.3149C17.0887 19.6337 18.4943 18.4802 19.4832 17.0001C20.4722 15.5201 21 13.78 21 12Z"
                    stroke={props.fill}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_6619_14608">
                    <rect width="24" height="24" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
