import React from 'react';
import useForm from '../../hooks';
import { BasicInputProps } from '../basic-input';
import { theme } from '../../../../Style/theme';
import './index.scss';

const TextArea: React.FC<BasicInputProps> = ({ label, name }) => {
    const { onChange, getErrors, getValue } = useForm();
    const errors = getErrors(name);
    const value = getValue(name);

    return (
        <div className="TextArea">
            <label
                className="text-body-small"
                style={{
                    color: errors?.length > 0 ? theme['surface-error-rest-primary'] : 'black',
                }}
            >
                {label}
            </label>
            <textarea
                style={{
                    borderColor: errors?.length > 0 ? theme['surface-error-rest-primary'] : '',
                }}
                onChange={onChange}
                name={name}
                rows={4}
                value={value}
            />

            <span className="text-surface-error-rest-primary">
                {errors?.[0]?.extraInformations}
            </span>
        </div>
    );
};

export default TextArea;
