import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import CONSTANTS from '../../../../Constants';
import WrapperSection from '../../../../Components/WrapperSection';
import Button from '../../../../Components/Button';
import heroPlaceholder from '../../../../Assets/Img/Home/view-demo-complete.webp';
import heroVideoMp4 from '../../../../Assets/MP4/view-demo-complete.mp4';
import heroVideoWebM from '../../../../Assets/MP4/view-demo-complete.webm';
import { Link } from '../../../../Components/Link';

import UseServices from '../../../../Hooks/use-services';
import { AnnoucementDto } from '../../../../Services/Annoucement';
import { motion } from 'framer-motion';
import { CloseIcon } from '../../../../Icons/close-icon';
import { theme } from '../../../../Style/theme';
import Copydeck from '../../../../i18n/Copydeck';
import AnnoucementTitle from '../../../../Layout/Components/annoucement-title';
import { ArrowRightIcon } from '../../../../Icons/arrow-right-icon';
import useAnnoucementLearnMore from '../../../../Hooks/use-annoucement-learn-more';

import './index.scss';

const Hero = () => {
    const { t } = useTranslation();
    const { annoucementService } = UseServices();
    const [lastAnnoucement, setLastAnnoucement] = useState<AnnoucementDto | null>(null);
    const [lastAnnoucementId, setLastAnnoucementId] = useState<string | null>(null);
    const [showLastAnnoucement, setShowLastAnnoucement] = useState(false);

    const getLastAnnoucement = async () => {
        try {
            const annoucement = await annoucementService.getLastOne();
            if (!annoucement) {
                return;
            }

            const isClosed = await annoucementService.isClosedAnnoucement(annoucement.id);

            if (isClosed) {
                return;
            }

            setLastAnnoucement(annoucement?.data);
            setShowLastAnnoucement(true);
            setLastAnnoucementId(annoucement.id);
        } catch (error) {
            console.error(error);
            toast.error(t('generic_api_error') as string);
        }
    };

    useEffect(() => {
        getLastAnnoucement();
    }, []);

    const onClickLearnMore = useAnnoucementLearnMore();

    const onPressAnnoucement = () => {
        const anchorEl = document.getElementById('annoucement');

        if (anchorEl) {
            const getPosY = anchorEl.getBoundingClientRect().top;

            window.scrollTo({
                top: getPosY - 130,
                behavior: 'smooth',
            });
        }
    };

    const onClickGoToAnnoucement = () => {
        if (!lastAnnoucement || !lastAnnoucementId || !onPressAnnoucement) {
            return;
        }

        // TODO: do we make this so it highlights announcement?: onPressAnnoucement(lastAnnoucementId);
        onPressAnnoucement();
    };

    const onCloseAnnoucement = async () => {
        if (!lastAnnoucementId) {
            return;
        }

        try {
            await annoucementService.closeAnnoucement(lastAnnoucementId);
            setShowLastAnnoucement(false);
        } catch (error) {
            console.error(error);
            toast.error(t('generic_api_error') as string);
        }
    };

    return (
        <WrapperSection className="Hero">
            <div className="wrapper-video">
                <video autoPlay muted loop playsInline poster={heroPlaceholder}>
                    <source src={heroVideoWebM} type="video/webm" />
                    <source src={heroVideoMp4} type="video/mp4" />
                </video>
            </div>

            {showLastAnnoucement && lastAnnoucement ? (
                <Announcement
                    lastAnnoucement={lastAnnoucement}
                    onClickGoToAnnoucement={onClickGoToAnnoucement}
                    onClickLearnMore={onClickLearnMore}
                    onCloseAnnoucement={onCloseAnnoucement}
                />
            ) : null}

            <div className="inner">
                <div className="extra-info">
                    <WrapperSection.Title htmlTag="h1" className="text-white">
                        {t('HomePage.home_page_title')}
                    </WrapperSection.Title>

                    <div className="text-body text-white">{t('HomePage.home_page_subtitle')}</div>
                    <Link pathConfig={{ where: CONSTANTS.SCREEN.WHAT_IS_SPREEAI }}>
                        <Button type="white" text={t('HomePage.cta_title_primary')} />
                    </Link>
                </div>
            </div>
        </WrapperSection>
    );
};

export default Hero;

interface AnnouncementProps {
    lastAnnoucement: AnnoucementDto | undefined;
    onClickLearnMore: (url: string) => void;
    onClickGoToAnnoucement: () => void;
    onCloseAnnoucement: () => void;
}

const Announcement = ({
    lastAnnoucement,
    onClickLearnMore,
    onClickGoToAnnoucement,
    onCloseAnnoucement,
}: AnnouncementProps) => {
    const hasRedirect = lastAnnoucement?.redirectionUrl;

    return (
        <motion.div
            className="annoucement-toast"
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1 }}
        >
            <div className="box btn-delete-default-style" onClick={onClickGoToAnnoucement}>
                <div className="left">
                    <div className="whatnews text-body-primary-font-bold text-white">
                        {Copydeck.annoucementSectionWhatsNews}
                    </div>

                    <div className="content">
                        <div className="annoucement-title text-body text-white">
                            <AnnoucementTitle annoucement={lastAnnoucement}>
                                {hasRedirect && (
                                    <button
                                        onClick={(e) => {
                                            e.stopPropagation();

                                            onClickLearnMore(lastAnnoucement?.redirectionUrl);
                                        }}
                                        className="text-body-bold text-white learn-more btn-delete-default-style"
                                    >
                                        {Copydeck.annoucementSectionLearnMoreAnnoucement}
                                        <div
                                            className={`wrapper-arrow-right ${
                                                hasRedirect ? '-has-link' : ''
                                            }`}
                                        >
                                            <ArrowRightIcon
                                                height={24}
                                                width={24}
                                                fill={theme['white']}
                                            />
                                        </div>
                                    </button>
                                )}
                            </AnnoucementTitle>
                        </div>
                        {!hasRedirect ? (
                            <ArrowRightIcon height={24} width={24} fill={theme['white']} />
                        ) : null}
                    </div>
                </div>

                <div className="right">
                    <button
                        className="close btn-delete-default-style"
                        onClick={(e) => {
                            e.stopPropagation();
                            onCloseAnnoucement();
                        }}
                    >
                        <CloseIcon fill={theme['white']} />
                    </button>
                </div>
            </div>
        </motion.div>
    );
};
