import { SvgProps } from './SvgProps';

export const MagnifyingGlassIcon = ({ ...props }: SvgProps) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_19_1093)">
                <path
                    d="M19.7099 18.2899L15.9999 14.6099C17.44 12.8143 18.1374 10.5352 17.9487 8.2412C17.76 5.94721 16.6996 3.81269 14.9854 2.27655C13.2713 0.74041 11.0337 -0.0805855 8.73283 -0.0176251C6.43194 0.0453353 4.24263 0.987466 2.61505 2.61505C0.987466 4.24263 0.0453353 6.43194 -0.0176251 8.73283C-0.0805855 11.0337 0.74041 13.2713 2.27655 14.9854C3.81269 16.6996 5.94721 17.76 8.2412 17.9487C10.5352 18.1374 12.8143 17.44 14.6099 15.9999L18.2899 19.6799C18.3829 19.7736 18.4935 19.848 18.6153 19.8988C18.7372 19.9496 18.8679 19.9757 18.9999 19.9757C19.1319 19.9757 19.2626 19.9496 19.3845 19.8988C19.5063 19.848 19.6169 19.7736 19.7099 19.6799C19.8901 19.4934 19.9909 19.2442 19.9909 18.9849C19.9909 18.7256 19.8901 18.4764 19.7099 18.2899ZM8.9999 15.9999C7.61544 15.9999 6.26206 15.5894 5.11091 14.8202C3.95977 14.051 3.06256 12.9578 2.53275 11.6787C2.00293 10.3996 1.86431 8.99214 2.13441 7.63427C2.4045 6.27641 3.07119 5.02912 4.05016 4.05016C5.02912 3.07119 6.27641 2.4045 7.63427 2.13441C8.99214 1.86431 10.3996 2.00293 11.6787 2.53275C12.9578 3.06256 14.051 3.95977 14.8202 5.11091C15.5894 6.26206 15.9999 7.61544 15.9999 8.9999C15.9999 10.8564 15.2624 12.6369 13.9497 13.9497C12.6369 15.2624 10.8564 15.9999 8.9999 15.9999Z"
                    fill="#2B2B2B"
                />
            </g>
            <defs>
                <clipPath id="clip0_19_1093">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
