import { SvgProps } from './SvgProps';

const ClockIcon: React.FC<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            fill="none"
        >
            <circle cx="12" cy="12" r="10" stroke={props.fill} strokeWidth="1.5" />
            <path
                d="M12 8V12L14.5 14.5"
                stroke={props.fill}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default ClockIcon;
