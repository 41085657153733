import { useCallback, useEffect } from 'react';

const useHandleAnimation = (
    classNameHandle: string,
    classNameAdd: string | null,
    onChangeVisible?: (isVisible: boolean) => void
) => {
    const SPEED_ANIMATION = 1200;

    const waitForElm = (selector: any) => {
        return new Promise((resolve) => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }

            const observer = new MutationObserver(() => {
                if (document.querySelector(selector)) {
                    observer.disconnect();
                    resolve(document.querySelector(selector));
                }
            });

            observer.observe(document.body, {
                childList: true,
                subtree: true,
            });
        });
    };

    const scrollAnimation = useCallback(() => {
        const containerAnimation: any = document.querySelector(classNameHandle);
        if (classNameAdd || !onChangeVisible) {
            containerAnimation.classList.remove(classNameAdd);
        } else {
            onChangeVisible(false);
        }

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const timeout = setTimeout(() => {
                        if (classNameAdd || !onChangeVisible) {
                            containerAnimation.classList.add(classNameAdd);
                        } else {
                            onChangeVisible(true);
                        }
                    }, SPEED_ANIMATION);

                    return () => clearTimeout(timeout);
                }

                if (classNameAdd || !onChangeVisible) {
                    containerAnimation.classList.remove(classNameAdd);
                } else {
                    onChangeVisible(false);
                }
            });
        });

        observer.observe(document.querySelector(classNameHandle) as any);
    }, [classNameAdd, onChangeVisible, classNameHandle]);

    useEffect(() => {
        if (!classNameHandle) {
            return;
        }

        waitForElm(classNameHandle).then(() => {
            scrollAnimation();
        });
    }, [classNameHandle, scrollAnimation]);
};

export default useHandleAnimation;
