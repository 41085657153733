import React from 'react';

export interface LayoutPolicyTitleProps {
    title: string;
}

const LayoutPolicyTitle: React.FC<LayoutPolicyTitleProps> = ({ title }) => {
    return <h1 className="LayoutPolicyTitle deprecated-text-headline-medium">{title}</h1>;
};

export default LayoutPolicyTitle;
