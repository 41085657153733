import React from 'react';
import { SvgProps } from './SvgProps';

const UserCircleIcon: React.FunctionComponent<SvgProps> = (props) => {
    return (
        <svg width="51" height="51" viewBox="0 0 51 51" fill="none">
            <g clip-path="url(#clip0_5823_13663)">
                <path
                    d="M25.375 44.25C35.7303 44.25 44.125 35.8553 44.125 25.5C44.125 15.1447 35.7303 6.75 25.375 6.75C15.0197 6.75 6.625 15.1447 6.625 25.5C6.625 35.8553 15.0197 44.25 25.375 44.25Z"
                    fill="transparent"
                    stroke={props.fill}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M12.8359 39.4397C14.0115 37.1241 15.8054 35.1793 18.0186 33.8208C20.2319 32.4623 22.7781 31.7432 25.375 31.7432C27.9719 31.7432 30.5181 32.4623 32.7314 33.8208C34.9446 35.1793 36.7385 37.1241 37.9141 39.4397"
                    stroke={props.fill}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M25.375 31.75C29.6897 31.75 33.1875 28.2522 33.1875 23.9375C33.1875 19.6228 29.6897 16.125 25.375 16.125C21.0603 16.125 17.5625 19.6228 17.5625 23.9375C17.5625 28.2522 21.0603 31.75 25.375 31.75Z"
                    stroke={props.fill}
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_5823_13663">
                    <rect
                        width="50"
                        height="50"
                        fill="transparent"
                        transform="translate(0.375 0.5)"
                    />
                </clipPath>
            </defs>{' '}
        </svg>
    );
};

export default UserCircleIcon;
