import React, { FunctionComponent } from 'react';
import './index.scss';
import { CloseIcon } from '../../Icons/close-icon';
import { theme } from '../../Style/theme';

interface OnBoardingModalProps {
    onClose: () => void;
    urlEmbed?: string;
}
const OnBoardingModal: FunctionComponent<OnBoardingModalProps> = ({ onClose, urlEmbed }) => {
    return (
        <div className="OnBoardingModal">
            <div className="video-container">
                <div className="wrapper-close-icon">
                    <div onClick={onClose} className="close-icon btn-delete-default-style">
                        <CloseIcon fill={theme['white']} />
                    </div>
                </div>

                <div className="video-container">
                    <iframe
                        src={
                            urlEmbed
                                ? urlEmbed
                                : 'https://www.youtube.com/embed/ocxrkwD9KRU?si=HRsusne_e1k4A0HU'
                        }
                        title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                        frameBorder={0}
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default OnBoardingModal;
