import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const PackageIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="48" height="50" viewBox="0 0 48 50" {...props} fill="none">
            <g clipPath="url(#clip0_7196_23599)">
                <path
                    d="M6.12109 15.0234L23.9588 25.2109L41.7966 15.0234"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M23.959 25.2129V45.3066"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M41.1491 35.7108L24.6777 45.121C24.4574 45.2468 24.2102 45.3127 23.959 45.3127C23.7078 45.3127 23.4606 45.2468 23.2402 45.121L6.76888 35.7108C6.53368 35.5765 6.33735 35.3788 6.20039 35.1383C6.06342 34.8978 5.99084 34.6234 5.99023 34.3436V15.66C5.99084 15.3803 6.06342 15.1058 6.20039 14.8653C6.33735 14.6248 6.53368 14.4271 6.76888 14.2928L23.2402 4.88269C23.4606 4.75686 23.7078 4.69092 23.959 4.69092C24.2102 4.69092 24.4574 4.75686 24.6777 4.88269L41.1491 14.2928C41.3843 14.4271 41.5806 14.6248 41.7176 14.8653C41.8546 15.1058 41.9271 15.3803 41.9277 15.66V34.3397C41.9278 34.6201 41.8555 34.8954 41.7185 35.1366C41.5815 35.3779 41.3848 35.5762 41.1491 35.7108Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.2656 9.43555L32.9424 19.5312V29.6875"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_7196_23599">
                    <rect width="47.9167" height="50" fill={props.fill} />
                </clipPath>
            </defs>
        </svg>
    );
};
