import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import WhiteCard from '../../../Components/WhiteCard';
import Button from '../../../Components/Button';
import CONSTANTS from '../../../Constants';
import { CheckmarkIcon } from '../../../Icons/checkmark-icon';
import { theme } from '../../../Style/theme';
import useCustomNavigation from '../../../Hooks/use-custom-navigation';

const PasswordSuccess = () => {
    const { t } = useTranslation();
    const navigate = useCustomNavigation();

    return (
        <div className="PasswordSuccess">
            <WhiteCard>
                <CheckmarkIcon
                    width={105}
                    height={150}
                    fill={theme['surface-brand-rest-primary']}
                />
                <h1 className="deprecated-text-display-small">
                    {t('ForgotPassword.password_reset_success')}
                </h1>
                <p className="deprecated-text-title-medium text-surface-grey-primary">
                    {t('ForgotPassword.password_reset_success_paragraph')}
                </p>
                <div className="line" />
                <Button
                    text={t('Login.login')}
                    type="primary"
                    onClick={() => navigate(CONSTANTS.SCREEN.LOGIN)}
                />
            </WhiteCard>
        </div>
    );
};

export default PasswordSuccess;
