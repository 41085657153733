import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';

import { ArticleDto } from '../../../../Services/Blog';
import WrapperSection from '../../../../Components/WrapperSection';
import ClockIcon from '../../../../Icons/ClockIcon';
import EnvelopeIcon from '../../../../Icons/envelope-icon';
import { LinkedinIcon } from '../../../../Icons/linkedin-icon';
import { TwitterIcon } from '../../../../Icons/twitter-icon';
import { HyperlinkIcon } from '../../../../Icons/hyperlink-icon';
import { formatDate } from '../../../../Utils/object';
import { useStores } from '../../../../Hooks/use-stores';
import { SupportedLanguage } from '../../../../Stores/LanguageStore';
import Copydeck from '../../../../i18n/Copydeck';
import { theme } from '../../../../Style/theme';
import { calculateReadingTime } from '../../../../Utils/object';

import './index.scss';

interface BlogBannerProps {
    article: ArticleDto | null;
}

export const BlogBanner = ({ article }: BlogBannerProps) => {
    const { languageStore } = useStores();
    const { t } = useTranslation();

    const buildMailToLink = () => {
        const textToWrite = `${Copydeck.blogPagePlaceholderShared}\n\n ${window.location.href} \n\n`;
        const subject = article?.attributes?.title || Copydeck.blogPagePlaceholderShared;
        return `mailto:?subject=${subject}&body=${textToWrite}`;
    };

    const copyLinkToClipboard = () => {
        const link = window.location.href;

        navigator.clipboard.writeText(link);
        toast.success(t('BlogPage.link_copied') as string);
    };

    const readingTime = useMemo(() => {
        if (!article) {
            return;
        }

        const blocks = article.attributes.blocks;
        let numberOfCharacters = 0;

        blocks.forEach((block) => {
            if (block.__component === 'shared.rich-text') {
                numberOfCharacters += block.body.length;
            }
        });

        return calculateReadingTime(numberOfCharacters);
    }, [article]);

    return (
        <div className="Banner">
            <div className="banner-wrapper-video">
                <picture>
                    <source
                        src={article?.attributes?.cover?.data?.attributes?.formats?.large?.url}
                        media="(max-width: 780px)"
                    />
                    <source
                        srcSet={article?.attributes?.cover?.data?.attributes?.url}
                        media="(min-width: 780px)"
                    />
                    <img
                        className="img"
                        src={article?.attributes?.cover?.data?.attributes?.url}
                        alt={article?.attributes?.title}
                        decoding="async"
                    />
                </picture>
            </div>
            <div className="banner-wrapper-content-info">
                <div className="content-info">
                    {article?.attributes?.category?.data?.attributes.name && (
                        <div className="category">
                            <div className="text-white text-body-medium-primary-font">
                                {article?.attributes?.category?.data?.attributes.name || 'none'}
                            </div>
                        </div>
                    )}

                    <WrapperSection.Title className="text-white">
                        {article?.attributes?.title}
                    </WrapperSection.Title>

                    {(article?.attributes?.authors?.data?.length || 0) > 0 && (
                        <div className="text-white text-body">
                            {Copydeck.blogPageBannerBy}:{' '}
                            {article?.attributes?.authors?.data
                                ?.map((author) => author.attributes.name)
                                .join(', ')}
                        </div>
                    )}
                    {article?.attributes?.publishedAt && (
                        <p className="text-white text-body">
                            {formatDate(
                                article?.attributes?.publishedAt || '',
                                languageStore.currentLanguage || SupportedLanguage.EN
                            )}
                        </p>
                    )}
                    <div className="text-white wrapper-time text-body">
                        <ClockIcon fill={theme['white']} />
                        <span className="text-body text-white">
                            {readingTime} {t('min')}
                        </span>
                    </div>
                    <div className="banner-wrapper-icon-share">
                        <a
                            href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`}
                            target="_blank"
                            rel="noreferrer"
                            title="Share on LinkedIn"
                        >
                            <LinkedinIcon height={24} width={24} fill="white" aria-hidden="true" />
                            <VisuallyHidden.Root>Share on LinkedIn</VisuallyHidden.Root>
                        </a>
                        <a
                            href={`https://twitter.com/intent/tweet?text=${window.location.href}`}
                            target="_blank"
                            rel="noreferrer"
                            title="Share on Twitter"
                        >
                            <TwitterIcon width={24} fill="white" height={24} />
                            <VisuallyHidden.Root>Share on Twitter</VisuallyHidden.Root>
                        </a>
                        <a href={buildMailToLink()} title="Share view email">
                            <EnvelopeIcon fill="white" />
                            <VisuallyHidden.Root>Share via email</VisuallyHidden.Root>
                        </a>
                        <button onClick={copyLinkToClipboard} title="Copy link to clipboard">
                            <HyperlinkIcon fill="white" width={24} height={24} />
                            <VisuallyHidden.Root>Copy link to clipboard</VisuallyHidden.Root>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
