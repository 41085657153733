import { Switch } from 'antd';
import React from 'react';
import { SwitchCheckIcon } from './switch-check-icon';
import { SwitchCloseIcon } from './switch-close-icon';

import './index.scss';

interface CustomSwitchProps {
    onChange: (checked: boolean) => void;
    checked: boolean;
}

const CustomSwitch: React.FC<CustomSwitchProps> = ({ onChange, checked }) => {
    return (
        <Switch
            onChange={onChange}
            checked={checked}
            checkedChildren={<SwitchCheckIcon />}
            unCheckedChildren={<SwitchCloseIcon />}
        />
    );
};

export default CustomSwitch;
