import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const TreeIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="51" height="50" viewBox="0 0 51 50" {...props} fill="none">
            <g clipPath="url(#clip0_7196_23675)">
                <path
                    d="M25.3516 45.3125V17.1875"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M25.3516 29.6875L15.9766 25"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M25.3516 25L34.7266 20.3125"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M25.3516 34.754C27.6537 36.6015 30.5376 37.5707 33.4883 37.4883C40.1153 37.3204 45.6817 31.586 45.6641 24.9571C45.657 22.4685 44.9073 20.0388 43.511 17.9789C42.1146 15.919 40.1352 14.3227 37.8262 13.3946C36.8918 10.8372 35.1939 8.62883 32.9626 7.06852C30.7313 5.50821 28.0743 4.67139 25.3516 4.67139C22.6289 4.67139 19.9719 5.50821 17.7406 7.06852C15.5093 8.62883 13.8114 10.8372 12.877 13.3946C10.5668 14.3232 8.58651 15.9207 7.19006 17.9821C5.79362 20.0435 5.04463 22.475 5.0391 24.9649C5.02153 31.5938 10.5899 37.3282 17.2168 37.4962C20.1679 37.576 23.0511 36.6041 25.3516 34.754Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_7196_23675">
                    <rect
                        width="50"
                        height="50"
                        fill={props.fill}
                        transform="translate(0.351562)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};
