import React, { useEffect } from 'react';
import './index.scss';
import Button from '../Button';
import { useStores } from '../../Hooks/use-stores';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from '../../Icons/close-icon';
import { theme } from '../../Style/theme';
import CookiesItems from './Components/cookies-items';
import { CookieInfo } from '../../Stores/CookiesStore';
import { useWindowDimensions } from '../../Hooks/use-window-dimensions';

const CookiesModalDetail: React.FC = () => {
    const { cookiesStore } = useStores();
    const { t } = useTranslation();
    const [cookies, setCookies] = React.useState<{
        necessaryCookies: CookieInfo[];
        performanceCookies: CookieInfo[];
    }>({
        necessaryCookies: [],
        performanceCookies: [],
    });
    const { windowWidth } = useWindowDimensions();

    const [consent, setConsent] = React.useState({
        necessary: true,
        performance: false,
    });

    const onPressAcceptAll = () => {
        cookiesStore.responseToCookies({
            necessary: true,
            performance: true,
        });
    };

    const onPressRejectAll = () => {
        cookiesStore.responseToCookies({
            necessary: false,
            performance: false,
        });
    };

    const onCloseDetail = () => {
        cookiesStore.closeCookiesDetailModal();
    };

    useEffect(() => {
        setCookies(cookiesStore.getAllCookies());
    }, [cookiesStore]);

    const onClickSaveChanges = () => {
        cookiesStore.responseToCookies({
            necessary: consent.necessary,
            performance: consent.performance,
        });
    };

    return (
        <motion.div
            className="CookiesModalDetail"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
        >
            <div className="content">
                <div className="top">
                    <div className="text-body-primary-font-bold">
                        {t('CookiesModal.cookie_preference')}
                    </div>
                    <CloseIcon onClick={onCloseDetail} fill={theme['black']} />
                </div>

                <div className="title text-body-primary-font-bold">
                    {t('CookiesModal.cookie_usage')}
                </div>
                <a
                    className="desc text-body"
                    href="https://spreeai.com/privacy-policy"
                    dangerouslySetInnerHTML={{
                        __html: t('CookiesModal.why_use_cookie'),
                    }}
                />

                <div className="wrapper-cookies-choose">
                    <CookiesItems
                        title={t('CookiesModal.cookie_title_necessary')}
                        state={consent.necessary}
                        onChange={() => {
                            setConsent((prev) => ({
                                ...prev,
                                necessary: !prev.necessary,
                            }));
                        }}
                        heightOpen={windowWidth > 768 ? 150 : 200}
                        options={cookies.necessaryCookies}
                    />
                    <CookiesItems
                        title={t('CookiesModal.cookie_title_performance')}
                        state={consent.performance}
                        onChange={() => {
                            setConsent((prev) => ({
                                ...prev,
                                performance: !prev.performance,
                            }));
                        }}
                        options={cookies.performanceCookies}
                        heightOpen={windowWidth > 768 ? 150 : 300}
                    />
                </div>

                <div className="wrapper-contact-us">
                    <div className="text-body-primary-font-bold">
                        {t('CookiesModal.more_information_cookie')}
                    </div>
                    <a
                        href="https://spreeai.com/contact-us"
                        className="text-body"
                        dangerouslySetInnerHTML={{
                            __html: t('CookiesModal.more_information_cookie_detail'),
                        }}
                    />
                </div>

                <div className="actions">
                    <div className="left">
                        <Button
                            type="primary"
                            text={t('CookiesModal.accepted_all')}
                            onClick={onPressAcceptAll}
                            fullInMobile={false}
                            className="accept-all-cta"
                        />
                        <Button
                            type="white-with-border"
                            text={t('CookiesModal.reject_all')}
                            onClick={onPressRejectAll}
                            fullInMobile={false}
                            className="reject-all-cta"
                        />
                    </div>

                    <div className="right">
                        <Button
                            type="white-with-border"
                            text={t('CookiesModal.save_changes')}
                            onClick={onClickSaveChanges}
                            fullInMobile={false}
                            className="reject-all-cta"
                        />
                    </div>
                </div>
            </div>
        </motion.div>
    );
};

export default CookiesModalDetail;
