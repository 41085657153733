import { useCallback, useEffect, useState } from 'react';
import Button from '../../../../../Components/Button';
import WrapperSectionTitle from '../../../../../Components/WrapperSection/title';
import Copydeck from '../../../../../i18n/Copydeck';
import ArticleCard from '../../../Components/ArticleCard';
import './index.scss';
import { ArticleDto } from '../../../../../Services/Blog';
import UseServices from '../../../../../Hooks/use-services';
import useCustomNavigation from '../../../../../Hooks/use-custom-navigation';
import CONSTANTS from '../../../../../Constants';
import { useWindowDimensions } from '../../../../../Hooks/use-window-dimensions';
import Carousel from '../../../../../Components/Carousel';

const NUMBER_OF_ARTICLES = 3;

const LatestUpdates = () => {
    const [articles, setArticles] = useState<ArticleDto[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const { blogService } = UseServices();
    const nav = useCustomNavigation();
    const { windowWidth } = useWindowDimensions();
    const FADING_CAROUSEL_BREAKPOINT = 900;

    const getTheLastestArticles = async () => {
        try {
            const response = await blogService.getMostRecentArticles(NUMBER_OF_ARTICLES);

            if (!response?.data?.[0]) {
                setArticles([]);
                return;
            }

            setArticles(response?.data);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoaded(true);
        }
    };

    useEffect(() => {
        getTheLastestArticles();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onClick = () => {
        nav(CONSTANTS.SCREEN.BLOG);
    };

    if (!isLoaded && articles.length <= 0) {
        return null;
    }

    if (articles.length === 0) {
        return null;
    }

    return (
        <div className="LatestUpdates">
            {windowWidth > FADING_CAROUSEL_BREAKPOINT ? (
                <div className="LatestUpdates__content">
                    <WrapperSectionTitle>
                        {Copydeck.blogDetailsLatestUpdatesTitle}
                    </WrapperSectionTitle>
                    <div className="LatestUpdates__articles">
                        {articles.map((article) => (
                            <ArticleCard article={article} key={article.id} />
                        ))}
                    </div>
                    <Button
                        text={Copydeck.blogDetailsReadOurBlogBtn}
                        type="black"
                        onClick={onClick}
                        fullInMobile={false}
                    />
                </div>
            ) : (
                <div className="mobile-carousel-container">
                    <WrapperSectionTitle>
                        {Copydeck.blogDetailsLatestUpdatesTitle}
                    </WrapperSectionTitle>

                    <Carousel
                        centerMode={false}
                        infiniteScroll={false}
                        slidesToShow={1}
                        carouselElements={
                            articles.length >= 3
                                ? [
                                      <ArticleCard
                                          article={articles?.[0]}
                                          key={articles?.[0]?.id}
                                      />,
                                      <ArticleCard
                                          article={articles?.[1]}
                                          key={articles?.[1]?.id}
                                      />,
                                      <ArticleCard
                                          article={articles?.[2]}
                                          key={articles?.[2]?.id}
                                      />,
                                  ]
                                : [<ArticleCard article={articles?.[0]} key={articles?.[0]?.id} />]
                        }
                    />

                    <Button
                        text={Copydeck.blogDetailsReadOurBlogBtn}
                        type="black"
                        onClick={onClick}
                        fullInMobile={false}
                    />
                </div>
            )}
        </div>
    );
};

export default LatestUpdates;
