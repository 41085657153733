import React from 'react';
import './index.scss';
import WrapperSectionTitle from '../../../../../Components/WrapperSection/title';
import FaqItem from '../../../../FAQ/Components/faq-item';
import { useTranslation } from 'react-i18next';

const FaqSection = () => {
    const { t } = useTranslation();

    const FAQ = [
        {
            question: 'FAQPage.faq_question1',
            answer: 'FAQPage.faq_answer1',
        },
        {
            question: 'FAQPage.faq_question2',
            answer: 'FAQPage.faq_answer2',
        },

        {
            question: 'FAQPage.faq_question3',
            answer: 'FAQPage.faq_answer3',
        },
        {
            question: 'FAQPage.faq_question4',
            answer: 'FAQPage.faq_answer4',
        },
        {
            question: 'FAQPage.faq_question5',
            answer: 'FAQPage.faq_answer5',
        },
        {
            question: 'FAQPage.faq_question6',
            answer: 'FAQPage.faq_answer6',
        },
        {
            question: 'FAQPage.faq_question7',
            answer: 'FAQPage.faq_answer7',
            hasCustomHTML: {
                answer: true,
            },
        },
    ];

    return (
        <div className="FaqSection">
            <div className="content">
                <div className="left">
                    <WrapperSectionTitle>{t('FAQPage.faq_title')}</WrapperSectionTitle>
                </div>
                <div className="right">
                    <div className="wrapper-faq">
                        {FAQ.map((faq, index) => (
                            <FaqItem type="small" key={index} {...faq} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FaqSection;
