import React from 'react';
import './pet-card.scss';

interface PetCardProps {
    src: string;
    owner: string;
}

const PetCard: React.FC<PetCardProps> = ({ src, owner }) => {
    return (
        <div className="PetCard">
            <img src={src} alt="pet" draggable={false} />
            <div className="text text-element-greys-secondary">{owner}</div>
        </div>
    );
};

export default PetCard;
