import classNames from 'classnames';
import { MotionConfig, motion, AnimatePresence } from 'framer-motion';
import { useCustomCarousel } from '../../../Components/CustomCarousel/hooks/useCustomCarousel';
import { Testimonial } from './Testimonials';

import styles from './styles/testimonial-item.module.scss';

const variants = {
    // @ts-ignore
    initial: (direction) => {
        return { x: `${25 * direction}%`, opacity: 0 };
    },
    active: { x: '0px', opacity: 1 },
    // @ts-ignore
    exit: (direction) => {
        return { x: `${-10 * direction}%`, opacity: 0 };
    },
};
interface TestimonialItemProps {
    testimonial: Testimonial;
    index: number;
}

export const TestimonialItem = ({ testimonial, index }: TestimonialItemProps) => {
    const { activeSlide, direction } = useCustomCarousel();

    const isActive = activeSlide === index;

    return (
        <MotionConfig transition={{ duration: 1, type: 'spring', bounce: 0 }}>
            <motion.div
                animate={{
                    opacity: isActive ? 1 : 0.8,
                    boxShadow: isActive ? '0px 4px 40px 0px rgba(43, 43, 43, 0.10)' : 'none',
                }}
                className={styles.testimonial}
            >
                <div className={styles.details}>
                    <div className={styles.quoteContainer}>
                        <div aria-hidden="true" className={classNames(styles.quote, styles.hidden)}>
                            <span>"</span>
                            <p aria-hidden="true">{testimonial.description}</p>
                        </div>
                        <AnimatePresence custom={direction} initial={false}>
                            {isActive && (
                                <motion.div
                                    variants={variants}
                                    initial="initial"
                                    animate="active"
                                    exit="exit"
                                    custom={direction}
                                    className={classNames(styles.quote, styles.absolute)}
                                >
                                    <span>
                                        <svg
                                            width="48"
                                            height="48"
                                            viewBox="0 0 33 23"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M10.6 23H0.4L10 0.299997H16.5L10.6 23ZM26.8 23H16.6L26.2 0.299997H32.7L26.8 23Z"
                                                fill="#2EB67D"
                                            />
                                        </svg>
                                    </span>
                                    <p>{testimonial.description}</p>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                    <AnimatePresence custom={direction} initial={false}>
                        {isActive && (
                            <motion.div
                                variants={variants}
                                initial="initial"
                                animate="active"
                                exit="exit"
                                custom={direction}
                                className={styles.footer}
                            >
                                <div className={styles.media}>
                                    <img
                                        src={testimonial.media}
                                        alt="Person smiling"
                                        loading="lazy"
                                    />
                                </div>
                                <div>
                                    <h3>{testimonial.name}</h3>
                                    <h4>{testimonial.position}</h4>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>
                </div>
            </motion.div>
        </MotionConfig>
    );
};
