import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const SMSIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="41" height="40" viewBox="0 0 41 40" {...props} fill="none">
            <g clipPath="url(#clip0_3597_20290)">
                <path
                    d="M20.5 21.875C21.5355 21.875 22.375 21.0355 22.375 20C22.375 18.9645 21.5355 18.125 20.5 18.125C19.4645 18.125 18.625 18.9645 18.625 20C18.625 21.0355 19.4645 21.875 20.5 21.875Z"
                    fill={props.fill}
                />
                <path
                    d="M13.625 21.875C14.6605 21.875 15.5 21.0355 15.5 20C15.5 18.9645 14.6605 18.125 13.625 18.125C12.5895 18.125 11.75 18.9645 11.75 20C11.75 21.0355 12.5895 21.875 13.625 21.875Z"
                    fill={props.fill}
                />
                <path
                    d="M27.375 21.875C28.4105 21.875 29.25 21.0355 29.25 20C29.25 18.9645 28.4105 18.125 27.375 18.125C26.3395 18.125 25.5 18.9645 25.5 20C25.5 21.0355 26.3395 21.875 27.375 21.875Z"
                    fill={props.fill}
                />
                <path
                    d="M12.9891 32.9859C16.1399 34.8095 19.8464 35.4249 23.4175 34.7175C26.9886 34.0101 30.1806 32.0282 32.3983 29.1411C34.616 26.2541 35.7081 22.6591 35.471 19.0263C35.2338 15.3936 33.6836 11.971 31.1094 9.39683C28.5352 6.82262 25.1127 5.27243 21.4799 5.03529C17.8472 4.79815 14.2521 5.89025 11.3651 8.10796C8.47809 10.3257 6.49612 13.5176 5.78872 17.0887C5.08132 20.6598 5.69678 24.3663 7.52035 27.5171L5.56567 33.3531C5.49222 33.5733 5.48156 33.8097 5.53488 34.0356C5.58821 34.2616 5.70341 34.4682 5.86758 34.6324C6.03175 34.7966 6.2384 34.9118 6.46436 34.9651C6.69032 35.0184 6.92667 35.0078 7.14692 34.9343L12.9891 32.9859Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3597_20290">
                    <rect width="40" height="40" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
