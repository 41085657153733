import Article from './Sections/Article';
import LatestUpdates from './Sections/LatestUpdates';
import './index.scss';

const BlogArticle = () => {
    return (
        <div className="BlogArticle">
            <Article />
            <LatestUpdates />
        </div>
    );
};

export default BlogArticle;
