import { useState } from 'react';

import WrapperSectionTitle from '../../../Components/WrapperSection/title';
import { JobListing } from '../../../Services/Careers';
import { JobListingItem } from './JobListingItem';
import { Filters } from './OpenPositions';

import sharedStyles from '../index.module.scss';
import styles from './styles/careers-list.module.scss';

// Create pages of specified length from job listings
const splitIntoPages = (arr: JobListing[], chunkSize: number) => {
    const chunks = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        chunks.push(arr.slice(i, i + chunkSize));
    }
    return chunks;
};

// Filter jobs based on search input
const filterJobs = (filters: Filters, jobs: JobListing[]) => {
    let result = [...jobs];

    result = [...result].filter((job) => {
        return job.title.toLowerCase().includes(filters.title.toLowerCase());
    });

    if (filters.department) {
        result = [...result].filter((job) => job.department === filters.department);
    }

    if (filters.region) {
        result = [...result].filter((job) => job.locations[0].region === filters.region);
    }

    if (filters.contractType) {
        result = [...result].filter((job) => job.employment_type === filters.contractType);
    }

    return result;
};

interface CareersListProps {
    filters: Filters;
    jobs: JobListing[];
}

export const CareersList = ({ filters, jobs }: CareersListProps) => {
    const filteredJobs = filterJobs(filters, jobs);
    const jobPages = splitIntoPages(filteredJobs, 4);

    if (jobPages.length === 0) {
        return (
            <div className={styles.emptyMessage}>
                <WrapperSectionTitle htmlTag="h3">
                    Sorry, No Results Matching Your Search
                </WrapperSectionTitle>
                <p className={sharedStyles.body1}>
                    Try removing the selected filters or refine your search.
                </p>
            </div>
        );
    }

    return <JobsList jobs={filteredJobs} />;
};

interface JobsListProps {
    jobs: JobListing[];
}

const JobsList = ({ jobs }: JobsListProps) => {
    const [page, setPage] = useState(1);

    const handleOnClick = () => {
        setPage((prev) => prev + 1);
    };

    const paginatedJobs = jobs.slice(0, page * 4);

    return (
        <div className={styles.jobsContainer}>
            <ul className={styles.jobList}>
                {paginatedJobs.map((job) => {
                    return (
                        <li key={job.shortcode}>
                            <JobListingItem job={job} />
                        </li>
                    );
                })}
            </ul>
            {paginatedJobs.length < jobs.length && jobs.length > 4 ? (
                <button onClick={handleOnClick} className={styles.buttonOutlined}>
                    Load More
                </button>
            ) : null}
        </div>
    );
};
