import React from 'react';

export const MenuBurgerIcon = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{ cursor: 'pointer' }}
        >
            <rect
                x="5.14285"
                y="8.75439"
                width="21.7143"
                height="2.11111"
                rx="1.05556"
                fill="#2B2B2B"
            />
            <rect
                x="5.14285"
                y="14.9368"
                width="21.7143"
                height="2.11111"
                rx="1.05556"
                fill="#2B2B2B"
            />
            <rect
                x="5.14285"
                y="21.1196"
                width="21.7143"
                height="2.11111"
                rx="1.05556"
                fill="#2B2B2B"
            />
        </svg>
    );
};
