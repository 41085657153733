import React, { FunctionComponent, ReactNode, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import './index.scss';
import { ChevronRightIcon } from '../../Icons/chevron-right-icon';
import { ChevronLeftIcon } from '../../Icons/chevron-left-icon';
import { theme } from '../../Style/theme';

interface CarouselTwoProps {
    carouselElements: ReactNode[];
    /**Must set a max-width on your carouselElements. Provide the same value here */
    elementMaxWidth: number;
    /**This is responsible for the offset of next slides visibility */
    partialVisibilityGutter: number;
}

// https://www.npmjs.com/package/react-multi-carousel

//recommended to a minimum of 1200PX window width. After that use the other carousel
const FadingCarousel: FunctionComponent<CarouselTwoProps> = ({
    carouselElements,
    elementMaxWidth,
    partialVisibilityGutter,
}) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const ref = React.useRef<Carousel>(null);

    const ButtonGroup = ({ next, previous, ...rest }: any) => {
        const {
            carouselState: { currentSlide, totalItems },
        } = rest;
        const leftDisabled = currentSlide === 0;
        const rightDisabled = currentSlide === totalItems - 1;

        return (
            <div className="carousel-button-group">
                <button
                    className={`custom-arrows left d-flex-align ${leftDisabled ? 'disabled' : ''}`}
                    style={{ left: -70 }}
                    onClick={() => {
                        previous();
                    }}
                >
                    <ChevronLeftIcon
                        fill={leftDisabled ? theme['surface-grey-tertiary'] : theme.white}
                    />
                </button>
                <button
                    className={`custom-arrows right d-flex-align ${
                        rightDisabled ? 'disabled' : ''
                    }`}
                    style={{ left: elementMaxWidth + 25 }}
                    onClick={() => {
                        next();
                    }}
                >
                    <ChevronRightIcon
                        fill={rightDisabled ? theme['surface-grey-tertiary'] : theme.white}
                    />
                </button>
            </div>
        );
    };

    return (
        <div className="FadingCarousel">
            <div className="offset" style={{ paddingLeft: `calc(50% - ${elementMaxWidth / 2}px)` }}>
                <Carousel
                    className="fading-carousel"
                    arrows={false}
                    draggable={false}
                    focusOnSelect={false}
                    partialVisible
                    renderArrowsWhenDisabled
                    showDots
                    renderDotsOutside
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 8000,
                                min: 0,
                            },
                            items: 1,
                            partialVisibilityGutter: partialVisibilityGutter,
                        },
                    }}
                    slidesToSlide={1}
                    itemClass="carouselItem"
                    renderButtonGroupOutside
                    customButtonGroup={<ButtonGroup />}
                    beforeChange={(nextSlide: number) => setCurrentSlide(nextSlide)}
                    ref={ref}
                >
                    {carouselElements.map((x, i, arr) => (
                        <div
                            key={i}
                            //the last slide does not go to center because of partialVisibilityGutter. Offset last slide when active
                            style={{
                                transition: 'all 500ms ease',
                                marginLeft:
                                    i === arr.length - 1 && currentSlide === i
                                        ? -partialVisibilityGutter
                                        : 0,
                            }}
                        >
                            {x}
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export default FadingCarousel;
