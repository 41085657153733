import React, { useMemo } from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import AdvantageCard from './AdvantageCard';
import { theme } from '../../../../Style/theme';
import CharLineUpIcon from '../../../../Icons/char-linup-icon';
import TrendUpIcon from '../../../../Icons/trend-up-icon';
import ShootingStarIcon from '../../../../Icons/shooting-star-icon';
import SmileyIcon from '../../../../Icons/smiley-icon';
import { useWindowDimensions } from '../../../../Hooks/use-window-dimensions';
import WrapperSection from '../../../../Components/WrapperSection';

const Advantages = () => {
    const { t } = useTranslation();
    const { windowWidth } = useWindowDimensions();
    const SMALLER_ICONS_BREAKPOINT = 780;

    const ICON_SIZE_SMALL = 32;
    const ICON_SIZE_LARGE = 48;

    const iconSize = useMemo(
        (): number => (windowWidth >= SMALLER_ICONS_BREAKPOINT ? ICON_SIZE_LARGE : ICON_SIZE_SMALL),
        [windowWidth]
    );

    return (
        <div className="Advantages">
            <WrapperSection.Title htmlTag="h1">{t('advantages_for_brands')}</WrapperSection.Title>
            <div className="cards-container">
                <div className="two-card">
                    <AdvantageCard
                        icon={
                            <CharLineUpIcon
                                width={iconSize}
                                height={iconSize}
                                fill={theme['black-secondary-alt']}
                            />
                        }
                        title={t('advantage_1_title')}
                        paragraph={t('advantage_1_paragraph')}
                    />
                    <AdvantageCard
                        icon={
                            <TrendUpIcon
                                width={iconSize}
                                height={iconSize}
                                fill={theme['black-secondary-alt']}
                            />
                        }
                        title={t('advantage_2_title')}
                        paragraph={t('advantage_2_paragraph')}
                    />
                </div>

                <div className="two-card">
                    <AdvantageCard
                        icon={
                            <ShootingStarIcon
                                fill={theme['black-secondary-alt']}
                                width={iconSize}
                                height={iconSize}
                            />
                        }
                        title={t('advantage_3_title')}
                        paragraph={t('advantage_3_paragraph')}
                    />
                    <AdvantageCard
                        icon={
                            <SmileyIcon
                                width={iconSize}
                                height={iconSize}
                                fill={theme['black-secondary-alt']}
                            />
                        }
                        title={t('advantage_4_title')}
                        paragraph={t('advantage_4_paragraph')}
                    />
                </div>
            </div>
        </div>
    );
};

export default Advantages;
