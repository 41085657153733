import React from 'react';
import './button-partner-access-dashboard.scss';
import { ChevronRightIcon } from '../../../../Icons/chevron-right-icon';
import { theme } from '../../../../Style/theme';
import { Link } from '../../../../Components/Link';

interface ButtonPartnerAccessDashboardProps {
    href: string;
    icon: React.ReactNode;
    label: string;
    onClose?: () => void;
}

const ButtonPartnerAccessDashboard: React.FC<ButtonPartnerAccessDashboardProps> = ({
    href,
    icon,
    label,
    onClose,
}) => {
    const handleClick = () => {
        if (onClose) {
            onClose();
        }
    };

    return (
        <Link
            pathConfig={{ where: href }}
            className="ButtonPartnerAccessDashboard"
            onClick={handleClick}
        >
            <div className="button">
                <div className="button-icon">{icon}</div>
                <div className="button-label text-body-small-primary-font-bold text-white">
                    {label}
                </div>
                <ChevronRightIcon fill={theme['white']} width={16} height={16} />
            </div>
        </Link>
    );
};

export default ButtonPartnerAccessDashboard;
