import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const ThreeSubIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" {...props} fill="none">
            <g clipPath="url(#clip0_7196_23584)">
                <path
                    d="M10.9375 20.3125H4.6875C3.82456 20.3125 3.125 21.0121 3.125 21.875V28.125C3.125 28.9879 3.82456 29.6875 4.6875 29.6875H10.9375C11.8004 29.6875 12.5 28.9879 12.5 28.125V21.875C12.5 21.0121 11.8004 20.3125 10.9375 20.3125Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M40.625 7.8125H31.25C30.3871 7.8125 29.6875 8.51206 29.6875 9.375V18.75C29.6875 19.6129 30.3871 20.3125 31.25 20.3125H40.625C41.4879 20.3125 42.1875 19.6129 42.1875 18.75V9.375C42.1875 8.51206 41.4879 7.8125 40.625 7.8125Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M40.625 29.6875H31.25C30.3871 29.6875 29.6875 30.3871 29.6875 31.25V40.625C29.6875 41.4879 30.3871 42.1875 31.25 42.1875H40.625C41.4879 42.1875 42.1875 41.4879 42.1875 40.625V31.25C42.1875 30.3871 41.4879 29.6875 40.625 29.6875Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.5 25H21.875"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M29.6875 35.9375H25C24.1712 35.9375 23.3763 35.6083 22.7903 35.0222C22.2042 34.4362 21.875 33.6413 21.875 32.8125V17.1875C21.875 16.3587 22.2042 15.5638 22.7903 14.9778C23.3763 14.3917 24.1712 14.0625 25 14.0625H29.6875"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_7196_23584">
                    <rect width="50" height="50" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
