import React, { FunctionComponent, ReactNode } from 'react';
import WhiteCard from '../../../../../Components/WhiteCard';
import { useWindowDimensions } from '../../../../../Hooks/use-window-dimensions';
import './index.scss';

interface AdvantageCardProps {
    title: string;
    paragraph: string;
    icon: ReactNode;
}
const AdvantageCard: FunctionComponent<AdvantageCardProps> = ({ title, paragraph, icon }) => {
    const { windowWidth } = useWindowDimensions();
    const SMALLER_PADDING_BREAKPOINT = 780;

    const SMALL_PADDING = 20;
    const LARGE_PADDING = 40;

    return (
        <div className="AdvantageCard">
            <WhiteCard
                padding={windowWidth >= SMALLER_PADDING_BREAKPOINT ? LARGE_PADDING : SMALL_PADDING}
                className="advantage-card"
            >
                <div className="icon">{icon}</div>
                <div className="texts">
                    <h3 className="text-body-primary-font-bold title text-surface-grey-opposite">
                        {title}
                    </h3>
                    <p className="text-body text-element-greys-secondary-alt body">{paragraph}</p>
                </div>
            </WhiteCard>
        </div>
    );
};

export default AdvantageCard;
