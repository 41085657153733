//to enable color referencing in components

export const theme = {
    // ------SURFACES -----------
    'surface-brand-rest-primary': '#2eb67d',
    'surface-brand-rest-secondary': '#d5f0e5',
    'surface-brand-rest-tertiary': '#175b3f',

    'surface-brand-overlay-primary': '#58c597',
    'surface-brand-overlay-secondary': '#abe2cb',
    'surface-brand-overlay-tertiary': '#0c2e1f',

    'surface-support-primary': '#fcab01',
    'surface-support-secondary': '#9f2589',
    'surface-support-tertiary': '#00bcb4',

    'surface-grey-opposite': '#1b1d1e',
    'surface-grey-primary': '#8a9192',
    'surface-grey-secondary': '#a7acad',
    'surface-grey-tertiary': '#c5c8c9',
    'surface-grey-disabled': '#e5e5e6',

    'surface-success-rest-primary': '#37be6e',
    'surface-success-rest-secondary': '#afe5c5',
    'surface-success-overlay-primary': '#5fcb8b',
    'surface-success-overlay-secondary': '#d7f2e2',

    'surface-warning-rest-primary': '#f2da52',
    'surface-warning-rest-secondary': '#faf0ba',
    'surface-warning-overlay-primary': '#f5e175',
    'surface-warning-overlay-secondary': '#fcf8dc',

    'surface-error-rest-primary': '#d7382d',
    'surface-error-rest-secondary': '#efafab',
    'surface-error-overlay-primary': '#df6057',
    'surface-error-overlay-secondary': '#f7d7d5',
    // ------SURFACES -----------

    // ------ELEMENTS -----------
    'element-brand-rest-primary': '#2eb67d',
    'element-brand-rest-secondary': '#d5f0e5',
    'element-brand-rest-tertiary': '#d5f0e5',

    'element-brand-overlay-primary': '#58c597',
    'element-brand-overlay-secondary': '#abe2cb',
    'element-brand-overlay-tertiary': '#0c2e1f',

    'element-support-primary': '#fcab01',
    'element-support-secondary': '#9f2589',
    'element-support-tertiary': '#00bcb4',
    // ------ELEMENTS -----------

    // ------FONT COLORS -----------
    'element-greys-primary': '#1b1d1e',
    'element-greys-secondary': '#6d7577',
    'element-greys-tertiary': '#8a9192',
    'element-greys-white': '#ffffff',
    'element-greys-opposite': '#ffffff',

    'element-onbrand-primary': '#ebf8f3',
    'element-onbrand-secondary': '#23895e',

    'element-onbrandaccent-primary': '#dad0fb',
    'element-onbrandaccent-secondary': '#8966f1',

    'element-onsuccess-primary': '#ecf9f1',
    'element-onsuccess-secondary': '#1c5f37',

    'element-onwarning-primary': '#796d29',
    'element-onwarning-secondary': '#796d29',

    'element-onerror-primary': '#fceceb',
    'element-onerror-secondary': '#d7382d',

    linkedin: '#007EBB',

    // ------FONT COLORS -----------
    white: '#ffffff',
    black: '#000000',

    'black-secondary-alt': '#2b2b2b',

    // ------GRADIENTS -----------
    'linear-gradient1': '#399DA9',
    'linear-gradient2': '#34A797',
    'linear-gradient3': '#2FB481',
    'linear-gradient4': '#2EB67D',
};

export const breakpoints = {
    s: '320px',
    m: '375px',
    l: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    '4k': '2560px',
};
