import React from 'react';
import { SvgProps } from './SvgProps';

const DressIcon: React.FunctionComponent<SvgProps> = (props) => {
    return (
        <svg width="41" height="40" viewBox="0 0 41 40" {...props} fill="none">
            <g clipPath="url(#clip0_3597_20252)">
                <path
                    d="M25.5 5.50313V1.25"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.5 1.25V5.50313"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M31.7501 35C31.9568 35 32.1604 34.9486 32.3424 34.8505C32.5244 34.7524 32.6793 34.6107 32.7931 34.438C32.9069 34.2654 32.9761 34.0672 32.9945 33.8612C33.0129 33.6553 32.9799 33.4479 32.8985 33.2579L25.5001 17.5L29.0719 11.8938C29.1887 11.6994 29.2504 11.4769 29.2504 11.25C29.2504 11.0232 29.1887 10.8007 29.0719 10.6063L25.5001 5.50317L24.4063 6.87505C23.9379 7.46127 23.3436 7.93456 22.6674 8.25981C21.9912 8.58507 21.2504 8.75396 20.5001 8.75396C19.7497 8.75396 19.0089 8.58507 18.3327 8.25981C17.6565 7.93456 17.0622 7.46127 16.5938 6.87505L15.5001 5.50474L11.9282 10.6063C11.8114 10.8007 11.7497 11.0232 11.7497 11.25C11.7497 11.4769 11.8114 11.6994 11.9282 11.8938L15.5001 17.5L8.10318 33.2579C8.02181 33.4478 7.9888 33.655 8.00712 33.8608C8.02543 34.0667 8.09449 34.2648 8.20812 34.4374C8.32175 34.61 8.4764 34.7517 8.65823 34.8499C8.84006 34.9481 9.0434 34.9997 9.25005 35H31.7501Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15.5 17.5H25.5"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3597_20252">
                    <rect width="40" height="40" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default DressIcon;
