import React, { useEffect } from 'react';

import LayoutHeader from './Components/Header/layout-header';
import LayoutFooter from './Components/layout-footer';
import { Helmet } from 'react-helmet-async';
import './default-layout.scss';
import { useLocation } from 'react-router-dom';
import CreateAccountHeader from './Components/create-account-layout/create-account-header';
import CreateAccountFooter from './Components/create-account-layout/create-account-footer';

export interface OptionSEO {
    title: string;
    description: string;
    type: string;
    name: string;
}

interface DefaultLayoutProps {
    children: React.ReactNode;
    options?: OptionSEO;
    usingCreateAccountLayout?: boolean;
    defaultFooter?: boolean;
}

const DefaultLayout: React.FC<DefaultLayoutProps> = ({
    children,
    options,
    usingCreateAccountLayout,
    defaultFooter = false,
}) => {
    const { pathname } = useLocation();
    const [forceUseDefaultFooter, setForceUseDefaultFooter] = React.useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    //Créer une fonction, qui va trouver une balise avec la class "CreateAccountFlow", et regarder si celle là a aussi la class "-step-3", si elle la elle fais un console.log
    const findStep3 = () => {
        const createAccountFlow = document.querySelector('.CreateAccountFlow');
        if (createAccountFlow?.classList.contains('-step-3')) {
            setForceUseDefaultFooter(true);
        } else {
            setForceUseDefaultFooter(false);
        }
    };

    useEffect(() => {
        findStep3();

        if (usingCreateAccountLayout) {
            const interval = setInterval(() => {
                findStep3();
            }, 200);

            return () => {
                clearInterval(interval);
            };
        }
    }, [pathname, usingCreateAccountLayout]);

    return (
        <div className="DefaultLayout">
            {options && (
                <Helmet>
                    {/* Standard metadata tags */}
                    <title>{options.title}</title>
                    <meta name="description" content={options.description} />
                    {/* End standard metadata tags */}

                    {/* Facebook tags */}
                    <meta property="og:type" content={options.type} />
                    <meta property="og:title" content={options.title} />
                    <meta property="og:description" content={options.description} />
                    {/* End Facebook tags */}

                    {/* Twitter tags */}
                    <meta name="twitter:creator" content={options.name} />
                    <meta name="twitter:card" content={options.type} />
                    <meta name="twitter:title" content={options.title} />
                    <meta name="twitter:description" content={options.description} />
                    {/* End Twitter tags */}
                </Helmet>
            )}

            {usingCreateAccountLayout ? <CreateAccountHeader /> : <LayoutHeader />}

            <div className={`content ${usingCreateAccountLayout ? 'create-account-layout' : ''}`}>
                {children}
            </div>

            {usingCreateAccountLayout && !forceUseDefaultFooter && !defaultFooter ? (
                <CreateAccountFooter />
            ) : (
                <LayoutFooter />
            )}
        </div>
    );
};

export default DefaultLayout;
