import React from 'react';
import './index.scss';
import Button from '../Button';
import { useStores } from '../../Hooks/use-stores';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { CookiesIcon } from '../../Icons/cookies-icon';
import { theme } from '../../Style/theme';

const CookiesModal: React.FC = () => {
    const { cookiesStore, languageStore } = useStores();
    const { t } = useTranslation();

    const onPressAcceptAll = () => {
        cookiesStore.responseToCookies({
            necessary: true,
            performance: true,
        });
    };

    const onPressRejectAll = () => {
        cookiesStore.responseToCookies({
            necessary: false,
            performance: false,
        });
    };

    const onClickDetail = () => {
        cookiesStore.openCookiesDetailModal();
    };

    return (
        <motion.div
            className={`CookiesModal -lng-${languageStore.currentLanguage}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
        >
            <div className="content">
                <div className="title text-body-primary-font-bold">
                    <CookiesIcon fill={theme['black']} />
                    {t('CookiesModal.we_use_cookies')}
                </div>
                <div
                    className="btn-delete-default-style desc text-body"
                    onClick={onClickDetail}
                    dangerouslySetInnerHTML={{ __html: t('CookiesModal.cookies_why_we_use') }}
                />

                <div className="actions">
                    <Button
                        type="white-with-border"
                        text={t('CookiesModal.reject_all')}
                        onClick={onPressRejectAll}
                        fullInMobile={false}
                        className="reject-all-cta"
                    />
                    <Button
                        type="primary"
                        className="accept-all-cta"
                        text={t('CookiesModal.accepted_all')}
                        onClick={onPressAcceptAll}
                        fullInMobile={false}
                    />
                </div>
            </div>
        </motion.div>
    );
};

export default CookiesModal;
