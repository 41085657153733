import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const BrainIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" {...props} fill="none">
            <g clipPath="url(#clip0_9137_7446)">
                <path
                    d="M13.75 21.25C14.9861 21.25 16.1945 21.6166 17.2223 22.3033C18.2501 22.9901 19.0512 23.9662 19.5242 25.1082C19.9973 26.2503 20.1211 27.5069 19.8799 28.7193C19.6388 29.9317 19.0435 31.0453 18.1694 31.9194C17.2953 32.7935 16.1817 33.3888 14.9693 33.6299C13.7569 33.8711 12.5003 33.7473 11.3582 33.2742C10.2162 32.8012 9.24007 32.0001 8.55331 30.9723C7.86656 29.9445 7.5 28.7361 7.5 27.5V26.4484"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M26.25 21.25C25.0139 21.25 23.8055 21.6166 22.7777 22.3033C21.7499 22.9901 20.9488 23.9662 20.4758 25.1082C20.0027 26.2503 19.8789 27.5069 20.1201 28.7193C20.3613 29.9317 20.9565 31.0453 21.8306 31.9194C22.7047 32.7935 23.8183 33.3888 25.0307 33.6299C26.2431 33.8711 27.4997 33.7473 28.6418 33.2742C29.7838 32.8012 30.7599 32.0001 31.4467 30.9723C32.1334 29.9445 32.5 28.7361 32.5 27.5V26.4484"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.2499 26.875H9.9999C8.22949 26.8766 6.5156 26.2518 5.16152 25.1113C3.80744 23.9708 2.90049 22.388 2.60116 20.6431C2.30182 18.8981 2.62941 17.1036 3.52596 15.577C4.4225 14.0504 5.83018 12.8901 7.4999 12.3016V11.25C7.4999 9.5924 8.15838 8.00269 9.33048 6.83058C10.5026 5.65848 12.0923 5 13.7499 5C15.4075 5 16.9972 5.65848 18.1693 6.83058C19.3414 8.00269 19.9999 9.5924 19.9999 11.25V27.5"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M28.75 26.875H30C31.7704 26.8766 33.4843 26.2518 34.8384 25.1113C36.1925 23.9708 37.0994 22.388 37.3987 20.6431C37.6981 18.8981 37.3705 17.1036 36.4739 15.577C35.5774 14.0504 34.1697 12.8901 32.5 12.3016V11.25C32.5 9.5924 31.8415 8.00269 30.6694 6.83058C29.4973 5.65848 27.9076 5 26.25 5C24.5924 5 23.0027 5.65848 21.8306 6.83058C20.6585 8.00269 20 9.5924 20 11.25"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M31.25 17.5H30.625C29.4647 17.5 28.3519 17.0391 27.5314 16.2186C26.7109 15.3981 26.25 14.2853 26.25 13.125V12.5"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.75 17.5H9.375C10.5353 17.5 11.6481 17.0391 12.4686 16.2186C13.2891 15.3981 13.75 14.2853 13.75 13.125V12.5"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_9137_7446">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
