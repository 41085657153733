import sharedStyles from '../index.module.scss';
import styles from './styles/new-positions.module.scss';
import { JobListingItem } from '../../Jobs/components/JobListingItem';
import { useGetJobListings } from '../../Jobs/hooks/use-get-job-listings';
import { Link } from '../../../Components/Link';
import Button from '../../../Components/Button';
import CONSTANTS from '../../../Constants';

export const NewPositions = () => {
    const { jobs } = useGetJobListings();

    const recentJobs = jobs
        .sort((a, b) => {
            return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
        })
        .slice(0, 3);

    return (
        <section className={styles.sectionWrapper}>
            <div className={styles.sectionContainer}>
                <h2 className={sharedStyles.heading2}>Recent Positions</h2>
                <div className={styles.divider} />
                <ul className={styles.jobList}>
                    {recentJobs.map((job) => {
                        return (
                            <li key={job.shortcode}>
                                <JobListingItem job={job} />
                            </li>
                        );
                    })}
                </ul>
                <Link pathConfig={{ where: CONSTANTS.SCREEN.JOBS }}>
                    <Button type="secondary" text="See All Open Positions" />
                </Link>
            </div>
        </section>
    );
};
