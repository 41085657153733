import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const BriefCaseIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            {...props}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_9885_17384)">
                <path
                    d="M33.75 10H6.25C5.55964 10 5 10.5596 5 11.25V31.25C5 31.9404 5.55964 32.5 6.25 32.5H33.75C34.4404 32.5 35 31.9404 35 31.25V11.25C35 10.5596 34.4404 10 33.75 10Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M26.25 10V7.5C26.25 6.83696 25.9866 6.20107 25.5178 5.73223C25.0489 5.26339 24.413 5 23.75 5H16.25C15.587 5 14.9511 5.26339 14.4822 5.73223C14.0134 6.20107 13.75 6.83696 13.75 7.5V10"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M35 18.4863C30.4415 21.1238 25.2665 22.5087 20 22.5004C14.7337 22.5088 9.55878 21.1245 5 18.4879"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M17.5 17.5H22.5"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_9885_17384">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
