import React from 'react';

interface UseHandleUrlAnchorProps {
    stopAutoScroll?: boolean;
}

const useHandleUrlAnchor = ({ stopAutoScroll = false }: UseHandleUrlAnchorProps = {}) => {
    React.useEffect(() => {
        if (!stopAutoScroll) {
            const anchor = window.location.href.split('#')[1];

            if (anchor) {
                const splitAnchor = anchor.split(':');

                const anchorEl = document.getElementById(splitAnchor?.[0]);
                if (anchorEl) {
                    setTimeout(() => {
                        const getPosY = anchorEl.getBoundingClientRect().top;

                        //scroll to the element
                        window.scrollTo({
                            top: getPosY - 130,
                            behavior: 'smooth',
                        });
                    }, 500);
                }
            }
        }
    }, [window.location.pathname]);

    const scrollToHash = (hash: string) => {
        window.history.pushState(null, '', hash);

        const element = document.getElementById(hash.replace('#', ''));

        if (element) {
            setTimeout(() => {
                const getPosY = element.getBoundingClientRect().top;

                //scroll to the element
                window.scrollTo({
                    top: getPosY - 130,
                    behavior: 'smooth',
                });
            }, 500);
        }
    };

    return { scrollToHash };
};

export default useHandleUrlAnchor;
