import React from 'react';
import FormContext from '../context';
import { useTranslation } from 'react-i18next';

export interface ValidationProps {
    required?: boolean;
    minLength?: number;
    isEmail?: boolean;
    passwordConfirmMatch?: { passwordField: string };
    inputName: string;
    acceptedFileTypes?: string[];
    isUrl?: boolean;
}

export enum ERROR_TYPE {
    REQUIRED = 'required',
    MIN_LENGTH = 'minLength',
    IS_EMAIL = 'isEmail',
    PASSWORDS_DONT_MATCH = 'passwordMatch',
    ACCEPTED_FILE_TYPES = 'acceptedFileTypes',
    IS_URL = 'isUrl',
}

export interface ERROR {
    type: ERROR_TYPE;
    extraInformations: string;
}

const FormProvider: React.FC<{ children: React.ReactNode; validation?: ValidationProps[] }> = ({
    children,
    validation,
}) => {
    const { t } = useTranslation();
    const [values, setValues] = React.useState<{
        [key: string]: string | File;
    }>({});
    const [errors, setErrors] = React.useState<{
        [key: string]: ERROR[];
    }>({});

    const addError = (inputName: string, error: ERROR) => {
        setErrors((prev) => {
            return {
                ...prev,
                [inputName]: [error],
            };
        });
    };

    const isValidUrl = (url: string) => {
        const urlRegex = /^(http|https):\/\/[^ "]+$/;

        return urlRegex.test(url);
    };

    const removeError = (inputName: string, error: ERROR) => {
        setErrors((prev) => {
            return {
                ...prev,
                [inputName]: prev?.inputName?.filter((e: ERROR) => e.type !== error.type),
            };
        });
    };

    const getErrors = (inputName: string): ERROR[] => {
        return errors[inputName];
    };

    const isValidEmail = (email: string) => {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        return emailRegex.test(email);
    };

    const validateFileType = (fileType: string, acceptedFileTypes: string[]) => {
        const allImagesAllowed = acceptedFileTypes.includes('image/*');

        if (!allImagesAllowed) {
            return acceptedFileTypes.includes(fileType);
        }

        return acceptedFileTypes.some((type) => {
            if (type.startsWith('image/') && fileType.startsWith('image/')) {
                return true;
            }
            return fileType.includes(type);
        });
    };

    const onSubmit = () => {
        let hasError = false;

        if (validation) {
            validation.forEach((v) => {
                if (
                    v?.required === false &&
                    (!values?.[v.inputName] || values?.[v.inputName] === '')
                ) {
                    return;
                }

                if (
                    v?.acceptedFileTypes &&
                    !validateFileType((values as any)?.[v.inputName]?.type, v.acceptedFileTypes)
                ) {
                    addError(v.inputName, {
                        type: ERROR_TYPE.ACCEPTED_FILE_TYPES,
                        extraInformations: t('Errors.e_accepted_file_types'),
                    });
                    hasError = true;
                    return;
                } else {
                    removeError(v.inputName, {
                        type: ERROR_TYPE.ACCEPTED_FILE_TYPES,
                        extraInformations: '',
                    });
                }
                if (v?.required === true && !values?.[v.inputName]) {
                    addError(v.inputName, {
                        type: ERROR_TYPE.REQUIRED,
                        extraInformations: t('Errors.e_required'),
                    });
                    hasError = true;
                    return;
                } else {
                    removeError(v.inputName, {
                        type: ERROR_TYPE.REQUIRED,
                        extraInformations: '',
                    });
                }
                if (v.minLength && (values as any)[v.inputName]?.length < v.minLength) {
                    addError(v.inputName, {
                        type: ERROR_TYPE.MIN_LENGTH,
                        extraInformations: t('Errors.e_min_char', { param1: v.minLength }),
                    });
                    hasError = true;
                    return;
                } else {
                    removeError(v.inputName, {
                        type: ERROR_TYPE.MIN_LENGTH,
                        extraInformations: ``,
                    });
                }

                if (v?.isEmail && !isValidEmail((values as any)?.[v?.inputName])) {
                    addError(v.inputName, {
                        type: ERROR_TYPE.IS_EMAIL,
                        extraInformations: t('Errors.e_email'),
                    });
                    hasError = true;
                    return;
                } else {
                    removeError(v.inputName, {
                        type: ERROR_TYPE.IS_EMAIL,
                        extraInformations: '',
                    });
                }

                if (v?.isUrl && !isValidUrl((values as any)?.[v?.inputName])) {
                    addError(v.inputName, {
                        type: ERROR_TYPE.IS_URL,
                        extraInformations: t('Errors.e_url'),
                    });
                    hasError = true;
                    return;
                } else {
                    removeError(v.inputName, {
                        type: ERROR_TYPE.IS_URL,
                        extraInformations: '',
                    });
                }

                if (
                    v?.passwordConfirmMatch &&
                    values?.[v.inputName] !== values?.[v?.passwordConfirmMatch.passwordField]
                ) {
                    addError(v.inputName, {
                        type: ERROR_TYPE.PASSWORDS_DONT_MATCH,
                        extraInformations: t('Errors.e_password'),
                    });
                    hasError = true;
                    return;
                } else {
                    removeError(v.inputName, {
                        type: ERROR_TYPE.PASSWORDS_DONT_MATCH,
                        extraInformations: '',
                    });
                }
            });
        }

        if (hasError) {
            return null;
        }

        return values;
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues((prev) => ({
            ...prev,
            [e.target.name]: e.target.value,
        }));
    };

    const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e?.target?.files?.[0]) {
            setValues((prev) => ({
                ...prev,
                [e.target.name]: e?.target?.files?.[0] || '',
            }));
        }
    };

    const getValue = (name: string) => {
        return values[name];
    };

    const clearValues = () => {
        setValues((prev) => {
            return Object.keys(prev).reduce((acc: any, key) => {
                acc[key] = '';
                return acc;
            }, {});
        });
    };

    return (
        <FormContext.Provider
            value={{
                onSubmit,
                onChange,
                getValue,
                getErrors,
                clearValues,
                onChangeFile,
            }}
        >
            {children}
        </FormContext.Provider>
    );
};

export default FormProvider;
