import React from 'react';
import { useWindowDimensions } from '../../Hooks/use-window-dimensions';
import WrapperSectionTitle, { WrapperSectionTitleProps } from './title';

type WrapperSectionProps = React.FunctionComponent<{
    padding?: string;
    paddingHorizontal?: string;
    paddingInMobile?: string;
    children: React.ReactNode;
    className?: string;
}> & {
    Title: React.FC<WrapperSectionTitleProps>;
};

const WrapperSection: WrapperSectionProps = ({
    padding,
    children,
    className,
    paddingInMobile,
    paddingHorizontal,
}) => {
    const isMobile = useWindowDimensions().windowWidth <= 1000;

    return (
        <div
            className={`WrapperSection ${className || ''}`}
            style={{
                padding: `${isMobile ? paddingInMobile || 0 : padding}px ${
                    isMobile ? '16px' : `${paddingHorizontal}px`
                }`,
            }}
        >
            {children}
        </div>
    );
};

WrapperSection.Title = WrapperSectionTitle;

export default WrapperSection;
