import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const ScaleIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" {...props} fill="none">
            <g clipPath="url(#clip0_7196_23627)">
                <path
                    d="M25 7.8125V42.1875"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.3125 42.1875H29.6875"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M10.9375 17.1875L39.0625 10.9375"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M4.6875 32.8125C4.6875 36.2637 8.59375 37.5 10.9375 37.5C13.2812 37.5 17.1875 36.2637 17.1875 32.8125L10.9375 17.1875L4.6875 32.8125Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M32.8125 26.5625C32.8125 30.0137 36.7188 31.25 39.0625 31.25C41.4062 31.25 45.3125 30.0137 45.3125 26.5625L39.0625 10.9375L32.8125 26.5625Z"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_7196_23627">
                    <rect width="50" height="50" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
