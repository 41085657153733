export const VideoConferenceIcon = () => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_10980_6389)">
                <path
                    d="M27 6H5C4.44772 6 4 6.44772 4 7V25C4 25.5523 4.44772 26 5 26H27C27.5523 26 28 25.5523 28 25V7C28 6.44772 27.5523 6 27 6Z"
                    stroke="#2B2B2B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20 6V26"
                    stroke="#2B2B2B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20 16H28"
                    stroke="#2B2B2B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M24 12.25C24.6904 12.25 25.25 11.6904 25.25 11C25.25 10.3096 24.6904 9.75 24 9.75C23.3096 9.75 22.75 10.3096 22.75 11C22.75 11.6904 23.3096 12.25 24 12.25Z"
                    fill="#2B2B2B"
                />
                <path
                    d="M24 22.25C24.6904 22.25 25.25 21.6904 25.25 21C25.25 20.3096 24.6904 19.75 24 19.75C23.3096 19.75 22.75 20.3096 22.75 21C22.75 21.6904 23.3096 22.25 24 22.25Z"
                    fill="#2B2B2B"
                />
                <path
                    d="M12 18C13.6569 18 15 16.6569 15 15C15 13.3431 13.6569 12 12 12C10.3431 12 9 13.3431 9 15C9 16.6569 10.3431 18 12 18Z"
                    stroke="#2B2B2B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8 21C8.44375 19.275 10.1363 18 12 18C13.8637 18 15.5575 19.2737 16 21"
                    stroke="#2B2B2B"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_10980_6389">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
