import React from 'react';
import './index.scss';
import FadingCarousel from '../../../../Components/FadingCarousel';
import Carousel from '../../../../Components/Carousel';
import { useWindowDimensions } from '../../../../Hooks/use-window-dimensions';
import { useTranslation } from 'react-i18next';
import ExperienceFlowStep from '../../../../Components/Carousel/Components/ExperienceFlowStep';

import Step1 from './../../../../Assets/Img/Experience/en/Flow_1.webp';
import Step2 from './../../../../Assets/Img/Experience/en/Flow_2.webp';
import Step3 from './../../../../Assets/Img/Experience/en/Flow_3.webp';
import Step4 from './../../../../Assets/Img/Experience/en/Flow_4.webp';
import Step5 from './../../../../Assets/Img/Experience/en/Flow_5.webp';

import Step1FR from './../../../../Assets/Img/Experience/fr/Flow_1.webp';
import Step2FR from './../../../../Assets/Img/Experience/fr/Flow_2.webp';
import Step3FR from './../../../../Assets/Img/Experience/fr/Flow_3.webp';
import Step4FR from './../../../../Assets/Img/Experience/fr/Flow_4.webp';
import Step5FR from './../../../../Assets/Img/Experience/fr/Flow_5.webp';

import Step1ES from './../../../../Assets/Img/Experience/es/Flow_1.webp';
import Step2ES from './../../../../Assets/Img/Experience/es/Flow_2.webp';
import Step3ES from './../../../../Assets/Img/Experience/es/Flow_3.webp';
import Step4ES from './../../../../Assets/Img/Experience/es/Flow_4.webp';
import Step5ES from './../../../../Assets/Img/Experience/es/Flow_5.webp';

import Step1IT from './../../../../Assets/Img/Experience/it/Flow_1.webp';
import Step2IT from './../../../../Assets/Img/Experience/it/Flow_2.webp';
import Step3IT from './../../../../Assets/Img/Experience/it/Flow_3.webp';
import Step4IT from './../../../../Assets/Img/Experience/it/Flow_4.webp';
import Step5IT from './../../../../Assets/Img/Experience/it/Flow_5.webp';

import WrapperImage from '../../../../Components/WrapperImage';
import WrapperSection from '../../../../Components/WrapperSection';

const ExperienceFlow = () => {
    const { t } = useTranslation();
    const { windowWidth } = useWindowDimensions();
    const FADING_CAROUSEL_BREAKPOINT = 1200;

    const carouselElements = [
        <ExperienceFlowStep
            imageElement={
                <WrapperImage
                    src={{
                        fr: Step1FR,
                        en: Step1,
                        it: Step1IT,
                        es: Step1ES,
                    }}
                    alt="Computer screen with Spree add-on"
                    style={{ marginBottom: windowWidth > FADING_CAROUSEL_BREAKPOINT ? 24 : 10 }}
                    className="image"
                />
            }
            stepNumber={1}
            stepText={t('experience_flow_step1')}
        />,
        <ExperienceFlowStep
            imageElement={
                <WrapperImage
                    src={{
                        fr: Step2FR,
                        en: Step2,
                        it: Step2IT,
                        es: Step2ES,
                    }}
                    alt="Upload file prompt"
                    className="image"
                />
            }
            stepNumber={2}
            stepText={t('experience_flow_step2')}
        />,
        <ExperienceFlowStep
            imageElement={
                <WrapperImage
                    src={{
                        fr: Step3FR,
                        en: Step3,
                        it: Step3IT,
                        es: Step3ES,
                    }}
                    alt="File uploaded preview"
                    className="ExperienceFlow__image"
                />
            }
            stepNumber={3}
            stepText={t('experience_flow_step3')}
        />,
        <ExperienceFlowStep
            imageElement={
                <WrapperImage
                    src={{
                        fr: Step4FR,
                        en: Step4,
                        it: Step4IT,
                        es: Step4ES,
                    }}
                    alt="New garment visual"
                    className="ExperienceFlow__image"
                />
            }
            stepNumber={4}
            stepText={t('experience_flow_step4')}
        />,
        <ExperienceFlowStep
            imageElement={
                <WrapperImage
                    src={{
                        fr: Step5FR,
                        en: Step5,
                        it: Step5IT,
                        es: Step5ES,
                    }}
                    alt="Continue shopping"
                    className="ExperienceFlow__image"
                />
            }
            stepNumber={5}
            stepText={t('experience_flow_step5')}
        />,
    ];

    return (
        <div className="ExperienceFlow">
            <WrapperSection.Title htmlTag="h1">{t('experience_flow')}</WrapperSection.Title>
            <div className="text-body disclaimer">{t('HomePage.how_it_works_disclaimer')}</div>

            {windowWidth > FADING_CAROUSEL_BREAKPOINT ? (
                <FadingCarousel
                    partialVisibilityGutter={0}
                    elementMaxWidth={840}
                    carouselElements={carouselElements}
                />
            ) : (
                <div className="mobile-carousel-container">
                    <Carousel
                        centerMode={false}
                        infiniteScroll={false}
                        slidesToShow={1}
                        carouselElements={carouselElements}
                    />
                </div>
            )}
        </div>
    );
};

export default ExperienceFlow;
