import React, { useState } from 'react';

import { MagnifyingGlassIcon } from '../../../Icons/magnifying-glass';
import { ChevronLeftIcon } from '../../../Icons/chevron-left-icon';
import { CustomSelect } from './CustomSelect';
import { useGetJobListings } from '../hooks/use-get-job-listings';
import { CareersList } from './CareersList';

import styles from './styles/open-positions.module.scss';
import sharedStyles from '../index.module.scss';
import { Link } from '../../../Components/Link';
import CONSTANTS from '../../../Constants';

export interface Filters {
    title: string;
    department: string;
    region: string;
    contractType: string;
}

export const OpenPostions = () => {
    const [filters, setFilters] = useState<Filters>({
        title: '',
        department: '',
        region: '',
        contractType: '',
    });

    const { jobs, isLoading } = useGetJobListings();

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
        const { name, value } = event.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleValueChange = (name: string, value: string) => {
        setFilters({ ...filters, [name]: value });
    };

    const handleResetFilters = () => {
        setFilters({
            title: '',
            department: '',
            region: '',
            contractType: '',
        });
    };

    // create a list of unique departments, regions, and contract type options
    const departmentOptions = jobs
        .map((job) => job.department)
        .filter(
            (value, index, array) => value !== null && array.indexOf(value) === index
        ) as string[];
    const regionOptions = jobs
        .map((job) => job.locations[0].region)
        .filter((value, index, array) => value && array.indexOf(value) === index);
    const contractTypeOptions = jobs
        .map((job) => job.employment_type)
        .filter((value, index, array) => value && array.indexOf(value) === index);

    const isFilterApplied = Object.values(filters).some((value) => value !== '');

    return (
        <section className={styles.careersSection}>
            <div className={styles.container}>
                <div className={styles.headerContent}>
                    <div className={styles.careersHeader}>
                        <Link
                            pathConfig={{ where: CONSTANTS.SCREEN.CAREERS }}
                            className={styles.buttonText}
                        >
                            <ChevronLeftIcon fill="#0b1926" /> Go Back to Careers
                        </Link>
                        <h2 className={sharedStyles.heading2}>Recent Positions</h2>
                    </div>
                    <div className={styles.searchContainer}>
                        <input
                            type="text"
                            name="title"
                            placeholder="Search for Jobs"
                            onChange={handleChange}
                            value={filters.title}
                        />
                        <MagnifyingGlassIcon />
                    </div>
                    <div className={styles.careerControls}>
                        <button
                            disabled={!isFilterApplied}
                            className={styles.buttonPrimary}
                            onClick={handleResetFilters}
                        >
                            View All
                        </button>
                        <div className={styles.selectWrapper}>
                            <CustomSelect
                                handleValueChange={handleValueChange}
                                label="Department"
                                name="department"
                                options={departmentOptions}
                                placeholder="Department"
                                value={filters.department}
                            />
                        </div>
                        <div className={styles.selectWrapper}>
                            <CustomSelect
                                handleValueChange={handleValueChange}
                                label="Region"
                                name="region"
                                options={regionOptions}
                                placeholder="Region"
                                value={filters.region}
                            />
                        </div>
                        <div className={styles.selectWrapper}>
                            <CustomSelect
                                handleValueChange={handleValueChange}
                                label="Contract-type"
                                name="contractType"
                                options={contractTypeOptions}
                                placeholder="Contract type"
                                value={filters.contractType}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className={styles.container}>
                    <div className={styles.carouselContent}>
                        {isLoading ? (
                            <div className={styles.loading}>
                                <div className={styles.spinner} />
                            </div>
                        ) : (
                            <CareersList filters={filters} jobs={jobs} />
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
};
