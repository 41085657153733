import { HTMLAttributes } from 'react';
import { EllipseIcon } from '../../../../Icons/ellipse-icon';
import './index.scss';

const AnimatedEllipses = ({ ...props }: HTMLAttributes<HTMLDivElement>) => {
    return (
        <div
            {...props}
            className={props.className ? `AnimatedEllipses ${props.className}` : 'AnimatedEllipses'}
        >
            <div className="ellipsesContainer">
                <div className="ellipses ellipses-topRight">
                    <EllipseIcon className="ellipse ellipse1" />
                    <EllipseIcon className="ellipse ellipse2" />
                </div>
            </div>
            <div className="ellipsesContainer">
                <div className="ellipses ellipses-bottomLeft">
                    <EllipseIcon className="ellipse ellipse1" />
                    <EllipseIcon className="ellipse ellipse2" />
                </div>
            </div>
        </div>
    );
};

export default AnimatedEllipses;
