import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const UsersThreeIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" {...props} fill="none">
            <g clipPath="url(#clip0_3595_49075)">
                <path
                    d="M30 18.75C31.4556 18.7489 32.8914 19.0873 34.1934 19.7383C35.4953 20.3892 36.6275 21.3349 37.5 22.5"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M2.5 22.5C3.37249 21.3349 4.50469 20.3892 5.80663 19.7383C7.10857 19.0873 8.54438 18.7489 10 18.75"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20 28.75C23.4518 28.75 26.25 25.9518 26.25 22.5C26.25 19.0482 23.4518 16.25 20 16.25C16.5482 16.25 13.75 19.0482 13.75 22.5C13.75 25.9518 16.5482 28.75 20 28.75Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.25 33.75C12.1473 32.2273 13.4265 30.9651 14.9611 30.0882C16.4957 29.2112 18.2325 28.75 20 28.75C21.7675 28.75 23.5043 29.2112 25.0389 30.0882C26.5735 30.9651 27.8527 32.2273 28.75 33.75"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M25.1562 12.5C25.39 11.5946 25.873 10.773 26.5503 10.1283C27.2276 9.48362 28.0721 9.04179 28.9879 8.85299C29.9037 8.66418 30.8541 8.73597 31.7311 9.06018C32.6082 9.3844 33.3768 9.94806 33.9496 10.6871C34.5223 11.4262 34.8764 12.3111 34.9715 13.2413C35.0667 14.1715 34.899 15.1098 34.4877 15.9495C34.0764 16.7892 33.4378 17.4968 32.6445 17.9918C31.8513 18.4869 30.9351 18.7495 30 18.75"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M9.99943 18.75C9.06437 18.7495 8.14818 18.4869 7.35491 17.9918C6.56163 17.4968 5.92307 16.7892 5.51173 15.9495C5.10039 15.1098 4.93277 14.1715 5.0279 13.2413C5.12302 12.3111 5.47709 11.4262 6.04988 10.6871C6.62267 9.94806 7.39124 9.3844 8.26829 9.06018C9.14534 8.73597 10.0957 8.66418 11.0115 8.85299C11.9273 9.04179 12.7718 9.48362 13.4492 10.1283C14.1265 10.773 14.6094 11.5946 14.8432 12.5"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3595_49075">
                    <rect width="40" height="40" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
