import React from 'react';
import './index.scss';

import TryOnBefore from './../../../../Assets/Img/Experience/en/TryOn_Before.webp';
import TryOnBeforeFR from './../../../../Assets/Img/Experience/fr/TryOn_Before.webp';
import TryOnBeforeES from './../../../../Assets/Img/Experience/es/TryOn_Before.webp';
import TryOnBeforeIT from './../../../../Assets/Img/Experience/it/TryOn_Before.webp';

import TryOnAfter from './../../../../Assets/Img/Experience/en/TryOn_After.webp';
import TryOnAfterFR from './../../../../Assets/Img/Experience/fr/TryOn_After.webp';
import TryOnAfterIT from './../../../../Assets/Img/Experience/it/TryOn_After.webp';
import TryOnAfterES from './../../../../Assets/Img/Experience/es/TryOn_After.webp';

import { useTranslation } from 'react-i18next';
import WrapperImage from '../../../../Components/WrapperImage';
import WrapperSection from '../../../../Components/WrapperSection';

const TryOn = () => {
    const { t } = useTranslation();

    return (
        <div className="TryOn">
            <div className="TryOn__content">
                <div className="TryOn__before TryOn__halfSide">
                    <div className="TryOn__halfSideContent">
                        <div className="TryOn__topContainer">
                            <WrapperSection.Title className="text-white" htmlTag="h1">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t('before_try_on'),
                                    }}
                                />
                            </WrapperSection.Title>
                            <div className="TryOn__paragraph text-body">
                                {t('before_try_on_card')}
                            </div>
                        </div>
                        <WrapperImage
                            src={{
                                fr: TryOnBeforeFR,
                                en: TryOnBefore,
                                it: TryOnBeforeIT,
                                es: TryOnBeforeES,
                            }}
                            alt="Shopping website"
                            className="TryOn__img"
                        />
                    </div>
                </div>
                <div className="TryOn__after TryOn__halfSide">
                    <div className="TryOn__triangle"></div>
                    <div className="TryOn__halfSideContent">
                        <div className="TryOn__topContainer">
                            <WrapperSection.Title className="text-white" htmlTag="h1">
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: t('after_try_on'),
                                    }}
                                />
                            </WrapperSection.Title>

                            <div className="TryOn__paragraph text-body">
                                {t('after_try_on_card')}
                            </div>
                        </div>
                        <WrapperImage
                            src={{
                                fr: TryOnAfterFR,
                                en: TryOnAfter,
                                it: TryOnAfterIT,
                                es: TryOnAfterES,
                            }}
                            alt="Shopping website"
                            className="TryOn__img"
                        />
                    </div>
                </div>
            </div>{' '}
        </div>
    );
};

export default TryOn;
