import { FunctionComponent } from 'react';
import { SvgProps } from '../../Icons/SvgProps';

export const SwitchCloseIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="15" height="16" viewBox="0 0 15 16" {...props} fill="none">
            <g clipPath="url(#clip0_6730_850)">
                <path
                    d="M11.7188 3.78125L3.28125 12.2188"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M11.7188 12.2188L3.28125 3.78125"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_6730_850">
                    <rect width="15" height="15" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
