import React, { FunctionComponent, ReactNode } from 'react';
import './index.scss';
import Copydeck from '../../../../i18n/Copydeck';

interface ExperienceFlowStepProps {
    imageElement: ReactNode;
    stepNumber: number;
    stepText: string;
}

const ExperienceFlowStep: FunctionComponent<ExperienceFlowStepProps> = ({
    imageElement,
    stepNumber,
    stepText,
}) => {
    return (
        <div className="ExperienceFlowStep">
            {imageElement}
            <p className="text-body-primary-font-bold">
                {Copydeck.step} {stepNumber}
            </p>
            <p className="text-body text-surface-grey-opposite">{stepText}</p>
        </div>
    );
};

export default ExperienceFlowStep;
