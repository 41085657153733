import { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import WrapperVideo from '../../../../Components/WrapperVideo';

import './index.scss';

import heroThumbnail from '../../../../Assets/Img/ImpactsAndInclusions/hero-thumbnail.png';

const InclusionsAndImpactsHero = () => {
    const texts = [
        'ImpactsAndInclusions.hero_returns',
        'ImpactsAndInclusions.hero_inclusive',
        'ImpactsAndInclusions.hero_supporting',
    ];
    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const { t } = useTranslation();

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        }, 4000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="HeroVideoContainer">
            <div className="overlay" />
            <div className="HeroVideoContent">
                <h1 className="text-main-header t-align-center">
                    {t('ImpactsAndInclusions.hero_titles')}
                </h1>
                <div className="HeroVideoSubtitle">
                    <div className="subtitle-wrapper">
                        <div className="text-body static-text t-align-center">
                            {t('ImpactsAndInclusions.hero_subtitle')}
                        </div>
                        <div className="container-cta">
                            <AnimatePresence mode="wait">
                                <motion.div
                                    key={currentTextIndex}
                                    className="dynamic-text-container"
                                    initial={{ width: 'auto' }}
                                    animate={{ width: 'auto' }}
                                    exit={{ width: 'auto' }}
                                    transition={{ duration: 0.3 }}
                                >
                                    <motion.div
                                        initial={{ opacity: 0 }}
                                        animate={{ opacity: 1 }}
                                        exit={{ opacity: 0 }}
                                        transition={{ duration: 0.3 }}
                                        className="text-body-bold dynamic-text"
                                    >
                                        {t(texts[currentTextIndex])}
                                    </motion.div>
                                </motion.div>
                            </AnimatePresence>
                        </div>
                    </div>
                </div>
            </div>
            <WrapperVideo
                alt="Hero Video"
                classNameOnVideo="HeroVideo"
                muted={true}
                poster={heroThumbnail}
                src={{
                    en: {
                        desktop: {
                            mp4: require('../../../../Assets/MP4/impacts-and-inclusions/hero-desktop.mp4'),
                            webm: require('../../../../Assets/MP4/impacts-and-inclusions/hero-desktop.webm'),
                        },
                        mobile: {
                            mp4: require('../../../../Assets/MP4/impacts-and-inclusions/hero-mobile.mp4'),
                            webm: require('../../../../Assets/MP4/impacts-and-inclusions/hero-mobile.webm'),
                        },
                    },
                    fr: {
                        desktop: {
                            mp4: require('../../../../Assets/MP4/impacts-and-inclusions/hero-desktop.mp4'),
                            webm: require('../../../../Assets/MP4/impacts-and-inclusions/hero-desktop.webm'),
                        },
                        mobile: {
                            mp4: require('../../../../Assets/MP4/impacts-and-inclusions/hero-mobile.mp4'),
                            webm: require('../../../../Assets/MP4/impacts-and-inclusions/hero-mobile.webm'),
                        },
                    },
                    it: {
                        desktop: {
                            mp4: require('../../../../Assets/MP4/impacts-and-inclusions/hero-desktop.mp4'),
                            webm: require('../../../../Assets/MP4/impacts-and-inclusions/hero-desktop.webm'),
                        },
                        mobile: {
                            mp4: require('../../../../Assets/MP4/impacts-and-inclusions/hero-mobile.mp4'),
                            webm: require('../../../../Assets/MP4/impacts-and-inclusions/hero-mobile.webm'),
                        },
                    },
                    es: {
                        desktop: {
                            mp4: require('../../../../Assets/MP4/impacts-and-inclusions/hero-desktop.mp4'),
                            webm: require('../../../../Assets/MP4/impacts-and-inclusions/hero-desktop.webm'),
                        },
                        mobile: {
                            mp4: require('../../../../Assets/MP4/impacts-and-inclusions/hero-mobile.mp4'),
                            webm: require('../../../../Assets/MP4/impacts-and-inclusions/hero-mobile.webm'),
                        },
                    },
                }}
            />
        </div>
    );
};

export default InclusionsAndImpactsHero;
