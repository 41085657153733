import React, { useEffect } from 'react';
import LayoutPolicy from '../../../Layout/Policy/layout-policy';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../../../Constants';
import PolicyPrivacyTableOfContents from './Components/PrivacyPolicyTableOfContents';

const PrivacyPolicyPage = () => {
    const { t } = useTranslation();

    useEffect(() => {
        const handleScrollToAnchor = (event: any) => {
            const offset = 100;

            event.preventDefault();
            const targetId = event.currentTarget.getAttribute('href').substring(1);
            const targetElement = document.getElementById(targetId);

            if (targetElement) {
                const elementPosition =
                    targetElement.getBoundingClientRect().top + window.pageYOffset;
                const offsetPosition = elementPosition - offset;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth',
                });
            }
        };

        const links = document.querySelectorAll('a[href^="#"]');
        links.forEach((link) => {
            link.addEventListener('click', handleScrollToAnchor);
        });

        return () => {
            links.forEach((link) => {
                link.removeEventListener('click', handleScrollToAnchor);
            });
        };
    }, []);
    return (
        <LayoutPolicy title={t('privacy_policy_title')}>
            <PolicyPrivacyTableOfContents />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Anchor id="introduction" />
            <LayoutPolicy.Title title={t('Policy.policy_text_1')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_2')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_3')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_4')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_5')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('Policy.policy_text_6')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_7')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Anchor id="section-1" />
            <LayoutPolicy.NumberedTitle number={1} title={t('Policy.policy_text_8')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_9')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_10')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_11')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('Policy.policy_text_12')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_13')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Anchor id="text-14" />
            <LayoutPolicy.Title title={t('Policy.policy_text_14')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_15')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('Policy.policy_text_16')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_17')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('Policy.policy_text_18')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_19')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_20')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_21')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_22')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('Policy.policy_text_23')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_24')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_25')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_26')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('Policy.policy_text_27')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_28')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_29')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_30')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_31')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Anchor id="section-2" />
            <LayoutPolicy.NumberedTitle number={2} title={t('Policy.policy_text_32')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_33')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('Policy.policy_text_34')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_35')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Anchor id="section-3" />
            <LayoutPolicy.NumberedTitle number={3} title={t('Policy.policy_text_36')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_37')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('Policy.policy_text_38')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_39')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Anchor id="section-4" />
            <LayoutPolicy.NumberedTitle number={4} title={t('Policy.policy_text_40')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_41')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Anchor id="section-5" />
            <LayoutPolicy.NumberedTitle number={5} title={t('Policy.policy_text_42')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_43')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_44')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_45')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List
                text={t('Policy.policy_text_46', { param1: CONSTANTS.SCREEN.CONTACT_US })}
            />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_47')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_48')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_49')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_50')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_51')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('Policy.policy_text_52')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Anchor id="section-6" />
            <LayoutPolicy.NumberedTitle number={6} title={t('Policy.policy_text_53')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_54')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Anchor id="section-7" />
            <LayoutPolicy.NumberedTitle number={7} title={t('Policy.policy_text_55')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_56')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Anchor id="section-8" />
            <LayoutPolicy.NumberedTitle number={8} title={t('Policy.policy_text_57')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_58')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Anchor id="section-9" />
            <LayoutPolicy.NumberedTitle number={9} title={t('Policy.policy_text_59')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_60')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Anchor id="section-10" />
            <LayoutPolicy.NumberedTitle number={10} title={t('Policy.policy_text_61')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_62')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Anchor id="section-11" />
            <LayoutPolicy.NumberedTitle number={11} title={t('Policy.policy_text_63')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_64')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_64_1')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_65')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_66')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_67')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Anchor id="section-12" />
            <LayoutPolicy.NumberedTitle number={12} title={t('Policy.policy_text_68')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_69')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_70')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_71')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('Policy.policy_text_72')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_73')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_74')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_75')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_76')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('Policy.policy_text_77')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_78')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Anchor id="text-79" />
            <LayoutPolicy.Title title={t('Policy.policy_text_79')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_80')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_81')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_82')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_83')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_84')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_85')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_86')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Anchor id="section-13" />
            <LayoutPolicy.NumberedTitle number={13} title={t('Policy.policy_text_87')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_88')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_89')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_90')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('Policy.policy_text_91')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_92')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_93')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_94')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_95')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_96')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_97')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_98')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('Policy.policy_text_99')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Anchor id="section-14" />
            <LayoutPolicy.NumberedTitle number={14} title={t('Policy.policy_text_100')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_101')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Anchor id="section-15" />
            <LayoutPolicy.NumberedTitle number={15} title={t('Policy.policy_text_102')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_103')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_103_1')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_103_2')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('Policy.policy_text_103_3')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_104')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_105')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_106')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_106_0_1')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('Policy.policy_text_106_1')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_107')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('Policy.policy_text_108')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_109')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_110')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('Policy.policy_text_111')} />
            <LayoutPolicy.Jump />
        </LayoutPolicy>
    );
};

export default PrivacyPolicyPage;
