import React from 'react';
import './layout-policy.scss';
import LayoutPolicyTitle, { LayoutPolicyTitleProps } from './Components/layout-policy-title';
import LayoutPolicyBasicText, {
    LayoutPolicyBasicTextProps,
} from './Components/layout-policy-basic-text';
import LayoutPolicyJump, { LayoutPolicyJumpProps } from './Components/layout-policy-jump';
import LayoutPolicyList, { LayoutPolicyListProps } from './Components/layout-policy-list';
import LayoutPolicyNumberedTitle, {
    LayoutPolicyNumberedTitleProps,
} from './Components/layout-policy-numbered-title';
import LayoutPolicyAnchor, { LayoutPolicyAnchorProps } from './Components/layout-policy-anchor';

type LayoutPolicyProps = React.FunctionComponent<{
    children: React.ReactNode;
    title?: string;
}> & {
    Title: React.FC<LayoutPolicyTitleProps>;
} & {
    BasicText: React.FC<LayoutPolicyBasicTextProps>;
} & {
    Jump: React.FC<LayoutPolicyJumpProps>;
} & {
    List: React.FC<LayoutPolicyListProps>;
} & {
    NumberedTitle: React.FC<LayoutPolicyNumberedTitleProps>;
} & {
    Anchor: React.FC<LayoutPolicyAnchorProps>;
};

const LayoutPolicy: LayoutPolicyProps = ({ title, children }) => {
    return (
        <div className="LayoutPolicy">
            {title && (
                <div className="terms-hero">
                    <h1 className="deprecated-text-display-large">{title}</h1>
                </div>
            )}

            <div className="terms-content">{children}</div>
        </div>
    );
};

LayoutPolicy.Title = LayoutPolicyTitle;
LayoutPolicy.BasicText = LayoutPolicyBasicText;
LayoutPolicy.Jump = LayoutPolicyJump;
LayoutPolicy.List = LayoutPolicyList;
LayoutPolicy.NumberedTitle = LayoutPolicyNumberedTitle;
LayoutPolicy.Anchor = LayoutPolicyAnchor;

export default LayoutPolicy;
