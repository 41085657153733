import Copydeck from '../../i18n/Copydeck';
import './index.scss';
import { Select } from 'antd';
interface SelectInputProps {
    options: Array<{
        value: string | number;
        label: string;
    }>;
    value: any;
    onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
    label: string;
    name: string;
    hiddenLabel?: boolean;
}

const SelectInput: React.FC<SelectInputProps> = ({
    options,
    onChange,
    value,
    label,
    name,
    hiddenLabel = false,
}) => {
    return (
        <Select
            options={options}
            labelInValue={true}
            value={value || Copydeck.blogPageSortBy}
            className="SelectInput"
            onChange={onChange}
            bordered={false}
        />
    );
};

export default SelectInput;
