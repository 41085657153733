import Step1 from '../../../../Assets/Img/Home/en/howitworks-image1.webp';
import Step2 from '../../../../Assets/Img/Home/en/howitworks-image2.webp';
import Step3 from '../../../../Assets/Img/Home/en/howitworks-image3.webp';

import Step1FR from '../../../../Assets/Img/Home/fr/howitworks-image1.webp';
import Step2FR from '../../../../Assets/Img/Home/fr/howitworks-image2.webp';
import Step3FR from '../../../../Assets/Img/Home/fr/howitworks-image3.webp';

import Step1ES from '../../../../Assets/Img/Home/es/howitworks-image1.webp';
import Step2ES from '../../../../Assets/Img/Home/es/howitworks-image2.webp';
import Step3ES from '../../../../Assets/Img/Home/es/howitworks-image3.webp';

import Step1IT from '../../../../Assets/Img/Home/it/howitworks-image1.webp';
import Step2IT from '../../../../Assets/Img/Home/it/howitworks-image2.webp';
import Step3IT from '../../../../Assets/Img/Home/it/howitworks-image3.webp';

import './index.scss';
import HowItWorksStep from '../../../../Components/Carousel/Components/HowItWorksStep';
import { useWindowDimensions } from '../../../../Hooks/use-window-dimensions';
import Carousel from '../../../../Components/Carousel';
import Copydeck from '../../../../i18n/Copydeck';
import WrapperSection from '../../../../Components/WrapperSection';
import HowItWorksVideoSection from './how-it-works-video-section';
import WrapperImage from '../../../../Components/WrapperImage';

const HowItWorks = () => {
    const { windowWidth } = useWindowDimensions();

    const breakpoint = 1190;

    const carouselElements = [
        <HowItWorksStep
            imageElement={
                <WrapperImage
                    src={{
                        fr: Step1FR,
                        en: Step1,
                        es: Step1ES,
                        it: Step1IT,
                    }}
                    alt={`${Copydeck.step} 1`}
                />
            }
            stepNumber={1}
            stepText={Copydeck.homePageHowItWorksStep1}
        />,
        <HowItWorksStep
            imageElement={
                <WrapperImage
                    src={{
                        fr: Step2FR,
                        en: Step2,
                        es: Step2ES,
                        it: Step2IT,
                    }}
                    alt={`${Copydeck.step} 2`}
                />
            }
            stepNumber={2}
            stepText={Copydeck.homePageHowItWorksStep2}
        />,
        <HowItWorksStep
            imageElement={
                <WrapperImage
                    src={{
                        fr: Step3FR,
                        en: Step3,
                        es: Step3ES,
                        it: Step3IT,
                    }}
                    alt={`${Copydeck.step} 3`}
                />
            }
            stepNumber={3}
            stepText={Copydeck.homePageHowItWorksStep3}
        />,
    ];

    return (
        <div className="HowItWorks">
            <WrapperSection
                padding="80"
                paddingHorizontal="64"
                paddingInMobile="80"
                className="HowItWorks__content"
            >
                <WrapperSection.Title className="title" htmlTag="h1">
                    {Copydeck.homePageHowItWorksHowItWorksTitle}
                </WrapperSection.Title>

                <p
                    className="text-body subtitle"
                    dangerouslySetInnerHTML={{
                        __html: Copydeck.homePageHowItWorksHowItWorksSecondTitle,
                    }}
                />

                {windowWidth > breakpoint ? (
                    <ul className="steps">
                        {carouselElements.map((element, index) => (
                            <li key={index} className="HowItWorks__step">
                                {element}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <Carousel
                        carouselElements={carouselElements}
                        centerMode={false}
                        slidesToShow={1}
                        infiniteScroll={false}
                    />
                )}
            </WrapperSection>
            <HowItWorksVideoSection />
        </div>
    );
};

export default HowItWorks;
