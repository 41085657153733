import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const EarthIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            fill="none"
        >
            <circle cx="19.9987" cy="19.9999" r="16.6667" stroke={props.fill} strokeWidth="2" />
            <path
                d="M9.60156 7.46675C10.902 8.65096 13.9808 12.272 14.2929 15.7457C14.5846 18.9926 16.7277 21.631 20.0016 21.6668C21.2597 21.6805 22.5321 20.7716 22.5289 19.5134C22.5279 19.1243 22.4637 18.7265 22.3632 18.3605C22.2234 17.8515 22.211 17.2658 22.5016 16.6667C23.518 14.571 25.5172 14.008 27.1013 12.8698C27.8039 12.3651 28.4448 11.8325 28.7274 11.4036C29.5077 10.2194 30.2879 7.85096 29.8978 6.66675"
                stroke={props.fill}
                strokeWidth="2"
            />
            <path
                d="M36.668 21.6667C36.119 23.2185 35.7305 27.2917 29.5317 27.3564C29.5317 27.3564 24.0422 27.3564 22.3954 30.4599C21.0779 32.9426 21.8465 35.6323 22.3954 36.6667"
                stroke={props.fill}
                strokeWidth="2"
            />
        </svg>
    );
};
