import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const CoatHangerIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="41" height="40" viewBox="0 0 41 40" {...props} fill="none">
            <g clipPath="url(#clip0_3597_20224)">
                <path
                    d="M15.5001 11.25C15.5001 9.92392 16.0268 8.65215 16.9645 7.71447C17.9022 6.77678 19.174 6.25 20.5001 6.25C21.8261 6.25 23.0979 6.77678 24.0356 7.71447C24.9733 8.65215 25.5001 9.92392 25.5001 11.25L3.50318 27.75C3.29351 27.9073 3.13857 28.1264 3.06027 28.3765C2.98196 28.6267 2.98426 28.8951 3.06683 29.1438C3.14939 29.3925 3.30806 29.609 3.52039 29.7627C3.73272 29.9163 3.98797 29.9993 4.25005 30H36.7501C37.0123 29.9997 37.2677 29.9169 37.4803 29.7634C37.6929 29.6098 37.8518 29.3934 37.9346 29.1445C38.0174 28.8957 38.0198 28.6272 37.9415 28.3769C37.8633 28.1266 37.7083 27.9073 37.4985 27.75L20.5001 15"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3597_20224">
                    <rect width="40" height="40" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
