import React from 'react';
import { useTranslation } from 'react-i18next';
import '../stepBaseStyle.scss';
import './index.scss';
import Button from '../../../Components/Button';
import CONSTANTS from '../../../Constants';
import WhiteCard from '../../../Components/WhiteCard';
import useCustomNavigation from '../../../Hooks/use-custom-navigation';
import EnvelopeCheckedIcon from '../../../Icons/envelope-checked-icon';

const EmailConfirmed = () => {
    const { t } = useTranslation();
    const navigate = useCustomNavigation();

    const onClickBackHonePage = () => {
        navigate(CONSTANTS.SCREEN.HOME);
    };

    return (
        <div className="EmailConfirmed">
            <div className="EmailConfirmed-content">
                <div className="whitecard-container">
                    <WhiteCard padding={80}>
                        <div className="wrapper-icon">
                            <EnvelopeCheckedIcon />
                        </div>

                        <div className="text text-body-large-primary-font-bold">
                            {t('EmailConfirmationFlow.email_confirmation_waiting_approval')}
                        </div>

                        <div className="spacer" />

                        <div
                            className="text text-body text-element-greys-secondary"
                            dangerouslySetInnerHTML={{
                                __html: t(
                                    'EmailConfirmationFlow.email_confirmation_waiting_approval_subtitle'
                                ),
                            }}
                        />
                        <div className="text">
                            {t(
                                'EmailConfirmationFlow.email_confirmation_waiting_approval_notification'
                            )}
                        </div>

                        <Button
                            text={t('home_page')}
                            type="primary"
                            onClick={onClickBackHonePage}
                            fullInMobile={false}
                        />
                    </WhiteCard>
                </div>
            </div>
        </div>
    );
};

export default EmailConfirmed;
