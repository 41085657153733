import { useStores } from './use-stores';

const useAnnoucementLearnMore = () => {
    const { onboardingModalStore } = useStores();

    const onClick = (redirectionUrl: string) => {
        if (redirectionUrl.includes('youtube')) {
            onboardingModalStore.setIsVisible(true, redirectionUrl);
            return;
        }

        if (redirectionUrl.includes('#')) {
            const [baseUrl, hash] = redirectionUrl.split('#');
            const isSamePage =
                baseUrl === window.location.origin + window.location.pathname ||
                redirectionUrl?.[0] === '#';

            if (isSamePage) {
                const element = document.getElementById(hash);

                if (element) {
                    const containerHeight = window.innerHeight;
                    const itemHeight = element.offsetHeight;
                    const scrollTo = element.offsetTop - containerHeight / 2 + itemHeight / 2;

                    window.scrollTo({
                        top: scrollTo - 50,
                        behavior: 'smooth',
                    });
                }
                window.history.pushState(null, '', redirectionUrl);
            } else {
                window.location.href = redirectionUrl;
            }
            return;
        }

        window.open(redirectionUrl, '_blank')?.focus();
    };

    return onClick;
};

export default useAnnoucementLearnMore;
