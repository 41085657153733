import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const ChevronTopIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="15"
            height="9"
            viewBox="0 0 15 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1.07395 7.10645L6.80693 1.2454C7.0028 1.03446 7.23634 0.936523 7.50001 0.936523C7.76368 0.936523 8.00475 1.04199 8.20062 1.2454L13.9261 7.10645C14.0918 7.27218 14.1822 7.48312 14.1822 7.73172C14.1822 8.23647 13.7905 8.62821 13.2933 8.62821C13.0522 8.62821 12.8186 8.53781 12.6454 8.357L7.50754 3.07603L2.35464 8.357C2.1889 8.53027 1.95537 8.62821 1.70676 8.62821C1.20955 8.62821 0.81781 8.23647 0.81781 7.73172C0.81781 7.49065 0.908212 7.27218 1.07395 7.10645Z"
                fill={props.fill}
            />
        </svg>
    );
};
