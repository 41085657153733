import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const ArrowRightIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 32 32"
            {...props}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_9522_15903)">
                <path
                    d="M5 16H27"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18 7L27 16L18 25"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_9522_15903">
                    <rect width="32" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
