import CONSTANTS from '../../../Constants';
import { SupportedLanguage } from '../../../Stores/LanguageStore';
import { getKeyByValueRecursive } from '../../../Utils/object';
import { I_NAV_OPTIONS } from './config';

export const isCurrentPath = (
    navOption: I_NAV_OPTIONS,
    currentLanguage: SupportedLanguage | null,
    path: string
) => {
    if (currentLanguage === null) {
        // console.error(`currentLanguage: ${currentLanguage} is not supported`);
        return;
    }

    const pathOfRoutes = (CONSTANTS.ROUTES as any)?.[currentLanguage]?.[navOption?.href];
    const keyRoutesOfPath = getKeyByValueRecursive(CONSTANTS.ROUTES, path);
    let currentPath = '';

    if (keyRoutesOfPath) {
        const lastKey = keyRoutesOfPath.split('.').pop();
        if (lastKey) currentPath = (CONSTANTS.ROUTES as any)?.[currentLanguage]?.[lastKey];
    }

    return currentPath === pathOfRoutes;
};
