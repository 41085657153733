import React from 'react';
import LayoutPolicy from '../../../Layout/Policy/layout-policy';
import { useTranslation } from 'react-i18next';

const DpaPage = () => {
    const { t } = useTranslation();
    return (
        <LayoutPolicy title={t('DpaPolicy.dpa_title_1')}>
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_1')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('DpaPolicy.dpa_title_2')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_2')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_3')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_4')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_5')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_6')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('DpaPolicy.dpa_title_3')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_7')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_8')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_9')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_10')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('DpaPolicy.dpa_title_4')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_11')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('DpaPolicy.dpa_title_5')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_12')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('DpaPolicy.dpa_title_6')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_13')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('DpaPolicy.dpa_title_7')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_14')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('DpaPolicy.dpa_title_8')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_15')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('DpaPolicy.dpa_title_9')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_16')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('DpaPolicy.dpa_title_10')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_17')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_18')} />

            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_19')} />

            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_20')} />

            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_21')} />

            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_22')} />

            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_23')} />

            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_24')} />

            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_25')} />

            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_26')} />

            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_27')} />

            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_28')} />

            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_29')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_30')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('DpaPolicy.dpa_title_11')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_31')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_32')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_33')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_34')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_35')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_36')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_37')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_38')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_39')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_40')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_41')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_42')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_43')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_44')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_45')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_46')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_47')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_48')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('DpaPolicy.dpa_title_12')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_49')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_50')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_51')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_52')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_53')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_54')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_55')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_56')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_57')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_58')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_59')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_60')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_61')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_62')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_63')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_64')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_65')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_66')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_67')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_68')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_69')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_70')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_71')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_72')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_73')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_74')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_75')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_76')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_77')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_78')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_79')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_80')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_81')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_82')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_83')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_84')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_85')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_86')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_87')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_88')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_89')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_90')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_91')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_92')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_93')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_94')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_95')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_96')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_97')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_98')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_99')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_100')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_101')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_102')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_103')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_104')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_105')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_106')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.List text={t('DpaPolicy.dpa_text_107')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_108')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_109')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.Title title={t('DpaPolicy.dpa_title_110')} />
            <LayoutPolicy.Jump />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_110')} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_111')} />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_112')} />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_113')} />
            <LayoutPolicy.BasicText text={t('DpaPolicy.dpa_text_114')} />
        </LayoutPolicy>
    );
};

export default DpaPage;
