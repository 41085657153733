import React, { FunctionComponent } from 'react';
import './index.scss';
import Copydeck from '../../../../i18n/Copydeck';
import Ellipses from '../../../../Assets/Img/Home/Intellectual_Ellipse.webp';
import WrapperSection from '../../../../Components/WrapperSection';
import Background from '../../../../Assets/Img/Home/patent-background.webp';
import BackgroundMobile from '../../../../Assets/Img/Home/patent-background-mobile.webp';
import { useWindowDimensions } from '../../../../Hooks/use-window-dimensions';

const IntellectualProperty: FunctionComponent = () => {
    const PATENT_PENDING = 23;
    const PATENT_ISSUED = 4;
    const { windowWidth } = useWindowDimensions();

    const isMobile = windowWidth < 768;

    return (
        <div className="IntellectualProperty">
            <img
                src={isMobile ? BackgroundMobile : Background}
                width="100%"
                alt="Patent background"
                className="background"
                loading="lazy"
            />

            <div className="overlay" />

            <img
                src={Ellipses}
                alt="Ellipses"
                className="IntellectualProperty__ellipses IntellectualProperty__ellipses--topRight"
                role="presentation"
                loading="lazy"
            />
            <img
                src={Ellipses}
                alt="Ellipses"
                className="IntellectualProperty__ellipses IntellectualProperty__ellipses--bottomLeft"
                role="presentation"
                loading="lazy"
            />

            <div className="content">
                <WrapperSection.Title className="title text-white">
                    {Copydeck.homePageIntellectualSectionIntellectualTitle}
                </WrapperSection.Title>

                <div className="subtitles">
                    <p className="subtitle text-body">
                        {Copydeck.homePageIntellectualSectionParagraph}
                    </p>
                    <p
                        className="subtitle text-body"
                        dangerouslySetInnerHTML={{
                            __html: Copydeck.homePageIntellectualSection2Paragraph2,
                        }}
                    />
                </div>

                <div className="patents">
                    <div className="patent">
                        <div className="amount text-white text-main-header">{PATENT_ISSUED}</div>
                        <div className="text-white text-body">
                            {Copydeck.homePageIntellectualSectionIssued}
                        </div>
                    </div>
                    <div className="patent">
                        <div className="amount text-white text-main-header">{PATENT_PENDING}</div>
                        <div className="text-white text-body">
                            {Copydeck.homePageIntellectualSectionPatentsPending}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntellectualProperty;
