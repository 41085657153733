import React, { FunctionComponent } from 'react';
import './index.scss';

interface ProgressBarProps {
    progress: number;
}

const ProgressBar: FunctionComponent<ProgressBarProps> = ({ progress }) => {
    return (
        <div className="ProgressBar">
            <div
                className="filled"
                style={{
                    width: `${progress}%`,
                }}
            ></div>
            <div className="unfilled" style={{ width: `${100 - progress}%` }}></div>
        </div>
    );
};

export default ProgressBar;
