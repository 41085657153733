import InclusionsAndImpactsHero from './Sections/Hero';
import './index.scss';
import InclusionsAndImpactsOurGoals from './Sections/OurGoals';
import InclusionsAndImpactsCoreValues from './Sections/CoreValues';
import FuturOfFashionSection from '../Home/Sections/FuturOfFashion';
import { useEffect, useRef, useState } from 'react';
import ImpactAndInclusionsCardsSection from './Sections/CardsSection';

const ImpactsAndInclusions = () => {
    const ctaRef = useRef<HTMLDivElement | null>(null);
    const [ctaDistanceRight, setCtaDistanceRight] = useState(0);

    const updateCtaDistanceRight = () => {
        const ctaElement = ctaRef.current;
        if (ctaElement) {
            const ctaRect = ctaElement.getBoundingClientRect();
            const distanceRight = window.innerWidth - ctaRect.right;
            setCtaDistanceRight(distanceRight);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', () => {
            updateCtaDistanceRight();
        });
    }, []);

    return (
        <div className="ImpactsAndInclusions">
            <InclusionsAndImpactsHero />
            <ImpactAndInclusionsCardsSection />
            <InclusionsAndImpactsCoreValues />
            <InclusionsAndImpactsOurGoals ctaDistanceRight={ctaDistanceRight} />
            <FuturOfFashionSection ctaRef={ctaRef} />
        </div>
    );
};

export default ImpactsAndInclusions;
