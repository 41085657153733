import React from 'react';
import './index.scss';
import FuturOfFashionSection from '../../../Home/Sections/FuturOfFashion';

const AboutUsSectionRevolution = () => {
    return (
        <div className="AboutUsSectionRevolution">
            <FuturOfFashionSection />
        </div>
    );
};

export default AboutUsSectionRevolution;
