import './index.scss';
import Button from '../../../Components/Button';
import CONSTANTS from '../../../Constants';
import useCustomNavigation from '../../../Hooks/use-custom-navigation';
import Copydeck from '../../../i18n/Copydeck';
import WrapperSection from '../../../Components/WrapperSection';
import { useTranslation } from 'react-i18next';
import FaqSection from './Sections/FaqSection';
import ParteningWithUsSection from './Sections/ParteningWithUs';
import { useWindowDimensions } from '../../../Hooks/use-window-dimensions';
import { useRulesOfThrees } from '../../../Hooks/use-rules-of-threes';
import WrapperImage from '../../../Components/WrapperImage';
import { useStores } from '../../../Hooks/use-stores';
import headerImage from '../../../Assets/Img/LandingPage/woman_using-spreeai.jpg';
import mobileHederImage from '../../../Assets/Img/LandingPage/woman_using_spreeai-m.jpg';

const CreateAccountLandingPage = () => {
    const navigate = useCustomNavigation();
    const { t } = useTranslation();
    const { windowWidth } = useWindowDimensions();
    const test = useRulesOfThrees(1100);
    const { languageStore } = useStores();

    return (
        <div className="LandingPage">
            <div className="hero">
                <div
                    className="wrapper-video"
                    style={{
                        height: windowWidth >= 1800 ? test : 'auto',
                    }}
                >
                    {windowWidth <= 590 ? (
                        <WrapperImage
                            uniqueSrc={mobileHederImage}
                            alt="Woman on computer using SpreeAI"
                        />
                    ) : (
                        <WrapperImage
                            uniqueSrc={headerImage}
                            alt="Woman on computer using SpreeAI"
                        />
                    )}
                </div>

                <div className={`inner -${languageStore.currentLanguage}`}>
                    <div className="LandingPage__topPart">
                        <WrapperSection.Title className="title text-white" htmlTag="h1">
                            {Copydeck.createAccountBecomePartner}
                        </WrapperSection.Title>
                        {windowWidth >= 900 && (
                            <div className="text-body text-white">
                                {t('LandingPage.unlock_amazing_stuff')}
                            </div>
                        )}
                    </div>

                    <div className="wrapper-btn">
                        <Button
                            text={t('HeaderNav.nav_become_partner')}
                            type="primary"
                            onClick={() => navigate(CONSTANTS.SCREEN.CREATE_ACCOUNT_FLOW)}
                            fullInMobile={false}
                        />

                        <Button
                            text={t('CreateAccount.create_account_login')}
                            type="black"
                            className="cta-sign-in"
                            onClick={() => navigate(CONSTANTS.SCREEN.LOGIN)}
                            fullInMobile={false}
                        />
                    </div>
                </div>
            </div>

            <ParteningWithUsSection />

            <FaqSection />

            <div className="LandingPageButtonsSection">
                <WrapperSection.Title>
                    {t('CreateAccount.interested_becoming_spreeai')}
                </WrapperSection.Title>

                <Button
                    text={t('HeaderNav.nav_become_partner')}
                    type="primary"
                    onClick={() => navigate(CONSTANTS.SCREEN.CREATE_ACCOUNT_FLOW)}
                    className="cta-sign-up"
                    fullInMobile={false}
                />
                <Button
                    text={Copydeck.createAccountAlreadyAPartnerAlt}
                    type="borderless"
                    onClick={() => navigate(CONSTANTS.SCREEN.LOGIN)}
                    className="cta-sign-in"
                    fullInMobile={false}
                />
            </div>
        </div>
    );
};

export default CreateAccountLandingPage;
