export const AnimatedCheckmarkIcon = () => {
    return (
        <svg
            id="animated-svg"
            className="run-animation"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 193.3 193.3"
        >
            <polyline className="stroke-1 animated" points="46.9 101.4 76.9 131.4 146.4 61.9" />
        </svg>
    );
};
