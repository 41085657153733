import * as React from 'react';
import { SvgProps } from '../../SvgProps';

export const LeafIcon: React.FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="33"
            height="32"
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clip-path="url(#clip0_7622_61175)">
                <path
                    d="M8.26363 24.0241C2.27513 14.0478 10.2644 4.07157 27.2257 5.06907C28.2236 22.0291 18.2436 30.0103 8.26363 24.0241Z"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    fill="#2EB67D"
                />
                <path
                    d="M20.2927 12L5.28711 27"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </g>

            <defs>
                <clipPath id="clip0_7622_61175">
                    <rect x="0.285156" width="32.012" height="32" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
export default LeafIcon;
