import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const TwitterIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <g clipPath="url(#clip0_9137_2551)">
                <path
                    d="M17.1757 3.86523H19.9357L13.9058 10.7577L21 20.135H15.4455L11.0955 14.447L6.117 20.135H3.3555L9.8055 12.7625L3 3.86598H8.6955L12.6278 9.06498L17.1757 3.86523ZM16.2075 18.4835H17.7367L7.8645 5.43048H6.2235L16.2075 18.4835Z"
                    fill={props.fill}
                />
            </g>
            <defs>
                <clipPath id="clip0_9137_2551">
                    <rect width="18" height="18" fill="white" transform="translate(3 3)" />
                </clipPath>
            </defs>
        </svg>
    );
};
