import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const PiggyBankIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="40" height="41" viewBox="0 0 40 41" {...props} fill="none">
            <g clipPath="url(#clip0_3595_49057)">
                <path
                    d="M28.125 20.5C29.1605 20.5 30 19.6605 30 18.625C30 17.5895 29.1605 16.75 28.125 16.75C27.0895 16.75 26.25 17.5895 26.25 18.625C26.25 19.6605 27.0895 20.5 28.125 20.5Z"
                    fill={props.fill}
                />
                <path
                    d="M17.5 11.75H23.75"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M33.75 6.75H22.5"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M1.25 23C1.25 22.0054 1.64509 21.0516 2.34835 20.3483C3.05161 19.6451 4.00544 19.25 5 19.25"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M34.4281 15.5H35C35.663 15.5 36.2989 15.7634 36.7678 16.2322C37.2366 16.7011 37.5 17.337 37.5 18V23C37.5 23.663 37.2366 24.2989 36.7678 24.7678C36.2989 25.2366 35.663 25.5 35 25.5H33.75L30.9219 33.4203C30.8351 33.6633 30.6753 33.8735 30.4644 34.022C30.2535 34.1705 30.0017 34.2502 29.7438 34.25H27.7563C27.4983 34.2502 27.2465 34.1705 27.0356 34.022C26.8247 33.8735 26.6649 33.6633 26.5781 33.4203L25.9828 31.75H15.2672L14.6719 33.4203C14.5851 33.6633 14.4253 33.8735 14.2144 34.022C14.0035 34.1705 13.7517 34.2502 13.4938 34.25H11.5063C11.2483 34.2502 10.9965 34.1705 10.7856 34.022C10.5747 33.8735 10.4149 33.6633 10.3281 33.4203L8.28126 27.6875C6.16773 25.3862 4.99652 22.3745 5.00001 19.25C5.00001 15.9348 6.31697 12.7554 8.66117 10.4112C11.0054 8.06696 14.1848 6.75 17.5 6.75H22.5C24.9392 6.74929 27.3253 7.46226 29.3643 8.80103C31.4033 10.1398 33.006 12.0459 33.975 14.2844C34.1464 14.6817 34.2977 15.0874 34.4281 15.5Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3595_49057">
                    <rect width="40" height="40" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
