import { FC } from 'react';
import { SvgProps } from './SvgProps';

const ShootingStarIcon: FC<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="48"
            height="48"
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            fill="none"
        >
            <g clipPath="url(#clip0_4887_24641)">
                <path
                    d="M31.6505 5.08865L34.5005 11.7318C34.5709 11.8942 34.6842 12.0345 34.8282 12.1375C34.9722 12.2405 35.1415 12.3025 35.318 12.3168L42.6061 12.9374C42.7967 12.9497 42.9793 13.0185 43.1305 13.135C43.2818 13.2516 43.3949 13.4107 43.4553 13.5918C43.5156 13.773 43.5206 13.9681 43.4696 14.1521C43.4185 14.3361 43.3137 14.5008 43.1686 14.6249L37.6317 19.3443C37.4992 19.4573 37.4002 19.6044 37.3454 19.7696C37.2905 19.9349 37.2819 20.112 37.3205 20.2818L38.978 27.3055C39.0191 27.4919 39.0047 27.6862 38.9364 27.8645C38.8681 28.0427 38.7491 28.197 38.594 28.3082C38.4388 28.4194 38.2545 28.4826 38.0638 28.49C37.8731 28.4974 37.6844 28.4487 37.5211 28.3499L31.2548 24.6243C31.1021 24.5341 30.9279 24.4865 30.7505 24.4865C30.573 24.4865 30.3989 24.5341 30.2461 24.6243L23.9798 28.3499C23.8166 28.4487 23.6279 28.4974 23.4371 28.49C23.2464 28.4826 23.0621 28.4194 22.907 28.3082C22.7518 28.197 22.6328 28.0427 22.5645 27.8645C22.4963 27.6862 22.4818 27.4919 22.523 27.3055L24.188 20.2912C24.2265 20.1214 24.2179 19.9443 24.1631 19.779C24.1082 19.6138 24.0092 19.4667 23.8767 19.3537L18.3398 14.6249C18.1947 14.5008 18.0899 14.3361 18.0389 14.1521C17.9878 13.9681 17.9928 13.773 18.0532 13.5918C18.1136 13.4107 18.2266 13.2516 18.3779 13.135C18.5292 13.0185 18.7118 12.9497 18.9023 12.9374L26.1923 12.3205C26.3676 12.3043 26.5352 12.241 26.6774 12.1374C26.8197 12.0337 26.9313 11.8936 27.0005 11.7318L29.8505 5.08865C29.9271 4.91372 30.053 4.7649 30.2128 4.6604C30.3727 4.5559 30.5595 4.50024 30.7505 4.50024C30.9414 4.50024 31.1283 4.5559 31.2881 4.6604C31.4479 4.7649 31.5739 4.91372 31.6505 5.08865Z"
                    stroke={props.fill}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M15 22.5L4.5 33"
                    stroke={props.fill}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M18 33L7.5 43.5"
                    stroke={props.fill}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M31.5 33L21 43.5"
                    stroke={props.fill}
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_4887_24641">
                    <rect width="48" height="48" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ShootingStarIcon;
