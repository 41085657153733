import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const HangerIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="30"
            height="31"
            viewBox="0 0 30 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6.25 23.5505H23.75L15 16.5818L6.25 23.5505ZM12.6875 8.80054C12.625 8.9672 12.5208 9.10783 12.375 9.22241C12.2292 9.337 12.0521 9.39429 11.8438 9.39429C11.5729 9.39429 11.349 9.30575 11.1719 9.12866C10.9948 8.95158 10.9062 8.72762 10.9062 8.45679V8.26929C10.9062 8.20679 10.9271 8.14429 10.9688 8.08179C11.3229 7.29012 11.8646 6.6495 12.5938 6.15991C13.3229 5.67033 14.125 5.42554 15 5.42554C16.2083 5.42554 17.2396 5.84741 18.0938 6.69116C18.9479 7.53491 19.375 8.56095 19.375 9.76929C19.375 10.7901 19.0521 11.6912 18.4062 12.4724C17.7604 13.2537 16.9375 13.7901 15.9375 14.0818V14.9568L27.125 23.738C27.25 23.8422 27.3438 23.9568 27.4063 24.0818C27.4688 24.2068 27.5 24.3422 27.5 24.488C27.5 24.7589 27.4115 24.9828 27.2344 25.1599C27.0573 25.337 26.8333 25.4255 26.5625 25.4255H3.4375C3.16667 25.4255 2.94271 25.337 2.76562 25.1599C2.58854 24.9828 2.5 24.7589 2.5 24.488C2.5 24.3422 2.53125 24.2068 2.59375 24.0818C2.65625 23.9568 2.75 23.8422 2.875 23.738L14.0625 14.9568V13.238C14.0625 12.988 14.151 12.7693 14.3281 12.5818C14.5052 12.3943 14.7188 12.3005 14.9688 12.3005C15.6771 12.3005 16.276 12.0557 16.7656 11.5662C17.2552 11.0766 17.5 10.4776 17.5 9.76929C17.5 9.08179 17.2552 8.49845 16.7656 8.01929C16.276 7.54012 15.6875 7.30054 15 7.30054C14.5 7.30054 14.0417 7.43595 13.625 7.70679C13.2083 7.97762 12.8958 8.3422 12.6875 8.80054Z"
                fill={props.fill}
            />
        </svg>
    );
};
