import React, { FunctionComponent, useMemo, useState } from 'react';
import Button from '../../../Components/Button';
import { AccountFlowBaseProps } from '..';
import { useTranslation } from 'react-i18next';
import WhiteCard from '../../../Components/WhiteCard';
import '../stepBaseStyle.scss';
import './index.scss';
import { DoubleArrowDownIcon } from '../../../Icons/double-arrow-down-icon';
import ProgressBar from '../Components/ProgressBar';
import { CheckmarkIcon } from '../../../Icons/checkmark-icon';
import { theme } from '../../../Style/theme';
import Checkbox from '../../../Components/Checkbox';
import CustomTermsConditionsPage from '../../LegalText/CustomerTermsConditions';

export interface Step3Props extends AccountFlowBaseProps {}

const Step3: FunctionComponent<Step3Props> = ({
    onPrevClick,
    onNextClick,
    totalSteps,
    elementsAreFocusable,
}) => {
    const { t } = useTranslation();
    const [scrolled, setScrolled] = useState(false);
    const [scrollProgress, setScrollProgress] = useState(0);
    const [checkboxState, setCheckboxState] = useState(false);

    const handleAgreementScroll = (progress: number) => {
        setScrolled(true);
        setScrollProgress((prev) => {
            if (prev < progress) return progress;
            return prev;
        });
    };

    const titleFromScrolling = useMemo((): string => {
        if (scrollProgress < 20) return t('CreateAccount.step_3_title_1');
        if (scrollProgress < 40) return t('CreateAccount.step_3_title_2');
        if (scrollProgress < 60) return t('CreateAccount.step_3_title_3');
        if (scrollProgress < 80) return t('CreateAccount.step_3_title_4');

        return t('CreateAccount.step_3_title_5');
    }, [scrollProgress, t]);

    const BREAKPOINT_FINISH_SCROLL = 98;

    return (
        <div className="Step3">
            <div className="flex-container">
                <div className="left-container">
                    <WhiteCard padding={50} onScroll={handleAgreementScroll}>
                        <CustomTermsConditionsPage />
                        <div className="checkmark">
                            <CheckmarkIcon
                                width={105}
                                height={105}
                                fill={theme['surface-brand-rest-primary']}
                            />
                        </div>
                        <div className={`fade ${scrolled ? 'fade-down' : ''}`}>
                            <div className="scroll-down">
                                {t('scroll_down')}
                                <DoubleArrowDownIcon />
                            </div>
                        </div>
                    </WhiteCard>
                </div>

                <div className="right-container">
                    <h3 className="deprecated-text-title-large-bold text-surface-grey-primary">
                        {t('CreateAccount.step_3_subtitle')}
                    </h3>
                    <h1 className="deprecated-text-display-medium">{titleFromScrolling}</h1>

                    <div className="progress-container">
                        <ProgressBar progress={scrollProgress} />
                        <p className="deprecated-text-title-medium text-element-greys-tertiary">
                            {scrollProgress < BREAKPOINT_FINISH_SCROLL
                                ? t('CreateAccount.scroll_to_progress')
                                : t('CreateAccount.complete_accept')}
                        </p>
                    </div>

                    <div
                        className={`complete-container ${
                            scrollProgress > BREAKPOINT_FINISH_SCROLL ? 'complete' : ''
                        }`}
                    >
                        <div className="line" />

                        <Checkbox
                            label={t('CreateAccount.agree_with_terms')}
                            onChange={() => setCheckboxState((prev) => !prev)}
                            checked={checkboxState}
                        />

                        <Button
                            text={t('CreateAccount.complete_partner_account')}
                            type="primary"
                            onClick={onNextClick}
                            disabled={!checkboxState}
                            isFocusable={elementsAreFocusable}
                        />
                    </div>

                    <div className="bottom-section">
                        <div className="navigation-container">
                            <Button
                                type="borderless"
                                className="prev-btn cursor-pointer"
                                text={t('previous_step')}
                                onClick={onPrevClick}
                                isFocusable={elementsAreFocusable}
                            />
                        </div>
                        <p className="deprecated-text-title-medium text-element-greys-secondary">
                            {t('step')} 3/{totalSteps}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Step3;
