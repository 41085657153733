import Service from '..';
import i18n from 'i18next';

export interface JobLocation {
    country: string;
    countryCode: string;
    city: string;
    region: string;
    hidden: boolean;
}

export interface JobListing {
    title: string;
    shortcode: string;
    code: string;
    employment_type: string;
    telecommuting: boolean;
    department: null | string;
    url: string;
    shortlink: string;
    application_url: string;
    published_on: string;
    created_at: string;
    country: string;
    city: string;
    state: string;
    education: string;
    experience: string;
    function: string;
    industry: string;
    locations: JobLocation[];
}

export interface CompanyProfile {
    name: string;
    description: string;
    jobs: JobListing[];
}

class CareerService extends Service {
    public async getJobsListings(): Promise<CompanyProfile | null> {
        const response = await this.get(
            'https://apply.workable.com/api/v1/widget/accounts/460970',
            true
        );

        if (!response) {
            this.toast().error(i18n.t('generic_api_error') as string);
            return null;
        }

        if (!response.ok) {
            this.toast().error(i18n.t('generic_api_error') as string);
        }

        return response.json();
    }
}

export default CareerService;
