import React from 'react';
import { useTranslation } from 'react-i18next';
import './create-account-footer.scss';

const CreateAccountFooter = () => {
    const { t } = useTranslation();

    return (
        <div className="CreateAccountFooter">
            <div className="text-white">
                <div className="inner">
                    <span>
                        {t('copyright_footer')} {new Date().getFullYear()}
                    </span>
                </div>
            </div>
        </div>
    );
};

export default CreateAccountFooter;
