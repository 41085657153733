import React, { useState } from 'react';
import MemberOfTeam from './Components/member-of-team';

import TeamAayushBansalPicture from './.../../../../../../Assets/Img/Teams/team_aayush_bansal.webp';
import TeamAnaLeePicture from './.../../../../../../Assets/Img/Teams/team_ana_lee.webp';
import TeamEnyoKimPicture from './.../../../../../../Assets/Img/Teams/team_enyo_kim.webp';
import TeamMarinaKimPicture from './.../../../../../../Assets/Img/Teams/team_marina_kim.webp';
import TeamMinPPicture from './.../../../../../../Assets/Img/Teams/team_min_p.webp';
import TeamEsterChinPicture from './../../../../Assets/Img/Teams/team_ester_chin.webp';
import TeamChadPicture from './.../../../../../../Assets/Img/Teams/team_chad.webp';
import TeamLoliaEtomiPicture from './.../../../../../../Assets/Img/Teams/team_lolia_etomi.webp';
import TeamJuliaNamkoongPicture from './.../../../../../../Assets/Img/Teams/team_julia_namkoong.webp';

import TeamAayushBansalPictureReverse from './.../../../../../../Assets/Img/Teams/team_aayush_bansal-reverse.webp';
import TeamAnaLeePictureReverse from './.../../../../../../Assets/Img/Teams/team_ana_lee-reverse.webp';
import TeamEnyoKimPictureReverse from './.../../../../../../Assets/Img/Teams/team_enyo_kim-reverse.webp';
import TeamMarinaKimPictureReverse from './.../../../../../../Assets/Img/Teams/team_marina_kim-reverse.webp';
import TeamMinPPictureReverse from './.../../../../../../Assets/Img/Teams/team_min_p-reverse.webp';
import TeamEsterChinPictureReverse from './../../../../Assets/Img/Teams/team_ester_chin-reverse.webp';
import TeamChadPictureReverse from './.../../../../../../Assets/Img/Teams/team_chad-reverse.webp';
import TeamLoliaEtomiPictureReverse from './.../../../../../../Assets/Img/Teams/team_lolia_etomi-reverse.webp';
import TeamJuliaNamkoongPictureReverse from './.../../../../../../Assets/Img/Teams/team_julia_namkoong_reverse.webp';

import './index.scss';
import { useTranslation } from 'react-i18next';
import Copydeck from '../../../../i18n/Copydeck';
import WrapperSectionTitle from '../../../../Components/WrapperSection/title';

const AboutUsTeamsSectionPage = () => {
    const { t } = useTranslation();

    const MEMBER_OF_TEAM = [
        {
            image: TeamMinPPicture,
            reverseImage: TeamMinPPictureReverse,
            name: 'Minh P. Vo, Ph.D.',
            jobTitle: Copydeck.aboutUsPageTeam1MemberTitle,
            linkedinUrl: 'https://www.linkedin.com/in/minh-vo-75771417',
        },
        {
            image: TeamAnaLeePicture,
            reverseImage: TeamAnaLeePictureReverse,
            name: 'Ana Lee',
            jobTitle: Copydeck.aboutUsPageTeam2MemberTitle,
            linkedinUrl: 'https://www.linkedin.com/in/ana-lee-0071931b/',
        },
        {
            image: TeamEnyoKimPicture,
            reverseImage: TeamEnyoKimPictureReverse,
            name: 'Enyo Kim',
            jobTitle: Copydeck.aboutUsPageTeam3MemberTitle,
            linkedinUrl: 'https://www.linkedin.com/in/enyokim/',
        },
        {
            image: TeamAayushBansalPicture,
            reverseImage: TeamAayushBansalPictureReverse,
            name: 'Aayush Bansal, Ph.D.',
            jobTitle: Copydeck.aboutUsPageTeam6MemberTitle,
        },
        {
            image: TeamMarinaKimPicture,
            reverseImage: TeamMarinaKimPictureReverse,
            name: 'Marina Kim',
            jobTitle: Copydeck.aboutUsPageTeam5MemberTitle,
            linkedinUrl: 'https://www.linkedin.com/in/marinakim-design',
        },
        {
            image: TeamChadPicture,
            reverseImage: TeamChadPictureReverse,
            name: 'Chad Grant',
            jobTitle: Copydeck.aboutUsPageTeam8MemberTitle,
            linkedinUrl:
                'https://www.linkedin.com/in/chadgrant?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
        },
        {
            image: TeamJuliaNamkoongPicture,
            reverseImage: TeamJuliaNamkoongPictureReverse,
            name: 'Julia Namkoong',
            // TODO: ADD TRANSLATIONS HERE
            jobTitle: 'Head of Human Resources',
            linkedinUrl: 'https://www.linkedin.com/in/julianamkoong/',
        },
        {
            image: TeamLoliaEtomiPicture,
            reverseImage: TeamLoliaEtomiPictureReverse,
            name: 'Lolia Etomi',
            jobTitle: Copydeck.aboutUsPageTeam9MemberTitle,
            linkedinUrl: 'https://www.linkedin.com/in/loliaetomi/',
        },
        {
            image: TeamEsterChinPicture,
            reverseImage: TeamEsterChinPictureReverse,
            name: 'Esther Chin',
            jobTitle: Copydeck.aboutUsPageTeam7MemberTitle,
            linkedinUrl: 'https://www.linkedin.com/in/estherchinn/',
        },
    ];

    const [isBlocked, setIsBlocked] = useState(false);

    return (
        <div className="AboutUsTeamsSectionPage">
            <WrapperSectionTitle htmlTag="h2" className="title">
                {t('AboutUsPage.our_teams')}
            </WrapperSectionTitle>

            <div className="teams">
                {MEMBER_OF_TEAM.map((member) => {
                    return (
                        <MemberOfTeam
                            key={member.name}
                            image={member.image}
                            name={member.name}
                            jobTitle={member.jobTitle}
                            imageReverse={member.reverseImage}
                            setIsBlocked={setIsBlocked}
                            isBlocked={isBlocked}
                        />
                    );
                })}
            </div>

            <div className="note text-body">{t('AboutUsPage.sub_header_abous_us')}</div>
        </div>
    );
};

export default AboutUsTeamsSectionPage;
