import { makeAutoObservable, observable } from 'mobx';
import CONSTANTS from '../Constants';
import Copydeck from '../i18n/Copydeck';

export interface CookieInfo {
    name: string;
    value?: string;
    expires?: string;
    domain?: string;
    path?: string;
    secure?: boolean;
    httpOnly?: boolean;
    sameSite?: 'Strict' | 'Lax' | 'None';
    description?: string;
}

class CookiesStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable
    public modalIsVisible: boolean = false;

    @observable
    public modalDetailIsVisible: boolean = false;

    // handle cookies banner
    private _hideCookiesBanner = () => {
        this.modalIsVisible = false;
    };

    private _showCookiesBanner = () => {
        this.modalIsVisible = true;
    };

    // handle cookies detail modal
    private _hideCookiesDetailModal = () => {
        this.modalDetailIsVisible = false;
    };

    private _showCookiesDetailModal = () => {
        this.modalDetailIsVisible = true;
    };

    public openCookiesDetailModal = () => {
        this._hideCookiesBanner();
        this._showCookiesDetailModal();
    };

    public closeCookiesDetailModal = () => {
        this._hideCookiesDetailModal();
        this._showCookiesBanner();
    };

    public run = () => {
        const cookieHasAccepted = this.checkIfCookiesAccepted();

        if (!cookieHasAccepted) this._showCookiesBanner();
    };

    private checkIfCookiesAccepted = () => {
        const cookiesAccepted = localStorage.getItem(CONSTANTS.LOCALSTORAGE_KEY.COOKIES_ACCEPTED);

        if (cookiesAccepted === 'true') return true;

        return false;
    };

    public responseToCookies = ({
        necessary,
        performance,
    }: {
        necessary?: boolean;
        performance?: boolean;
    }) => {
        localStorage.setItem(CONSTANTS.LOCALSTORAGE_KEY.COOKIES_ACCEPTED, 'true');

        localStorage.setItem(
            CONSTANTS.LOCALSTORAGE_KEY.COOKIES_NECESSARY_ACCEPTED,
            necessary ? 'true' : 'false'
        );
        localStorage.setItem(
            CONSTANTS.LOCALSTORAGE_KEY.COOKIES_PERFORMANCE_ACCEPTED,
            performance ? 'true' : 'false'
        );

        this._hideCookiesBanner();
        this._hideCookiesDetailModal();
    };

    public getAllCookies(): {
        necessaryCookies: CookieInfo[];
        performanceCookies: CookieInfo[];
    } {
        const necessaryCookies: CookieInfo[] = [
            {
                name: 'cookie_consent',
                value: 'true',
                expires: '6 ' + Copydeck.month,
                domain: window.location.hostname,
                description: Copydeck.emailConfirmationFlowCookieConsentDetail,
            },
        ];

        const performanceCookies: CookieInfo[] = [
            {
                name: '_ga',
                domain: 'google.com',
                description: Copydeck.emailConfirmationFlowCookieGoogleAnalyticsDetail,
                expires: '13 ' + Copydeck.month,
            },
            {
                name: '_gid',
                domain: 'google.com',
                description: Copydeck.emailConfirmationFlowCookieGoogleAnalyticsIdDetail,
                expires: '1 ' + Copydeck.day,
            },
        ];

        return {
            necessaryCookies,
            performanceCookies,
        };
    }

    public getCurrentCookiesConsent(): {
        necessary: boolean;
        performance: boolean;
    } {
        const necessaryAccepted = localStorage.getItem(
            CONSTANTS.LOCALSTORAGE_KEY.COOKIES_NECESSARY_ACCEPTED
        );
        const performanceAccepted = localStorage.getItem(
            CONSTANTS.LOCALSTORAGE_KEY.COOKIES_PERFORMANCE_ACCEPTED
        );

        return {
            necessary: necessaryAccepted === 'true',
            performance: performanceAccepted === 'true',
        };
    }
}

export default CookiesStore;
