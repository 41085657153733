import { FunctionComponent } from 'react';
import { SvgProps } from '../../Icons/SvgProps';

export const SwitchCheckIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="15"
            height="16"
            viewBox="0 0 15 16"
            {...props}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_6730_739)">
                <path
                    d="M2.34375 8.9375L5.625 12.2188L13.125 4.71875"
                    stroke="white"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_6730_739">
                    <rect width="15" height="15" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};
