import React from 'react';
import * as Select from '@radix-ui/react-select';

import { ChevronBottomIcon } from '../../../Icons/chevron-bottom-icon';
import styles from './styles/custom-select.module.scss';
import { CheckmarkIcon } from '../../../Icons/checkmark-icon';

interface CustomSelectProps {
    label: string;
    name: 'department' | 'region' | 'contractType';
    options: string[];
    placeholder: string;
    value: string;
    handleValueChange: (name: string, value: string) => void;
}

export const CustomSelect = ({
    handleValueChange,
    label,
    name,
    options,
    placeholder,
    value,
}: CustomSelectProps) => {
    return (
        <Select.Root
            value={value}
            onValueChange={(value) => {
                handleValueChange(name, value === 'all' ? '' : value);
            }}
        >
            <Select.Trigger className={styles.trigger} aria-label={label}>
                <Select.Value placeholder={placeholder} aria-label={value}>
                    {value ? `${label} (1)` : label}
                </Select.Value>
                <Select.Icon className={styles.icon}>
                    <ChevronBottomIcon className={styles.chevron} fill="#2B2B2B" />
                </Select.Icon>
            </Select.Trigger>
            <Select.Portal>
                <Select.Content
                    className={styles.content}
                    position="popper"
                    side="bottom"
                    avoidCollisions={false}
                >
                    <Select.Viewport>
                        <Select.Group>
                            <Select.Separator className={styles.separator} />
                            <Select.Item key="placeholder" className={styles.item} value="all">
                                <Select.ItemText>All {label}s</Select.ItemText>
                            </Select.Item>
                            {options.map((option) => (
                                <Select.Item key={option} className={styles.item} value={option}>
                                    <Select.ItemText>{option}</Select.ItemText>
                                    <Select.ItemIndicator className={styles.itemIndicator}>
                                        <CheckmarkIcon width={20} height={20} fill="#2b2b2b" />
                                    </Select.ItemIndicator>
                                </Select.Item>
                            ))}
                        </Select.Group>
                    </Select.Viewport>
                </Select.Content>
            </Select.Portal>
        </Select.Root>
    );
};
