import React from 'react';
import './index.scss';
import CONSTANTS from '../../../../Constants';
import { TLEADER } from '../../../../Constants/type';
import { useTranslation } from 'react-i18next';
import CardLeader from './Components/card-leader';
import WrapperSectionTitle from '../../../../Components/WrapperSection/title';

const AboutUsSectionLeaders = () => {
    const { t } = useTranslation();

    return (
        <div className="AboutUsSectionLeaders">
            <WrapperSectionTitle htmlTag="h2">{t('AboutUsPage.our_leaders')}</WrapperSectionTitle>

            <div className="leaders">
                {CONSTANTS.LEADERS.map((leader: TLEADER) => {
                    return <CardLeader leader={leader} key={leader.fullName} />;
                })}
            </div>
        </div>
    );
};

export default AboutUsSectionLeaders;
