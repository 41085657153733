import React from 'react';
import ExperienceHero from './Sections/Hero';
import ExperienceHowItWorks from './Sections/HowItWorks';
import Advantages from './Sections/Advantages';
import ExperienceBenefits from './Sections/Benefits';
import TryOn from './Sections/TryOn';
import ExperienceRevolution from './Sections/Revolution';
import ExperienceFlow from './Sections/ExperienceFlow';
import AccurateSizingAndFit from './Sections/AccurateSizingAndFit';
import './index.scss';

const ExperiencePage = () => {
    return (
        <div className="ExperiencePage">
            <ExperienceHero />
            <ExperienceHowItWorks />
            <TryOn />
            <ExperienceBenefits />
            <Advantages />
            <AccurateSizingAndFit />
            <ExperienceFlow />
            <ExperienceRevolution />
        </div>
    );
};

export default ExperiencePage;
