import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import WrapperSection from '../../../../Components/WrapperSection';
import { useStores } from '../../../../Hooks/use-stores';

const ExperienceHero = () => {
    const { t } = useTranslation();
    const { languageStore } = useStores();

    return (
        <div className="ExperienceHero">
            <WrapperSection padding="80" paddingInMobile="80" paddingHorizontal="16">
                <WrapperSection.Title
                    className={`capitalize -lng-${languageStore.currentLanguage?.toLocaleLowerCase()}`}
                    htmlTag="h1"
                >
                    {t('WhatIsSpreeAI.what_is_spree_ai_hero_title')}{' '}
                    <div className="sliding-words-wrapper">
                        <div className="sliding-words gradient-text text-main-header">
                            <span>{t('WhatIsSpreeAI.what_is_spree_ai_hero_title_3')}</span>
                            <span>{t('WhatIsSpreeAI.what_is_spree_ai_hero_title_2')}</span>
                            <span>{t('WhatIsSpreeAI.what_is_spree_ai_hero_title_1')}</span>
                        </div>
                    </div>
                </WrapperSection.Title>
                <div className="text-body text-element-greys-secondary subTitle">
                    {t('WhatIsSpreeAI.what_is_spree_ai_hero_subtitle')}
                </div>
            </WrapperSection>
        </div>
    );
};

export default ExperienceHero;
