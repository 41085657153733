import { SvgProps } from './SvgProps';

const EnvelopeOutlinedIcon: React.FC<SvgProps> = ({ ...props }) => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" {...props} fill="none">
            <g clipPath="url(#clip0_6378_12510)">
                <path
                    d="M49 12.25L28 31.5L7 12.25"
                    stroke={props.fill ?? '#62C89D'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M7 12.25H49V42C49 42.4641 48.8156 42.9092 48.4874 43.2374C48.1592 43.5656 47.7141 43.75 47.25 43.75H8.75C8.28587 43.75 7.84075 43.5656 7.51256 43.2374C7.18437 42.9092 7 42.4641 7 42V12.25Z"
                    stroke={props.fill ?? '#62C89D'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M24.1828 28L7.54028 43.2556"
                    stroke={props.fill ?? '#62C89D'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M48.4596 43.2556L31.8171 28"
                    stroke={props.fill ?? '#62C89D'}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_6378_12510">
                    <rect width="56" height="56" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default EnvelopeOutlinedIcon;
