import React from 'react';

export interface LayoutPolicyBasicTextProps {
    text: string;
}

const LayoutPolicyBasicText: React.FC<LayoutPolicyBasicTextProps> = ({ text }) => {
    return (
        <p
            className="LayoutPolicyBasicText deprecated-text-body-large"
            dangerouslySetInnerHTML={{
                __html: text,
            }}
        />
    );
};

export default LayoutPolicyBasicText;
