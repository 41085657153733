import Button from '../../../Components/Button';
import CONSTANTS from '../../../Constants';

import { FadeUp } from './FadeUp';
import { Link } from '../../../Components/Link';
import careersHero from '../../../Assets/Img/Careers/careers_page_hero.webp';
import careersHeroMobile from '../../../Assets/Img/Careers/inclusion_diversity_mobile.png';

import sharedStyles from '../index.module.scss';
import styles from './styles/hero.module.scss';

export const Hero = () => {
    return (
        <section className={styles.container}>
            <picture>
                <source media="(max-width: 550px)" srcSet={careersHeroMobile} />
                <img className={styles.heroImage} src={careersHero} alt="Careers Hero" />
            </picture>
            <div className={styles.contentOverlay}>
                <div>
                    <FadeUp delay={0.1}>
                        <h1>Join SpreeAI to Shape the Future of Fashion and AI</h1>
                    </FadeUp>
                    <FadeUp delay={0.2}>
                        <p className={sharedStyles.body1}>
                            At SpreeAI, we believe in the power of innovation, creativity, and
                            collaboration. Our mission is to revolutionize the fashion industry by
                            creating innovative technology solutions that drive real impact.
                        </p>
                    </FadeUp>
                    <FadeUp delay={0.3}>
                        <Link pathConfig={{ where: CONSTANTS.SCREEN.JOBS }}>
                            <Button type="primary" text="View Open Positions" />
                        </Link>
                    </FadeUp>
                </div>
            </div>
        </section>
    );
};
