import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

interface ArrowIconProps extends SvgProps {
    direction: 'left' | 'right' | 'up' | 'down';
}

export const ArrowIcon: FunctionComponent<ArrowIconProps> = ({ ...props }) => {
    const getRotation = () => {
        switch (props.direction) {
            case 'left':
                return 'rotate(0)';
            case 'right':
                return 'rotate(180deg)';
            case 'up':
                return 'rotate(90deg)';
            case 'down':
                return 'rotate(-90deg)';
            default:
                return 'rotate(0)';
        }
    };

    return (
        <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            style={{
                transform: getRotation(),
            }}
            {...props}
        >
            <path
                d="M5.67969 13.6455C5.67969 13.9092 5.79395 14.1729 5.99609 14.3662L11.7969 20.1582C12.0078 20.3604 12.2363 20.457 12.4912 20.457C13.0449 20.457 13.4492 20.0615 13.4492 19.5254C13.4492 19.2441 13.3438 19.0068 13.1592 18.8311L11.1816 16.8271L8.63281 14.498L10.6807 14.6211H21.333C21.9131 14.6211 22.3174 14.2168 22.3174 13.6455C22.3174 13.0654 21.9131 12.6611 21.333 12.6611H10.6807L8.6416 12.7842L11.1816 10.4551L13.1592 8.45117C13.3438 8.27539 13.4492 8.03809 13.4492 7.75684C13.4492 7.2207 13.0449 6.8252 12.4912 6.8252C12.2363 6.8252 11.999 6.92188 11.7705 7.1416L5.99609 12.916C5.79395 13.1094 5.67969 13.373 5.67969 13.6455Z"
                fill={props.fill}
            />
        </svg>
    );
};
