import { SvgProps } from './SvgProps';

export const MapIcon = ({ ...props }: SvgProps) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill={props.fill}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16 18.6667C18.9413 18.6667 21.3333 16.2747 21.3333 13.3334C21.3333 10.3921 18.9413 8.00008 16 8.00008C13.0587 8.00008 10.6667 10.3921 10.6667 13.3334C10.6667 16.2747 13.0587 18.6667 16 18.6667ZM16 10.6667C17.4707 10.6667 18.6667 11.8627 18.6667 13.3334C18.6667 14.8041 17.4707 16.0001 16 16.0001C14.5293 16.0001 13.3333 14.8041 13.3333 13.3334C13.3333 11.8627 14.5293 10.6667 16 10.6667Z"
                fill={props.fill}
            />
            <path
                d="M15.2267 29.0854C15.4524 29.2466 15.7227 29.3332 16 29.3332C16.2773 29.3332 16.5477 29.2466 16.7734 29.0854C17.1787 28.7987 26.7054 21.9201 26.6667 13.3334C26.6667 7.45208 21.8814 2.66675 16 2.66675C10.1187 2.66675 5.33337 7.45208 5.33337 13.3267C5.2947 21.9201 14.8214 28.7987 15.2267 29.0854ZM16 5.33341C20.412 5.33341 24 8.92142 24 13.3401C24.028 19.2574 18.1494 24.5707 16 26.3134C13.852 24.5694 7.97204 19.2547 8.00004 13.3334C8.00004 8.92141 11.588 5.33341 16 5.33341Z"
                fill={props.fill}
            />
        </svg>
    );
};
