import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { useStores } from '../../../Hooks/use-stores';
import UseServices from '../../../Hooks/use-services';
import { ArticleDto } from '../../../Services/Blog';

interface UseGetArticleById {
    articleId: string | undefined;
}

export const useGetArticleById = ({ articleId }: UseGetArticleById) => {
    const [article, setArticle] = useState<ArticleDto | null>(null);
    const [video, setVideo] = useState<{
        rawData?: { html: string };
    } | null>(null);

    const { blogService } = UseServices();
    const { loadingStore, languageStore } = useStores();
    const { t } = useTranslation();

    const getDetailOnArticle = async () => {
        loadingStore.addLoading();

        if (!articleId) {
            toast.error(t('BlogPage.fetch_error') as string);
            return;
        }

        try {
            const response = await blogService.getOneArticle(
                articleId,
                languageStore.currentLanguage || 'en'
            );

            if (!response) {
                toast.error(t('BlogPage.fetch_error') as string);
                return;
            }

            setArticle(response.data);
        } catch (error) {
            console.error(error);
        } finally {
            loadingStore.removeLoading();
        }
    };

    useEffect(() => {
        getDetailOnArticle();
    }, [articleId, languageStore.currentLanguage]);

    useEffect(() => {
        if (!article) {
            return;
        }

        setVideo(JSON.parse(article.attributes.oembed));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [article?.id]);

    return {
        article,
        video,
    };
};
