import LayoutPolicy from '../../../Layout/Policy/layout-policy';
import Copydeck from '../../../i18n/Copydeck';

const BiometricConsent = () => {
    return (
        <LayoutPolicy title={Copydeck.biometricPolicyBiometicPolicyTitle}>
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.biometricPolicyBiometricText1} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.biometricPolicyBiometricText2} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.biometricPolicyBiometricText3} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.biometricPolicyBiometricText4} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.biometricPolicyBiometricText5} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.biometricPolicyBiometricText6} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.biometricPolicyBiometricText7} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.biometricPolicyBiometricText8} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.biometricPolicyBiometricText9} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.biometricPolicyBiometricText10} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.biometricPolicyBiometricText11} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.biometricPolicyBiometricText12} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.biometricPolicyBiometricText13} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.biometricPolicyBiometricText14} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.biometricPolicyBiometricText15} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.biometricPolicyBiometricText16} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.biometricPolicyBiometricText17} />
            <LayoutPolicy.Jump />

            <LayoutPolicy.BasicText text={Copydeck.biometricPolicyBiometricText18} />
            <LayoutPolicy.Jump />
        </LayoutPolicy>
    );
};

export default BiometricConsent;
