import classNames from 'classnames';

import { ArrowIcon } from '../../../Icons/arrow-icon';

import styles from '../styles/custom-buttons.module.scss';

interface CustomButtonsProps {
    handleNext: () => void;
    handlePrev: () => void;
    theme: 'dark' | 'light';
}

export const CustomButtons = ({ handleNext, handlePrev, theme }: CustomButtonsProps) => {
    const buttonClasses = classNames(styles.sliderButton, {
        [styles.lightModeButton]: theme === 'light',
        [styles.darkModeButton]: theme === 'dark',
    });

    return (
        <div className={styles.sliderButtons}>
            <button
                className={buttonClasses}
                onClick={handlePrev}
                aria-labelledby="prev-button-label"
            >
                <span id="prev-button-label" hidden>
                    Previous page
                </span>
                <ArrowIcon direction="left" />
            </button>
            <button
                className={buttonClasses}
                onClick={handleNext}
                aria-labelledby="next-button-label"
            >
                <span id="next-button-label" hidden>
                    Next page
                </span>
                <ArrowIcon direction="right" />
            </button>
        </div>
    );
};
