import React from 'react';
import { BlockDto } from '../../../Services/Blog';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './index.scss';

interface RichTextProps {
    block: BlockDto;
}

const RichText: React.FC<RichTextProps> = ({ block }) => {
    return (
        <Markdown className="RichText" remarkPlugins={[remarkGfm]}>
            {block.body}
        </Markdown>
    );
};

export default RichText;
