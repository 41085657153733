import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import '../stepBaseStyle.scss';
import './index.scss';
import Button from '../../../Components/Button';
import CONSTANTS from '../../../Constants';
import UseServices from '../../../Hooks/use-services';
import { useStores } from '../../../Hooks/use-stores';
import { AccountFlowBaseProps } from '..';
import useCustomNavigation from '../../../Hooks/use-custom-navigation';
import WhiteCard from '../../../Components/WhiteCard';
import EnvelopeOutlinedIcon from '../../../Icons/envelope-outlined-icon';

interface Step4Props extends AccountFlowBaseProps {
    email: string;
}

const Step4Email: FunctionComponent<Step4Props> = ({ email }) => {
    const { t } = useTranslation();
    const navigate = useCustomNavigation();
    const { accountService } = UseServices();
    const { loadingStore } = useStores();

    const onClickResendEmail = async () => {
        loadingStore.addLoading();
        try {
            await accountService.resendConfirmationEmail({
                email,
            });
        } catch (error) {
            console.error(error);
        } finally {
            loadingStore.removeLoading();
        }
    };

    const onClickBackHonePage = () => {
        navigate(CONSTANTS.SCREEN.HOME);
    };

    return (
        <div className="Step4Email stepBaseStyle">
            <div className="whitecard-container">
                <WhiteCard padding={80}>
                    <div className="wrapper-icon">
                        <EnvelopeOutlinedIcon />
                    </div>

                    <div className="text text-body-large-primary-font-bold">
                        {t('EmailConfirmationFlow.email_confirmation_flow_title')}
                    </div>
                    <div className="text text-body text-element-greys-secondary">
                        {t('EmailConfirmationFlow.email_confirmation_flow_subtitle')}
                    </div>

                    <div className="spacer"></div>

                    <div className="text text-element-greys-secondary-alt">
                        {t('EmailConfirmationFlow.email_confirmation_flow_no_email')}
                    </div>

                    <Button text={t('resend_email')} type="primary" onClick={onClickResendEmail} />
                    <Button
                        type="borderless"
                        onClick={onClickBackHonePage}
                        className="back-to-home-page"
                        text={t('back_to_home_page')}
                    />
                </WhiteCard>
            </div>
        </div>
    );
};

export default Step4Email;
