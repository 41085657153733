import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import WrapperSectionTitle from '../../../../Components/WrapperSection/title';

const AboutUsSectionHero = () => {
    const { t } = useTranslation();

    return (
        <div className="AboutUsSectionHero">
            <div className="row">
                <WrapperSectionTitle htmlTag="h2" className="title capitalize">
                    {t('AboutUsPage.aboutus_page_title')}
                    <span className="subtitle gradient-text capitalize">
                        {' '}
                        {t('AboutUsPage.aboutus_page_title_p2')}
                    </span>
                </WrapperSectionTitle>
                <div className="paragraph text-body text-element-greys-secondary">
                    {t('AboutUsPage.aboutus_page_sub_title')}
                </div>
            </div>
        </div>
    );
};

export default AboutUsSectionHero;
