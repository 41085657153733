import Service from '..';
import i18n from 'i18next';

class ContactUsService extends Service {
    public async sendContactUsMessage(request: {
        first_name: string;
        last_name: string;
        phone: string;
        email: string;
        company_name: string;
        subject: string;
        message: string;
    }): Promise<boolean> {
        const response = await this.post('v1/user/contact-us/json', request);

        if (response) {
            this.toastNotification(
                response,
                i18n.t('contact_us_toast_success') as string,
                i18n.t('contact_us_toast_error') as string
            );
        }

        return response?.status === 204;
    }
}

export default ContactUsService;
