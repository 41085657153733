import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const LinkedinIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.57143 3C4.88944 3 4.23539 3.27092 3.75315 3.75315C3.27092 4.23539 3 4.88944 3 5.57143V18.4286C3 19.1106 3.27092 19.7646 3.75315 20.2468C4.23539 20.7291 4.88944 21 5.57143 21H18.4286C19.1106 21 19.7646 20.7291 20.2468 20.2468C20.7291 19.7646 21 19.1106 21 18.4286V5.57143C21 4.88944 20.7291 4.23539 20.2468 3.75315C19.7646 3.27092 19.1106 3 18.4286 3H5.57143ZM6.98829 8.52471C7.39577 8.52471 7.78657 8.36284 8.0747 8.0747C8.36284 7.78657 8.52471 7.39577 8.52471 6.98829C8.52471 6.5808 8.36284 6.19 8.0747 5.90187C7.78657 5.61373 7.39577 5.45186 6.98829 5.45186C6.5808 5.45186 6.19 5.61373 5.90187 5.90187C5.61373 6.19 5.45186 6.5808 5.45186 6.98829C5.45186 7.39577 5.61373 7.78657 5.90187 8.0747C6.19 8.36284 6.5808 8.52471 6.98829 8.52471ZM8.274 18.1881V9.72943H5.70257V18.1881H8.274ZM9.98143 9.72943H12.5529V10.8621C12.9321 10.2681 13.7653 9.46843 15.3146 9.46843C17.1634 9.46843 18.1676 10.6937 18.1676 13.0247C18.1676 13.1366 18.1779 13.647 18.1779 13.647V18.1869H15.6064V13.6483C15.6064 13.0247 15.4753 11.7994 14.0893 11.7994C12.702 11.7994 12.585 13.3397 12.5529 14.3477V18.1869H9.98143V9.72943Z"
                fill={props.fill}
            />
        </svg>
    );
};
