import { SvgProps } from './SvgProps';

export const TimeFiveIcon = ({ ...props }: SvgProps) => {
    return (
        <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill={props.fill}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.0001 2.66675C8.64808 2.66675 2.66675 8.64808 2.66675 16.0001C2.66675 23.3521 8.64808 29.3334 16.0001 29.3334C23.3521 29.3334 29.3334 23.3521 29.3334 16.0001C29.3334 8.64808 23.3521 2.66675 16.0001 2.66675ZM16.0001 26.6667C10.1187 26.6667 5.33341 21.8814 5.33341 16.0001C5.33341 10.1187 10.1187 5.33341 16.0001 5.33341C21.8814 5.33341 26.6667 10.1187 26.6667 16.0001C26.6667 21.8814 21.8814 26.6667 16.0001 26.6667Z"
                fill={props.fill}
            />
            <path
                d="M17.3334 9.33342H14.6667V16.5521L19.0574 20.9427L20.9427 19.0574L17.3334 15.4481V9.33342Z"
                fill={props.fill}
            />
        </svg>
    );
};
