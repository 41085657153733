import React from 'react';
import './index.scss';
import { useTranslation } from 'react-i18next';
import WhiteCard from '../../../Components/WhiteCard';
import { EmailSentIcon } from '../../../Icons/email-sent-icon';
import Button from '../../../Components/Button';
import CONSTANTS from '../../../Constants';
import useCustomNavigation from '../../../Hooks/use-custom-navigation';

const InstructionsSent = () => {
    const { t } = useTranslation();
    const navigate = useCustomNavigation();

    return (
        <div className="InstructionsSent">
            <WhiteCard>
                <EmailSentIcon />
                <h1 className="deprecated-text-headline-small">
                    {t('ForgotPassword.reset_instructions_sent')}
                </h1>
                <p className="deprecated-text-title-medium text-surface-grey-primary">
                    {t('ForgotPassword.reset_instructions_sent_paragraph')}
                </p>
                <Button
                    text={t('login')}
                    type="primary"
                    onClick={() => navigate(CONSTANTS.SCREEN.LOGIN)}
                />
            </WhiteCard>
        </div>
    );
};

export default InstructionsSent;
