import { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const AiIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="45" height="40" viewBox="0 0 45 40" fill="none" {...props}>
            <path
                d="M35.7699 10.1149L31.8544 8.43479C31.7393 8.38521 31.6399 8.29565 31.5698 8.17817C31.4996 8.0607 31.462 7.92096 31.462 7.77778C31.462 7.6346 31.4996 7.49486 31.5698 7.37738C31.6399 7.25991 31.7393 7.17035 31.8544 7.12077L35.7699 5.44066C35.8515 5.40583 35.9255 5.35069 35.9869 5.27906C36.0483 5.20744 36.0955 5.12106 36.1254 5.02594L37.5655 0.457825C37.608 0.323503 37.6848 0.207604 37.7854 0.125745C37.8861 0.0438859 38.0059 0 38.1286 0C38.2514 0 38.3711 0.0438859 38.4718 0.125745C38.5725 0.207604 38.6493 0.323503 38.6918 0.457825L40.1319 5.02594C40.1617 5.12106 40.209 5.20744 40.2704 5.27906C40.3318 5.35069 40.4058 5.40583 40.4874 5.44066L44.4029 7.12077C44.518 7.17035 44.6174 7.25991 44.6875 7.37738C44.7577 7.49486 44.7953 7.6346 44.7953 7.77778C44.7953 7.92096 44.7577 8.0607 44.6875 8.17817C44.6174 8.29565 44.518 8.38521 44.4029 8.43479L40.4874 10.1149C40.4058 10.1497 40.3318 10.2049 40.2704 10.2765C40.209 10.3481 40.1617 10.4345 40.1319 10.5296L38.6918 15.0977C38.6493 15.2321 38.5725 15.348 38.4718 15.4298C38.3711 15.5117 38.2514 15.5556 38.1286 15.5556C38.0059 15.5556 37.8861 15.5117 37.7854 15.4298C37.6848 15.348 37.608 15.2321 37.5655 15.0977L36.1254 10.5296C36.0955 10.4345 36.0483 10.3481 35.9869 10.2765C35.9255 10.2049 35.8515 10.1497 35.7699 10.1149Z"
                fill={props.fill}
            />
            <path
                d="M9.68478 27.5642L1.20114 23.724C0.951682 23.6106 0.736442 23.4059 0.584418 23.1374C0.432394 22.8689 0.350891 22.5495 0.350891 22.2222C0.350891 21.895 0.432394 21.5756 0.584418 21.307C0.736442 21.0385 0.951682 20.8338 1.20114 20.7205L9.68478 16.8803C9.86143 16.8007 10.0218 16.6746 10.1549 16.5109C10.2879 16.3472 10.3903 16.1497 10.455 15.9323L13.5752 5.49092C13.6673 5.18389 13.8336 4.91898 14.0517 4.73188C14.2699 4.54477 14.5294 4.44446 14.7953 4.44446C15.0612 4.44446 15.3208 4.54477 15.5389 4.73188C15.7571 4.91898 15.9234 5.18389 16.0155 5.49092L19.1357 15.9323C19.2004 16.1497 19.3028 16.3472 19.4358 16.5109C19.5688 16.6746 19.7292 16.8007 19.9059 16.8803L28.3895 20.7205C28.639 20.8338 28.8542 21.0385 29.0063 21.307C29.1583 21.5756 29.2398 21.895 29.2398 22.2222C29.2398 22.5495 29.1583 22.8689 29.0063 23.1374C28.8542 23.4059 28.639 23.6106 28.3895 23.724L19.9059 27.5642C19.7292 27.6438 19.5688 27.7699 19.4358 27.9336C19.3028 28.0973 19.2004 28.2947 19.1357 28.5121L16.0155 38.9536C15.9234 39.2606 15.7571 39.5255 15.5389 39.7126C15.3208 39.8997 15.0612 40 14.7953 40C14.5294 40 14.2699 39.8997 14.0517 39.7126C13.8336 39.5255 13.6673 39.2606 13.5752 38.9536L10.455 28.5121C10.3903 28.2947 10.2879 28.0973 10.1549 27.9336C10.0218 27.7699 9.86143 27.6438 9.68478 27.5642Z"
                fill={props.fill}
            />
        </svg>
    );
};
