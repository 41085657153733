import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from '../../Hooks/use-stores';
import { SupportedLanguage } from '../../Stores/LanguageStore';

interface WrapperImageProps {
    /**alt: Must be copydeck key for translations */
    alt: string;
    className?: string;
    style?: React.CSSProperties;
    width?: number;

    onMouseEnter?: () => void;
    onMouseLeave?: () => void;
    onClick?: () => void;
}

interface WrapperImageMultiLanguageProps extends WrapperImageProps {
    /**src: Provide the image path for each language, and if there is only one image intended for all languages, use the uniqueSrc. */
    src: {
        en: string;
        fr?: string;
        it?: string;
        es?: string;
    };
}

interface WrapperImageUniqueProps extends WrapperImageProps {
    /**uniqueSrc: Path for all languages */
    uniqueSrc: string;
}

const WrapperImage: React.FC<WrapperImageMultiLanguageProps | WrapperImageUniqueProps> = (
    props
) => {
    const { t } = useTranslation();
    const { languageStore } = useStores();
    const [currentLanguage, setCurrentLanguage] = React.useState<SupportedLanguage>(
        SupportedLanguage.EN
    );

    const getCurrentLanguageForImage = useCallback(() => {
        if (!languageStore?.currentLanguage) {
            return;
        }

        setCurrentLanguage(languageStore.currentLanguage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageStore?.currentLanguage]);

    React.useEffect(() => {
        if (!languageStore?.currentLanguage) {
            return;
        }

        getCurrentLanguageForImage();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [languageStore?.currentLanguage]);

    if ('uniqueSrc' in props) {
        return (
            <img
                src={props.uniqueSrc}
                alt={t(props.alt)}
                loading="lazy"
                className={props.className}
                style={props.style}
                width={props.width}
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
                onClick={props.onClick}
            />
        );
    }

    try {
        if (!(props.src as any)?.[(currentLanguage || SupportedLanguage.EN).toLocaleLowerCase()]) {
            throw new Error('No image found for the current language');
        }

        return (
            <img
                src={
                    (props.src as any)?.[
                        (currentLanguage || SupportedLanguage.EN).toLocaleLowerCase()
                    ]
                }
                alt={t(props.alt)}
                loading="lazy"
                className={props.className}
                style={props.style}
                width={props.width}
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
                onClick={props.onClick}
            />
        );
    } catch (error) {
        console.error('Error rendering image', error);
        return (
            <img
                src={(props.src as any)?.[SupportedLanguage.EN.toLocaleLowerCase()]}
                alt={t(props.alt)}
                loading="lazy"
                className={props.className}
                style={props.style}
                width={props.width}
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
                onClick={props.onClick}
            />
        );
    }
};

export default WrapperImage;
