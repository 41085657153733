import { action, observable, makeAutoObservable } from 'mobx';

class LoadingStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable loadingCount = 0;

    @observable isLoading(): boolean {
        return this.loadingCount > 0;
    }

    @action addLoading(): void {
        this.loadingCount++;
    }

    @action removeLoading(): void {
        if (this.loadingCount > 0) this.loadingCount--;
    }

    @action removeAllLoading(): void {
        this.loadingCount = 0;
    }
}

export default LoadingStore;
