import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const FeatureMultimediaIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg
            width="41"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M33.9258 6.47815H11.4258C10.7627 6.47815 10.1269 6.74154 9.65801 7.21038C9.18917 7.67922 8.92578 8.31511 8.92578 8.97815V11.4781H6.42578C5.76274 11.4781 5.12686 11.7415 4.65801 12.2104C4.18917 12.6792 3.92578 13.3151 3.92578 13.9781V31.4781C3.92578 32.1412 4.18917 32.7771 4.65801 33.2459C5.12686 33.7148 5.76274 33.9781 6.42578 33.9781H28.9258C29.5888 33.9781 30.2247 33.7148 30.6935 33.2459C31.1624 32.7771 31.4258 32.1412 31.4258 31.4781V28.9781H33.9258C34.5888 28.9781 35.2247 28.7148 35.6935 28.2459C36.1624 27.7771 36.4258 27.1412 36.4258 26.4781V8.97815C36.4258 8.31511 36.1624 7.67922 35.6935 7.21038C35.2247 6.74154 34.5888 6.47815 33.9258 6.47815ZM11.4258 8.97815H33.9258V18.7828L32.3523 17.211C32.1202 16.9788 31.8446 16.7946 31.5412 16.6689C31.2379 16.5432 30.9127 16.4785 30.5844 16.4785C30.256 16.4785 29.9309 16.5432 29.6275 16.6689C29.3242 16.7946 29.0486 16.9788 28.8164 17.211L25.6914 20.336L18.8164 13.461C18.3476 12.9925 17.712 12.7293 17.0492 12.7293C16.3865 12.7293 15.7508 12.9925 15.282 13.461L11.4258 17.3172V8.97815ZM28.9258 31.4781H6.42578V13.9781H8.92578V26.4781C8.92578 27.1412 9.18917 27.7771 9.65801 28.2459C10.1269 28.7148 10.7627 28.9781 11.4258 28.9781H28.9258V31.4781ZM33.9258 26.4781H11.4258V20.8531L17.0508 15.2281L24.8102 22.9875C25.0446 23.2218 25.3624 23.3534 25.6938 23.3534C26.0251 23.3534 26.3429 23.2218 26.5773 22.9875L30.5867 18.9781L33.9258 22.3188V26.4781ZM25.1758 13.3531C25.1758 12.9823 25.2857 12.6198 25.4918 12.3115C25.6978 12.0031 25.9906 11.7628 26.3333 11.6209C26.6759 11.479 27.0529 11.4418 27.4166 11.5142C27.7803 11.5865 28.1144 11.7651 28.3766 12.0273C28.6388 12.2895 28.8174 12.6236 28.8898 12.9874C28.9621 13.3511 28.925 13.7281 28.7831 14.0707C28.6411 14.4133 28.4008 14.7061 28.0925 14.9122C27.7841 15.1182 27.4216 15.2281 27.0508 15.2281C26.5535 15.2281 26.0766 15.0306 25.725 14.679C25.3733 14.3273 25.1758 13.8504 25.1758 13.3531Z"
                fill={props.fill}
            />
        </svg>
    );
};
