import React, { FunctionComponent, useState } from 'react';
import Button from '../../../Components/Button';
import { useTranslation } from 'react-i18next';
import '../stepBaseStyle.scss';
import './index.scss';
import { CheckmarkIcon } from '../../../Icons/checkmark-icon';
import { theme } from '../../../Style/theme';
import Checkbox from '../../../Components/Checkbox';
import './step3-mobile.scss';
import { Step3Props } from '.';
import CustomTermsConditionsPage from '../../LegalText/CustomerTermsConditions';

const Step3Mobile: FunctionComponent<Step3Props> = ({
    onPrevClick,
    onNextClick,
    elementsAreFocusable,
}) => {
    const { t } = useTranslation();
    const [checkboxState, setCheckboxState] = useState(false);

    return (
        <div className="Step3Mobile">
            <div className="top-section">
                <div className="navigation-container">
                    <Button
                        type="borderless"
                        className="prev-btn cursor-pointer"
                        text={t('previous_step')}
                        onClick={onPrevClick}
                        isFocusable={elementsAreFocusable}
                    />
                </div>
                <h3 className="deprecated-text-title-medium text-surface-grey-primary">
                    {t('CreateAccount.step_3_subtitle')}
                </h3>
                <h1 className="deprecated-text-headline-medium">
                    {t('CreateAccount.step_3_title_1')}
                </h1>
            </div>

            <CustomTermsConditionsPage />

            <div className="checkmark">
                <CheckmarkIcon
                    width={105}
                    height={105}
                    fill={theme['surface-brand-rest-primary']}
                />
            </div>

            <div className="bottom-section complete">
                <div className="inner">
                    <Checkbox
                        label={t('CreateAccount.agree_with_terms')}
                        onChange={() => setCheckboxState((prev) => !prev)}
                        checked={checkboxState}
                    />

                    <Button
                        text={t('CreateAccount.complete_partner_account')}
                        type="primary"
                        onClick={onNextClick}
                        disabled={!checkboxState}
                        isFocusable={elementsAreFocusable}
                    />

                    <Button
                        type="borderless"
                        className="prev-btn cursor-pointer"
                        text={t('previous_step')}
                        onClick={onPrevClick}
                        isFocusable={elementsAreFocusable}
                    />
                </div>
            </div>
        </div>
    );
};

export default Step3Mobile;
