import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const LaptopIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" {...props} fill="none">
            <path
                d="M32.7458 25.8929H7.25555M32.7458 25.8929V13.3334C32.7458 10.1907 32.7458 8.61937 31.7694 7.64306C30.7931 6.66675 29.2218 6.66675 26.0791 6.66675H13.9222C10.7795 6.66675 9.20817 6.66675 8.23186 7.64306C7.25555 8.61937 7.25555 10.1907 7.25555 13.3334V25.8929M32.7458 25.8929L35.6525 28.8931C35.7267 28.9697 35.7639 29.0081 35.7985 29.0461C36.3336 29.6337 36.6412 30.3931 36.6657 31.1875C36.6673 31.239 36.6673 31.2923 36.6673 31.3989C36.6673 31.6476 36.6673 31.772 36.6607 31.8769C36.5548 33.5523 35.2196 34.8876 33.5442 34.9934C33.4392 35.0001 33.3148 35.0001 33.0662 35.0001H6.93512C6.68645 35.0001 6.56212 35.0001 6.45712 34.9934C4.78173 34.8876 3.44648 33.5523 3.34062 31.8769C3.33398 31.772 3.33398 31.6476 3.33398 31.3989C3.33398 31.2923 3.33398 31.239 3.33558 31.1875C3.36014 30.3931 3.66769 29.6337 4.20276 29.0461C4.23744 29.0081 4.27462 28.9697 4.34878 28.8931L7.25555 25.8929"
                stroke={props.fill}
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M15.834 30.8333H24.1673"
                stroke={props.fill}
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M21.25 11.25C21.25 11.9404 20.6904 12.5 20 12.5C19.3096 12.5 18.75 11.9404 18.75 11.25C18.75 10.5596 19.3096 10 20 10C20.6904 10 21.25 10.5596 21.25 11.25Z"
                fill={props.fill}
            />
        </svg>
    );
};
