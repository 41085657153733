import React, { FunctionComponent } from 'react';
import { observer } from 'mobx-react';
import Logo from '../../Assets/Img/Color_Spree_Logo.webp';
import './global-loading-container.scss';
import { useStores } from '../../Hooks/use-stores';

const GlobalLoadingContainer: FunctionComponent = observer(() => {
    const { loadingStore } = useStores();
    return (
        <div className={`GlobalLoadingContainer ${loadingStore.isLoading() ? 'loading' : ''}`}>
            {loadingStore.isLoading() && (
                <div>
                    <img loading="lazy" className="logo" src={Logo} alt="SpreAI Logo" />
                </div>
            )}
        </div>
    );
});

export default GlobalLoadingContainer;
