import { useCallback, useEffect, useState } from 'react';
import TabElement from '../../../../Components/TabElement';
import './index.scss';
import UseServices from '../../../../Hooks/use-services';
import { CategoryDto } from '../../../../Services/Blog';

interface CategoryFilterProps {
    onChangeFilter: (filter: { value: string; label: string }) => void;
    defaultCurrentFilter: { value: string; label: string };
}

const CategoryFilter: React.FC<CategoryFilterProps> = ({
    onChangeFilter,
    defaultCurrentFilter,
}) => {
    const [categories, setCategories] = useState<CategoryDto[]>([]);
    const { blogService } = UseServices();

    const [currentFilter, setCurrentFilter] = useState(defaultCurrentFilter);

    useEffect(() => {
        if (!currentFilter) {
            return;
        }

        onChangeFilter(currentFilter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentFilter]);

    const getAllCategories = useCallback(async () => {
        try {
            const response = await blogService.getAllCategories();

            setCategories(
                response.data?.filter(
                    (category) => category?.attributes?.articles?.data?.length > 0
                )
            );
        } catch (error) {
            console.error(error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getAllCategories();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (categories.length === 0) {
        return null;
    }

    return (
        <div className="CategoryFilter">
            <div className="wrapper">
                <TabElement
                    isActive={currentFilter.value === 'all'}
                    label="All"
                    value="id"
                    onClick={() =>
                        setCurrentFilter({
                            value: 'all',
                            label: 'all',
                        })
                    }
                    key="id"
                    backgroundColor="#EBFAF3"
                    textColor="#2EB67D"
                />

                {categories.map(({ attributes, id }) => {
                    return (
                        <TabElement
                            isActive={Number(currentFilter.value) === Number(id)}
                            label={attributes?.name}
                            value={id}
                            onClick={() =>
                                setCurrentFilter({
                                    value: id,
                                    label: attributes.name,
                                })
                            }
                            key={id}
                            backgroundColor={attributes.backgroundColor || '#EBFAF3'}
                            textColor={attributes.primaryColor || '#2EB67D'}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default CategoryFilter;
