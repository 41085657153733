import React, { FunctionComponent } from 'react';
import { SvgProps } from './SvgProps';

export const RulerIcon: FunctionComponent<SvgProps> = ({ ...props }) => {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" {...props} fill="none">
            <g clipPath="url(#clip0_3597_20324)">
                <path
                    d="M21.2074 3.2933L3.79317 20.7076C3.40264 21.0981 3.40264 21.7313 3.79317 22.1218L10.379 28.7076C10.7695 29.0981 11.4027 29.0981 11.7932 28.7076L29.2075 11.2933C29.598 10.9028 29.598 10.2696 29.2075 9.87911L22.6217 3.2933C22.2311 2.90277 21.598 2.90277 21.2074 3.2933Z"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M16.5 8L20.5 12"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M12.5 12L16.5 16"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.5 16L12.5 20"
                    stroke={props.fill}
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_3597_20324">
                    <rect width="32" height="32" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default RulerIcon;
