import React from 'react';
import './index.scss';
import Button from '../../Components/Button';
import { useTranslation } from 'react-i18next';
import CONSTANTS from '../../Constants';
import WhiteCard from '../../Components/WhiteCard';
import useCustomNavigation from '../../Hooks/use-custom-navigation';

const NotFound404 = () => {
    const { t } = useTranslation();
    const navigate = useCustomNavigation();

    return (
        <div className="NotFound404">
            <h1 className="text-white">{CONSTANTS.ERROR_CODE.NOT_FOUND}</h1>
            <WhiteCard className="card" greenBorder>
                <h3 className="deprecated-text-headline-large">{t('page_not_found')}</h3>
                <p className="deprecated-text-body-large">{t('page_not_found_paragraph')}</p>
                <Button
                    text={t('back_to_home')}
                    type="primary"
                    onClick={() => navigate(CONSTANTS.SCREEN.HOME)}
                />
            </WhiteCard>
        </div>
    );
};

export default NotFound404;
