import React, { FunctionComponent, ReactNode } from 'react';
import Slider, { Settings } from 'react-slick';
import './index.scss';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

//https://react-slick.neostack.com/docs/example/simple-slider

interface CarouselProps {
    carouselElements: ReactNode[];
    centerMode: boolean;
    slidesToShow: number;
    infiniteScroll: boolean;
    showArrows?: boolean;
    showDots?: boolean;
    responsive?: { breakpoint: number; settings: Settings }[];
    autoplay?: boolean;
    autoplaySpeed?: number;
    onSlideChange?: (index: number) => void;
}

const Carousel: FunctionComponent<CarouselProps> = ({
    carouselElements,
    centerMode,
    slidesToShow,
    infiniteScroll,
    showArrows = false,
    showDots = true,
    responsive,
    autoplay,
    autoplaySpeed,
    onSlideChange,
}) => {
    const CustomArrow = (props: any) => {
        const { className, style, onClick } = props;
        return (
            <div
                className={className}
                style={{ ...style, display: 'block', background: 'green' }}
                onClick={onClick}
            />
        );
    };

    const afterChange = (i: number) => {
        if (onSlideChange) onSlideChange(i);
    };

    const settings: Settings = {
        dots: showDots,
        infinite: infiniteScroll,
        slidesToShow,
        slidesToScroll: 1,
        centerMode,
        arrows: showArrows,
        responsive,
        nextArrow: <CustomArrow />,
        prevArrow: <CustomArrow />,
        autoplay,
        autoplaySpeed,
        afterChange,
    };

    return (
        <div className="Carousel">
            <Slider {...settings}>
                {carouselElements.map((x, i) => (
                    <div key={i}>{x}</div>
                ))}
            </Slider>
        </div>
    );
};

export default Carousel;
